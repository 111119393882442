import {CSSTransitionsHelper} from "../../../../../../../../../shared/events/transitions/CSSTransitionsHelper";

const TRANS_STATES = {
    MOVING: 'level-moving',
    STILL: 'level-still',
}

const DISPLAY_CLASS = 'display-on'

const _buildConfig = (menuContainer, translationValue, useTransition, isTransitionCanceled, delay) => ({
    useTransition,
    isCanceledFunc: isTransitionCanceled,
    clearCssClasses: Object.values(TRANS_STATES),
    transitioningElement: menuContainer,
    stateElement: menuContainer,
    beginTransCssClass: TRANS_STATES.MOVING,
    beginTransFunction: () => {
        setTimeout(() => {
            menuContainer.classList.toggle(DISPLAY_CLASS, translationValue === 0)
            menuContainer.style.transform = 'translateX(' + translationValue + 'px)'
        }, delay ?? 0)
    },
    endTransCssClass: TRANS_STATES.STILL,
})

export const Tr_LevelMover = Object.freeze({

    INITIAL_STATE: TRANS_STATES.STILL,

    TRANS_STATES,

    /**
     * @param {HTMLElement} menuContainer
     * @param {int} translationValue
     * @param {boolean} useTransition
     * @param {TransitionCancellationFunc} isTransitionCanceled
     * @return {Promise}
     */
    moveFunc: (menuContainer, translationValue, useTransition, isTransitionCanceled, delay) =>
        CSSTransitionsHelper.execute(_buildConfig(menuContainer, translationValue, useTransition, isTransitionCanceled, delay))
})
