import {StateWithPath} from "../state/StateWithPath";
import {TreeActionExecutionLimited} from "./TreeActionExecutionLimited";
import {TreeExecutor} from "./TreeExecutor";
import {ifDebugNavigaton, navilog} from "../debug";
import {TAction} from "./TAction";

const DETERMINED_ACTION__START_NEW = 'start new'
const DETERMINED_ACTION__NONE = 'none (state already reached)';

class TActionExecutor {
    /**
     * @param {NavigationState} state
     * @param {TransitionCancellationFunc} isTransitionCanceledFunc
     * @param {function(function)} executeFunc
     * @param {NavigationDebugInfo} debugInfo
     */
    constructor(state, isTransitionCanceledFunc, executeFunc, debugInfo) {
        this._state = state
        this._isTransitionCanceledFunc = isTransitionCanceledFunc;
        this._limiter = new TreeActionExecutionLimited()
        this._executeFunc = executeFunc
        this._debugInfo = debugInfo
    }

    /**
     * @param {TAction} tAction
     * @return {Promise<void>}
     */
    async execute(tAction) {
        const determinedAction = this._determineStateOfAction(tAction)

        ifDebugNavigaton(() => logAction(this._debugInfo, tAction, determinedAction))

        if (determinedAction === DETERMINED_ACTION__NONE) {
            return
        }


        try {
            await this._startAction(tAction)
        } catch (e) {
            ifDebugNavigaton(() => logAction(this._debugInfo, tAction, '! not setting endstate:', e.message))
            return
        }

        if (this._isTransitionCanceledFunc()) {
            ifDebugNavigaton(() => logAction(this._debugInfo, tAction, '! was canceled, not setting endstate:'))
            return
        }

        ifDebugNavigaton(() => logAction(this._debugInfo, tAction, 'will set endstate'))
        this._setEndStates(tAction);
    }

    _determineStateOfAction(tAction) {
        if (!tAction.stateUpdates_afterExecution.length && !tAction.stateUpdates_beforeExecution.length) {
            return DETERMINED_ACTION__START_NEW
        }


        const isActionCompleted = !tAction.forceExecution && !tAction.stateUpdates_afterExecution.some(([path, wantedEndState]) => {
            return this._readPath(tAction, path) !== wantedEndState
        })

        if (isActionCompleted) {
            return DETERMINED_ACTION__NONE
        }

        return DETERMINED_ACTION__START_NEW
    }

    _setEndStates(tAction) {
        tAction.stateUpdates_afterExecution.forEach(([_path, _value]) => this._updateState(tAction, _path, _value))
    }

    /**
     * @param {TAction} tAction
     * @return {Promise}
     */
    _startAction(tAction) {
        tAction.stateUpdates_beforeExecution.forEach(([_path, _value]) => this._updateState(tAction, _path, _value))

        return this._executeAction(() => tAction.action(this._isTransitionCanceledFunc))
    }

    /**
     * @param {TAction} tAction
     * @param {string[]} path
     * @param {*} value
     */
    _updateState(tAction, path, value) {
        StateWithPath.setValue(this._state, [...tAction.basePath, ...path], value)
    }


    /**
     * @param {TAction} tAction
     * @param {string[]} path
     */
    _readPath(tAction, path) {
        return StateWithPath.getValue(this._state, [...tAction.basePath, ...path])

    }

    /**
     * @param {function(function)} wrappedAction
     * @returns {Promise}
     */
    _executeAction(wrappedAction) {
        return this._limiter.executeAction(() => this._executeFunc(wrappedAction))
    }
}

/**
 ä#@param {?NavigationDebugInfo} debugInfo
 * @param {NavigationState} state
 * @param {TransitionCancellationFunc} isTransitionCanceledFunc
 * @param {function(function)} executeFunc
 * @param {NavigationDebugInfo} debugInfo
 * @return {ExecuteActionFunc}
 */
export const createExecuteActionFunc = (state, isTransitionCanceledFunc, executeFunc, debugInfo) => {
    const actionExecutor = new TActionExecutor(state, isTransitionCanceledFunc, executeFunc, debugInfo)
    return (tAction) => actionExecutor.execute(tAction)
}

const
    logAction = (debugInfo, tAction, ...rest) => {
        if (!debugInfo) {
            return
        }
        const lengths = [35, 25]
        const descrParts = tAction.debugDescription.split('\t')
        let txt = ''
        for (let i = 0; i < descrParts.length; i++) {
            txt += i > 0 ? ' ' : ''
            txt += descrParts[i].padEnd(lengths.length > i ? lengths[i] : -1, '.')
        }

        navilog(debugInfo, 'ACTION', '\t', txt, ...rest)
    };
