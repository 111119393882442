'use strict'
import throwError from "../../libs/@elements/throw-error"
import {showNotification} from "../../libs/@elements/alert-notification"
import {addLoadingContainer, addSuccessContainer, removeLoadingContainer} from '../../scripts/loading';
import fetch from "../../libs/@elements/fetch"
import {handleTrackingOnPromise} from '../../scripts/tracking'
import {isParsleyForm, isValid} from "../../libs/@elements/parsley-bootstrap-validation";
import formDataEntries from 'form-data-entries';
import {breakPoint, openCart} from './header'
import {openCart as loadCart} from './cart-dropdown'
import {MOBILE_MAIN_MENUS__DROPDOWNS, Mobile_MainMenuTriggers} from "./navigation/mobile/MainMenu/Mobile_MainMenuTriggers";
import {DESKTOP_MAIN_MENUS__DROPDOWNS, Desktop_MainMenuTriggers} from "./navigation/desktop/MainMenu/Desktop_MainMenuTriggers";

let addToCartUrl = null;

export function init() {
    if(window['_addToCart']){
        addToCartUrl = window['_addToCart'].url || throwError("missing window['_addToCart'].url in window['_addToCart']")
    }else {
        throwError("missing window['_addToCart'] ")
    }
}

export function initInScope($scope) {
    if(!addToCartUrl){
        return
    }
    const
        $cartBtns = $scope.find('.js-add-to-cart');

    $cartBtns.each((_,el) => {
        initCartBtn($(el))
    })
}

function initCartBtn($button) {
    const
        articleId = $button.data('product-id');

    let pendingRequest = null, $notificationContainer;

    function addToCart(e) {
        if(pendingRequest){
            pendingRequest.abort();
            pendingRequest = null;
        }

        let payload = [];

        if($button.closest('form').length){
            e.preventDefault();
            const $form = $button.closest('form');
            $notificationContainer = $form.find('.js-add-to-cart__notifications')

            if (isParsleyForm($form) && !isValid($form)) {
                e.preventDefault();
                e.stopImmediatePropagation();
                return;
            }

            payload = formDataEntries($form[0])
        }

        if(articleId){
            payload.push(['articleId', articleId])
        }

        addLoadingContainer($button,[],false)

        pendingRequest = fetch(addToCartUrl, {
            method: 'POST',
            body: new URLSearchParams(payload)
        });
        pendingRequest.then((res) => {
            return res.clone().json()
        })
            .then((res) => {
                if(res.success){
                    notifyBadge(res.count);
                    addSuccessContainer($button);
                    loadCart(null, null, true);
                    if (breakPoint.matches) {//todo 55059 remove after old mobile navi is obsolete
                        return Mobile_MainMenuTriggers.openDropdown(MOBILE_MAIN_MENUS__DROPDOWNS.MOBILE_DROPDOWN_NAME__CART)
                    } else if (document.getElementById('desktop-nav')) {
                        return Desktop_MainMenuTriggers.openDropdown(DESKTOP_MAIN_MENUS__DROPDOWNS.DESKTOP_DROPDOWN_NAME__CART)
                    } else {
                        //TODO: remove this after desktop-nav deployment
                        openCart();
                    }
                }
            })
            .finally(() => {
                removeLoadingContainer($button)
            })
            .catch((error) => {
                if(error.name === 'AbortError'){
                }else {
                    console.error(error)
                }
            })

        if($notificationContainer !== undefined && $notificationContainer.length){
            showNotification(pendingRequest, {
                $container: $notificationContainer,
                styleModifier: 'test-style-right-now'
            });
        }else{
            showNotification(pendingRequest);
        }

        // Track add to cart
        handleTrackingOnPromise(pendingRequest);
    }

    $button.on('click', (e) => addToCart(e))
}

function notifyBadge(count) {
    $('body').find('.js-cart-badge').trigger('cart:update', {count: count});
}
