"use strict";

// taken from Deuter NG; slightly adapted to match older code base

import {findIn, findAllIn, on, addClass, hasClass, removeClass} from "../../libs/@elements/dom-utils";
import Collapse from 'bootstrap/js/dist/collapse';
import initModulesInScope from '../../libs/@elements/init-modules-in-scope';
import {onFind} from '../ng-functions';

export function init($scope) {
    onFind($scope,'.js-collapse-row', function (row) {
        let collapseToggles = findAllIn('.js-collapse-row__toggle', row);

        collapseToggles.map((element) => {
            on('click', function () {
                let template = findIn('.js-collapse-row__content-template', element);
                let target = findIn(element.getAttribute('data-bs-target'), row);

                if (matchMedia('(max-width: 767px)').matches) {
                    target = findIn(element.getAttribute('data-bs-target-xs'), row);
                }

                if (hasClass('is-active', element)) {
                    removeClass('is-active', element);
                    $(target).slideUp();
                } else {
                    findIn('.js-collapse-row__content', target).innerHTML = template.innerHTML;

                    const targets = findAllIn('.js-collapse-row__toggle[data-bs-target]', row)
                    if (matchMedia('(min-width: 768px)').matches) {
                        targets.map((target) => {
                            if (element.getAttribute('data-bs-target') === target.getAttribute('data-bs-target')) {
                                removeClass('is-active', target)
                            }
                        })
                    }
                    addClass('is-active', element);
                    $(target).slideDown();
                }

                initModulesInScope($(findIn('.js-collapse-row__content', target)));
            }, element);
        });
    });
}
