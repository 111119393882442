'use strict'

import fetch from "../libs/@elements/fetch";
import initModulesInScope from "../libs/@elements/init-modules-in-scope";
import 'url-search-params-polyfill'; // Edge Polyfill
import 'url-polyfill';

let countryMismatchPopupGeoCheckUrl,
    lightboxtimer;

const showAfterSeconds = 1;

export function init() {

    if(document.referrer !== '') {
        return;
    }

    fetch(`${window.location.href}?render-country-mismatch-popup=1&${Date.now()}`).then((res) => {
        return res.clone().json();
    }).then((res) => {
        if (res.html) {
            const
                $html = $(res.html), /* html is espected to be a bootstrap modal! */
                $closeBtn = $html.find('.js-countryMismatchPopup__close'),
                $lightboxContainer = $('body').find('.js-lightbox-container').length ? $('body').find('.js-lightbox-container') : $('body');
            $lightboxContainer.append($html);
            initModulesInScope($html);
            initSubmit($html, res.geoLocatedLink, res.defaultCountry);

            lightboxtimer = setTimeout(
                () => {
                    $html.modal({keyboard: false});
                    $html.modal('show');
                },
                showAfterSeconds * 1000
            );

            $html.on('hide.bs.modal', () => {
                if (lightboxtimer) {
                    clearTimeout(lightboxtimer);
                }
            });
            $closeBtn.on('click', () => {
                $html.modal('hide');
            })


        }
    })
}

export function close($scope) {
    if ($scope) {
        $scope.modal('hide');
    }
}

function initSubmit($scope, geoLocatedLink, defaultCountry) {
    const submitButton = $scope.find('.js-countryMismatchPopup--submit')
    submitButton.on('click', (e) => {
        const selectedCountry = $scope.find('input[type="radio"][name="selectCountry"]:checked')[0].value
        if (selectedCountry !== defaultCountry) {
            window.location.href = geoLocatedLink
        }
        close($scope);
    });
}
