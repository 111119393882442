'use strict'
import fetch from "../../libs/@elements/fetch";

export function initInScope($scope) {
    const $form = $scope.find('form[name=pro_access_registration_form]');
    if ($form.length !== 0) {
        const select = $form[0].elements['pro_access_registration_form_proType']
        select.addEventListener('change', function (el) {
            getRequiredApplicationData($form, el.target.value, $scope)
        })
    }
}

function getRequiredApplicationData($form, id, $scope) {
    const container = $scope.find('#required-application-data')[0]
    if (id) {
        const url = $form.data('required-application')
        fetch(`${url}?id=${id}`, {})
            .then(response => response.json())
            .then(response => {
                if (response.content) {
                    container.removeAttribute('hidden')
                    container.firstElementChild.innerHTML = response.content
                    return
                }
                container.setAttribute('hidden', true)
            })
    }
    container.setAttribute('hidden', true)
}
