import {
    _setCssEndClasses,
    getElementsStyleTransitionPriority,
    getElementsStyleTransitionProperty,
    maybeSetCssDisplayClass,
    maybeUnsetCssDisplayClass,
    setElementsStyleTransitionProperty,
    wait
} from "./functions";

export const skipCssTransition = async (conf) => {
    const storedPropValue = getElementsStyleTransitionProperty(conf.transitioningElement)
    const storedPriority = getElementsStyleTransitionPriority(conf.transitioningElement)

    setElementsStyleTransitionProperty(conf.transitioningElement, 'none', 'important')

    await wait(30)

    await maybeSetCssDisplayClass(conf)
    await conf.beginTransFunction()
    await _setCssEndClasses(conf)
    await maybeUnsetCssDisplayClass(conf)

    await wait(30);

    setElementsStyleTransitionProperty(conf.transitioningElement, storedPropValue, storedPriority)
}
