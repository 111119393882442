"use strict";

export function initInScope() {
    window.Parsley.addValidator('mindate', {
        requirementType: 'date',
        validateDate: function (value, requirement) {
            const timestamp = Date.parse(value),
                  minDate = Date.parse(requirement);

            return isNaN(timestamp) ? false : timestamp <= minDate;
        }
    })
}
