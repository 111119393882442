'use strict';

const style = document.body.style
const docEl = document.documentElement

let scrollTop

export const disableScrolling = () => {
    scrollTop = docEl.scrollTop;

    style.top = `-${scrollTop}px`;
    style.position = 'fixed'
    style.width = '100%'
    style.overflowY = 'scroll'
};

export const enableScrolling = () => {
    style.removeProperty('position')
    style.removeProperty('width')
    style.removeProperty('overflowY')

    docEl.style.scrollBehavior = 'auto'
    docEl.scrollTop = scrollTop;
    docEl.style.removeProperty("scroll-behavior");
    style.removeProperty("top");
};
