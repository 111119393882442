'use strict';

export function initInScope($scope) {
    let $rangeOfUseMobile =     $scope.find('.js-range-of-use-mobile'),
        $rangeOfUseDesktop =    $scope.find('.js-range-of-use-desktop');

    initApplicationCollapse();


    function initApplicationCollapse() {
        const
            mobileBreak = window.matchMedia('(max-width: 767px)');

        changeLayout(mobileBreak.matches, 'init');
        /**
         * manuel polyfill. addListener is deprecated but in ie and edge addEventListerner is not yet supported
         */
        if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)) {
            mobileBreak.addListener((e) => {
                changeLayout(e.matches);
            })
        } else {
            mobileBreak.addEventListener('change', (e) => {
                changeLayout(e.matches);
            })
        }
        $(mobileBreak).trigger('change')
    }

    function changeLayout(isMobile, init) {
        if(isMobile){
            $rangeOfUseMobile.html($rangeOfUseDesktop.html());
            $rangeOfUseDesktop.html('');
        }else{
            if(!init){
                $rangeOfUseDesktop.html($rangeOfUseMobile.html());
                $rangeOfUseMobile.html('');
            }
        }
    }
}
