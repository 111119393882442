'use strict';

import {TreeExecutor} from "../core/TransitionTree/TreeExecutor";
import {Overlay} from "./Overlay/Overlay";

/**
 * main menus have to commit to these classes
 */
const TRANS_STATES = {
    OPENING: 'opening',
    CLOSING: 'closing',
    OPEN: 'open',
    CLOSED: 'closed',
}

const INITIAL_STATE = TRANS_STATES.CLOSED

const KEYS = Object.freeze({
    ACTIVE_MENU: 'activeMenu',
    OVERLAY: 'overlay_state',
    _STATE_: '_state',
    _MENUNAME_: '_menuName',
    _CLOSE_FUNCTION_: '_close_func',
    _MENU_SUB_ID_ : '_menu_sub_id', //additional identifier data besides the menuname, e.g. active menu item
})

/**
 * @typedef MainState
 *
 * @property activeMenu
 * @property {string} activeMenu._state
 * @property {string} activeMenu._menuName
 * @property {?string} activeMenu._menu_sub_id
 * @property {function} activeMenu._close_func
 *
 * @property {string} overlay_state
 */

/**
 * @return {MainState}
 */
const createInitialState = () => {
    return {
        [TreeExecutor.PATH_CURRENT_TRANSITION_TIMESTAMP]: undefined,
        [KEYS.ACTIVE_MENU]: {
            [KEYS._STATE_]: INITIAL_STATE,
            [KEYS._MENUNAME_]: undefined,
            [KEYS._MENU_SUB_ID_]: undefined,
            [KEYS._CLOSE_FUNCTION_]: undefined,
        },
        [KEYS.OVERLAY]: {
            [KEYS._STATE_]: Overlay.INITIAL_STATE,
        },
    }
}

export const MainMenuState = Object.freeze({
    KEYS,
    TRANS_STATES,
    createInitialState,
    isAVisibleState: state => [TRANS_STATES.CLOSED].indexOf(state) === -1,
})