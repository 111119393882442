"use strict";
import {addLoadingContainer, removeLoadingContainer} from "./loading";
import {isParsleyForm, isValid} from "../libs/@elements/parsley-bootstrap-validation";

export function initInScope($scope) {
    const submitBtn = $scope.find('.js-submit-form__btn');
    window.addEventListener('pagehide', (event) => {
        removeLoadingIndicator($scope, submitBtn)
    });
    submitBtn.on('click', function (e) {
        e.preventDefault();
        const $form = $scope.find('.js-submit-form');
        if (isParsleyForm($form) && isValid($form)) {
            addLoadingIndicator($scope, this)
        }
        if (!isParsleyForm($form)) {
            addLoadingIndicator($scope, this)
        }

        $form.submit();
    });
}

function addLoadingIndicator($scope, el) {
    $(el).addClass('disabled');
    el.disabled = true
    addLoadingContainer($scope.find(el), [], false);
}

function removeLoadingIndicator($scope, el) {
    $(el).removeClass('disabled');
    el.disabled = false
    removeLoadingContainer($scope.find(el), [], false);
}
