import {CSSTransitionsHelper} from "../../../../../../../../../shared/events/transitions/CSSTransitionsHelper";

const DISPLAY_CLASS = 'display-on'

const TRANS_STATES = {
    INACTIVE: 'inactive',
    ACTIVATING: 'activating',
    ACTIVE: 'active',
    DEACTIVATING: 'deactivating',
}

const _getBaseConfig = (menuElement, useTransition, isTransitionCanceled) => ({
    useTransition,
    isCanceledFunc: isTransitionCanceled,
    clearCssClasses: Object.values(TRANS_STATES),
    transitioningElement: menuElement,
    stateElement: menuElement
})

const _getActivateConfig = (menuElement, useTransition, isTransitionCanceled) => ({
    ..._getBaseConfig(menuElement, useTransition, isTransitionCanceled),
    setDisplayCssClass: DISPLAY_CLASS,
    beginTransCssClass: TRANS_STATES.ACTIVATING,
    endTransCssClass: TRANS_STATES.ACTIVE,
})

const _getDeactivateConfig = (menuElement, useTransition, isTransitionCanceled) => ({
    ..._getBaseConfig(menuElement, useTransition, isTransitionCanceled),
    unsetDisplayCssClass: DISPLAY_CLASS,
    beginTransCssClass: TRANS_STATES.DEACTIVATING,
    endTransCssClass: TRANS_STATES.INACTIVE,
})


export const Tr_LevelContent = Object.freeze({

    INITIAL_STATE: TRANS_STATES.INACTIVE,
    TRANS_STATES: TRANS_STATES,

    isAVisibleState: state => [TRANS_STATES.INACTIVE].indexOf(state) === -1,

    /** @type {TransitionInfo} */
    activate: {
        /**
         * @param {HTMLElement} menuElement
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (menuElement, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_getActivateConfig(menuElement, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.ACTIVATING,
        endState: TRANS_STATES.ACTIVE,
        debugDescription: 'LevelContent\tActivate',
    },

    /** @type {TransitionInfo} */
    deactivate: {
        /**
         * @param {HTMLElement} menuElement
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (menuElement, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_getDeactivateConfig(menuElement, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.DEACTIVATING,
        endState: TRANS_STATES.INACTIVE,
        debugDescription: 'LevelContent\tDeactivate',
    },

})
