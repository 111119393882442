"use strict";
export function initInScope ($scope) {

    $('.js-accordion-scroll').on('shown.bs.collapse', function (e) {
        let panel = $(e.target).parent(),
            headerHeight = $('.js-header__nav').height();
        if(!headerHeight){
            headerHeight = 0;
        }
        $('html, body').animate({
            scrollTop: panel.offset().top - headerHeight
        }, 400);
    });
}