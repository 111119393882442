'use strict';

import {TreeExecutor} from "./TreeExecutor";
import {TItemResolverLimited} from "./ItemResolver";
import {TAnyNode, TResolvedNode} from "./NodesClasses";
import {cloneState} from "../state/cloneState";
import {createExecuteActionFunc} from "./TActionExecutor";

const STATE_AFTER_LAST_NODE = 'state_after_last_node'
const INITIAL_STATE = 'initial_state'

/**
 * Resolution mechanism:
 *  - Each TNode can have a name, to which unresolveds (TUnresolvedItem, TUnresolvedNode) can refer to in their dependencies property.
 *  - The dependencies property declares which other nodes are needed in order to resolve this element.
 *  - When resolving an item the states of the dependencies property are passed and can be used freely.
 *  - When an item was resolved or when a generic state has been reached, the state has to be stored in the 'storedStates' object.
 *      The store is then used for further resolutions.
 *  - At the moment only one generic dependency is implemented: STATE_AFTER_LAST_NODE = the very last state after all actions have executed ones
 *  - The whole resolution of the tree is an iterative process. At the moment it only takes 2 cycles:
 *      1) determine the last state (no items resolved)
 *      2) resolve via the temporary endstate
 *
 * TBD:
 * - allow each node to have a name
 * - the names have to be easily configurable in the scenarios (and work with createPath etc.)
 * - with the new mechanism the STATE_AFTER_LAST_NODE becomes unnecessary and must be removed
 *
 * @param {NavigationState} initialState
 * @param {TAnyNode} scenarioRoot
 * @param {TransitionCancellationFunc} isTransitionCanceled
 * @return {TResolvedNode}
 */
const resolveTree = async (initialState, scenarioRoot, isTransitionCanceled) => {
    const tempRoot = new TResolvedNode()
    //necessary because we don't get the reference for a new head, if it is an TUnresolvedNode
    tempRoot.nextNode = scenarioRoot

    let resolver = new TItemResolverLimited()
    const storedStates = {
        [INITIAL_STATE]: cloneState(initialState)
    }

    do {
        const state_to_work_with = cloneState(initialState)
        resolver.startCycle()

        await execute_to_resolve(
            state_to_work_with,
            tempRoot,
            () => false,
            (item) => resolver.tryResolve(storedStates, item)
        )

        if (!storedStates[STATE_AFTER_LAST_NODE]) {
            storedStates[STATE_AFTER_LAST_NODE] = cloneState(state_to_work_with)
        }

    } while (resolver.scheduled > 0)

    return tempRoot.nextNode
};

/**
 * @param {TAnyNode} out_unresolvedTreeRoot
 * @param {NavigationState} out_endState
 * @param {TransitionCancellationFunc} isTransitionCanceledFunc
 * @param {ResolveFunction} resolveFunc
 */
const execute_to_resolve = (out_endState, out_unresolvedTreeRoot, isTransitionCanceledFunc, resolveFunc) =>
    TreeExecutor.executeWholeTreeWith(
        createExecuteActionFunc(out_endState, isTransitionCanceledFunc, noExecuteFunc, null),
        resolveFunc,
        out_unresolvedTreeRoot,
        isTransitionCanceledFunc,
        null
    )

/**
 * @param {function} executeFunc
 * @return {function(function)}
 */
const noExecuteFunc = (executeFunc) => {
}


export const TreeResolver = Object.freeze({
    /**
     * @param {NavigationState} futureEndState
     * @param {TAnyNode} scenarioRoot
     * @param {TransitionCancellationFunc} isTransitionCanceled
     * @return {TResolvedNode}
     */
    resolve: resolveTree,

    STATE_AFTER_LAST_NODE,
    INITIAL_STATE,
})