"use strict";

import {addLoadingContainer, removeLoadingContainer} from "./loading";
import {translate} from "../../../shared/translations/translations";
import * as parsleyMindate from './parsleyMindate';

export function initInScope ($scope) {
    window.onload = function(){

        let unzerInstance = new unzer(_config.paymentAccessKey, {locale: _config.paymentLocale});

        let $errorHolder = $('#error-holder');

        let Card = unzerInstance.Card();
        // Rendering input fields
        Card.create('number', {
            containerId: 'card-element-id-number',
            onlyIframe: false
        });
        Card.create('expiry', {
            containerId: 'card-element-id-expiry',
            onlyIframe: false
        });
        Card.create('cvc', {
            containerId: 'card-element-id-cvc',
            onlyIframe: false
        });

        let ccForm = document.getElementById('cc-form');
        let paymentForm = document.getElementById('payment-form');
        let paylaterForm = document.getElementById('paylater-form');
        // General event handling
        let buttonDisabled = {};
        let submitButton = document.getElementById('submit-button');
        submitButton.disabled = true;


        Card.addEventListener('change', function(e) {
            if (e.success) {
                buttonDisabled[e.type] = true;
                submitButton.disabled = false;
                $errorHolder.html('')
            } else {
                buttonDisabled[e.type] = false;
                submitButton.disabled = true;
                $errorHolder.html(e.error)
            }

            submitButton.disabled = (!(buttonDisabled.number && buttonDisabled.expiry && buttonDisabled.cvc) || buttonDisabled.loading);
        });


        window.addEventListener('pagehide', (event) => {
            removeLoadingIndicator($scope, submitButton, buttonDisabled)
        });

        let Sepa = unzerInstance.SepaDirectDebit();

        Sepa.create('sepa-direct-debit', {
            containerId: 'sepa-IBAN'
        });

        let sepaForm = document.getElementById('payment-form-sepa');
        let submitButtonSepa = document.getElementById('submit-button-sepa');
        submitButtonSepa.disabled = true;

        Sepa.addEventListener('change', function(e) {
            if (e.success) {
                submitButtonSepa.disabled = false;
                $errorHolder.html('')
            } else {
                submitButtonSepa.disabled = true;
                $errorHolder.html(e.error)
            }

        });

        let successHandler = function(data) {
            data.method = data.method ? data.method : 'card';
            console.log("payment successHandler. method=" + data.method);
            $('.js-payment-method-hidden').val(data.method);
            $('.js-payment-id-hidden').val(data.id);

            if(data.method == 'card') {
                $('.js-payment-credit-card-hidden').val(data.brand);
                paymentForm.submit();
            }

            if(data.method == 'sepa-direct-debit') {
                paymentForm.submit();
            }

            if(data.method === 'paylater-invoice' || data.method === 'invoice-secured') {
                let birthdate = document.getElementsByClassName('js-payment-birthdate-hidden');
                for (let i = 0; i < birthdate.length; i++) {
                    birthdate[i].value = document.getElementById("customerBirthdate").value;
                    console.log("payment customerBirthdate=" + birthdate[i].value);
                }
                paymentForm.submit();
            }
        };

        let errorHandler = function(error) {
            removeLoadingIndicator($scope, submitButton, buttonDisabled)
            $errorHolder.html(error.message);
        };

        ccForm.addEventListener('submit', function(event) {
            event.preventDefault();
            addLoadingIndicator($scope, submitButton, buttonDisabled)
            Card.createResource()
                .then(successHandler)
                .catch(errorHandler)
        });

        sepaForm.addEventListener('submit', function(event) {
            event.preventDefault();
            addLoadingIndicator($scope, submitButton, buttonDisabled)
            Sepa.createResource()
                .then(successHandler)
                .catch(errorHandler)
        });


        $('.js-payment-method-btn-paypal').on('click', function(e){
            e.preventDefault();
            addLoadingIndicator($scope, submitButton, buttonDisabled)
            var Paypal = unzerInstance.Paypal();

            Paypal.createResource()
                .then(successHandler)
                .catch(errorHandler)
        });

        $('.js-payment-method-btn-sofort').on('click', function(e){
            e.preventDefault();
            addLoadingIndicator($scope, submitButton, buttonDisabled)
            var Sofort = unzerInstance.Sofort();

            Sofort.createResource()
                .then(successHandler)
                .catch(errorHandler)
        });

        if (paylaterForm) {
            const invoicePaymentMethod = document.getElementById('invoicePaymentMethod').value;
            const UnzerInvoice = (invoicePaymentMethod === 'paylater-invoice') ? unzerInstance.PaylaterInvoice() : unzerInstance.InvoiceSecured();
            let paylaterFormParsleyInstance = $(paylaterForm).parsley();
            const submitButton = paylaterForm.querySelector('#submit-button-invoicePayment');
            let hasBeenValidated = false;

            if (invoicePaymentMethod === 'paylater-invoice') {
                UnzerInvoice.create({
                        containerId: 'paylater-optin',
                        paymentTypeName: 'paylater-invoice',
                        customerType: 'B2C',
                        errorHolderId: 'error-holder'
                    }
                );
            }

            // Initialize custom parsley validation hardcoded after 1000ms because PaylaterInvoice.create has no callback function
            setTimeout(() => {
                parsleyMindate.initInScope()
                // HACK: Manually add "required" and more attributes to each input
                const paylaterInvoiceOptInInputs = paylaterForm.querySelectorAll('#paylater-optin input:not([type="hidden"])');
                paylaterInvoiceOptInInputs.forEach((paylaterInvoiceOptInInputElem, i) => {
                    paylaterInvoiceOptInInputElem.parentNode.classList.add('custom-checkbox');
                    paylaterInvoiceOptInInputElem.classList.add('custom-checkbox__input');
                    paylaterInvoiceOptInInputElem.setAttribute('required', '');
                    paylaterInvoiceOptInInputElem.setAttribute('data-parsley-error-message', translate(`paylaterInvoice-optin-${i + 1}.error`));
                    paylaterInvoiceOptInInputElem.setAttribute('data-parsley-errors-container', `div#${paylaterInvoiceOptInInputElem.id}-error-holder`);

                    if (paylaterInvoiceOptInInputElem.nextElementSibling) {
                        const nextElementSibling = paylaterInvoiceOptInInputElem.nextElementSibling
                        nextElementSibling.insertAdjacentHTML('afterend', `<span class="custom-checkbox__text">${nextElementSibling.innerHTML}</span>`);
                        nextElementSibling.parentNode.removeChild(nextElementSibling);
                    }

                    paylaterInvoiceOptInInputElem.insertAdjacentHTML('afterend', `<span class="custom-checkbox__box icon icon-check"></span>`);
                    paylaterInvoiceOptInInputElem.parentNode.insertAdjacentHTML('afterend', `<div id="${paylaterInvoiceOptInInputElem.id}-error-holder" class="unzerUI form error form-errors w-100"></div>`);
                    paylaterFormParsleyInstance.refresh();
                });

                paylaterFormParsleyInstance.refresh();

                paylaterInvoiceOptInInputs.forEach(paylaterInvoiceOptInInput => {
                    paylaterInvoiceOptInInput.addEventListener('input', (e) => {
                        if (!hasBeenValidated) {
                            return;
                        }

                        paylaterFormParsleyInstance.validate()
                    })
                })
            }, 1000)

            paylaterFormParsleyInstance.on('field:validated', () => {
                hasBeenValidated = true;

                if (paylaterFormParsleyInstance.isValid()) {
                    submitButton.removeAttribute('disabled');
                } else {
                    submitButton.setAttribute('disabled', '');
                }
            })

            paylaterForm.addEventListener('submit', function(event) {
                event.preventDefault();
                if (!paylaterFormParsleyInstance.isValid()) return

                UnzerInvoice.createResource()
                    .then(successHandler)
                    .catch(errorHandler)
            });
        }
    };


    $scope.find('.js-payment-method-btn').on('click',function (e) {
        $('.js-payment-method-btn').removeClass('payment-methods__item--selected');

        $(this).addClass('payment-methods__item--selected');

        $('.js-submit-payment__btn').removeAttr('hidden');
    });


    $scope.find('.js-payment-method-btn--collapse').on('click',function (e) {
        $('.js-payment-method-btn').removeClass('payment-methods__item--selected');
        $('.js-submit-payment__btn').attr('hidden','hidden');
    });

    if (_config.paymentShowDonationOverlay) {
        let $modal = $('#payment-donation-modal');
        let donationValueSelected = false;
        let $donationForm = $('.js-payment__donation-form');

        $scope.find('.js-submit-payment__btn').on('click', function (evt) {
            if (!donationValueSelected) {
                evt.preventDefault();
                evt.stopImmediatePropagation();

                $modal.on('donation-value-selected', function () {
                    $('.js-payment__form').submit();
                });

                $modal.modal({backdrop: 'static', keyboard: false}).modal('show');
            }
        });

        $scope.find('.js-payment__payment-form-submit').on('click', function (evt) {
            let $submitButton = $(this);
            if (!donationValueSelected) {
                evt.preventDefault();
                evt.stopImmediatePropagation();

                $modal.on('donation-value-selected', function () {
                    addLoadingIndicator($scope, $submitButton, buttonDisabled)
                    $submitButton.click();
                });

                $modal.modal({backdrop: 'static', keyboard: false}).modal('show');
            }
        });

        $donationForm.on('submit', function (evt) {
            evt.preventDefault();

            if ($(this).parsley().isValid()) {
                $('.js-payment__form-donate-value').val($('.js-payment__donation-option input:checked').val());
                donationValueSelected = true;

                $(this).trigger('donation-value-selected');

                $modal.modal('hide');
            }
        });
    }


    if(window.matchMedia('(max-width: 767px)').matches) {
        $('#cc-form-collapse').on('shown.bs.collapse', function () {
            $('html, body').stop().animate({
                scrollTop: $('#cc-form-collapse').offset().top-85
            }, '300');
        });
        $('#invoicePayment').on('shown.bs.collapse', function () {
            $('html, body').stop().animate({
                scrollTop: $('#invoicePayment').offset().top-85
            }, '300');
        });
        $('#direct-debit-form-collapse').on('shown.bs.collapse', function () {
            $('html, body').stop().animate({
                scrollTop: $('#direct-debit-form-collapse').offset().top-85
            }, '300');
        });
    }
}

function addLoadingIndicator($scope, submitButton, buttonDisabled) {
    buttonDisabled.loading = true
    addLoadingContainer($scope.find(submitButton), [], false);
}

function removeLoadingIndicator($scope, submitButton, buttonDisabled) {
    buttonDisabled.loading = false
    submitButton.disabled = false
    removeLoadingContainer($scope.find(submitButton), [], false);
}
