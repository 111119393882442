import {Tr_Overlay} from "./Tr_Overlay";
import {disableScrolling, enableScrolling} from "../../../../../../../../shared/DOM/scrollbars";

export const Overlay = Object.freeze({

    INITIAL_STATE: Tr_Overlay.INITIAL_STATE,

    /** @type {TransitionInfo} */
    show: ({
        /**
         * @param {HTMLElement} overlayElement
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         */
        async func(overlayElement, useTransition, isTransitionCanceled) {
            disableScrolling()
            await Tr_Overlay.show.func(overlayElement, useTransition, isTransitionCanceled)
        },
        startState: Tr_Overlay.show.startState,
        endState: Tr_Overlay.show.endState,
        debugDescription: 'Overlay\tShow',
    }),

    /** @type {TransitionInfo} */
    hide: ({
        /**
         * @param {HTMLElement} overlayElement
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        async func(overlayElement, useTransition, isTransitionCanceled) {
            enableScrolling()
            await Tr_Overlay.hide.func(overlayElement, useTransition, isTransitionCanceled)
        },
        startState: Tr_Overlay.hide.startState,
        endState: Tr_Overlay.hide.endState,
        debugDescription: 'Overlay\tHide',
    }),

    initCloseTrigger(overlayElement, callback) {
        overlayElement.addEventListener('click', callback)
    }
})
