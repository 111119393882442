"use strict";

import $ from 'jquery';
import throwError from '../throw-error';

let id = 0;

let defaultOptions = {
    linkClass: 'sr-only sr-only-focusable'
};
let defaultSelectors = {
    list: '.js-toc__list',
    title: '.js-toc__title',
};

function isFocusable( $el ) {
    let tabIndex = +$el.attr( "tabindex" );
    tabIndex = isNaN( tabIndex ) ? -1 : tabIndex;
    return $el.is( ":input, a[href], area[href], iframe" ) || tabIndex > -1;
}

export function create(options = defaultOptions, selectors = defaultSelectors) {
    options = {...defaultOptions, ...options};
    selectors = {...defaultSelectors, ...selectors};
    let $tocList;

    function addTocEntry(text, anchorId) {
        $tocList.append(`<li><a class="${options.linkClass}" href="#${anchorId}">${text}</a></li>`);
    }

    return {
        init: function init () {
            $tocList = $(selectors.list);
            if (!$tocList || !$tocList.length) {

                throwError(`can\'t render Table of content. No ${selectors.list} found`);
            }

            $tocList.on('click', 'a', function () {
                let $target = $($(this).attr('href'));

                if (!$target || !$target.length) {
                    throwError('Could not jump to "', $(this).attr('href'), '". Element was not found');
                    return;
                }

                let $tabPaneParent = $target.closest('.tab-pane:not(.active)');
                if ($tabPaneParent && $tabPaneParent.length) {
                    let targetSelector = '#' + $tabPaneParent.attr('id');
                    let $trigger = $('[data-bs-toggle="tab"]').filter('[data-target="' + targetSelector + '"],[data-bs-target="' + targetSelector + '"],[href="' + targetSelector + '"]');
                    $trigger.tab('show');
                    window.scrollTo(window.scrollX, $tabPaneParent.parent().offset().top);
                }

                let $collapseParent = $target.closest('.collapse:not(.in)');
                if ($collapseParent && $collapseParent.length) {
                    $collapseParent.collapse('show');
                    window.scrollTo(window.scrollX, $collapseParent.parent().offset().top);
                }
            });
        },
        initInScope: function initInScope ($scope) {
            $scope.find(selectors.title).each(function () {
                let $title = $(this);
                let anchorId = 'toc-entry-target-' + id;
                $title.attr('id', 'toc-entry-target-' + id);
                if (!isFocusable($title)) {
                    $title.attr('tabindex', '-1');
                }
                id++;
                addTocEntry($title.text(), anchorId);
            });
        }
    }
}

// default exports
let toc = create();
export const init = toc.init;
export const initInScope = toc.initInScope;

