import {MenuPositioner} from "../MenuLevel/MenuPositioner";
import {CSSTransitionsHelper} from "../../../../../../../../../shared/events/transitions/CSSTransitionsHelper";

let _container
let _containerWidth

const TRANS_STATES = {
    MOVING: 'level-moving',
    STILL: 'level-still',
}

/**
 * @param {HTMLElement} menuContainer
 * @param {int} menuWidth
 * @param {int} newActiveLevel
 * @param {boolean} useTransition
 * @param {TransitionCancellationFunc} isTransitionCanceled
 */
const _buildConfig = (menuContainer, menuWidth, newActiveLevel, useTransition, isTransitionCanceled) => ({
    useTransition,
    isCanceledFunc: isTransitionCanceled,
    clearCssClasses: Object.values(TRANS_STATES),
    transitioningElement: menuContainer,
    stateElement: menuContainer,
    beginTransCssClass: TRANS_STATES.MOVING,
    beginTransFunction: () => {
        const offset = MenuPositioner.sum_menu_widths(0, Math.max(newActiveLevel, 0))
        const value = offset + (menuWidth - _containerWidth - offset) / 2;
        menuContainer.style.transform = 'translateX(' + value + 'px)'
    },
    endTransCssClass: TRANS_STATES.STILL,
})


const init = () => {
    _container = document.getElementById('desknav-imagetiles-container')
    if (!_container) {
        return
    }
    _containerWidth = parseInt(getComputedStyle(/** @type Element */_container).width.replace('px', ''))
};


export const Tr_ImageTiles_Move = Object.freeze({

    /** @type {TransitionInfo} */
    moveContainer: {
        /**
         * @param {int} menuWidth
         * @param {int} newActiveLevel
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (menuWidth, newActiveLevel, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_buildConfig(_container, menuWidth, newActiveLevel, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.MOVING,
        endState: TRANS_STATES.STILL,
        debugDescription: 'ImageTiles\tMove Container',
    },

    init,

})

