export class LevelMenu_ScenarioData {
    /**
     * @param {int} menuId
     * @param {HTMLElement} menuElement
     * @param {int} level
     */
    constructor(menuId, menuElement, level) {
        this.menuId = menuId;
        this.menuElement = menuElement;
        this.level = level
    }
}
