'use strict'

import throwError from '../../libs/@elements/throw-error'
import fetch from "../../libs/@elements/fetch";
import initModulesInScope from "../../libs/@elements/init-modules-in-scope";
import { isParsleyForm, isValid } from "../../libs/@elements/parsley-bootstrap-validation"
import asyncAppend from '../../libs/@elements/async-append';
import formDataEntries from 'form-data-entries';
import 'url-search-params-polyfill'; // Edge Polyfill
import 'url-polyfill';


/**
 This module opens a Lightbox automatically when mouse is outside the document (yes.. there are better solutions and apis, but this is an explicit customer wish...).
 For mobile it is disabled.

 When closed a parameter in session storage is written, so it wont open again.
 */
let popupUrl;
let lightboxtimer;
const showAfterSeconds = 3;

export function init() {
    if(typeof Storage === "undefined"){
        throwError('cannot access localStorage - sheep stories not loaded');
        return
    }

    /**
     * the popupUrl is a predefined property that can be set on every page.
     */
    popupUrl = _config['popupSnippetUrl'];

    /**
     * check if already dissmissed
     */

    initPopup();
}

function initPopup() {
    fetch(popupUrl).then((res) => {
        return res.clone().json();
    }).then((res) => {
        if(res.success && res.html){
            const
                $html               = $(res.html), /* html is espected to be a bootstrap modal! */
                $closeBtn           = $html.find('.js-popup__close'),
                $lightboxContainer  = $('body').find('.js-lightbox-container').length ? $('body').find('.js-lightbox-container') : $('body');

            $lightboxContainer.append($html);
            initModulesInScope($html);

            lightboxtimer = setTimeout(
                () => {
                    $html.modal({backdrop: 'static', keyboard: false});
                    $html.modal('show');

                },
                showAfterSeconds * 1000
            );

            $html.on('hidden.bs.modal', () => {
                removePopup($html);
            })
            $html.on('hide.bs.modal', () => {
                disablePopup();
            });

            $closeBtn.on('click', () => {
                $html.modal('hide');
            })

        }
    })
}

export function disablePopup() {
    if(lightboxtimer){
        clearTimeout(lightboxtimer);
    }
    $(document).off('mouseleave.popupLightbox');
}

export function removePopup($scope = $('body')) {
    const $modal = $scope.hasClass('.modal--popup') ? $scope : $scope.find('.modal--popup');
    if($modal.hasClass('show')){
        $modal.modal('hide');
    }
    $modal.remove();
}