'use strict';

import {Tr_LevelContent} from "../TransitionElements/MenuLevel/Tr_LevelContent";
import {Tr_LevelMover} from "../TransitionElements/MenuLevel/Tr_LevelMover";
import {Tr_LevelTrigger} from "../TransitionElements/MenuLevel/Tr_LevelTrigger";
import {Tr_ImageTiles_ActiveItem} from "../TransitionElements/ImageTiles/Tr_ImageTiles_ActiveItem";
import {MenuPositioner} from "../TransitionElements/MenuLevel/MenuPositioner";
import {Tr_ImageTiles_Move} from "../TransitionElements/ImageTiles/Tr_ImageTiles_Move";

/**
 * @typedef LevelState
 *
 * @property activeContent
 * @property {string} activeContent._state
 * @property {HTMLElement} activeContent._element
 *
 * @property activeTrigger
 * @property {string} activeTrigger._state
 * @property {HTMLElement} activeTrigger._element
 *
 * @property openClose
 * @property {string} openClose._state
 *
 * @property activeImageTilesContent
 * @property {string} activeImageTilesContent._state
 * @property {HTMLElement} activeImageTilesContent._element
 *
 * @property imageTilesPosition
 * @property {string} imageTilesPosition._state
 *
 * @property submenus_levelPosition
 * @property {string} submenus_levelPosition._state
 * @property {int} submenus_levelPosition._level
 */

const KEYS = Object.freeze({
    _PREFIX_LEVEL: 'lvl_',
    _ELEMENT_: '_element',
    _STATE_: '_state',
    _LEVEL_: '_level',
    MAX_LEVEL: '_max_level',
    ACTIVE_CONTENT: 'activeContent',
    ACTIVE_TRIGGER: 'activeTrigger',
    OPEN_CLOSE: 'openClose',
    ACTIVE_IMAGETILES_CONTENT: 'activeImageTilesContent',
    IMAGETILES_POSITION: 'imageTilesPosition',
    SUBMENUS_LEVEL_POSITION: 'submenus_levelPosition',
})


const _createOneInitialLevelState = () => ({
    [KEYS.ACTIVE_CONTENT]: {
        [KEYS._STATE_]: Tr_LevelContent.INITIAL_STATE,
        [KEYS._ELEMENT_]: undefined,
    },
    [KEYS.ACTIVE_TRIGGER]: {
        [KEYS._STATE_]: Tr_LevelTrigger.INITIAL_STATE,
        [KEYS._ELEMENT_]: undefined,
    },
    [KEYS.OPEN_CLOSE]: {
        [KEYS._STATE_]: Tr_LevelMover.INITIAL_STATE,
    },
})

export const LevelMenuState = Object.freeze({
    KEYS,

    /**
     * @param {int} toplevel
     * @param {int} n_levels
     * @return {object}
     */
    createInitialState: (toplevel, n_levels) => {
        let state = {
            [KEYS.MAX_LEVEL]: n_levels,

            [KEYS.ACTIVE_IMAGETILES_CONTENT]: {
                [KEYS._STATE_]: Tr_ImageTiles_ActiveItem.INITIAL_STATE,
                [KEYS._ELEMENT_]: undefined,
            },

            [KEYS.SUBMENUS_LEVEL_POSITION]: {
                [KEYS._STATE_]: MenuPositioner.INITIAL_STATE,
                [KEYS._LEVEL_]: undefined,
            },

            [KEYS.IMAGETILES_POSITION]: {
                [KEYS._STATE_]: Tr_ImageTiles_Move.moveContainer.endState,
                [KEYS._LEVEL_]: undefined,
            },
        }

        for (let n = toplevel; n < n_levels; n++) {
            state[KEYS._PREFIX_LEVEL + n] = _createOneInitialLevelState()
        }
        return state
    }
})