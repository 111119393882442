import {CSSTransitionsHelper} from "../../../../../../shared/events/transitions/CSSTransitionsHelper";
import {MainMenuState} from "./MainMenu/MainMenuState";

const TRANS_STATES = MainMenuState.TRANS_STATES

const DISPLAY_CLASS = 'display-on'

const _buildBasicConfig = (contentContainer, useTransition, isTransitionCanceled) => ({
    useTransition,
    isCanceledFunc: isTransitionCanceled,
    transitioningElement: contentContainer,
    stateElement: contentContainer,
    clearCssClasses: Object.values(TRANS_STATES),
})

const _buildOpenTransitionConfig = (contentContainer, useTransition, isTransitionCanceled) => ({
    ..._buildBasicConfig(contentContainer, useTransition, isTransitionCanceled), ...{
        setDisplayCssClass: DISPLAY_CLASS,
        beginTransCssClass: TRANS_STATES.OPENING,
        endTransCssClass: TRANS_STATES.OPEN,
    }
})
const _buildCloseTransitionConfig = (contentContainer, useTransition, isTransitionCanceled) => ({
    ..._buildBasicConfig(contentContainer, useTransition, isTransitionCanceled), ...{
        unsetDisplayCssClass: DISPLAY_CLASS,
        beginTransCssClass: TRANS_STATES.CLOSING,
        endTransCssClass: TRANS_STATES.CLOSED,
    }
})

export const Tr_DropdownMenu = Object.freeze({
    isVisible: state => [TRANS_STATES.CLOSED].indexOf(state) === -1,

    /**
     * @param {boolean} useTransition
     * @param {TransitionCancellationFunc} isTransitionCanceled
     * @param {HTMLElement} contentContainer
     * @return {Promise}
     */
    open: (contentContainer, useTransition, isTransitionCanceled) => {
        return CSSTransitionsHelper.execute(_buildOpenTransitionConfig(contentContainer, useTransition, isTransitionCanceled));
    },

    /**
     * @param {boolean} useTransition
     * @param {TransitionCancellationFunc} isTransitionCanceled
     * @param {HTMLElement} contentContainer
     * @return {Promise}
     */
    close: (contentContainer, useTransition, isTransitionCanceled) => {
        return CSSTransitionsHelper.execute(_buildCloseTransitionConfig(contentContainer, useTransition, isTransitionCanceled));
    },
})
