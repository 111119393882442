'use strict'

import {registerPermissionGrantedCallback, getServicePermission} from "../../libs/@elements/cookie-permissions";

const COOKIEBANNER_CLOSE_EVENTNAME = 'COI_Button_Clicked';
const COOKIEBANNER_COOKIENAME = 'CookieInformationConsent';
const COOKIEBANNER_CONSENT_APPROVED_OBJKEY = 'consents_approved';
const COOKIEBANNER_NECESSARY_COOKIENAME = 'cookie_cat_necessary';

class TrackingHandler {
    supports() {
    }

    handle(payload, callback) {
    }
}

class TagManagerHandler extends TrackingHandler {
    constructor() {
        super();

        this._allowed = false;
        this._isCOINecessaryCookieSet = isCOINecessaryCookieSet();

        registerPermissionGrantedCallback("tagManager", () => {
            this._allowed = true;
        });
    }

    supports(type) {
        return dataLayer != null && type === "gtm" && this._allowed;
    }

    handle(payload) {
        if (this._isCOINecessaryCookieSet) {
            dataLayer.push(payload);
        } else {
            document.addEventListener(COOKIEBANNER_CLOSE_EVENTNAME, () => {
                setTimeout(() => {
                    this._isCOINecessaryCookieSet = isCOINecessaryCookieSet();

                    if (this._isCOINecessaryCookieSet) {
                        dataLayer.push(payload);
                    }
                }, 100)
            })
        }
    }
}

const handlers = [
    new TagManagerHandler()
];

export function initInScope($scope) {
    $scope.find(".js-tracking").each(function () {
        track($(this), [new DelegationConstraint('.js-compare-list__tracking, .js-wishlist__tracking')]);
    });

    $scope.find(".js-compare-list__tracking, .js-wishlist__tracking").each(function () {
        track($(this), [new HasClassConstraint('is-active')]);
    });
}

function track($element, constraints) {
    let action = $element.data("tracking-action");
    let type = $element.data("tracking-type");
    let payload = $element.data("tracking-payload") ? $element.data("tracking-payload") : null;

    if (action !== undefined) {
        $element.on(action, function (event) {
            if (!evaluateConstraints(constraints, $element, event)) {
                return;
            }
            handle(payload, type);
        });
    } else {
        if (!evaluateConstraints(constraints, $element)) {
            return;
        }
        handle(payload, type);
    }
}

function evaluateConstraints(constraints, $element, event) {
    for (let constraint of constraints) {
        if (! constraint instanceof TrackingConstraint) {
            continue;
        }
        if (!constraint.isAllowed($element, event)) {
            return false;
        }
    }

    return true;
}

function handle(payload, type) {
        handlers.forEach((function (handler) {
            if (!handler.supports(type)) {
                return;
            }

            if (payload && payload.ecommerce && !payload.dontClearECommerceObj) {
                handler.handle({ ecommerce: null });
            }

            handler.handle(payload);
        }));
}


function isCOINecessaryCookieSet() {
    const cookieObj = document.cookie.split(';').filter(elem => elem.indexOf(COOKIEBANNER_COOKIENAME) > -1).length ? JSON.parse(decodeURIComponent(document.cookie.split(';').filter(elem => elem.indexOf(COOKIEBANNER_COOKIENAME) > -1)[0].trim().split('=')[1])) : false;

    if (!cookieObj || !cookieObj[COOKIEBANNER_CONSENT_APPROVED_OBJKEY]) return false;

    return cookieObj[COOKIEBANNER_CONSENT_APPROVED_OBJKEY].includes(COOKIEBANNER_NECESSARY_COOKIENAME);
}


class TrackingConstraint {
    isAllowed(element = null, event = null) {
        return true;
    }
}

class HasClassConstraint extends TrackingConstraint {
    constructor(selector) {
        super();
        this.selector = selector;
    }
    isAllowed(element, event = null) {
        return !element.hasClass(this.selector);
    }
}

class DelegationConstraint extends TrackingConstraint {
    constructor(selector) {
        super();
        this.selector = selector;
    }
    isAllowed(element = null, event) {
        if (typeof event === typeof undefined) {
            return true;
        }
        if (typeof event.target === typeof undefined) {
            return true;
        }
        const $target = $(event.target);
        if ($target.is(this.selector)) {
            return false;
        }
        const $parents = $target.parentsUntil($(event.currentTarget));
        if($parents.length) {
            for (let i = 0; i <= $parents.length; i++) {
                const $element = $($parents[i]);
                if ($element.is(this.selector)) {
                    return false;
                }
            }
        }

        return true;
    }
}
