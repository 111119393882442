"use strict";

export function initInScope ($scope) {
    $scope.find('.js-ajax-shipping__link').on('click',function (e) {
        e.preventDefault();

        $('.shipping-methods__item').removeClass('shipping-methods__item--selected');
        $(this).closest('.shipping-methods__item').addClass('shipping-methods__item--selected');

        $('.js-checkout-sidebar .loading-overlay').removeAttr('hidden');

        let url = $(this).attr('href');
        $.ajax({
            type: "POST",
            url: url,
            dataType: 'JSON'
        }).done(function(response) {
            $('.js-ajax-cart').html(response.html);

            $('.js-checkout-sidebar .loading-overlay').attr('hidden','hidden');
        });
    });

}