'use strict'

import Splide from '@splidejs/splide';

const Selector = {
    sliderModule: '.js-teaser-text-media__slider.splide',
};

export function initInScope() {
    document.querySelectorAll(Selector.sliderModule).forEach(sliderElement => {
        const splide = new Splide(
            sliderElement, {
                pagination: false,
                arrows: false,
                fixedWidth: '140px',
                mediaQuery: 'min',
                breakpoints: {
                    576: {
                        fixedWidth: '160px',
                    },
                    768: {
                        destroy: true
                    },
                }
            })

        splide.mount()
    })
}