export class MainMenu_ScenarioData {
    /**
     * @param {object} overlay
     * @param {string} menuName
     * @param {?string} menuSubId
     * @param {function(TransitionCancellationFunc)} openFunc - curried version of the openFunc
     * @param {function(TransitionCancellationFunc)} closeFunc - curried version of the openFunc
     */
    constructor(overlay, menuName, openFunc, closeFunc, menuSubId = null) {
        this.overlay = overlay
        this.menuName = menuName
        this.openFunc = openFunc
        this.closeFunc = closeFunc
        this.menuSubId = menuSubId ? menuSubId : undefined
    }
}
