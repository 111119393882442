'use strict'

import Splide from '@splidejs/splide';

const SELECTOR = {
    tabs: '#mobile-nav__tabs-slide',
    menucards: '.mobile-nav__menucard',
};

class NavSlider {
    constructor(rootMenuitemContainer) {
        this._rootMenuitemContainer = rootMenuitemContainer

        this._splide = new Splide(SELECTOR.tabs, {
            autoWidth: true,
            padding: 10,
            rewind: true,
            pagination: false,
            isNavigation: true,
            arrows: false,
            mediaQuery: 'min',
            breakpoints: {
                576: {
                    padding: 30,
                }
            },
        })

        const cards = this._rootMenuitemContainer.querySelectorAll(':scope > ' + SELECTOR.menucards)

        this._splide.on('ready', () => {
            const offsetY = this._rootMenuitemContainer.getBoundingClientRect().top
            cards.forEach(card => {
                card.style.top = offsetY + 'px'
                card.style.height = 'calc(100% - ' + offsetY + 'px)'
            })
        })

        this._splide.on('active', (slide) => {
            const index = slide.index
            cards[index].querySelector('.nav__menucard__scrollable_content').scrollTo({top: 0, behavior: 'instant'})
            for (let i = 0; i < cards.length; i++) {
                const card = cards[i]
                card.style.left = (100 * (i - index)) + '%'
            }

        })

        this._splide.mount();
    }
}

let instance = null

export function init() {
    if (!instance) {
        instance = new NavSlider(document.getElementById('mobile-nav__root-menuitem-container'));
    }
}

export function resetNavSlider() {
    if (instance) {
        instance._splide.go(0)
    }
}