'use strict'

import $ from 'jquery';
import fetch from '../../../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill
import initModulesInScope from "../../../libs/@elements/init-modules-in-scope";
import throwError from "../../../libs/@elements/throw-error"
import {showNotification} from "../../../libs/@elements/alert-notification";
import { addLoadingContainer, removeLoadingContainer } from "../../../scripts/loading";
import formDataEntries from 'form-data-entries';

const selectors = {
    deleteModal:         '.js-ajax-delete-product__modal',
    deleteForm:          '.js-ajax-delete-product__form',
    deleteAccordionElem: '.js-ajax-delete-product__accordion',
    deviceId:            (deviceId) => `[data-device-id="${deviceId}"]`,
    loadingContainer:    '.modal-content'
}

const attributes = {
    id:         'id',
    action:     'action',
    method:     'method'
}

const DELETE_MODAL_DEVICE_ID_PREFIX = 'modal-delete-product-';

export function initInScope() {
    const deleteModalElems = document.querySelectorAll(selectors.deleteModal) ?? [];

    if (!deleteModalElems.length) return;

    Array.prototype.slice.call(deleteModalElems).forEach(deleteModalElem => {
        initDeleteModal(deleteModalElem);
    })
}

function initDeleteModal(deleteModalElem) {
    const deviceId = getDeviceId(deleteModalElem);

    if (!deviceId) throw new Error('1669806535 – AJAX delete product: Could not determ device id');

    const
        deleteAccordionElem = getDeleteModalAccordion(deviceId),
        deleteForm          = deleteModalElem.querySelector(selectors.deleteForm),
        loadingContainer    = deleteModalElem.querySelector(selectors.loadingContainer);

    if (!deleteForm) throw new Error('1669806538 – AJAX delete product: No form element found');

    let
        pendingRequest,
        action = deleteForm.getAttribute(attributes.action),
        method = deleteForm.getAttribute(attributes.method);

    deleteForm.addEventListener('submit', (e) => {
        if (action) {
            e.preventDefault();
        } else {
            return;
        }

        if(pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        addLoadingContainer($(loadingContainer), [true, false]);

        pendingRequest = fetch(action, {
            method: method,
        });

        pendingRequest.then((res) => {
            return res.clone().json();
        }).then((res) => {
            if(res.success){
                removeLoadingContainer($(loadingContainer));
                $(deleteModalElem).modal('hide');
                setTimeout(() => {
                    deleteAccordionElem.remove()
                }, 300)
            } else {
                throw new Error(`1669806543 – AJAX delete product: ${res.msg}`);
            }
        }).catch(err => {
            console && console.error && console.error(err)
            $(deleteModalElem).modal('hide');
        })

        showNotification(pendingRequest);
    });
}

function getDeviceId(deleteModalElem) {
    const elemId = deleteModalElem.getAttribute(attributes.id);
    return elemId.replace(DELETE_MODAL_DEVICE_ID_PREFIX, '');
}

function getDeleteModalAccordion(deviceId) {
    return document.querySelector(`${selectors.deviceId(deviceId)}${selectors.deleteAccordionElem}`);
}
