'use strict'
import {setValue, setValues, removeValue} from "./productGridFilterMobile";
import {reloadGrid} from "../libs/@elements/product-grid";

const
    sliderOptions = {
        draggable: true,
        adaptiveHeight: false,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        dots: false,
        autoplay: false,
        mobileFirst: true,
        infinite: false,
        waitForAnimate: false,
        variableWidth: false,
        prevArrow: '',
        nextArrow: '',
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    prevArrow: '<button class="btn btn-primary product-grid-top-filter__btn-prev h-100"><i class="icon icon-arrow-left"></i></button>',
                    nextArrow: '<button class="btn btn-primary product-grid-top-filter__btn-next h-100"><i class="icon icon-arrow-right"></i></button>',
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1439,
                settings: {
                    prevArrow: '<button class="btn btn-primary product-grid-top-filter__btn-prev h-100"><i class="icon icon-arrow-left"></i></button>',
                    nextArrow: '<button class="btn btn-primary product-grid-top-filter__btn-next h-100"><i class="icon icon-arrow-right"></i></button>',
                    slidesToShow: 7,

                }
            }
        ]

    }

let $productGrid

export function init() {
    $productGrid = $('body').find('.js-product-grid').length === 1 ? $('body').find('.js-product-grid') : throwError(translate('product grid init error: no product grid forund!'))
}


export function initInScope($scope) {
    const
        $TopFilter = $scope.find('.js-product-grid-top-filter'),
        $filterCollapses    = $scope.find('.js-product-grid__filter-collapse'),
        $filterDropdowns    = $scope.find('.js-product-grid__filter-dropdown'),
        $filterCollapseBtns = $scope.find('.js-product-grid-top-filter__collapse-submit');

    let dropdownsOpen       = false;
    let gotoSlide = 1;

    if($TopFilter.length){
        $TopFilter.on('init', (e, slick) => {
            slick.$slides.each((i, el) => {
                const $slide = $(el);
                if($slide.find('.product-grid__category-link--active').length){
                    gotoSlide = i;
                }
            })
        })

        const filterSlider = $TopFilter.slick(sliderOptions)
        gotoSlide = gotoSlide - filterSlider.get(0).slick.options.slidesToShow + 1
        filterSlider.slick('slickGoTo', gotoSlide);

        if(window.matchMedia('(min-width: 767px)').matches) {
            if($TopFilter.find('.slick-slide').length > 5){
                $scope.find('.js-product-grid-top-filter').css('padding', '0 36px');
            }
        }
        if(window.matchMedia('(min-width: 1439px)').matches) {
            if($TopFilter.find('.slick-slide').length > 7){
                $scope.find('.js-product-grid-top-filter').css('padding', '0 36px');
            }
        }
    }


    function checkClick(e){
        const $clickTarget = $(e.target);
        if(!$filterDropdowns.is(e.target)
            && $clickTarget.parents('.product-grid__filter-dropdown-content').length === 0){
            $filterDropdowns.dropdown('hide');
            $filterDropdowns.attr('aria-expanded', false)
            dropdownsOpen = false;
            $('body').off('click.gridFilter', checkClick)
        }
    }

    $filterDropdowns.on('click', function (e) {
        if(dropdownsOpen){
            e.stopImmediatePropagation();
            const
                $targetDropdown = $('.js-product-grid__filter-dropdown[aria-expanded="true"]');
            $filterDropdowns.dropdown('hide');
            dropdownsOpen = false
            $targetDropdown.attr('aria-expanded', false)
            $('body').off('click.gridFilter', checkClick)
        }else{
            $(this).dropdown('show');
            $(this).attr('aria-expanded', true)
            $('body').on('click.gridFilter', checkClick)
            dropdownsOpen = true;
        }
    });

    $filterCollapses.each((_,el) => {
        const
            $filterBtn  = $(el),
            $target     = $filterBtn.nextAll('.js-product-grid__filter-collapse-target'),
            $input      = $filterBtn.nextAll('input[type=hidden]');

        $filterBtn.on('click', function (e) {
            e.preventDefault();
            if($target.hasClass('collapsing')){
                return
            }
            if($target.hasClass('show')){
                $target.stop().collapse('hide');
                $filterBtn.removeClass('show');
                $input.attr('value', false)
            }else{
                $target.stop().collapse('show');
                $filterBtn.addClass('show');
                $input.attr('value', true)
            }
        })
    })

    $filterCollapseBtns.on('click', (e) => {
        submitFilterDropdown(e)
    })

    function submitFilterDropdown(e, $targ = null){
        const $target = $targ ? $targ : $(e.target);
        const $dropdown = $target.closest('.dropdown-menu');

        const $additionalInputs     = getChangeInputElements($dropdown);
        $additionalInputs.each((_, el) => {
            const $input = $(el);
            if($input.is(':checkbox')){
                if($input.is(':checked')){
                    setValue($input.prop('name'), $input.val())
                }else {
                    removeValue($input.prop('name'), $input.val())
                }
            }else if($input.is(':radio')) {
                if($input.is(':checked')){
                    setValue($input.prop('name'), $input.val())
                }
            }else{
                if($input.val()){
                    setValue($input.prop('name'), $input.val());
                }
            }
        })

        const $submitFilterInputs   = getSubmitFilterInputs($dropdown)
        $submitFilterInputs.each((_, el) => {
            const $input = $(el);
            if($input.is(':checkbox')){
                if($input.is(':checked')){
                    setValue($input.prop('name'), $input.val())
                }
                else {
                    removeValue($input.prop('name'), $input.val())
                }
            }else if($input.is(':radio')) {
                if($input.is(':checked')){
                    setValue($input.prop('name'), $input.val())
                }
            }else{
                if($input.val()){
                    setValue($input.prop('name'), $input.val());
                }
            }
        })

        reloadGrid($productGrid);
    }
}




/* get all inputs that trigger a reaload on change*/
function getChangeInputElements($scope) {
    return $scope.find(
        'input, ' +
        'select'
    ).not(
        '.js-product-grid__submit-section .js-product-grid__input, ' +
        '.js-product-grid__submit-section input, ' +
        '.js-product-grid__submit-section select' +
        '.js-product-grid__mobile-submit-section .js-product-grid__input, ' +
        '.js-product-grid__mobile-submit-section input, ' +
        '.js-product-grid__mobile-submit-section select, ' +
        '.js-product-grid__mobile-filter-ignore'
    );
}

function getSubmitFilterInputs($scope) {
    return $scope.find(
        '.js-product-grid__mobile-submit-section input, ' +
        '.js-product-grid__mobile-submit-section select'
    )
}