import {MenuPositioner} from "./LevelMenu/TransitionElements/MenuLevel/MenuPositioner";
import {LevelMenuStateObject} from "./LevelMenu/state/LevelMenuStateObject";
import {LevelMenuState} from "./LevelMenu/state/LevelMenuState";
import {MenuLevelContainerLayer} from "./LevelMenu/TransitionElements/MenuLevel/MenuLevelContainerLayer";
import {DOM_MenuContainerCache} from "./LevelMenu/DOM_MenuContainerCache";
import {Executor} from "../shared/core/TransitionTree/Executor";
import {LevelMenu_ScenarioMatching} from "./LevelMenu/TransitionsConfig/scenarioMatching";
import {LevelMenuTriggers} from "./LevelMenu/LevelMenuTriggers";
import {Desktop_MainMenuTriggers, DESKTOP_MAIN_MENUS, DESKTOP_MAIN_MENUS__DROPDOWNS, TOPLEVEL} from "./MainMenu/Desktop_MainMenuTriggers";
import {Desktop_MainMenuStateObject} from "./MainMenu/Desktop_MainMenuStateObject";
import {DOM_MainMenuItemCache} from "../shared/MainMenu/DOM_MainMenuItemCache";
import {MainMenuState} from "../shared/MainMenu/MainMenuState";
import {LevelMenu_Toplevel_ScenarioMatching} from "./LevelMenu/TransitionsConfig/scenarioMatching.topLevel";
import {BasicScenarioFinder} from "../shared/core/TransitionTree/BasicScenarioFinder";
import {DropDownMenu} from "../shared/DropdownMenu";
import {Tr_DesktopOverlay} from "./MainMenu/Overlay";
import {Tr_ImageTiles_Move} from "./LevelMenu/TransitionElements/ImageTiles/Tr_ImageTiles_Move";
import {LevelMenu_Shared_Scenarios} from "./LevelMenu/TransitionsConfig/scenarios.shared";
import {TRANSLATE_OVERRIDES} from "./LevelMenu/TransitionsConfig/translate_overrides"

export function init() {
    const menuDesktop = document.getElementById('desknav-level1p-container')
    if (!menuDesktop) {
        return
    }

    if (document.readyState === 'interactive') {
        _init()
    } else {
        window.addEventListener('DOMContentLoaded', () => _init())
    }
}

function _init() {

    Tr_ImageTiles_Move.init()

    LevelMenuStateObject.set(LevelMenuState.createInitialState(1, 4))
    DOM_MenuContainerCache.initialize(0, 1, 2, 3)
    DOM_MainMenuItemCache.initialize(...Object.values(DESKTOP_MAIN_MENUS))

    MenuLevelContainerLayer.init_MenuContainers_and_MenuItems(1, 2, 3)

    MenuPositioner.init(TRANSLATE_OVERRIDES)

    Desktop_MainMenuStateObject.set(MainMenuState.createInitialState())

    LevelMenuTriggers.initLevels((scenarioData) => {
        const levelStateObject = LevelMenuStateObject.get()
        const activeLevel = LevelMenu_Shared_Scenarios.getHighestActiveLevel(levelStateObject)
        if (!activeLevel || scenarioData.level > activeLevel + 1) {
            return
        }

        return Executor.handle(LevelMenu_ScenarioMatching.findScenario, levelStateObject, scenarioData, [])
    }, 2, 3)


    LevelMenuTriggers.initLevels(levelMenu_scenarioData => {
        const levelStateObject = LevelMenuStateObject.get()
        // return Executor.handle(LevelMenu_Toplevel_ScenarioMatching.findScenario, levelStateObject, levelMenu_scenarioData, []) // for debugging toplevel without mainmenu integration

        const mainMenuStateObject = Desktop_MainMenuStateObject.get()
        const scenario = LevelMenu_Toplevel_ScenarioMatching.findScenario(levelStateObject, levelMenu_scenarioData)

        const closeFunc = (isTransitionCanceledFunc) => {
            const closeScenario = LevelMenu_Toplevel_ScenarioMatching.getCloseScenario()

            return Executor.handleScenario(
                closeScenario,
                levelStateObject,
                levelMenu_scenarioData,
                [mainMenuStateObject]
            );
        }

        const openFunc = (isTransitionCanceledFunc) => {
            const actualScenario = LevelMenu_Toplevel_ScenarioMatching.findScenario(levelStateObject, levelMenu_scenarioData, true)
            return Executor.handleScenario(
                actualScenario,
                levelStateObject,
                levelMenu_scenarioData,
                [mainMenuStateObject]
            )
        }

        if (scenario.scenarioType === BasicScenarioFinder.MENU_TRIGGER_TYPES.CLOSE) {
            return Desktop_MainMenuTriggers.close()
        }

        return Desktop_MainMenuTriggers.open(DESKTOP_MAIN_MENUS.DESKTOP_NAME_LEVELMENU, openFunc, closeFunc, levelMenu_scenarioData.menuElement)

    }, TOPLEVEL)


    Desktop_MainMenuTriggers.initDropdownMenus((menuName) => Desktop_MainMenuTriggers.triggerDropdown(menuName))

    DropDownMenu.initTriggers(Desktop_MainMenuTriggers.close, Object.values(DESKTOP_MAIN_MENUS__DROPDOWNS))

    Tr_DesktopOverlay.initCloseTrigger(Desktop_MainMenuTriggers.close)
    _initCloseButtons();
}

function _initCloseButtons() {
    document.getElementById('desktop-nav').querySelectorAll(':scope .js-menu-close-button').forEach(el => {
        el.addEventListener('click', Desktop_MainMenuTriggers.close)
    })
}
