'use strict';
import * as smoothScroll from '../../libs/@elements/smooth-scroll';

let options = {
    offset: window.matchMedia('(max-width: 991px)').matches ? 75 : 100
}

export function init() {
    smoothScroll.init(options);
}

export function initInScope($scope){
    smoothScroll.initInScope($scope);

    const $links = $scope.find('.js-smoothscroll__link');

    $links.on('click', (e) => {
        e.stopImmediatePropagation();
        smoothScroll.setOffset(window.matchMedia('(max-width: 991px)').matches ? 75 : 100)
    })
}


