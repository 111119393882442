'use strict';

import {
    _setCssBeginClasses,
    _setCssEndClasses,
    debugCssElementFunc, debugFindElementName,
    findMaxTransitionDurationInElement,
    hasElementAppliedTransitions,
    maybeSetCssDisplayClass,
    maybeUnsetCssDisplayClass,
    wait
} from "./functions";

const EXTRA_DELAY_AFTER_TIMEOUT = 30
const TIMEOUT_BEFORE_SETTING_LISTENERS = 10

export const doCssTransition = async (conf) => {
    await maybeSetCssDisplayClass(conf)

    try {
        await startCssTransition(conf)

    } catch (e) {
        if (e instanceof TimeoutError) {
            debugCssElementFunc(conf, e.message)
        } else {
            debugCssElementFunc(conf, 'some error occurred while css transitioning.', e.message)
        }
    }

    if (!conf.isCanceledFunc(debugFindElementName(conf))) {
        debugCssElementFunc(conf, 'setting endstate')

        _setCssEndClasses(conf)
        maybeUnsetCssDisplayClass(conf)

    } else {
        debugCssElementFunc(conf, 'was cancelled, not setting endstate')
    }
}

/**
 * @param conf
 * @return {Promise<void>}
 */
const startCssTransition = async (conf) => {
    //one of both will start the transition effectively
    _setCssBeginClasses(conf);
    await conf.beginTransFunction()

    if (!hasElementAppliedTransitions(conf.transitioningElement)) {
        console.error('no transitions applied, 584848592921', conf)//todo, this problem should not occurr, otherwise there is maybe a problem with the delays (see CONSTs)
        return
    }

    await wait(TIMEOUT_BEFORE_SETTING_LISTENERS)

    await _setupAndWaitForEvents(conf.transitioningElement)
}

/**
 * the transition can end in 3 ways:
 * 1. transition ends successfully
 * 2. transition gets cancelled
 * 3. the transition is somehow stuck and a timeout occurs
 *
 * @param {HTMLElement} transElement
 */
const _setupAndWaitForEvents = (transElement) => new Promise((resolve, reject) => {
    const cancelFunc = (evt) => {
        transElement.removeEventListener('transitionend', endFunc)
        clearTimeout(timeout)

        reject(new CSSTransitionCanceledError(transElement, evt))
    };

    const endFunc = () => {
        transElement.removeEventListener('transitioncancel', cancelFunc)
        clearTimeout(timeout)

        resolve()
    };

    let timeout = setTimeout(
        () => {
            transElement.removeEventListener('transitionend', endFunc);
            transElement.removeEventListener('transitioncancel', cancelFunc);
            reject(new TimeoutError(transElement))
        },
        _calcTimeoutValue(transElement)
    );

    transElement.addEventListener('transitionend', endFunc, {once: true})
    transElement.addEventListener('transitioncancel', cancelFunc, {once: true})
})

const _calcTimeoutValue = transElement => findMaxTransitionDurationInElement(transElement) + EXTRA_DELAY_AFTER_TIMEOUT;

class TimeoutError extends Error {
    constructor(element) {
        super('timeout occured');
        this.element = element
    }
}

class CSSTransitionCanceledError extends Error {
    constructor(element, cssEvent) {
        super('the css transition was directly cancelled (probably through user interaction)');
        this.element = element
        this.cssEvent = cssEvent
    }
}