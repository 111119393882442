'use strict';

import {TAnyNode, TResolvedNode} from "./NodesClasses";

/**
 * convenience function to create linked Nodes out of an array
 * @param {(TAnyNode|TAction)[][]} steps
 * @return {TAnyNode}
 */
const createPath = (steps) => {
    if (steps.length < 1) {
        throw new Error('not enough nodes to create a path, 0394853udns')
    }

    const nodes = steps.map(_nodeAndActionsArray_or_anyNode => {
        if (Array.isArray(_nodeAndActionsArray_or_anyNode)) {
            const filtered = _nodeAndActionsArray_or_anyNode.filter(x => x)
            if (!filtered.length) {
                return undefined
            }
            return new TResolvedNode(filtered);
        }

        if (_nodeAndActionsArray_or_anyNode instanceof TAnyNode) {
            return _nodeAndActionsArray_or_anyNode
        }

        return undefined
    }).filter(x => x)

    makeListLinked(nodes)

    // noinspection JSValidateTypes
    return nodes[0]
}

/**
 * @param {TAnyNode[]} nodes
 * @return {TAnyNode}
 */
const makeListLinked = nodes => {
    nodes.forEach((_node, index) => _node.nextNode = nodes[index + 1] || null);
};

/**
 * joins two paths by chaining the second one to the first
 *
 * @param {TAnyNode} path1
 * @param {TAnyNode} path2 - if null it will return the first path
 * @return {TAnyNode} - head of the joined path/tree
 */
const joinPaths = (path1, path2) => {
    if (path2 instanceof TResolvedNode) {
        getLastNode(path1).nextNode = path2
    }
    return path1
};

/**
 * @param {TAnyNode} head
 * @return {TAnyNode}
 */
const getLastNode = head => {
    let currentNode = head;
    while (currentNode && currentNode.nextNode) {
        currentNode = currentNode.nextNode;
    }
    return currentNode;
}

/**
 * @param {undefined|TAnyNode} previousNode
 * @param {TUnresolvedNode} nodeToReplace
 * @param {TAnyNode} headToReplaceWith
 * @return {TAnyNode} head of the replacement
 */
const replaceWithChain = (previousNode, nodeToReplace, headToReplaceWith) => {
    //connect to previous node
    if (previousNode) {
        previousNode.nextNode = headToReplaceWith
    }

    //connect to following node
    if (nodeToReplace.nextNode) {
        getLastNode(headToReplaceWith).nextNode = nodeToReplace.nextNode
    }

    return headToReplaceWith
}

export const TNodeHelper = Object.freeze({
    createPath,
    joinPaths,
    getLastNode,
    replaceWithChain
})