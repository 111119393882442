"use strict";
export function initInScope ($scope) {

    $scope.on('click', function (e) {
        if (!$(e.target).length) {
            if (console && console.warn) {
                console.warn('no event target', e, e.target);
            }
            return
        }

        const $trigger = $(e.target).closest('.js-accordion-custom-toggle');

        if (!$trigger.length) {
            if (console && console.warn) {
                console.warn('no trigger element', $trigger);
            }
            return
        }

        const targetSelector = $trigger.data('target'),
              collapseToggle = $trigger.data('collapse'),
              isMultiTargetDisabledMobile = $trigger.data('multitarget-disabled-mobile') !== undefined,
              isMobile = isMobileView();

        if (!targetSelector) {
            if (console && console.warn) {
                console.warn('no target selector', targetSelector);
            }
            return
        }

        if (collapseToggle === undefined || collapseToggle === null) {
            if (console && console.warn) {
                console.warn('no collapse toggle directive');
            }
            return
        }

        const $targets = isMultiTargetDisabledMobile && isMobile
                ? $(targetSelector.split(',')[0])
                : $(targetSelector);

        if (!$targets.length) {
            if (console && console.warn) {
                console.warn('no target elements', $targets, targetSelector);
            }
            return
        }

        $targets.each(function() {
            if (collapseToggle) {
                $(this).collapse('show');
                if(isMultiTargetDisabledMobile && isMobile) {
                    // Place collapsible item in viewport of off-canvas (Mobile view - issue with collapse and slide up effect)
                    setTimeout(function () {
                        const offset = 130;
                        const elementPosition = $(this).get(0).getBoundingClientRect();
                        if(elementPosition.top < offset) {
                            $('html, body').animate({
                                scrollTop: $(this).offset().top - offset
                            }, 400);
                        }
                    }.bind(this), 800)
                }
            } else {
                $(this).collapse('hide');
            }
        })

        function isMobileView() {
            return window.innerWidth < 1024;
        }
    });
}

export function updateCollapseStateOfRelatedTriggers (event, collapseToggle) {
    if (!event.currentTarget || !event.currentTarget.activeElement) {
        if (console && console.warn) {
            console.warn('could not find related triggers');
        }
        return;
    }

    // Target CSS ID quickfix
    let targetSelectorString = '#' + event.target.id + ',' + $(event.currentTarget.activeElement).data('target');

    // Partial multi target quickfix
    if ($(event.target).data('partial-multi-target')) {
        targetSelectorString = '#' + event.target.id;
    }

    if (!targetSelectorString) {
        if (console && console.warn) {
            console.warn('no target selector', targetSelectorString);
        }
        return
    }

    const targetSelectors = targetSelectorString.split(',');
    let relatedTriggers = [];

    Array.prototype.slice.call(targetSelectors).forEach(function(targetSelector) {
        $('[data-target*="' + targetSelector + '"]').each(function() {
            if (relatedTriggers.indexOf(this) !== -1) {
                return;
            }
            relatedTriggers.push(this);
        })
    });

    Array.prototype.slice.call(relatedTriggers).forEach(function(relatedTriggerElement) {
        $(relatedTriggerElement).attr('aria-expanded', collapseToggle)
                                .toggleClass('collapsed', !collapseToggle)
    })
}
