export class ExecutionLimiter {
    constructor(max) {
        this._max = max
        this._count = 1
    }

    inc() {
        if (this._count > this._max) {
            throw new NumberOfExecutionsExceeded(this._max)
        }
        this._count++
    }
}

export class NumberOfExecutionsExceeded extends Error {
    constructor(maximum) {
        super('the value of max executions has exceeded: ' + maximum);
    }
}