'use strict';
import fetch from '../libs/@elements/fetch';
import initModulesInScope from "../libs/@elements/init-modules-in-scope";

export function initInScope($scope) {
    let $link       = $scope.find('.js-storyHubTeaser__category'),
        $content    = $scope.find('.js-storyHubTeaser__content')
    
    if($link.length){
        let pendingRequest;
        $link.on('click', (e) => {
            e.preventDefault();
            let showall;
            let $target = $(e.target).is('a') ? $(e.target) : $(e.target).closest('a');
            let url = $target.attr('href');

            if(pendingRequest){
                pendingRequest.abort();
            }

            if($target.hasClass('js-storyHubTeaser__category--active')){
                url = window.location.pathname + "?ajax=1&full-ajax-form=1";
                showall = true;
            }

            pendingRequest = fetch( url , {
                method: 'POST'
            } )

            $scope.find('.js-loading').removeAttr('hidden')

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                $scope.find('.js-loading').attr('hidden', 'hidden')
                if (res.success) {
                    $link.removeClass('active js-storyHubTeaser__category--active');
                    if(showall){
                        $target.closest('.js-storyHubTeaser__nav').find('.js-storyHubTeaser__category--all').addClass('active js-storyHubTeaser__category--active')
                    }else{
                        $target.addClass('active js-storyHubTeaser__category--active');
                    }
                    $content.html(res.html)
                    initModulesInScope($content)
                }
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    console.error(error);
                    $scope.find('.js-loading').attr('hidden', 'hidden')
                }
            })
        });
    }
    if($content.length){
        $content.each((_, storyHubTeaser) => {
            const
                $storyHubTeaser = $(storyHubTeaser)

            let headerHeight;

            $storyHubTeaser.on('fetch.ajax-form', () => {

                if(window.matchMedia('(min-width: 992px)').matches) {
                    headerHeight    = 125;
                }else{
                    headerHeight    = 75;
                }

                $('html, body').animate({
                    scrollTop: $storyHubTeaser.closest('.content-block').offset().top - headerHeight
                }, 500);


            })
        })
    }
}