import $ from 'jquery';
import { translate } from '../../../../../shared/translations/translations';
import { getPrefixedDataSet } from '../data-set-utils';
import { showNotification } from '../alert-notification';
import fetch from '../fetch'
import 'url-search-params-polyfill'; // Edge Polyfill

const defaultOptions = {
    wishlistName: '',
    activeIds: [],
    routeAdd: '',       //Todo @Backend set route
    routeRemove: '',    //Todo @Backend set route
    notificationOptions: {
        $container: $('.js-wishlist__notification')
    },
    translations: {
        add: 'wishlist.add',
        remove: 'wishlist.remove'
    }
};
const defaultSelectors = {
    item: '.js-wishlist__btn',
    itemId: 'wishlist-id',
    wishlistId: 'wishlist-list',
    itemActiveClass: 'is-active'
};

export function createWishlist(options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options
    };
    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    let _requests = {};

    const addToWishlist = function(id, params = {}) {
        if(_requests[id]){
            return;
        }

        options.activeIds.push(id);
        notifyCallbacks('adding');

        _requests[id] = fetch(options.routeAdd, {
            body: new URLSearchParams({
                id: id,
                wishlistId: options.wishlistName ? options.wishlistName : '',
                ...params
            })
        });
        showNotification(_requests[id], options.notificationOptions);
        _requests[id].then(function (res) {
            return res.clone().json()
        })
        .then(function (res) {
            options.activeIds = res.activeIds;
            notifyCallbacks('added');
        }).catch(function () {
            options.activeIds.splice(options.activeIds.indexOf(id), 1);
            notifyCallbacks('add-failed');
        }).finally(function () {
            _requests[id] = null;
        });

        return _requests[id];
    };

    const removeFromWishlist = function(id, params = {}) {
        if(_requests[id]){
            return;
        }

        options.activeIds.splice(options.activeIds.indexOf(id), 1);
        notifyCallbacks('removing');

        _requests[id] = fetch(options.routeRemove, {
            body: new URLSearchParams({
                id: id,
                wishlistId: options.wishlistName ? options.wishlistName : '',
                ...params
            })

        });
        showNotification(_requests[id], options.notificationOptions);
        _requests[id].then(function (res) {
            return res.clone().json()
        }).then(function (res) {
            options.activeIds = res.activeIds;
            notifyCallbacks('removed');
        }).catch(function () {
            options.activeIds.push(id);
            notifyCallbacks('remove-failed');
        }).finally(function () {
            _requests[id] = null;
        });

        return _requests[id];
    };

    let callbacks = [];
    const onStateChange = function(callback) {
        callbacks.push(callback);
    };
    const notifyCallbacks = function(eventname) {
        callbacks.forEach(fnc => fnc(options.activeIds));
        if(eventname){
            triggerEvent(eventname)
        }
    };
    const getState = function () {
        return options.activeIds;
    };

    const triggerEvent = function(eventname){
        $(document).trigger(eventname + '.wishlist' , {list: selectors.wishlistId, activeIds: options.activeIds})
    };

    const renderWishlist = function ($scope = $('body')) {
        triggerEvent("renderd");
        let $wishButtons = $scope.find(selectors.item);
        $wishButtons.each((_, el) => {
            let $button = $(el),
                id = $button.data(selectors.itemId),
                isInList = options.activeIds.includes(id);

            if (isInList) {
                $button.attr('title', translate(options.translations.remove))
                    .attr('aria-label', translate(options.translations.remove))
                    .addClass(selectors.itemActiveClass)
            } else {
                $button.attr('title', translate(options.translations.add))
                    .attr('aria-label', translate(options.translations.add))
                    .removeClass(selectors.itemActiveClass)}
        })
    };


    const init = function () {
        $('body').on('click', selectors.item, function() {
            const id = $(this).data(selectors.itemId);
            if(options.activeIds.includes(id)){
                removeFromWishlist(id, getPrefixedDataSet('wishlist', $(this)));
            }else{
                addToWishlist(id, getPrefixedDataSet('wishlist', $(this)));
            }
        });
        onStateChange(()  => renderWishlist());
    };

    return {
        init: init,
        initInScope: renderWishlist,
        renderWishlist: renderWishlist,
        add: addToWishlist,
        remove: removeFromWishlist,
        getState: getState,
        onstatechange: onStateChange
    }
}