'use strict'

/**
 * makes all shorter rows (divs etc) sticky
 */

import {debounce} from "debounce";

export function init(className) {
    window.addEventListener('load', () => {

        const columns = Array.from(document.getElementsByClassName(className))

        window.addEventListener('resize', () => setStickiness(columns));

        initSizeObserver(columns)
    });
}

function initSizeObserver(columns, _setStickinessUnique) {
    const setStickinessUnique = debounce(() => setStickiness(columns), 200)

    for (const column of columns) {
        new ResizeObserver(setStickinessUnique).observe(column)
    }
}

function setStickiness(columns) {
    const isMobile = matchMedia('(max-width: 767px)').matches;

    if (isMobile) {
        resetColumnStickiness(columns)
    } else {
        makeColumnsSticky(columns);
    }
}

function makeColumnsSticky(columns) {
    const max = Math.max(...Array.from(columns).map((el) => el.offsetHeight))

    for (const column of columns) {
        const isSticky = column.offsetHeight < max
        if (isSticky) {
            column.classList.add('sticky-column')
        } else {
            column.classList.remove('sticky-column')
        }

    }
}

function resetColumnStickiness(allColumns) {
    for (const column of allColumns) {
        column.classList.remove('sticky-column')
    }
}
