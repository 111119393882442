'use strict'

import {onEnterViewPort} from '../../libs/@elements/viewport-utils';

export function initInScope ($scope) {

    const $videos = $scope.find('.js-video--play-when-visible');

    onEnterViewPort($videos, function($video) {
        $video[0].play();
    });
}
