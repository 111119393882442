'use strict'

export function initInScope($scope) {
    const $iframes = $scope.find('.js-iframe-resizing');

    window.addEventListener('message', function (msg, ...rest) {
        if (typeof msg.data === 'object' && msg.data.height) {
            $iframes.each((_,iframe) => {
                if (iframe.getAttribute('src').indexOf(msg.origin) >= 0) {
                    iframe.style.height = msg.data.height + 'px';
                    iframe.setAttribute('scrolling', 'no');
                }
            })
        }
    }, false);

    $iframes.each((_,iframe) => {
        iframe.addEventListener('load', function () {
            iframe.contentWindow.postMessage('getHeight', '*');
        });
    })
}

