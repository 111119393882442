import $ from 'jquery';

import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap-5/js/dist/tab';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "../libs/@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('../libs/@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

import {cachedFetches} from "../../../shared/cached-fetches/cached-fetches";
window.cachedFetches = cachedFetches.init();

import {unhideByLoginState} from '../../../shared/unhide-by-login-state/unhide-by-login-state'
unhideByLoginState()

import {hideByLoginState} from '../../../shared/hide-by-login-state/hide-by-login-state'
hideByLoginState()

import './scripts/loading'


/**********************************
 *
 *      Cookies
 *
 * ********************************/

import * as cookieOverlay from '../../../shared/cookie-overlay/cookieOverlay';
cookieOverlay.register();

import * as youtubeVideos from '../../../shared/youtube-videos/youtubeVideos';
youtubeVideos.register();

import * as cookieInformation from '../../../shared/cookie-overlay/cookieInformation'
cookieInformation.init();
window.initializeCookieElements = cookieInformation.initializeCookieElements

import * as cookieOverlaysInLightGallery from '../../../shared/cookie-overlay/cookieOverlaysInLightGallery';
window.initializeCookieOverlaysInLightGallery = cookieOverlaysInLightGallery.initializeCookieOverlaysInLightGallery

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import { init as notificationInit, PLACEMENT_TYPES }  from '../libs/@elements/alert-notification';
notificationInit();

import * as ejb from '../libs/@elements/elements-job-bundle';
app.modules.ejbAjaxForm = ejb.ajaxForm.initInScope;
app.modules.ejbDateInput = ejb.dateInput.initInScope;
app.modules.ejbGallery = ejb.gallery.initInScope;

import './../scripts/unfocus'

import * as browser from './../scripts/detectBrowser';
app.browser = browser.init;

import * as lightbox from '../libs/@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as ajaxInclude from './../scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '../libs/@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '../libs/@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '../libs/@elements/lazy-img';
app.modules.lazyImg = lazyImg.createInitInScope(
    {
        base: '.js-lazy-img',
        backgroundModifier: '.js-lazy-img--bg'
    },
    {
        offset: window.innerHeight / 2,
        preventNative: false
    });

import * as lazyIframe from '../libs/@elements/lazy-iframe';
app.modules.lazyIframe = lazyIframe.initInScope;

import * as shariff from './../scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as raffle from './../scripts/raffle';
app.raffle = raffle.init;
app.modules.raffle = raffle.initInScope;

import * as accordionScroll from './../scripts/accordionScroll';
app.modules.accordionScroll = accordionScroll.initInScope;

import * as datepickerForm from './../scripts/datepickerForm';
app.modules.datepickerForm = datepickerForm.initInScope;

import * as dateInput from '../libs/@elements/date-input';
app.modules.dateInput = dateInput.initInScope;

import * as smoothScroll from './scripts/smooth-scroll';
app.smoothScroll = smoothScroll.init();
app.modules.smoothScroll = smoothScroll.initInScope;

import * as scrollTo from './scripts/scrollTo';
app.modules.scrollTo = scrollTo.initInScope

import * as imageSlider from './scripts/imageSlider';
app.modules.imageSlider = imageSlider.initInScope;

import * as heroSlider from './scripts/heroSlider';
app.modules.heroSlider = heroSlider.initInScope;

import * as storyHubTeaser from './../scripts/storyHubTeaser';
app.modules.storyHubTeaser = storyHubTeaser.initInScope;

import * as globalAlert from './../scripts/globalAlert';
app.modules.globalAlert = globalAlert.initInScope;

import * as header from './scripts/header';
app.header = header.init;

import * as mobileMenu from './scripts/navigation/mobile/main';
cachedFetches.get('is_user_logged_in').then(()=> mobileMenu.init()).catch(()=>{})

import * as desktopMenu from './scripts/navigation/desktop/main';
desktopMenu.init()

import * as mobileDesktopMenuSwitch from './scripts/navigation/shared/mobile-desktop-switch'
mobileDesktopMenuSwitch.init()

import * as parsley from '../libs/@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;
import * as parsleyPassword from './../scripts/parsleyPassword';
app.modules.parsleyPassword = parsleyPassword.initInScope;
import * as parsleyZIP from './../scripts/parsleyZip';
app.modules.parsleyZIP = parsleyZIP.initInScope;
import * as customFile from './../scripts/customFile';
app.modules.customFile = customFile.initInScope;

import * as friendlyCaptchaV1 from './../scripts/friendlyCaptchaV1';
app.modules.friendlyCaptchaV1 = friendlyCaptchaV1.initInScope;

import * as actionChanger from '../libs/@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as popover from './../scripts/popover';
app.modules.popover = popover.initInScope;
import * as tooltip from './../scripts/tooltip';
app.modules.tooltip = tooltip.initInScope;

import * as cart from './../scripts/cart';
app.modules.cart = cart.initInScope;

import * as contentTeam from './../scripts/contentTeam';
app.modules.contentTeam = contentTeam.initInScope;

import * as collapseRow from './scripts/ng-collapse-row';
app.modules.collapseRow = collapseRow.init;

import * as scrollingArea from './scripts/ng-scrolling-area';
app.modules.scrollingArea = scrollingArea.init;

import * as jsVideo from './../scripts/jsVideo';
app.modules.jsVideo = jsVideo.initInScope;

import * as dropdownmenu from './scripts/dropdownMenu';
app.modules.dropdownmenu = dropdownmenu.initInScope;

import * as pds from './scripts/pds';
app.modules.pds = pds.initInScope;

import * as teaserCardsSlider from './scripts/teaserCardsSlider';
app.modules.teaserCardsSlider = teaserCardsSlider.initInScope;

import * as pdsLightbox from './scripts/pdsLightbox';
app.modules.pdsLightbox = pdsLightbox.initInScope;

import * as additionalInfo from './../scripts/additionalInfo';
app.modules.additionalInfo = additionalInfo.initInScope;

import * as teaserGrid from './../scripts/teaserGrid';
app.modules.teaserGrid = teaserGrid.initInScope;

import * as overlay from './../scripts/overlay';
app.overlay = overlay.init;
app.modules.overlay = overlay.initInScope;

import * as squarelovin from './../scripts/squarelovin';
app.modules.squarelovin = squarelovin.initInScope;

import * as cartDropdown from './scripts/cart-dropdown';
app.cartDropdown = cartDropdown.init;
app.modules.cartDropdown = cartDropdown.initInScope;

import * as tracking from './scripts/tracking';
app.modules.tracking = tracking.initInScope;

import * as paymentPending from './../scripts/paymentPending';
app.modules.paymentPending = paymentPending.initInScope;

import * as swissModal from './scripts/swissModal';
app.modules.swissModal = swissModal.initInScope;

import * as newsletterBanner from './scripts/newsletterBanner';
app.modules.newsletterBanner = newsletterBanner.initInScope;

import * as newsletterPopup from './scripts/newsletterPopup';
app.newsletterPopup = newsletterPopup.init;

import * as iFrameResize from './../scripts/iFrame-resizing';
app.modules.iFrameResize = iFrameResize.initInScope;

import * as addToCart from './scripts/addToCart';
app.addToCart = addToCart.init;
app.modules.addToCart = addToCart.initInScope;
import * as pdsSizeChange from './scripts/pds/pdsSizeChange';
app.pdsSizeChange = pdsSizeChange.init;

import * as cartBadge from './../scripts/cartBadge';
app.modules.cartBadge = cartBadge.initInScope;

import * as ajaxLogin from './../scripts/ajaxLogin';
app.modules.ajaxLogin = ajaxLogin.initInScope;

import * as ajaxAddressModal from './../scripts/ajaxAddressModal';
app.modules.ajaxAddressModal = ajaxAddressModal.initInScope;

import * as numberSpinner from './../scripts/numberSpinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as addressValidation from './../scripts/addressValidation';
app.modules.addressValidation = addressValidation.initInScope;

import * as ajaxModal from './../scripts/ajaxModal';
app.ajaxModal = ajaxModal.init;
app.modules.ajaxModal = ajaxModal.initInScope;

import * as ajaxFlyout from './scripts/ajaxFlyout';
app.ajaxFlyout = ajaxFlyout.init;
app.modules.ajaxFlyout = ajaxFlyout.initInScope;

import * as proAccess from './scripts/proAccess';
app.modules.proAccess = proAccess.initInScope;

import * as reactiveConditions from './scripts/reactiveConditions';
app.modules.reactiveConditions = reactiveConditions.initInScope;

import * as ajaxForm from '../libs/@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    submitOnChange: false,
    addUrlParams: false
});

import * as ajaxAccount from './../scripts/ajaxAccount';
app.modules.ajaxAccount = ajaxAccount.initInScope;

import * as productGridFilterMobile from './scripts/productGridFilterMobile';
app.productGridFilterMobile = productGridFilterMobile.init;
app.modules.productGridFilterMobile = productGridFilterMobile.initInScope;

import * as productGrid from '../libs/@elements/product-grid';
app.productGrid = productGrid.init;
app.modules.productGrid = productGrid.initInScope;
import * as productGridSlider from './../scripts/productGridSlider';
app.modules.productGridSlider = productGridSlider.initInScope;
import * as productGridItem from './scripts/productGridItem';
app.modules.productGridItem = productGridItem.initInScope;
import * as productGridTopFilter from './../scripts/productGridTopFilter';
app.productGridTopFilter = productGridTopFilter.init;
app.modules.productGridTopFilter = productGridTopFilter.initInScope;

import {init as initStickyColumns} from '../../../shared/sticky-columns/sticky-columns'
initStickyColumns('pdp-column')

import * as notifyAvailability from './scripts/notifyAvailability';
app.modules.notifyAvailability = notifyAvailability.initInScope;

import * as linkWithInputVal from './../scripts/linkWithInputVal';
app.modules.linkWithInputVal = linkWithInputVal.initInScope;

import * as payment from './../scripts/payment';
app.modules.payment = payment.initInScope;

import * as ajaxShippingMethods from './../scripts/ajaxShippingMethods';
app.modules.ajaxShippingMethods = ajaxShippingMethods.initInScope;

import * as collapsingCheckbox from './../scripts/collapsingCheckbox';
app.modules.collapsingCheckbox = collapsingCheckbox.initInScope;

import * as langModal from './../scripts/langModal';
app.modules.langModal = langModal.initInScope;

import * as submitForm from './../scripts/submitForm';
app.modules.submitForm = submitForm.initInScope;

import * as submitFormBuilderForm from './../scripts/submitFormBuilderForm';
app.modules.submitFormBuilderForm = submitFormBuilderForm.initInScope;

import * as pwCheck from './../scripts/pw-check';
app.modules.pwCheck = pwCheck.initInScope;

import * as productRating from './scripts/productRating';
app.modules.productRating = productRating.initInScope;

import * as productReviewAutoTranslate from './../scripts/productReviewAutoTranslate';
app.modules.productReviewAutoTranslate = productReviewAutoTranslate.initInScope;

import * as productPriceRequest from './scripts/productPriceRequest';
app.modules.productPriceRequest = productPriceRequest.initInScope;

import * as floatingLabel from '../libs/@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as stdSlider from './scripts/stdSlider';
app.modules.stdSlider = stdSlider.initInScope;

import * as fullWidthSlider from './../scripts/fullWidthSlider';
app.modules.fullWidthSlider = fullWidthSlider.initInScope;

import * as dotModule from './scripts/pds/dotModule';
app.dotModule = dotModule.init;
app.modules.dotModule = dotModule.initInScope;

import * as sheepstories from './scripts/sheepstories';
app.sheepstories = sheepstories.init;

import * as paymentAuthorize from './scripts/payment-authorize';
app.paymentAuthorize = paymentAuthorize.init;

import * as expandable from './scripts/expandable';
app.modules.expandable = expandable.initInScope;

import * as deselectableRadio from './scripts/deselectableRadio';
app.modules.deselectableRadio = deselectableRadio.initInScope;

import * as compareProduct from './../scripts/compareProduct';
app.compareProduct = compareProduct.init;
app.modules.compareProduct = compareProduct.initInScope;

import * as scrollableTabs from './scripts/scrollableTabs';
app.modules.scrollableTabs = scrollableTabs.initInScope;

import * as diractVoice from './scripts/diractVoice';
app.diractVoice = diractVoice.init;
app.modules.diractVoice = diractVoice.initInScope;

import * as uspDesktop from './scripts/usp-desktop';
app.modules.uspDesktop = uspDesktop.initInScope;

import * as uspSliderMobile from './scripts/usp-slider-mobile';
app.modules.uspSliderMobile = uspSliderMobile.initInScope;

import * as uspVideo from './scripts/usp-video';
app.modules.uspVideo = uspVideo.initInScope;

import * as table from './../scripts/table';
app.modules.table = table.initInScope;

import * as initiativesSlider from './scripts/m5InitiativesSlider';
app.modules.initiativesSlider = initiativesSlider.initInScope;

import * as partnerSlider from './scripts/partnerSlider';
app.modules.partnerSlider = partnerSlider.initInScope;

import * as loadIcons from '../scripts/loadIcons';
app.modules.loadIcons = loadIcons.init;

import * as popupSnippet from './scripts/popup';
app.popupSnippet = popupSnippet.init;

import * as contentCompareTable from './scripts/compareTable';
app.modules.contentCompareTable = contentCompareTable.init;

import * as metaTracking from './scripts/metaTracking';
app.modules.metaTracking = metaTracking.initInScope;

// custom snippets before pimcore x
import * as snippets from './scripts/snippets';
app.modules.snippets = snippets.initInScope;

import * as autoplayVideoControls from './scripts/autoplayVideoControls';
app.modules.autoplayVideoControls = autoplayVideoControls.initInScope;

import * as tabmodule from '../../../shared/contents/tab-module';
tabmodule.init()

import * as typeahead from '../libs/@elements/typeahead';
let typeaheadOptions = {
    hint: false,
    highlight: true,
    minLength: 2,
    autocompleteText: 'title',
    inputId: 'input-search-header'
};
app.modules.typeahead = typeahead.createInitInScope(typeaheadOptions,{
    headerLayout : (data, category) => {
        return(`<div class="typeahead-heading d-flex justify-content-between">
            ${!!data.overviewUrl ? (
            `<a href="${encodeURI(data.overviewUrl)}"
                    class="typeahead-heading__text mb0">`
        ) : ''}
            ${category.title ? (
            `<span class="typeahead-heading__icon text-primary ' + category.icon + '"> ${category.title}</span>`
        ) : ''}
            ${data.overviewUrl ? (
            `</a>`
        ) : ''}
            ${data.overviewUrl ? (
            `<a href="${encodeURI(data.overviewUrl)}"
                class="typeahead-heading__link mb0"><span class="">${category.overviewText || translate('typeahead.showmore')}</span></a>`
        ) : ''}
        </div>`)
    },
    suggestionLayout : (data, category) => {

        const printImage = (imgUrl) => {
            return(
                '<div>' +
                '<img src="' + data.imgurl + '" alt="' + data.title + '"/>' +
                '</div>'
            )
        }

        const printGenderImage = () => {
            return(
                '<img style="width: 30px;margin-top: -19px;margin-bottom: -18px;margin-left: -10px" src="' + data.genderIcon + '.svg" alt="' + data.gender + '"/>'
            )
        }

        if(category.name === 'product'){
            return (
                ' <div class="d-flex pb-1 mb-1 border-bottom ml-3">' +
                (data.imgurl ? printImage(data.imgurl) : '') +
                '<div class="ml-3 d-flex justify-content-between flex-column flex-md-row">' +
                '<div class="">' +
                (data.genderIcon ? '<div class="d-flex">' : '') +
                '<p class="mb-0 word-break-word">' + data.title + '</p>' +
                (data.genderIcon ? printGenderImage() + '</div>' : '') +
                '<small class="word-break-word">' + data.text + '</small>' +
                '</div>' +
                '<div class="text-md-right">' +
                '<strong class="ml-1">' + data.price + '</strong>' +
                '</div>' +
                '</div>' +
                '</div>'
            )
        }else{
            return (
                ' <div class="typeahead-suggestion">' + data.title + '</div>'
            )
        }
    },
    errorLayout : (data) => {
        return false
    }
});

import * as videoDelayedStart from './scripts/videoPlayWhenVisible';
app.modules.videoDelayedStart = videoDelayedStart.initInScope;

import * as polyfillObjectFit from '../scripts/polyfillObjectFit';
app.modules.polyfillObjectFit = polyfillObjectFit.initInScope;

import * as lottie from '../scripts/lottie';
app.modules.lottie = lottie.initInScope;

import * as lottieSlider from '../scripts/lottieSlider';
app.modules.lottieSlider = lottieSlider.initInScope;

import * as additionalInfoSize from './scripts/additionalInfoSize';
app.modules.additionalInfoSize = additionalInfoSize.initInScope;

import * as heroSliderLightboxVideoPause from './scripts/heroSlider-lightboxVideoPause';
app.modules.heroSliderLightboxVideoPause = heroSliderLightboxVideoPause.initInScope;

import * as shubPopup from './scripts/shubPopup';
app.shubPopup = shubPopup.init;

import * as ajaxEditProductAlias from './scripts/shub/ajax-edit-product-alias';
app.modules.ajaxEditProductAlias = ajaxEditProductAlias.initInScope;

import * as ajaxDeleteProduct from './scripts/shub/ajax-delete-product';
app.modules.ajaxDeleteProduct = ajaxDeleteProduct.initInScope;

import * as ajaxExtendProductWarranty from './scripts/shub/ajax-extend-product-warranty';
app.modules.ajaxExtendProductWarranty = ajaxExtendProductWarranty.initInScope;

import * as activeTabs from './scripts/activeTabs';
app.modules.activeTabs = activeTabs.initInScope;

import * as countryMismatchPopup from './../scripts/countryMismatchPopup';
app.countryMismatchPopup = countryMismatchPopup.init;

import * as productSlider from './scripts/productSlider';
app.modules.productSlider = productSlider.initInScope;

import * as productGridCategoriesMobile from './scripts/productGridCategoriesMobile';
app.modules.productGridCategoriesMobile = productGridCategoriesMobile.initInScope;

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);

import * as wcagLinkTargetBlank from '../../../shared/wcag-link-target-blank/wcagLinkTargetBlank'
wcagLinkTargetBlank.init()


import * as content from '../../../shared/contents/content';
content.init()
 
