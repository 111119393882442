'use strict'

import $ from 'jquery';
import fetch from '../../../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill
import initModulesInScope from "../../../libs/@elements/init-modules-in-scope";
import throwError from "../../../libs/@elements/throw-error"
import {showNotification} from "../../../libs/@elements/alert-notification";
import { addLoadingContainer, removeLoadingContainer } from "../../../scripts/loading";
import formDataEntries from 'form-data-entries';

const selectors = {
    warrantyModal:    '.js-ajax-extend-product-warranty__modal',
    warrantyForm:     '.js-ajax-extend-product-warranty__form',
    warrantyText:     '.js-ajax-extend-product-warranty__text',
    deviceId:         (deviceId) => `[data-device-id="${deviceId}"]`,
    loadingContainer: '.modal-content'
}

const attributes = {
    id:         'id',
    action:     'action',
    method:     'method'
}

const WARRANTY_MODAL_DEVICE_ID_PREFIX = 'modal-extend-warranty-';

export function initInScope() {
    const warrantyModalElems = document.querySelectorAll(selectors.warrantyModal) ?? [];

    if (!warrantyModalElems.length) return;

    Array.prototype.slice.call(warrantyModalElems).forEach(warrantyModalElem => {
        initWarrantyModal(warrantyModalElem);
    })
}

function initWarrantyModal(warrantyModalElem) {
    const deviceId = getDeviceId(warrantyModalElem);

    if (!deviceId) throw new Error('1669807040 – AJAX extend product warranty: Could not determ device id');

    const
        warrantyTextElems   = getAliasTextElems(deviceId),
        warrantyForm        = warrantyModalElem.querySelector(selectors.warrantyForm),
        loadingContainer    = warrantyModalElem.querySelector(selectors.loadingContainer);

    if (!warrantyForm) throw new Error('1669818950 – AJAX extend product warranty: No form element found');

    let
        pendingRequest,
        formData = {},
        action = warrantyForm.getAttribute(attributes.action),
        method = warrantyForm.getAttribute(attributes.method);

    warrantyForm.addEventListener('submit', (e) => {
        if (action) {
            e.preventDefault();
        } else {
            return;
        }

        formDataEntries(warrantyForm).forEach(formDataEntry => {
            formData[formDataEntry[0]] = formDataEntry[1]
        });

        if(pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        addLoadingContainer($(loadingContainer), [true, false]);

        pendingRequest = fetch(action, {
            method: method,
            body: JSON.stringify(formData)
        });

        pendingRequest.then((res) => {
            return res.clone().json();
        }).then((res) => {
            if(res.success){
                if (res.data && res.data.warrantyExpirationDate) {
                    updateWarrantyTextElemsText(warrantyTextElems, res.data.warrantyExpirationDate)
                }
            } else {
                throw new Error(`1669818953 – AJAX extend product warranty: ${res.msg}`);
            }
        }).finally(() => {
            removeLoadingContainer($(loadingContainer));
            $(warrantyModalElem).modal('hide');
        }).catch(err => {
            console && console.error && console.error(err)
            $(warrantyModalElem).modal('hide');
        })

        showNotification(pendingRequest);
    });
}

function getDeviceId(warrantyModalElem) {
    const elemId = warrantyModalElem.getAttribute(attributes.id);
    return elemId.replace(WARRANTY_MODAL_DEVICE_ID_PREFIX, '');
}

function getAliasTextElems(deviceId) {
    return Array.prototype.slice.call(document.querySelectorAll(`${selectors.deviceId(deviceId)}${selectors.warrantyText}`));
}

function updateWarrantyTextElemsText(warrantyTextElems, newText) {
    warrantyTextElems.forEach(warrantyTextElems => warrantyTextElems.innerText = newText);
}
