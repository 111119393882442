import {findMenuContainer} from "./DomFunctions";

const _cache = {}

/**
 * @param {...int[]} levels
 */
export const _initialize = (...levels) => levels.forEach(lvl => _cache[lvl] = findMenuContainer(lvl))

export const DOM_MenuContainerCache = Object.freeze({

    initialize: _initialize,

    /**
     * @param {int} level
     * @return {HTMLElement}
     */
    getContainer: (level) => _cache[level],

})
