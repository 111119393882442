'use strict';

import {getCollapsableSize} from './collapsableSize';

const minHeightUncollapsed = 685;

export function initInScope($scope) {
    if (!window.matchMedia('(min-width: 768px)').matches) {
        return;
    }

    const $el = $scope.find('#pds__accordion-additional-info-wrapper');
    if (!$el.length) {
        return;
    }
    const sizes = getCollapsableSize($el[0]);

    if (sizes.uncollapsed.height < minHeightUncollapsed) {
        $('#pds__accordion-additional-info-toggle').attr('style', 'display: none !important');
    }
}
