/**
 * allows click and double-click only every x ms, practically treats double-clicks as single-clicks
 * @param element
 * @param clickAction
 * @param delay
 */
export function setupThrottledClickDoubleClick(element, clickAction, delay= 250) {
    let lastCall = 0

    element.addEventListener('click', e => {
        const now = Date.now()
        if (now - lastCall > delay) {
            clickAction(e)
            lastCall = now
        }
    })
}
