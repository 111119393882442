'use strict'
import {throwError, throwWarning} from "../libs/@elements/throw-error"
import fetch from "../libs/@elements/fetch";
import 'url-search-params-polyfill';
import {translate} from "../../../shared/translations/translations";

let pendingRequest = null;
let $badges = null

export function initInScope() {
    $badges = $(document.body).find('.js-cart-badge').length ? $(document.body).find('.js-cart-badge') : throwWarning('no badges found');
    if ($badges) {
        $badges.on('cart:update', function (e, _data) {
            $badges.html(_data.count > 0 ? _data.count : '');
        })

        updateBadgeCount()
    }
}

function updateBadgeCount() {
    if (pendingRequest) {
        pendingRequest.abort();
        pendingRequest = null;
    }

    let $form = $(document.body).find('.js-cart-dropdown__form').length ? $(document.body).find('.js-cart-dropdown__form') : throwError('no form found');
    let url = $form.data('action-refresh') || throwError(translate('cart.no.action.url'))

    pendingRequest = fetch(url, {
        cache: "no-store"
    });
    pendingRequest.then((res) => {
        return res.clone().json()
    })
        .then((res) => {
            if (res.success && res.count !== 'undefined') {
                $badges.trigger('cart:update', {count: res.count});
            }
        })
        .catch((error) => {
            if (error.name === 'AbortError') {
            } else {
                console.error(error)
            }
        })
}