"use strict";

const Selectors = {
    table: '.wysiwyg table',
    fixedRow: 'th[scope="row"]',
    fixedColumn: '.fixed-column',
    fixedCell: '.fixed-cell'
};

export function initInScope($scope) {
    let $tables = $scope.find(Selectors.table);

    $tables.each((_, el) => {
        initTable($(el));
    });
}

function initTable($table) {
    $table.css({
        "width": "auto",
        "word-break": "normal"
    });

    let tableWidth = $table.outerWidth();

    if(tableWidth > $(window).width()) {
        $table.wrap( "<div class='table-responsive table-fixed'></div>" );
        $table.after("<div class='fixed-column'></div>");

        let
            tablePositionLeft = $table.position().left,
            $fixedRowElements = $table.find(Selectors.fixedRow),
            fixedRowWith = Math.ceil($fixedRowElements.first().outerWidth()),
            halfViewport = $(window).width() / 2,
            $fixedColumn = $table.next(Selectors.fixedColumn);

        $fixedColumn.css({
            "top": $table.position().top
        })

        $fixedRowElements.each((_, el) => {
            let
                $element = $(el),
                $nextElement = $(el).next(),
                elementStyle;

            $fixedColumn.append("<div class='fixed-cell'>" + $element.html() + "</div>");

            let $fixedColumnElement = $fixedColumn.find(Selectors.fixedCell).last();

            if(fixedRowWith < halfViewport) {
                elementStyle = {
                    "white-space": "nowrap",
                    "left": tablePositionLeft,
                    "width": fixedRowWith
                };
            } else {
                elementStyle = {
                    "white-space": "inherit",
                    "left": tablePositionLeft,
                    "width": halfViewport
                }
            }

            $element.css(elementStyle);
            $fixedColumnElement.css(elementStyle);
            $element.hide();

            // table cell height calculation
            if($element.outerHeight() > $nextElement.outerHeight()) {
                $nextElement.outerHeight($element.outerHeight());
            }
            if($element.outerHeight() < $nextElement.outerHeight()) {
                $element.outerHeight($nextElement.outerHeight());
            }

            $fixedColumnElement.outerHeight($element.outerHeight());
        });

        $table.css({
            'margin-left': Math.ceil($fixedColumn.find(Selectors.fixedCell).last().outerWidth())
        });

        if(fixedRowWith > halfViewport) {
            $fixedRowElements.each((_, el) => {
                $(el).css({
                    "width": halfViewport,
                    "white-space": "inherit"
                });
            });
            fixedRowWith = halfViewport;
        }
    } else {
        $table.css({
            "width": "100%",
            "word-break": "inherit"
        });
    }
}