"use strict";

import {loadParsley} from "../libs/@elements/parsley-bootstrap-validation";

export function initInScope($scope) {

    loadParsley().then(function () {
        let emailInput = $('body').find('.js-parsley-validate-current-password');
        window.Parsley.addValidator('currentpassword', {
            validateString: function (value, requirement) {
                $.ajax({
                    url: requirement,
                    data: {currentPassword: value}
                }).done(function (data) {
                    return data.success
                });
            }
        })
    });
}