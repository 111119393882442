'use strict'
import 'pwstrength-bootstrap/dist/pwstrength-bootstrap'
import { translate } from '../../../shared/translations/translations'

export function initInScope($scope) {
    const $inputContainers = $scope.find('.js-pw-check');
    $inputContainers.each((_, el) => initPwCheck($(el)))
}

function initPwCheck($container) {
    let options = {};

    options.ui = {
        container: $container,
        viewports: {
            progress: $container.find('.js-pw-check__container')
        },
        showVerdictsInsideProgressBar: true
    };
    options.common = {
        debug: false,
        onLoad: function () {
            $('#messages').text('Start typing password');
        }
    };
    options.i18n = {
        t: function (key) {
            return translate('password.check.' + key);
        }
    };

    $container.find('.js-pw-check__input').pwstrength(options);
}
