'use strict'
import { addLoadingContainer, removeLoadingContainer } from "./loading";
import { showNotification } from "../libs/@elements/alert-notification"
import throwError from "../libs/@elements/throw-error"
import { translate } from "../../../shared/translations/translations"
import initModulesInScope from "../libs/@elements/init-modules-in-scope";
import fetch from "../libs/@elements/fetch";
import 'url-search-params-polyfill'; // Edge Polyfill
import formDataEntries from 'form-data-entries';
import {handleTrackingOnPromise} from "./tracking";

export function initInScope($scope) {

    const
        $container  = $scope.find('.js-cart__container');

    let $form = $scope.find('.js-cart__form');
    let $countLabel = $scope.find('.js-cart__count-label');
    let pendingRequest = null;

    initForm($form);

    function submitForm(removeId = null) {
        if(pendingRequest){
            pendingRequest.abort();
            pendingRequest = null;
        }

        addLoadingContainer($container,[true, false])

        let url = $form.data('action-refresh') || throwError(translate('cart.no.action.url'))

        let formData = new URLSearchParams(formDataEntries($form[0]));

        if(removeId){
            formData = new URLSearchParams({
                removeId : removeId
            });
        }
        pendingRequest = fetch(url, {
            method: 'POST',
            body: formData
        });
        pendingRequest.then((res) => {
                return res.clone().json()
            })
            .then((res) => {
                if(res.success){
                    const $resultHtml = $(res.html);
                    $form = $resultHtml.filter('form.js-cart__form');
                    initModulesInScope($form)
                    initForm($form);
                    $container.html($resultHtml);

                    if(res.count !== 'undefined'){
                        notifyBadge(res.count);
                    }
                    if(res.countLabel !== 'undefined'){
                        $countLabel.html(res.countLabel);
                    }
                    if(res.modal){
                        const $modal = $(res.modal)
                        $('#lightbox-container').append($modal);
                        $modal.modal('show');
                    }
                }
            })
            .finally(() => {
                removeLoadingContainer($container)
            })
            .catch((error) => {
                if(error.name == 'AbortError'){
                    return;
                }else {
                    console.error(error)
                }
            })
        showNotification(pendingRequest);

        // Track changes to cart
        handleTrackingOnPromise(pendingRequest);
    }

    function initForm($scope) {
        const
            $inputs     = $scope.find('.js-cart__autosubmit-change'),
            $buttons    = $scope.find('.js-cart__autosubmit-click'),
            $removeBtn = $scope.find('.js-cart-item__remove');

        $inputs.on('change', () => submitForm());
        $buttons.on('click', () => submitForm());

        $removeBtn.on('click', function (e) {
            e.preventDefault();
            const removeId = $(this).data('product-id');
            submitForm(removeId);
        });
    }

}

function notifyBadge(count) {
    $('body').find('.js-cart-badge').trigger('cart:update', {count: count})
}