'use strict'

import {init as storecheckInit} from './storecheck';
import {initInScope as initAddToCart} from './../addToCart';

let $colorVariants;

export function init() {
    const $changeSizeContainer = $('.js-selected-size');
    const $changeSize = $('.js-selected-size--radio');
    const $currentSize = $changeSize.find('.custom-radio__input:checked').first();
    
    $changeSize.on('click', changeSizeCallback);
    if($currentSize.length && $currentSize.val()) {
        activateSize($currentSize);
    }

    if ($changeSizeContainer.data('store-check')) storecheckInit($changeSize);
}

function changeSizeCallback(e) {
    const $eventTarget = $(e.target);

    if($eventTarget.length && $eventTarget.val()) {
        activateSize($eventTarget);
    }
}

function activateSize($element) {
    const   $productPriceContainer = $('.js-product-price-container'),
            $productAvailabilityContainer = $('.js-product-availability-container'),
            $addToCartBtn = $('.js-pds-addToCart-btn__container');

    $colorVariants = $('.js-pds-color-variant__link');

    $productPriceContainer.html($element.data('price'));
    $productAvailabilityContainer.html($element.data('availability'));
    $addToCartBtn.html($element.data('cart-button'));

    // #59271, #52914
    //initModulesInScope($('.js-pds__product'));
    const $pdsProduct = $('.js-pds__product');
    initAddToCart($pdsProduct);

    if ($element.data('product-size')) {
        $colorVariants.length && updateSizeUrlOnColorVariants($element.data('product-size'))
        setSizeUrlParameter($element.data('product-size'));
    }
}

function setSizeUrlParameter(size) {
    const url = new URL(window.location.href);
    url.searchParams.set('size', encodeURIComponent(String(size).toLowerCase()));

    window.history.pushState({ path: url.href }, '', url.href);
}

function updateSizeUrlOnColorVariants(size) {
    $colorVariants.each(function(index) {
        updateSizeUrlOnColorVariant($(this), size)
    });
}

function updateSizeUrlOnColorVariant($colorVariantEl, size) {
    const initialHref = removeParamFromUrl($colorVariantEl.attr('href'), 'size');
    const availableSizes = $colorVariantEl.data('available-sizes');
    let sizeVariantHref = false

    for (var availableSize in availableSizes) {
        if (availableSizes.hasOwnProperty(availableSize)) {
            if (availableSizes[availableSize].sizeName === size) {
                sizeVariantHref = removeParamFromUrl(availableSizes[availableSize].detailLink, 'size') + `?size=${String(size).toLowerCase()}`
            }
        }
    }

    $colorVariantEl.attr('href', sizeVariantHref !== false ? sizeVariantHref : initialHref);
}

function removeParamFromUrl(url, paramToRemove) {
    // Create an anchor element and set its href to the relative URL
    const anchor = document.createElement('a');
    anchor.href = url;
    const searchParams = new URLSearchParams(anchor.search);

    searchParams.delete(paramToRemove);
    const newSearchParamsString = searchParams.toString();

    return `${anchor.pathname}${newSearchParamsString ? `?${newSearchParamsString}` : ''}${anchor.hash}`;
}
