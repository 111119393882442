'use strict';

import {Desktop_MainMenuStateObject} from "./Desktop_MainMenuStateObject";
import {MainMenuTriggers} from "../../shared/MainMenu/MainMenuTriggers";
import {Tr_DesktopOverlay} from "./Overlay";
import {MainMenu_ScenarioData} from "../../shared/MainMenu/ScenarioData";
import {DOM_MainMenuItemCache} from "../../shared/MainMenu/DOM_MainMenuItemCache";
import * as R from "ramda";
import {Tr_DropdownMenu} from "../../shared/Tr_DropdownMenu";

export const TOPLEVEL = 1

export const DESKTOP_MAIN_MENUS__DROPDOWNS = {
    DESKTOP_DROPDOWN_NAME__SEARCH: 'desktop-nav__search',
    DESKTOP_DROPDOWN_NAME__ACCOUNT: 'desktop-nav__account',
    DESKTOP_DROPDOWN_NAME__CART: 'desktop-nav__cart',
}

export const DESKTOP_MAIN_MENUS = {
    ...DESKTOP_MAIN_MENUS__DROPDOWNS,
    DESKTOP_NAME_LEVELMENU: 'desktop-nav__level-menu',
}

const _createDropdownScenarioData = (menuName) => {
    const menuContainer = DOM_MainMenuItemCache.getMenu(menuName)
    return new MainMenu_ScenarioData(
        Tr_DesktopOverlay,
        menuName,
        R.curry(Tr_DropdownMenu.open)(menuContainer)(true),
        R.curry(Tr_DropdownMenu.close)(menuContainer)(true)
    )
}
/**
 * Adapter for Desktop & MainMenuTriggers
 */
export const Desktop_MainMenuTriggers = Object.freeze({

    initDropdownMenus: (callback) => MainMenuTriggers.initDropdownMenus(
        Object.values(DESKTOP_MAIN_MENUS__DROPDOWNS),
        callback
    ),

    trigger: (menuName, openFunc, closeFunc, subMenuId = null) => MainMenuTriggers.trigger(
        Desktop_MainMenuStateObject.get(),
        new MainMenu_ScenarioData(Tr_DesktopOverlay, menuName, openFunc, closeFunc, subMenuId)
    ),

    open: (menuName, openFunc, closeFunc, subMenuId = null) => MainMenuTriggers.open(
        Desktop_MainMenuStateObject.get(),
        new MainMenu_ScenarioData(Tr_DesktopOverlay, menuName, openFunc, closeFunc, subMenuId),
    ),

    close: () => MainMenuTriggers.close(
        Tr_DesktopOverlay,
        Desktop_MainMenuStateObject.get()
    ),

    triggerDropdown: (menuName) => MainMenuTriggers.trigger(
        Desktop_MainMenuStateObject.get(),
        _createDropdownScenarioData(menuName)
    ),

    openDropdown: (menuName) => MainMenuTriggers.open(
        Desktop_MainMenuStateObject.get(),
        _createDropdownScenarioData(menuName)
    ),

})
