import {onEnterViewPort} from "../../libs/@elements/viewport-utils";

export function initInScope($scope) {

    $(document).ready(function() {
        const hash = window.location.hash || null;
        const $navTabs = $scope.find('.nav.nav-tabs');

        if (!$navTabs.length || hash === null) {
            return;
        }

        const targetTab = $scope.find(`[href="${hash}"]`);
        if(targetTab  && typeof targetTab.tab === 'function') {
            targetTab.tab('show');
        }

        var scrollHeight = targetTab.offset().top - $('.js-header__nav').height();

        $(window).on('focus', function() {
            scrollToTab(scrollHeight)
        });

        scrollToTab(scrollHeight);

    });
}

function scrollToTab(scrollHeight) {
    $('html, body').animate({
        scrollTop: scrollHeight
    }, 100);
}
