'use strict'
let loggedInHref;

export function initInScope($scope) {
    const $links = $scope.find('.js-ajax-account-link');
    $links.each((_, el) => initAccountLink($(el)));
}

async function initAccountLink($link) {
    loggedInHref = $link.data("logged-in-href");
    if (!loggedInHref) {
        return;
    }

    if (await window.cachedFetches.get('is_user_logged_in')) {
        onLoggedIn($link);
    }
}

function onLoggedIn($link) {
    const $linkClone = $link.clone();
    $link.hide();
    $linkClone.removeAttr('data-toggle');
    $linkClone.attr('href', loggedInHref)
    $linkClone.insertAfter($link)

}
