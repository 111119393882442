'use strict';

/**
added option 'leaveCallback' to add an onLeaveViewPort Callback
added setting 'triggerOnlyOnce' to re-subscribe when the element isn't in the viewport anymore
**/

import $ from 'jquery';

let enterSubscriptions = [];
let leaveSubscriptions = [];

let onViewportInterval;
const defaultSettings = {
    offset: 0,
    triggerOnlyOnce: true,
};

export function onEnterViewPort($newElements, callback, settings, leaveCallback) {
    $newElements.each(function(index) {
        enterSubscriptions.push({
            $element: $newElements.eq(index),
            callback: callback,
            settings: $.extend({}, defaultSettings, settings),
            leaveCallback: leaveCallback,
        });
    });

    if (!onViewportInterval) {
        onViewportInterval = setInterval(function() {
            const updatedEnterSubscriptions = [];
            const updatedLeaveSubscriptions = [];

            enterSubscriptions.forEach(subscription => {
                const inDom = isElementInDom(subscription.$element);
                const inViewport = isElementInViewport(subscription.$element, subscription.settings.offset);

                if (inDom && inViewport) {
                    subscription.callback.call(subscription.$element, subscription.$element);
                    updatedLeaveSubscriptions.push(subscription);
                } else {
                    updatedEnterSubscriptions.push(subscription);
                }
            });

            leaveSubscriptions.forEach(subscription => {
                const inDom = isElementInDom(subscription.$element);
                const inViewport = isElementInViewport(subscription.$element, subscription.settings.offset);

                if (inDom && !inViewport) {
                    if (subscription.leaveCallback) {
                        subscription.leaveCallback.call(subscription.$element, subscription.$element);
                    }
                    if (!subscription.settings.triggerOnlyOnce) {//re-add
                        updatedEnterSubscriptions.push(subscription);
                    }
                } else if (inDom) {//don't keep elements in the loop which are removed from DOM
                    updatedLeaveSubscriptions.push(subscription);
                }
            });

            if (updatedEnterSubscriptions.length === 0 && updatedLeaveSubscriptions.length === 0) {
                clearInterval(onViewportInterval);
                onViewportInterval = null;
            } else {
                enterSubscriptions = updatedEnterSubscriptions;
                leaveSubscriptions = updatedLeaveSubscriptions;
            }
        }, 200);
    }
}

export function isElementInViewport(el, offset = 0) {
    if (typeof $ === 'function' && el instanceof $) {
        el = el[0];
    }

    let rect = el.getBoundingClientRect();

    if (!isElementVisible(el)) {
        return false;
    }

    return (
        rect.bottom + offset >= 0 &&
        rect.top - offset <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.left <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}

function isElementVisible(el) {
    if (typeof $ === 'function' && el instanceof $) {
        el = el[0];
    }

    let rect = el.getBoundingClientRect();

    return !(rect.top === 0 &&
        rect.bottom === 0 &&
        rect.height === 0 &&
        rect.width === 0
    );
}

function isElementInDom(el) {
    if (typeof $ === 'function' && el instanceof $) {
        el = el[0];
    }

    return document.body.contains(el);
}
