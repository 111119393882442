'use strict';

import {_completeConfig, debugCssElementFunc} from "./functions";
import {applyToCloneIf} from "../helpers/doWithClone";

import {doCssTransition} from "./doCssTransition";
import {skipCssTransition} from "./skipCssTransition";
import {TransitionCanceledError} from "../../../static/js/ortovox/scripts/navigation/shared/core/TransitionTree/TreeExecutor";

/**
 * @param {TransitionConfig} conf
 */
const _execute = async (conf) => {
    // debugCssElementFunc(conf, conf)

    const handler = conf.useTransition ? doCssTransition : skipCssTransition

    if (conf.isCanceledFunc()) {
        throw new TransitionCanceledError()
    }

    await handler(conf)

    if (conf.isCanceledFunc()) {
        throw new TransitionCanceledError()
    }
}


export const CSSTransitionsHelper = Object.freeze({
    /**
     * @param {TransitionConfig} originalConfig
     * @param {boolean} useTransition
     * @return {Promise}
     */
    execute: (originalConfig, useTransition = undefined) => {
        const usedConfig = applyToCloneIf(originalConfig, typeof useTransition !== 'undefined',
            (conf) => ({...conf, useTransition: useTransition}))
        return _execute(_completeConfig(usedConfig));
    }
})
