import '../../libs/@elements/menu-aim';

export function initInScope($scope) {
    let $menu =             $scope.find('.js-in-page-menu__menu-aim'),
        $dropdown =             $scope.find('.js-in-page-menu__dropdown '),
        $closeSecondLevel =    $scope.find('.js-close-second-level ');

    initApplicationCollapse();
    function initApplicationCollapse() {
        const
            mobileBreak     = window.matchMedia('(max-width: 767px)');
        /**
         * manuel polyfill. addListener is deprecated but in ie and edge addEventListerner is not yet supported
         */
        if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)){
            mobileBreak.addListener((e) => {
                if(e.matches){
                    destroyDesktop();
                    deactivateSubmenu();
                    initMobile();
                }else{
                    destroyMobile();
                    initDesktop();
                }
            })
        }else{
            mobileBreak.addEventListener('change', (e) => {
                if(e.matches){
                    destroyDesktop();
                    deactivateSubmenu();
                    initMobile();
                }else{
                    destroyMobile();
                    initDesktop();
                }
            })
        }
        $(mobileBreak).trigger('change')
    }

    /*initial init*/
    // $menuContainer.outerWidth($toggleBtn.outerWidth());
    if(matchMedia('(min-width: 768px)').matches){
        initDesktop();
    }else{
        initMobile();
    }
    initTouchdevice();

    $dropdown.on('hide.bs.collapse show.bs.collapse', function () {
        deactivateSubmenu()
    });

    $dropdown.each(function () {
        let $currentDropdown = $(this);
        $currentDropdown.on('show.bs.collapse', function () {
            $(document.body).on('click', bodyClickHandler)
        }).on('hide.bs.collapse', function () {
            $(document.body).off('click', bodyClickHandler)
        });

        function bodyClickHandler(evt) {
            if (!$(evt.target).is('.js-in-page-menu__dropdown')
                && !$(evt.target).closest('.js-in-page-menu__dropdown').length) {
                evt.preventDefault();
                $currentDropdown.collapse('hide');
            }
        }
    });

    /**
     * opens submenu
     * @param row - li element of content about to open
     */
    function activateSubmenu(row, e = null) {
        let $menuHeight = $menu.outerHeight();
        let $row = $(row);


        if($row.find('.js-in-page-menu__second-level').length > 0){
            $row.find('.js-in-page-menu__second-level').attr('hidden', null);

            if($row.find('.js-in-page-menu__second-level-list').outerHeight() > $menuHeight){
                $menu.css('min-height', $row.find('.js-in-page-menu__second-level-list').outerHeight() + 2);
            }
            $closeSecondLevel.on('click', closelistener);


            if(e !== null){
                e.preventDefault();
            }
        }else{
            // e.preventDefault();
        }
    }

    /**
     * closes Submenu
     * @param row - li element of content about to close
     */
    function deactivateSubmenu(row) {
        if(row)
        {
            let $row = $(row);
            $row.find('.js-in-page-menu__second-level').attr('hidden', 'hidden');
            $menu.css('min-height', '');
            $closeSecondLevel.off('click', closelistener)
        }else{
            $menu.find('.js-in-page-menu__second-level').attr('hidden', 'hidden');
            $menu.css('min-height', '');
        }
    }

    /**
     * triggered if mouse leaves menuaim. closes content
     * @param row
     */
    function exitMenu(row) {
        let $row = $(row);
        $row.find('.js-in-page-menu__second-level').attr('hidden', 'hidden');
    }

    /**
     * mobile listener if list should close
     * @param e
     */
    function closelistener(e){
        deactivateSubmenu($(e.target).closest('.js-in-page-menu__first-level')[0]);

        e.stopPropagation();
        e.preventDefault();
    }

    function initMobile() {
        $scope.find('.js-in-page-menu__first-level').on('click', (e) => {
            let row = $(e.delegateTarget);

            if($(e.target).closest('.js-in-page-menu__second-level-list').length > 0){
                return;
            }
            if($(e.delegateTarget).find('.js-in-page-menu__first-level-child').length > 0){
                activateSubmenu(row, e);
            }
        });
    }
    function destroyMobile() {
        $scope.find('.js-in-page-menu__first-level').off('click');
    }

    function initDesktop() {
        $menu.menuAim({
            activate: activateSubmenu,
            deactivate: deactivateSubmenu,
            exitMenu: exitMenu
        });
    }

    function destroyDesktop() {
        $menu.menuAim('destroy');

    }

    function initTouchdevice() {
        let openMenu;
        $scope.find('.js-in-page-menu__first-level-child-link').on('touchend', (e) => {
            if(openMenu){
                deactivateSubmenu(openMenu);
            }
            if($(e.delegateTarget).closest('.js-in-page-menu__first-level').find('.js-in-page-menu__second-level').length > 0){
                openMenu = $(e.target).closest('.js-in-page-menu__first-level')[0];
                e.preventDefault();
                activateSubmenu($(e.target).closest('.js-in-page-menu__first-level')[0], e);
            }
        });
    }
}