export class TAction {
    /**
     * @param {string} debugDescription
     * @param {function} action
     * @param {string[]} basePath
     * @param {StateMultiUpdate} stateUpdates_beforeExecution
     * @param {StateMultiUpdate} stateUpdates_afterExecution
     * @param {boolean} forceExecution
     */
    constructor(debugDescription, action, basePath, stateUpdates_beforeExecution, stateUpdates_afterExecution, forceExecution = false) {
        this.debugDescription = debugDescription;
        this.basePath = basePath;
        this.action = action
        this.stateUpdates_beforeExecution = stateUpdates_beforeExecution;
        this.stateUpdates_afterExecution = stateUpdates_afterExecution;
        this.forceExecution = forceExecution
    }
}
