export function initInScope($scope) {

    var mainFormName = hintAddressFormName();

    if (isModalAvailable() && mainFormName != null) {
        document.onreadystatechange = function () {
            let $validateModal = $('#addressValidationModal');
            let mainFormFieldPrefix = hintAddressFormFieldPrefix(mainFormName);
            setAddressTexts(mainFormFieldPrefix);
            prepareOnSubmitForm(mainFormName, mainFormFieldPrefix);
            prepareOnCloseForm(mainFormName);
            $validateModal.modal('show');
        }
    }

    function setAddressTexts(mainFormFieldPrefix) {
        let dataSources = ["service", "user"];
        let targetElements = ["validate_serviceAddress", "validate_userAddress"]

        let i;
        for (i = 0; i < dataSources.length; i++) {
            let dataSource = dataSources[i];
            let targetElementId = targetElements[i];

            let targetElement = document.getElementById(targetElementId);

            let country = document.getElementById(mainFormFieldPrefix + 'Country');
            let selectedCountry = country.options[country.selectedIndex].innerHTML;
            let countryCode = targetElement.dataset.countryCode;

            targetElement.innerHTML = '';

            if (document.getElementById('validate_' + dataSource + '_shippingStreetNumber') != null) {
                if (document.getElementById('validate_streetNumberFirst').value === "1") {
                    targetElement.innerHTML += document.getElementById('validate_' + dataSource + '_shippingStreetNumber').value + ' ' + document.getElementById('validate_' + dataSource + '_shippingStreet').value;
                } else {
                    targetElement.innerHTML += document.getElementById('validate_' + dataSource + '_shippingStreet').value + ' ' + document.getElementById('validate_' + dataSource + '_shippingStreetNumber').value;
                }
            } else {
                targetElement.innerHTML += document.getElementById('validate_' + dataSource + '_shippingStreet').value;
            }

            targetElement.innerHTML += '<br />';
            if (document.getElementById('validate_' + dataSource + '_shippingAddressAddon').value !== '') {
                targetElement.innerHTML += document.getElementById('validate_' + dataSource + '_shippingAddressAddon').value;
                targetElement.innerHTML += '<br />';
            }
            targetElement.innerHTML += document.getElementById('validate_' + dataSource + '_shippingZip').value + ' ' + document.getElementById('validate_' + dataSource + '_shippingCity').value;
            if (document.getElementById('validate_' + dataSource + '_shippingStateName') != null && document.getElementById('validate_user_shippingStateName') != null) {
                targetElement.innerHTML += ', ' + document.getElementById('validate_' + dataSource + '_shippingStateName').value;
            }
            targetElement.innerHTML += '<br />';

            let countryOutput = countryCode ? '<span class="btn-icon"><img src="/static/img/flags/4x3/round/flag_' + countryCode + '.svg" width="18" class="mr-2 mb-1" />' + selectedCountry + '</span>' : selectedCountry;
            targetElement.innerHTML += countryOutput;
        }
    }

    function prepareOnSubmitForm(mainFormName, mainFormFieldPrefix) {
        let modalForm = document.getElementById('validate-form');
        let mainForm = document.getElementsByName(mainFormName)[0];

        modalForm.addEventListener('submit', function (e) {
            let modalFormLoading = document.getElementById('addressValidationLoading');
            modalFormLoading.removeAttribute("hidden");

            e.preventDefault();
            if (e.target.elements.validate_selectSource.value === 'service') {
                document.getElementById(mainFormFieldPrefix + 'Street').value = e.target.elements.validate_service_shippingStreet.value;
                if (typeof e.target.elements.validate_service_shippingStreetNumber !== 'undefined' && document.getElementById(mainFormFieldPrefix + 'StreetNumber') != null) {
                    document.getElementById(mainFormFieldPrefix + 'StreetNumber').value = e.target.elements.validate_service_shippingStreetNumber.value;
                }
                document.getElementById(mainFormFieldPrefix + 'AddressAddon').value = e.target.elements.validate_service_shippingAddressAddon.value;
                document.getElementById(mainFormFieldPrefix + 'City').value = e.target.elements.validate_service_shippingCity.value;
                document.getElementById(mainFormFieldPrefix + 'Zip').value = e.target.elements.validate_service_shippingZip.value;
                if (typeof e.target.elements.validate_service_shippingState !== 'undefined' && document.getElementById(mainFormFieldPrefix + 'State') != null) {
                    document.getElementById(mainFormFieldPrefix + 'State').value = e.target.elements.validate_service_shippingState.value;
                }
            } else {
                document.getElementById(mainFormFieldPrefix + 'Street').value = e.target.elements.validate_user_shippingStreet.value;
                if (typeof e.target.elements.validate_user_shippingStreetNumber !== 'undefined' && document.getElementById(mainFormFieldPrefix + 'StreetNumber') != null) {
                    document.getElementById(mainFormFieldPrefix + 'StreetNumber').value = e.target.elements.validate_user_shippingStreetNumber.value;
                }
                document.getElementById(mainFormFieldPrefix + 'AddressAddon').value = e.target.elements.validate_user_shippingAddressAddon.value;
                document.getElementById(mainFormFieldPrefix + 'City').value = e.target.elements.validate_user_shippingCity.value;
                document.getElementById(mainFormFieldPrefix + 'Zip').value = e.target.elements.validate_user_shippingZip.value;
                if (typeof e.target.elements.validate_user_shippingState !== 'undefined' && document.getElementById(mainFormFieldPrefix + 'State') != null) {
                    document.getElementById(mainFormFieldPrefix + 'State').value = e.target.elements.validate_user_shippingState.value;
                }
            }
            mainForm.submit();
            return false;
        });
    }

    function prepareOnCloseForm(addressFormName) {
        let shippingAddressLookupName = hintShippingAddressLookupName(addressFormName);
        let $validateModal = $('#addressValidationModal');
        $validateModal.on('hide.bs.modal', function () {
            document.getElementById(shippingAddressLookupName).value = '';
        })
    }

    function hintAddressFormName() {
        if (document.getElementsByName('customer').length > 0) {
            return 'customer';
        } else if (document.getElementsByName('guest').length > 0) {
            return 'guest';
        } else {
            return '';
        }
    }

    function hintAddressFormFieldPrefix(addressFormName) {
        if (addressFormName === 'customer') {
            return 'customer_shipping';
        }
        if (addressFormName === 'guest') {
            let shippingAddressAlternative = document.getElementById('guest_shippingAddressAlternative');
            if (shippingAddressAlternative != null && shippingAddressAlternative.checked) {
                return 'guest_shipping';
            } else {
                return 'guest_customer';
            }
        }
        return null;
    }

    function hintShippingAddressLookupName(addressFormName) {
        if (addressFormName === 'customer') {
            return 'customer_shippingAddressLookup';
        }
        if (addressFormName === 'guest') {
            return 'guest_shippingAddressLookup';
        }
        return null;
    }

    function isModalAvailable() {
        if (document.getElementById('addressValidationModal') != null) {
            return true;
        } else {
            return false;
        }
    }

}