import {tryNTimes} from "./tryNTimes";

export async function waitUntilRendered(element, times = 100, interval = 1) {
    return tryNTimes(() => {
        const rect = element.getBoundingClientRect()
        for (const prop of ['x', 'y', 'width', 'height', 'top', 'right', 'bottom', 'left']) {
            if (rect[prop]) {
                return;
            }
        }
        throw Error()
    }, times, interval)
}