'use strict';

import * as R from "ramda";
import {LevelMenuState} from "../state/LevelMenuState";
import {Tr_TopLevel} from "../TransitionElements/MenuLevel/Tr_TopLevel";
import {Tr_LevelContent} from "../TransitionElements/MenuLevel/Tr_LevelContent";
import {DOM_MenuContainerCache} from "../DOM_MenuContainerCache";
import {TOPLEVEL} from "../../MainMenu/Desktop_MainMenuTriggers";
import {MenuPositioner} from "../TransitionElements/MenuLevel/MenuPositioner";
import {Tr_LevelTrigger} from "../TransitionElements/MenuLevel/Tr_LevelTrigger";
import {TAction} from "../../../shared/core/TransitionTree/TAction";

const KEYS = LevelMenuState.KEYS

/**
 * @param {TransitionInfo} transitionInfo
 * @param {boolean} useTransition
 * @return {TAction}
 */
const _create_openCloseTopLevel_Base = (transitionInfo, useTransition) => {
    const container = DOM_MenuContainerCache.getContainer(TOPLEVEL)
    const transContainer = document.getElementById(container.dataset['menuTransitionContainer'])
    return new TAction(transitionInfo.debugDescription, R.curry(transitionInfo.func)(transContainer, useTransition), [KEYS._PREFIX_LEVEL + TOPLEVEL, KEYS.OPEN_CLOSE], [[[KEYS._STATE_], transitionInfo.startState]], [[[KEYS._STATE_], transitionInfo.endState]])
}

/**
 * @param {string} key
 * @param {TransitionInfo} transitionInfo
 * @param {HTMLElement} menuElement
 * @param {int} level
 * @param {boolean} useTransition
 */
const _create_activateItemBase = (key, transitionInfo, level, menuElement, useTransition) => {
    return new TAction('* (lvl ' + level + ') ' + transitionInfo.debugDescription, R.curry(transitionInfo.func)(menuElement, useTransition), [KEYS._PREFIX_LEVEL + level, key], [[[KEYS._STATE_], transitionInfo.startState], [[KEYS._ELEMENT_], menuElement],], [[[KEYS._STATE_], transitionInfo.endState], [[KEYS._ELEMENT_], menuElement],],)
}

/**
 * @param {string} key
 * @param {TransitionInfo} transitionInfo
 * @param {HTMLElement} menuElement
 * @param {int} level
 * @param {boolean} useTransition
 */
const _create_deactivateItemBase = (key, transitionInfo, level, menuElement, useTransition) => {
    return new TAction('(lvl ' + level + ') ' + transitionInfo.debugDescription, R.curry(transitionInfo.func)(menuElement, useTransition), [KEYS._PREFIX_LEVEL + level, key], [[[KEYS._STATE_], transitionInfo.startState], [[KEYS._ELEMENT_], menuElement],], [[[KEYS._STATE_], transitionInfo.endState], [[KEYS._ELEMENT_], undefined],],)
}

const _create_Action__moveLevel = (level, useTransition, isTransitionCanceled, translateOverrides, forceExecution, useCurrentLevel, delay) => {
    const transInfo = MenuPositioner.setActiveMenuLevel_moveMenus;
    return new TAction('(lvl ' + level + ') ' + transInfo.debugDescription, transInfo.func(level, useTransition, isTransitionCanceled, translateOverrides, useCurrentLevel, delay), [KEYS.SUBMENUS_LEVEL_POSITION], [[[KEYS._STATE_], transInfo.startState], [[KEYS._LEVEL_], level],], [[[KEYS._STATE_], transInfo.endState], [[KEYS._LEVEL_], level],], forceExecution)
};

export const LevelMenu_Atomics = Object.freeze({
    create_Action__show_LevelNavigation: (useTransition) => _create_openCloseTopLevel_Base(Tr_TopLevel.open, useTransition),
    create_Action__hide_LevelNavigaton: (useTransition) => _create_openCloseTopLevel_Base(Tr_TopLevel.close, useTransition),

    create_Action__setActiveMenuLevel_moveMenus: (level, useTransition, isTransitionCanceled, translateOverrides, forceExecution, useCurrentLevel, delay) => _create_Action__moveLevel(level, useTransition, isTransitionCanceled, translateOverrides, forceExecution, useCurrentLevel, delay),

    create_Action__fadeInContent: (level, menuElement, useTransition) => _create_activateItemBase(KEYS.ACTIVE_CONTENT, Tr_LevelContent.activate, level, menuElement, useTransition),
    create_Action__fadeOutContent: (level, menuElement, useTransition) => _create_deactivateItemBase(KEYS.ACTIVE_CONTENT, Tr_LevelContent.deactivate, level, menuElement, useTransition),

    create_Action__highlight_Trigger: (level, triggeringMenuElement, useTransition) => _create_activateItemBase(KEYS.ACTIVE_TRIGGER, Tr_LevelTrigger.activate, level, triggeringMenuElement, useTransition),
    create_Action__deHighlight_Trigger: (level, triggeringMenuElement, useTransition) => _create_deactivateItemBase(KEYS.ACTIVE_TRIGGER, Tr_LevelTrigger.deactivate, level, triggeringMenuElement, useTransition),
})
