'use strict';

import {setupThrottledClickDoubleClick} from "../../../../../../../shared/events/dbl_click/setupThrottledClickDoubleClick";
import {LevelMenu_ScenarioData} from "./TransitionsConfig/ScenarioData";
import {findMenuContainer, findMenuElement, findTriggeringElements} from "./DomFunctions";
import {DOM_MenuContainerCache} from "./DOM_MenuContainerCache";


const _initLevel = (callbackTrigger, level) => {
    const menuContainerPreviousLevel = findMenuContainer(level - 1);
    const elements = findTriggeringElements(menuContainerPreviousLevel)
    initMenuTriggers(callbackTrigger, level, elements)
}

const initMenuTriggers = (callbackTrigger, level, elements) => {
    elements.forEach(el => {
        setupThrottledClickDoubleClick(el, () => {
            // noinspection JSUnresolvedReference
            const menuId = el.dataset.menuTargetId
            callbackTrigger(new LevelMenu_ScenarioData(menuId, findMenuElement(menuId, DOM_MenuContainerCache.getContainer(level)), level))
        })
    })
};

export const LevelMenuTriggers = Object.freeze({

    initLevels: (callbackTrigger, ...levels) => {
        levels.forEach(level => _initLevel(callbackTrigger, level))
    },

})