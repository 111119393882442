"use strict";

export function initInScope ($scope) {
    class ParsleyZip{
        constructor(country, zip){
            this.$country = country;
            this.$zip = zip;

            this.$zipMessageDefault = $(this.$zip).data('parsley-error-message');
            this.$zipMessageRegex = '';
            this.$zipRegex = '';

            this.updateCountry();
            this.updateZIP();

            this.$country.trigger('change');
        }

        updateCountry(){
            this.$country.on('change', () => {
                this.$zipRegex = this.$country.find('option:selected').data('zip-regex');
                this.$zipMessageRegex = this.$country.find('option:selected').data('zip-regex-message');

                if(this.$zipRegex) {
                    this.$zip.attr('data-parsley-pattern', this.$zipRegex); // zb ^[0-9]{5}$
                }
                if(!this.$zipRegex || this.$zipRegex.length === 0){
                    this.$zip.removeAttr('data-parsley-pattern');
                }
                this.$zip.trigger('input');
            });
        }

        updateZIP(){
            $(this.$zip).on('input', () => {
                if(this.$zip.val().length > 0 && this.$zipMessageRegex){
                    this.$zip.attr('data-parsley-error-message', this.$zipMessageRegex);
                }else{
                    this.$zip.attr('data-parsley-error-message', this.$zipMessageDefault);

                }
            })
        }
    }


    //initialize instances of class
    let $country = $scope.find('.js-parsley-country'),
        $zip = $scope.find('.js-parsley-zip ');
    for(let i = 0; i < $country.length; i++){
        if(!$('.js-parsley-zip').length){
            $country.splice(i, 1);
        }
        if($zip[i] && $country[i]){
            new ParsleyZip($($country[i]), $($zip[i]));
        }
    }
}