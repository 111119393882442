'use strict'

export function init() {

    const $paymentForm = $('.js-payment-authorize');

    if($paymentForm.length){
        const
            $submitBtn  = $('.js-payment-authorize__submit'),
            $paymentSubmitBtn = $('.js-submit-payment__btn'),
            $paypalBtn = $('.js-payment-method-btn-paypal'),
            $paymentMethodInput = $('#paymentMethod'),
            $errorLists = $('.js-payment-authorize .form-errors'),
            $errorList  = $('.js-payment-authorize__error-list');

        const
            $cardNumber     = $('.js-payment-authorize__card-number'),
            $cardNumberIcon = $cardNumber.parent().find('.form-control-icon'),
            $expDate        = $('.js-payment-authorize__exp-date'),
            $cardCode       = $('.js-payment-authorize__card-code'),
            errorMapping    = new Map([
                ['E_WC_05', $cardNumber],
                ['E_WC_06', $expDate],
                ['E_WC_07', $expDate],
                ['E_WC_08', $expDate],
                ['E_WC_15', $cardCode]
            ]),
            cardIconMap     = new Map([
                ['visa', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABbElEQVQokc1SPUtCURh+UvGaWSmk3hAy+kIiMFsaKmi0IWhol6amamoom/oFhWuDLmH0A3KvoSW8UoGlhZIXrylZflQ3CuN9E8FovEPPcs57znk+3pfTIctyGEAA2iBCgg2NxBg6LcUIhuKjjNzFIaY6JTyoDthmdhHPfWLnKAW/tw+xRAledzfW/G4EoykUXlQmHqxOwGLSYyOc5DOqR0QzdHaHC76FTUQ/1mFsvCGfu8FZssykycEeXvutAkKxLBOHnWY2EK0CYlKpZZBWXttbdg2MYft6EXXlCqfJMpy9AkSrke+IbDEZeF97/8LKvIvTHZ8rbPCnIKVJ1+zYvxxHXf0hSZkq31ErW0tDPAJKRK1TOno3KprRJegRz1TaBcmR3O6fwA9mPTYoz2orVTB6i3jTgIROpCLvacZU3xV+JST4mjOb89jYIN8UJCSyVU5HZsvTItc0y72Ah2uClKng//9DEoxopgZEvgE0x5mNOs7TqwAAAABJRU5ErkJggg=="],
                ['mastercard', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABZElEQVQoka2TP0/CQBjGn7u2BhIgITGRxhgGEWJcREcUBxc0rgadnBhd/B76Beogk8bZP1/AVWAxBqqbTW8joSVUilfTG0yO00luu/d57ve+d+97xHGcKwAnmM1qxsBoGqVZ16CXNyAfLrBRAqltQq8Auv8MbdgFT5oI8kcI8sdKFQpQO7egXViSaW4pQOIU4PMJKT5abmBUaEgxKu0GngIjCYr0Vh9Gtw9w2Z58t0Am3t9A+tJTrkBzBsgcB5lwIDQUXR/IZ6ji+OeSgHytqNA4CxGNKSKdAkao6JOMfEauMJPG15n8yFHA4T1lEZayAOWSNl7YBR06UuzXsaG3d6LbYmx2yiCVVTE22ugV+qAtxibu8OfigVKxArRtG77nwzRzcF0G5rpIpVPImeaPJ9bjWKfVwmG9LgH16QyP9w/C6DKGoe+jtr+Ht54ttEJxBZ1WG4wxrJfLItl2tSolm+3XA5rfsh6KeVG3NTgAAAAASUVORK5CYII="],
                ['maestro', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABjklEQVQoka2Tv0tCURTHv8/eSx4mPn9kamAZDuYQLRFkEFJEUyRU0OTU3F5z7e0NuvQPhEO2heKYDdmgQmomZJrYy0fq84YPE+8znPxO537POZ9zL5zLFIvFEIAgxqNwF0jUqHwmi9rFJbyxJBhC8Ly2jGrwEFcVHRJat+KtN9M4XbHCPe+kelk1LJfOwLZ7DHf9u+/ZkyXcuA149LjQ0GgV75ZfQiL5hZihBrNR6Ndq1MD6+SX0A7Cuor4jYGISvlKVrmX1uH+TKG8I6I0nqXOH0SDv8CjxrCgpzx1UgTWNBnY0jMohQA9C1KleeiQwtbFCFxCChdcnJS5M8SAMTXV1KqOB02cnqFoMlLcdvwbTkhCzmynf0vqEz6EbDXTMOSFGQkjsb+HdZkZ5xoSsfxGbOzL8SGGmWYGt+YGDnwdEVjswCfTwf/ewq3K5DI7jILfbYDkOLMtCFEVIkgRBEEAIgdFoHOob2sM/3UWjMAgCci8vCpjneUxbrZAaDQUmyzL2AgFlkPqG4/t6QPgXF1CPUkWvMnoAAAAASUVORK5CYII="],
                ['discover', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAB30lEQVQoka2S32tScRiHn5OmJCmUTK1EHbqVaNEam0UN7MYV3XSz6mZ30UV/Tl1GN6vBFjEIGmSTNigGq6CLakvccqf5c8cFs/zROTueOI6FY0o3Plcv3w/vw8v7foWZiYdxp+dMjC6w+SMZNzo8wZivL9gNHw11Z/RQV0wtGPVycmqafHGTky4nG9k8uUKB8dtjzL6eo8duJ+I1M2Jb42PhMDMpMzdvXCf+Zp5CUSLQ60XMZIlcOM/ZPi/NCRVFYfzOLVbT66jqDpHBgabshMPB/Xt3cctfOHpujIiritvVw6OJSWJXo/yRZRoNjVRaJBwKNccU3i0kNDEn8Xl5hUvDQyRTq0hbJfr9fqRSiWqtTnQohEFM8NsW5PTwNV68nCU6coWnz57j93mwHzuOwWjglN26K+xtc5R0Ok2lUsFqtaJpGqqqIstyszaZTAQCgQM9yx/md3fYDqfTSbVaxWKx/EvL5TJmsxlBEPZ1/NwqIc494OL2k85CXdQq23trJSN+R1p8zOD2NG5qIBg6CztRr9VYWXqFbW2KsLJIP43930bfyf8o5rNkv77lSCZBuL7AZX2aNugmY2kjGRe0xmhrrqoKv3LfMEqf8FTe49NEfHuhQW80HdRpsCQPrP8FG9a+MPCl9KgAAAAASUVORK5CYII="],
                ['amex', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAAA80lEQVQokWN8+vTpAgYGhngG6oCFIAP/oxsl0/WaKNOflIliiDFRyWW0M5AFm2CunQgDMyMjw+9//xm+/PzL8OXnPwYpflaG338hocPMxMjw8tMf4g189P43w99//xk+//wH5oMMFeFhYXjx6TcDNxsTw9OPfxg0xNixGojVyxsvf2R48uE3g4IQK4MINzODmhg7w+8//xlURNgZQG6U4mdh+PHnH/EuBAEBTmaGhafeMyiLsDFI8LEyqImyM+y59YXh4tPvDDxsTGBvtzoIEW/ggTtfwPTdN7/A+Oi9r3C5L7+wuw6nlykBVDeQulmPgWEhAEdtVM/9FW+oAAAAAElFTkSuQmCC"],
                ['jcb15', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABx0lEQVQoka2TT0iTcRzGP3tfU99t73w35wpzWyrhkHARUdgCo5NFUN282UJF0UNEehDzz03pYocuXXIgnoIiCCuyEAwpUdhVF/5Z7w4x/+yPrT+6RUNlb7Pcwef28P09D8/3+/DTqao6AjRyOPD9MUztWomiiNVq5dWHBWZCMZAM9F+v5vPb9yT9CygJHSU9zTyfn2I5Oo9Z2uamo4FYLLaXJi8zl16v59mLWVoejLNuK4Hio1whwHRnH1WbIs41gZdX7XhfD1Jh2aLC/CttmAnh70XvdY9p+GhPr4Z7nw799zZ5/xq4T1hZ2jqCZDRS5nZh+S4gTAWoK68BfYqkTgWCWbqshLsYbvJw2qEw8GYc0/FS1qbnSEbiDF/rSL8Y8NzdV5dlKMuFmIwFKIYCNr79RJJlzXwjEWdyxc/XxGpuhtWuMhzHFG49fIc/GKGr9iJRNYSl9gxCkZH+iRHqHG4ezfn2Ncy64ceZAFGzmXVbMt1yIh7ny6dZDJsickRgctEPcmqn5RwSZuLJ7bMaXvpY23jnufsHJ7zTXs8PqZCqU05unHdia2vFsg3lJ10o9ZfomwhDPnjslVy2XyAcDmv0h/v1wPcbudqI3X7kvboAAAAASUVORK5CYII="],
                ['jcb', "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABx0lEQVQoka2TT0iTcRzGP3tfU99t73w35wpzWyrhkHARUdgCo5NFUN282UJF0UNEehDzz03pYocuXXIgnoIiCCuyEAwpUdhVF/5Z7w4x/+yPrT+6RUNlb7Pcwef28P09D8/3+/DTqao6AjRyOPD9MUztWomiiNVq5dWHBWZCMZAM9F+v5vPb9yT9CygJHSU9zTyfn2I5Oo9Z2uamo4FYLLaXJi8zl16v59mLWVoejLNuK4Hio1whwHRnH1WbIs41gZdX7XhfD1Jh2aLC/CttmAnh70XvdY9p+GhPr4Z7nw799zZ5/xq4T1hZ2jqCZDRS5nZh+S4gTAWoK68BfYqkTgWCWbqshLsYbvJw2qEw8GYc0/FS1qbnSEbiDF/rSL8Y8NzdV5dlKMuFmIwFKIYCNr79RJJlzXwjEWdyxc/XxGpuhtWuMhzHFG49fIc/GKGr9iJRNYSl9gxCkZH+iRHqHG4ezfn2Ncy64ceZAFGzmXVbMt1yIh7ny6dZDJsickRgctEPcmqn5RwSZuLJ7bMaXvpY23jnufsHJ7zTXs8PqZCqU05unHdia2vFsg3lJ10o9ZfomwhDPnjslVy2XyAcDmv0h/v1wPcbudqI3X7kvboAAAAASUVORK5CYII="],
            ])

        //not imported as npm module due to security reasons - do not change!
        import('./../../scripts/payment-helper-cleave').then((Cleave) => {
            var credit = new Cleave.default('.js-payment-authorize__card-number', {
                creditCard: true,
                onCreditCardTypeChanged: function (type) {
                    if(cardIconMap.has(type)){
                        $cardNumberIcon.empty();
                        $cardNumberIcon.append('<img class="" src="' + cardIconMap.get(type) + '" alt="payment type ' + type +'"/>');
                    }else{
                        $cardNumberIcon.empty();
                        $cardNumberIcon.append('<i class="icon icon-credit_card"><i/>')
                    }
                }
            });
            var date = new Cleave.default('.js-payment-authorize__exp-date', {
                date: true,
                datePattern: ['m', 'y']
            });
        });


        const changeInputListener = function(e){
            const
                $input          = $(e.target),
                $formGroup      = $input.closest('.form-group'),
                $errorContainer = $formGroup.find('.form-errors');

            $input.removeClass('is-invalid');
            $formGroup.removeClass('has-error is-invalid');
            $errorContainer.empty();
        }

        $cardNumber.add($expDate).add($cardCode).on('input', changeInputListener);

        const sendPaymentDataToAnet = function() {
            $errorLists.empty();
            $errorList.empty();

            var authData = {};

            authData.clientKey = window._config.paymentAuthorize__clientKey ? window._config.paymentAuthorize__clientKey : '' ;
            authData.apiLoginID = window._config.paymentAuthorize__loginId ? window._config.paymentAuthorize__loginId : '' ;

            var cardData = {};
            cardData.cardNumber = document.getElementById("cardNumber").value.replace(/ /g,'');
            cardData.month = document.getElementById("expDate").value.substring(0,2);
            cardData.year = document.getElementById("expDate").value.substring(3,5);
            cardData.cardCode = document.getElementById("cardCode").value;


            var secureData = {};
            secureData.authData = authData;
            secureData.cardData = cardData;

            // var x = Accept.dispatchData(secureData, responseHandler);
            var xx = window.Accept.dispatchData(secureData, responseHandler);

            function responseHandler(response) {
                try {
                    if (response.messages.resultCode === "Error") {
                        var i = 0;
                        while (i < response.messages.message.length) {
                            console.error(
                                response.messages.message[i].code + ": " +
                                response.messages.message[i].text
                            );

                            if (errorMapping.has(response.messages.message[i].code)) {
                                const
                                    $errorInput = errorMapping.get(response.messages.message[i].code),
                                    $formGroup = $errorInput.closest('.form-group'),
                                    $errorContainer = $formGroup.find('.form-errors');

                                $errorInput.addClass('is-invalid');
                                $formGroup.addClass('has-error is-invalid');
                                if($errorContainer.find('ul').length){
                                    $errorContainer.find('ul').append('<li class="parsley-custom-error-message">' + response.messages.message[i].text + '</li>');
                                }else{
                                    $errorContainer.append('<ul class="parsley-errors-list filled"><li class="parsley-custom-error-message">' + response.messages.message[i].text + '</li></ul>')
                                }
                            } else {
                                $errorList.append($('<li class="parsley-custom-error-message">' + response.messages.message[i].text + '</li>'))
                            }

                            i = i + 1;
                        }
                    } else {
                        paymentFormUpdate(response.opaqueData);
                    }
                } catch(error){
                    console.error(error);
                }
            }
        }


        const paymentFormUpdate = function(opaqueData) {
            document.getElementById("dataDescriptor").value = opaqueData.dataDescriptor;
            document.getElementById("dataValue").value = opaqueData.dataValue;

            // If using your own form to collect the sensitive data from the customer,
            // blank out the fields before submitting them to your server.
            document.getElementById("cardNumber").value = "";
            document.getElementById("expDate").value = "";
            document.getElementById("cardCode").value = "";


            $paymentForm.attr('action', $paymentForm.data('action')); 

            document.getElementById("paymentForm").submit();

        }

        $paymentSubmitBtn.on('click', function (e) {
            let paypalSelected = $paypalBtn.hasClass('payment-methods__item--selected');

            if(paypalSelected) {
                $paymentMethodInput.val('paypal');
            }
        });


        $('.js-payment-method-btn').on('click',function (e) {
            $('.js-payment-method-btn').removeClass('payment-methods__item--selected');

            $(this).addClass('payment-methods__item--selected');

            $('.js-submit-payment__btn').removeAttr('hidden');
        });


        $('.js-payment-method-btn--collapse').on('click',function (e) {
            $('.js-payment-method-btn').removeClass('payment-methods__item--selected');
            $('.js-submit-payment__btn').attr('hidden','hidden');
        });


        $submitBtn.on('click', sendPaymentDataToAnet);
    }
}