'use strict'

import {unhideByLoginState} from "../../../../../../shared/unhide-by-login-state/unhide-by-login-state";
import {copyFooterTo} from "./footerHandler";
import {waitUntilRendered} from "../../../../../../shared/tryNTimes/waitUntilRendered";

const SELECTOR = {
    menucards: '.mobile-nav__menucard',
    menucardTriggers: '[data-mobile-nav__menucard-target]',
    menucardBackBtns: '.js-menucard-back-btn',
    menucardCloseBtns: '.js-menucard-close-btn',
};

export const menucards_CLASS = {
    active: 'show',
    isInitialized: 'is-initialized',
    displayOff: 'd-none',
}

export function initMenuCard(menuCard, closeFn) {
    copyFooterTo(menuCard)
    unhideByLoginState(menuCard)

    menuCard.querySelectorAll(':scope ' + SELECTOR.menucardTriggers).forEach(menucardTrigger => {
        const target = menucardTrigger.dataset.mobileNav__menucardTarget
        const targetElement = document.getElementById(target)

        menucardTrigger.addEventListener('click', (e) => {
            e.preventDefault();
            targetElement.classList.remove(menucards_CLASS.displayOff)

            setTimeout(() => {
                targetElement.querySelector('.nav__menucard__scrollable_content').scrollTo({top: 0, behavior: 'instant'})

                if (!targetElement.classList.contains(menucards_CLASS.isInitialized)) {
                    initMenuCard(targetElement, closeFn)

                    targetElement.classList.add(menucards_CLASS.isInitialized)
                    waitUntilRendered(targetElement).then(() => {
                        targetElement.classList.add(menucards_CLASS.active) //wait until is rendered before starting the css transition
                    })
                } else {
                    targetElement.classList.add(menucards_CLASS.active)
                }
            }, 10)
        })
    });

    menuCard.querySelectorAll(':scope ' + SELECTOR.menucardBackBtns).forEach(el => {
        el.addEventListener('click', (e) => {
            e.stopImmediatePropagation()
            const menucard = e.currentTarget.closest(SELECTOR.menucards);
            menucard.classList.remove('show');
        })
    });

    menuCard.querySelectorAll(':scope ' + SELECTOR.menucardCloseBtns).forEach(el => {
        el.addEventListener('click', (e) => {
            e.preventDefault()
            closeFn()
        })
    })
}