import {initSliders} from './sliderInit';

const
    initiativesSliderOptions = {
        arrows: false,
        dots: false,
        infinite: false,

        slidesToShow: 1,
        adaptiveHeight: true,
        variableWidth: true,
        // Redmine: 41807
        // @see: https://github.com/kenwheeler/slick/pull/2635
        outerEdgeLimit: true,
    };

export function initInScope($scope) {

    const $sliders = $scope.find('.js-partner-slider');
    initSliders($sliders, initiativesSliderOptions);
}
