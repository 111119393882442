'use strict';

import {ExecutionLimiter} from "./ExecutionLimiter";
import {TNodeHelper} from "./TNodeHelper";

/**
 * wrapper for the actual function creatPath.
 * the wrapper is only used to prevent infinite loops (while setting up the configuration)
 */
export class PathCreationLimited {
    constructor() {
        this._limiter = new ExecutionLimiter(50)
    }

    /**
     * @param {...(TAnyNode|TAction)[][]} steps
     * @return {TAnyNode}
     */
    createPath(...steps) {
        this._limiter.inc()
        return TNodeHelper.createPath(steps)
    }
}