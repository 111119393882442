'use strict'

export function initInScope($scope) {
    let showOriginal = $scope.find('.js-auto-translate-product-review-original-show');
    let showTranslation = $scope.find('.js-auto-translate-product-review-translation-show');

    showOriginal.each(function (index, showOriginalButton) {
        showOriginalButton.addEventListener('click', (event) => {
            event.stopPropagation();
            event.preventDefault();

            let reviewId = showOriginalButton.getAttribute("data-review-id");

            let originalContainer = $scope.find('.auto-translate-original__container[data-review-id="'+reviewId+'"]');
            let originalText = $scope.find('.js-auto-translate-product-review-original-text[data-review-id="'+reviewId+'"]');
            let translationContainer = $scope.find('.auto-translate-translation__container[data-review-id="'+reviewId+'"]');
            let translationText = $scope.find('.js-auto-translate-product-review-translation-text[data-review-id="'+reviewId+'"]');

            originalContainer.addClass('d-none');
            originalText.removeClass('d-none');

            translationContainer.removeClass('d-none');
            translationText.addClass('d-none');
        });
    });

    showTranslation.each(function (index, showTranslationButton) {
        showTranslationButton.addEventListener('click', (event) => {
            event.stopPropagation();
            event.preventDefault();

            let reviewId = showTranslationButton.getAttribute("data-review-id");

            let originalContainer = $scope.find('.auto-translate-original__container[data-review-id="'+reviewId+'"]');
            let originalText = $scope.find('.js-auto-translate-product-review-original-text[data-review-id="'+reviewId+'"]');
            let translationContainer = $scope.find('.auto-translate-translation__container[data-review-id="'+reviewId+'"]');
            let translationText = $scope.find('.js-auto-translate-product-review-translation-text[data-review-id="'+reviewId+'"]');

            translationContainer.addClass('d-none');
            translationText.removeClass('d-none');

            originalContainer.removeClass('d-none');
            originalText.addClass('d-none');
        });
    });


    /*
     * ANSWERS
     */
    let answerShowOriginal = $scope.find('.js-auto-translate-product-review-answer-original-show');
    let answerShowTranslation = $scope.find('.js-auto-translate-product-review-answer-translation-show');

    answerShowOriginal.each(function (index, showAnswerOriginalButton) {
        showAnswerOriginalButton.addEventListener('click', (event) => {
            event.stopPropagation();
            event.preventDefault();

            let reviewId = showAnswerOriginalButton.getAttribute("data-review-id");

            let answerOriginalContainer = $scope.find('.auto-translate-answer-original__container[data-review-id="'+reviewId+'"]');
            let answerOriginalText = $scope.find('.js-auto-translate-product-review-answer-original-text[data-review-id="'+reviewId+'"]');
            let answerTranslationContainer = $scope.find('.auto-translate-answer-translation__container[data-review-id="'+reviewId+'"]');
            let answerTranslationText = $scope.find('.js-auto-translate-product-review-answer-translation-text[data-review-id="'+reviewId+'"]');

            answerOriginalContainer.addClass('d-none');
            answerOriginalText.removeClass('d-none');

            answerTranslationContainer.removeClass('d-none');
            answerTranslationText.addClass('d-none');
        });
    });

    answerShowTranslation.each(function (index, showAnswerTranslationButton) {
        showAnswerTranslationButton.addEventListener('click', (event) => {
            event.stopPropagation();
            event.preventDefault();

            let reviewId = showAnswerTranslationButton.getAttribute("data-review-id");

            let answerOriginalContainer = $scope.find('.auto-translate-answer-original__container[data-review-id="'+reviewId+'"]');
            let answerOriginalText = $scope.find('.js-auto-translate-product-review-answer-original-text[data-review-id="'+reviewId+'"]');
            let answerTranslationContainer = $scope.find('.auto-translate-answer-translation__container[data-review-id="'+reviewId+'"]');
            let answerTranslationText = $scope.find('.js-auto-translate-product-review-answer-translation-text[data-review-id="'+reviewId+'"]');

            answerTranslationContainer.addClass('d-none');
            answerTranslationText.removeClass('d-none');

            answerOriginalContainer.removeClass('d-none');
            answerOriginalText.addClass('d-none');
        });
    });
}