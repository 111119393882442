'use strict';

import {MainMenu_Scenarios} from "./scenarios";
import {BasicScenarioFinder} from "../core/TransitionTree/BasicScenarioFinder";
import {StateWithPath} from "../core/state/StateWithPath";
import {MainMenuState} from "./MainMenuState";

const MENU_TRIGGER_TYPES = BasicScenarioFinder.MENU_TRIGGER_TYPES

const scenarioMapping = new Map([
    [MENU_TRIGGER_TYPES.OPEN, MainMenu_Scenarios.mainMenu_open],
    [MENU_TRIGGER_TYPES.CLOSE, MainMenu_Scenarios.mainMenu_close],
    [MENU_TRIGGER_TYPES.SWITCH, MainMenu_Scenarios.mainMenu_switch],
    [MENU_TRIGGER_TYPES.REOPEN, MainMenu_Scenarios.mainMenu_reopen],
])

/**
 * @param {MainMenuState} state
 * @param {MainMenu_ScenarioData} scenarioData
 * @param {boolean} onlyActivatingTypes
 * @return {string}
 */
const _findScenarioType = (state, scenarioData, onlyActivatingTypes = false) => {
    const currentTransState = StateWithPath.getValue(state, [MainMenuState.KEYS.ACTIVE_MENU, MainMenuState.KEYS._STATE_])
    const activeMenu = StateWithPath.getValue(state, [MainMenuState.KEYS.ACTIVE_MENU, MainMenuState.KEYS._MENUNAME_])

    if (!MainMenuState.isAVisibleState(currentTransState)) {
        return MENU_TRIGGER_TYPES.OPEN
    }

    const isSameMenu = activeMenu === scenarioData.menuName

    if (!isSameMenu) {
        return MENU_TRIGGER_TYPES.SWITCH
    }

    if (!onlyActivatingTypes && (currentTransState === MainMenuState.TRANS_STATES.OPEN || currentTransState === MainMenuState.TRANS_STATES.OPENING)) {
        return MENU_TRIGGER_TYPES.CLOSE
    } else {
        return MENU_TRIGGER_TYPES.REOPEN
    }
}

export const MainMenu_ScenarioMatching = Object.freeze({

    getScenario: (scenarioType) => BasicScenarioFinder.retrieveScenario_fromMapping(
        scenarioMapping,
        scenarioType
    ),

    findScenario: (state, scenarioData, onlyActivatingTypes = false) => MainMenu_ScenarioMatching.getScenario(_findScenarioType(state, scenarioData, onlyActivatingTypes))
})