'use strict';

export function initInScope($scope) {
    let $input = $('.js-date-input__input'),
        $button = $('.js-datepicker-form');

    $input.on('change keyup', () => {
        if($input.val() > ''){
            $button.removeAttr('disabled');
        }else{
            $button.attr('disabled', true);
        }
    })
}