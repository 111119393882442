'use strict';

import * as R from "ramda";
import {LevelMenu_Atomics as At} from "./atomics";
import {findTriggeringElement} from "../DomFunctions";
import {LevelMenu_Shared_Scenarios} from "./scenarios.shared";
import {TreeResolver} from "../../../shared/core/TransitionTree/TreeResolver";
import {ImageTiles_Content_Atomics as At_ImgContent} from "../TransitionElements/ImageTiles/atomics.content"
import {ImageTiles_Move_Atomics as At_ImgMove} from "../TransitionElements/ImageTiles/atomics.move"
import {TUnresolvedItem} from "../../../shared/core/TransitionTree/NodesClasses";
import {TRANSLATE_OVERRIDES} from "./translate_overrides"
import {TNodeHelper} from "../../../shared/core/TransitionTree/TNodeHelper";

export const LevelMenu_Scenarios = Object.freeze({

    /**
     * @param {NavigationState} state
     * @param {CreatePathFunc} createPath
     * @param {LevelMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    sublevel_open: (createPath, state, scenarioData) => {
        const sublevels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, scenarioData.level + 1)
        return createPath(
            [

                ...LevelMenu_Shared_Scenarios.fadeout_all_contents(state, sublevels, true),
                At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level - 1, true, undefined, TRANSLATE_OVERRIDES),

                LevelMenu_Shared_Scenarios.setTriggerHighlighting(state, findTriggeringElement(scenarioData.menuId), scenarioData.level, true),
                new TUnresolvedItem([TreeResolver.INITIAL_STATE], R.curry(At_ImgMove.create_Action__moveImageTilesContainer)(true)),

            ], [

                At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level, true, undefined, 0),

                new TUnresolvedItem([TreeResolver.STATE_AFTER_LAST_NODE], R.curry(At_ImgMove.create_Action__moveImageTilesContainer)(true)),
                At.create_Action__fadeInContent(scenarioData.level, scenarioData.menuElement, true),
                At_ImgContent.create_Path__switchImagetiles(createPath, true)

            ],
        )
    },


    /**
     * @param {CreatePathFunc} createPath
     * @param {NavigationState} state
     * @param {LevelMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    sublevel_switch: (createPath, state, scenarioData) => {
        const levelAndSublevels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, scenarioData.level),
              forceExecution = true,
              isLevel1 = levelAndSublevels[0] == 2,
              transitionDurationInMs = 200
        return createPath(
            [
                LevelMenu_Shared_Scenarios.setTriggerHighlighting(state, findTriggeringElement(scenarioData.menuId), scenarioData.level, true),

                ...LevelMenu_Shared_Scenarios.fadeout_all_contents(state, levelAndSublevels, true),
                At_ImgContent.create_Action__deactivateImagetiles(true, true),
                new TUnresolvedItem([TreeResolver.INITIAL_STATE], R.curry(isLevel1 ? At_ImgMove.create_Action__moveImageTilesContainerForceLvl1 : At_ImgMove.create_Action__moveImageTilesContainerForceLvl2)(true)),
            ], [
                isLevel1 ? At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level, true, undefined, TRANSLATE_OVERRIDES, forceExecution, isLevel1) : At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level - 1, true, undefined, TRANSLATE_OVERRIDES, forceExecution),
            ], [
                At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level, true, undefined, TRANSLATE_OVERRIDES, forceExecution, false, transitionDurationInMs),

                At_ImgContent.create_Path__switchImagetiles(createPath, true, true),
                new TUnresolvedItem([TreeResolver.STATE_AFTER_LAST_NODE], R.curry(isLevel1 ? At_ImgMove.create_Action__moveImageTilesContainerForceLvl2 : At_ImgMove.create_Action__moveImageTilesContainer)(true)),

                At.create_Action__fadeInContent(scenarioData.level, scenarioData.menuElement, true),
            ],
        )
    },

    /**
     * @param {CreatePathFunc} createPath
     * @param {NavigationState} state
     * @param {LevelMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    sublevel_reopen: (createPath, state, scenarioData) => {
        const sublevels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, scenarioData.level+ 1)
        return createPath(
            [
                createPath([...LevelMenu_Shared_Scenarios.fadeout_all_contents(state, sublevels, true),
                    At.create_Action__fadeInContent(scenarioData.level, scenarioData.menuElement, true),
                ]),
                At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level, true),
                LevelMenu_Shared_Scenarios.setTriggerHighlighting(state, findTriggeringElement(scenarioData.menuId), scenarioData.level, true),
                new TUnresolvedItem([TreeResolver.STATE_AFTER_LAST_NODE], R.curry(At_ImgMove.create_Action__moveImageTilesContainer)(true)),

                At_ImgContent.create_Path__switchImagetiles(createPath, true),
            ],
        )
    },


    /**
     * @param {CreatePathFunc} createPath
     * @param {NavigationState} state
     * @param {LevelMenu_ScenarioData }scenarioData
     * @return {TAnyNode}
     */
    sublevel_close: (createPath, state, scenarioData) => {
        const levelAndSublevels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, scenarioData.level)
        return createPath(
            [
                LevelMenu_Shared_Scenarios.setTriggerHighlighting(state, null, scenarioData.level, true),
                ...LevelMenu_Shared_Scenarios.fadeout_all_contents(state, levelAndSublevels, true),

                At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level - 1, true, undefined, TRANSLATE_OVERRIDES),

                new TUnresolvedItem([TreeResolver.STATE_AFTER_LAST_NODE], R.curry(At_ImgMove.create_Action__moveImageTilesContainer)(true)),
                At_ImgContent.create_Path__switchImagetiles(createPath, true)

            ],
        )
    },


    /**
     * same as open but the imagetiles switch is already handled at this point
     * (maybe parameterize the open func?)
     *
     * @param {NavigationState} state
     * @param {CreatePathFunc} createPath
     * @param {LevelMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    sublevel_autoopen: (createPath, state, scenarioData) => {
        const forceExecution = true,
              transitionDurationInMs = 1000
        return createPath(
            [
                At.create_Action__fadeInContent(scenarioData.level, scenarioData.menuElement, true),
                At.create_Action__highlight_Trigger(scenarioData.level, findTriggeringElement(scenarioData.menuId), true),
                At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level, true),
            ],
            [
                At.create_Action__setActiveMenuLevel_moveMenus(scenarioData.level, true, undefined, TRANSLATE_OVERRIDES, forceExecution, false, transitionDurationInMs),
            ]
        )
    },

})
