'use strict'

export function initInScope($scope) {
    const $form = $scope.find('form.sso__reactive-form');
    if ($form.length !== 0) {
        const formElements = $form[0].elements
        const reactiveFormElements = [].filter.call(
            formElements, el => [].includes.call(el.classList, 'sso__reactive-form-input')
        )
        const reactiveConditions = []
        let reactiveInputElements = []

        reactiveFormElements.forEach(el => {
            const conditions = JSON.parse(el.getAttribute('data-reactive-conditions'))
            reactiveConditions.push(conditions)

            el.addEventListener('change', function (el) {
                hideReactiveInputElements(reactiveInputElements, $scope)
                const conditionalTargets = conditions[el.target.value];
                if(conditionalTargets) {
                    conditionalTargets.forEach(target => {
                        let targetEl
                        if(target.includes('#')) {
                            targetEl = $scope.find(`[id$="${target.replace('#', '')}"]`)
                        } else {
                            targetEl = $scope.find(`[class$="${target.replace('.', '')}"]`)
                        }
                        if(targetEl.length) {
                            targetEl[0].removeAttribute('hidden')
                            const targetInputEl = targetEl[0].querySelector('input')
                            if(targetInputEl) {
                                targetInputEl.removeAttribute('data-parsley-excluded')
                            }
                        }
                    })
                }
            })
        })


        // get all reactive input elements and hide them initial
        reactiveConditions.forEach((condition) => {
            reactiveInputElements = [].concat.apply([], Object.values(condition))
        })

        hideReactiveInputElements(reactiveInputElements, $scope)
    }
}

function hideReactiveInputElements(elements, $scope) {
    elements.forEach(el => {
        const targetEl = $scope.find(el)
        if(targetEl.length) {
            const targetInputEl = targetEl[0].querySelector('input')
            if(targetInputEl) {
                targetInputEl.setAttribute('data-parsley-excluded', 'true')
                targetInputEl.classList.remove('is-invalid')
            }
            targetEl[0].setAttribute('hidden', true)
        }
    })
}
