'use strict';
import throwError from "../../../libs/@elements/throw-error"
import "../../../libs/@popperjs/core@2/popper.min";
import "../../../libs/@tippy.js@6/tippy-bundle.umd";

const Selectors = {
    Container: '.pds__dotmodule-wrapper',
    Dot: '.js-dotmodule__dot',
    DotContent: '.js-dotmodule__dot-inner',
    DotClose: '.js-dotmodule__dot-close',
    DotsWrapper: '.pds__dotmodule-image-wrapper',
}

const Attributes = {
    Id: 'id',
    Content: 'data-content'
}

const tippySettings = {
    maxWidth: 250,
    arrow: true,
    trigger: 'click',
    allowHTML: true,
    theme: 'ovx',
    offset: [0, 16],
    interactive: true,
    interactiveBorder: 30,
};

const DEBUGMODE_IDENTIFIER = 'pds__dotmodule-wrapper--debug';

let $container;
let $dotsWrapper;
let $dots;
let isDebugMode = false;

export function init($scope = $('body')) {
    $container = $scope.find(Selectors.Container).length ? $scope.find(Selectors.Container) : throwError("Dot Module JS – Container missing");
    $dots = $container.find(Selectors.Dot);
    isDebugMode = $container.hasClass(DEBUGMODE_IDENTIFIER);

    if (isDebugMode) console.log('dotModule.js:init()')
    if (!$container.find(Selectors.Dot).length && console && console.warn) console.warn("Dot Voice JS – Dots missing");
}

export function initInScope($scope = $('body')) {
    if (isDebugMode) console.log('dotModule.js:initInScope()')
    $dots.each(function() {
        initDot(this);
    })
}

function initDot(dot) {
    const id = $(dot).attr(Attributes.Id);
    const wrapper = $(dot).parent(Selectors.Container).get(0);
    const contentElement = $(dot).find(Selectors.DotContent);

    if (isDebugMode) console.log('dotModule.js:initDot()')

    if (!contentElement) {
        throwError("Dot Voice JS – Dot content missing");
        return;
    }

    const content = $(contentElement).html();

    const dotPopover = tippy(dot, $.extend(true, tippySettings, {
        onMount(instance) {
            if (!isDebugMode) return;
            console.log('onMount', instance);
        },
        onShow(instance) {
            if (!isDebugMode) return;
            console.log('onShow', instance);
        },
        onTrigger(instance, event) {
            if (!isDebugMode) return;
            console.log('onTrigger', instance, event);
        },
        onShow(instance) {
            initCloseBtn(instance);
        },
        content: content,
        popperOptions: {
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: {
                        padding: 20,
                        boundary: wrapper
                    },
                },
            ],
        }
    }));
}

function initCloseBtn(tippyInstance) {
    if (!tippyInstance.popper) {
        throwError("Dot Voice JS – Dot content missing");
    }

    $(tippyInstance.popper).find(Selectors.DotClose).on('click', function() {
        tippyInstance.hide();
    })
}
