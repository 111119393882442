'use strict'

export function initInScope(scope) {
    const $langModal = $(scope).find('.js-lang-modal, .mobile-nav__footer-languages-list')
    if($langModal && $langModal.length){
        initLangDropdowns($langModal)
    }
}

function initLangDropdowns($modal){
    const $langButtons = $modal.find('.js-lang-modal__btn');

    $langButtons.on('click', (e) => {
        e.preventDefault();
        const
            $target         = $(e.target),
            $button         = $target.hasClass('js-lang-modal__btn') ? $target : $target.closest('.js-lang-modal__btn'),
            $collapses      = $modal.find('.js-lang-modal__collapse'),
            $targetCollapse = $button.next('.js-lang-modal__collapse');

        $collapses.removeClass('is-active');
        $targetCollapse.addClass('is-active');
    })
}