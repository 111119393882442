"use strict";
import 'slick-carousel';
import {preloadNSlide} from '../../libs/@elements/slider';
import {onEnterViewPort} from "../../libs/@elements/viewport-utils";

export function initInScope($scope) {
    const
        $sliders = $scope.find('.js-imageSlider'),
        bigOptions = {
            infinite: true,
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-Pfeil-nachoben rotate-right text-white"></i></button>',
            prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-Pfeil-nachoben rotate-left text-white"></i></button>',
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        dots: true,
                        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-Pfeil-nachoben rotate-right text-white"></i></button>',
                        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-Pfeil-nachoben rotate-left text-white"></i></button>',
                    }
                }
            ]
        },
        smallOptions = {
            infinite: true,
            dots: false,
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: 0,
            focusOnSelect: true,
            nextArrow: '',
            prevArrow: '',
        }

    if ($sliders.length < 0) {
        return;
    }

    $sliders.each((_, slider) => {
        const $slider = $(slider);
        const $tabPaneParent = $slider.closest('.tab-pane:not(.active)');
        const $collapseParent = $slider.closest('.collapse');
        const $modalParent = $slider.closest('.modal');

        // sliders are not initialized if they are inside a tab #59592 code copied from public/static/js/libs/@elements/slider/index.js
        if ($tabPaneParent && $tabPaneParent.length) {
            const tabPaneId = $tabPaneParent.attr('id');
            const $tabTrigger5 = $('[href="#' + tabPaneId + '"],[data-bs-toggle=tab][data-target="#' + tabPaneId + '"],[data-bs-toggle=tab][data-bs-target="#' + tabPaneId + '"]');
            $tabTrigger5.each(function (idx, el) {
                el.addEventListener('shown.bs.tab', function () {
                    initBothSliders($slider, bigOptions, smallOptions);
                }, {once: true});
            })
        } else if ($collapseParent && $collapseParent.length) {
            /* wait until slide is shown if it is initially hidden in a collapse/accordion */
            $collapseParent.one('shown.bs.collapse', function () {
                initBothSliders($slider, bigOptions, smallOptions);
            });

        } else if ($modalParent && $modalParent.length) {
            /* wait until slide is shown if it is initially  hidden in a modal */
            $modalParent.one('shown.bs.modal', function () {
                initBothSliders($slider, bigOptions, smallOptions);
            });

        } else {
            // no tab, accordion, modal -> init slider as usual
            initBothSliders($slider, bigOptions, smallOptions);
        }

    })

}

function initBothSliders($slider, bigOptions, smallOptions) {
    const
        $bigSlider = $slider.find('.js-imageSlider__big'),
        $smallSlider = $slider.find('.js-imageSlider__small');

    if ($smallSlider.length > 0) {
        initSlider($bigSlider, {...bigOptions, asNavFor: $smallSlider});
        initSlider($smallSlider, {...smallOptions, asNavFor: $bigSlider});
    } else {
        initSlider($bigSlider, {...bigOptions});
    }
}

function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}