import {curry} from "ramda";

// Dom traversing
export const find = selector => findIn(selector, document);
export const findAll = selector => findAllIn(selector, document);
export const findIn = curry((selector, element) => element.querySelector(selector));
export const findAllIn = curry((selector, element) => toArray(element.querySelectorAll(selector)));

export const closest = curry((selector, element) => element.closest(selector));

//Dom manipulation
export const setText = curry((text, element) => element.textContent = text);
export const empty = element => element.textContent = '';

// Class manipulation
// todo ie11
export const addClass = curry((className, element) => {
    element.classList.add(className);
    return element;
});
export const removeClass = curry((className, element) => {
    element.classList.remove(className);
    return element;
});
export const toggleClass = curry((className, element) => {
    element.classList.toggle(className);
    return element;
});
export const hasClass = curry((className, element) => element.classList.contains(className));
export const matches = curry((selector, element) => (element.matches || element.matchesSelector || element.msMatchesSelector || element.mozMatchesSelector || element.webkitMatchesSelector || element.oMatchesSelector).call(element, selector));
export const is = matches;

// Attributes
export const setAttribute = curry((name, value, element) => element.setAttribute(name, value));
export const removeAttribute = curry((name, element) => element.removeAttribute(name));

// Events
export const on = curry((eventType, fnc, element) => {
    element.addEventListener(eventType, fnc);
    return element;
});

export const off = curry((eventType, fnc, element) => {
    element.removeEventListener(eventType, fnc);
    return element;
});

export const trigger = curry((eventType, element) => {
    element.dispatchEvent(new Event(eventType, {bubbles: true}));
    return element;
});

export const triggerWith = curry((eventType, eventData, element) => {
    element.dispatchEvent(new CustomEvent(eventType, {'detail': eventData}));
    return element;
});

// Element creation
export const createElementsByHTML = markup => {
    var tmp = document.implementation.createHTMLDocument();
    tmp.body.innerHTML = markup;
    return tmp.body.children;
};

// Misc
export const toHTMLElementArray = x => {
    if (NodeList.prototype.isPrototypeOf(x)) {
        return Array.from(x);
    } else if (Array.isArray(x)) {
        return x;
    } else {
        return [x];
    }
};

// private helper
const toArray = iterable => Array.from(iterable);
