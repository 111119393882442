'use strict'

const
    Selectors = {
        desktop: '.usp__container--desktop',
        features: '.usp__feature',
        feature: '.usp__feature--{i}',
        media: '.usp__feature-media',
        medium: '.usp__feature-media--{i}',
        video: '.js-usp-video',
    },
    Settings = {
        animationDuration: 300,
        featureId: 1
    };

export function initInScope($scope) {
    const $uspDesktop = $scope.find(Selectors.desktop);

    $uspDesktop.each((_, el) => {
        initUspDesktop($(el));
    });
}

function initUspDesktop($uspContainer) {
    const $features = $uspContainer.find(Selectors.features);

    $features.each((_, el) => {
        $(el).hover(function () {
            if (this.dataset.id === Settings.featureId) {
                return;
            }
            clearAnimations(this);
            activateFeature(this);
            deActivateFeatureMedium(function (){
                activateFeatureMedium(this);
            }.bind(this));
        })
    });
}

function activateFeature(feature) {
    $(Selectors.feature.replace('{i}', Settings.featureId)).removeClass('active');
    $(feature).addClass('active');
}

function deActivateFeatureMedium(callback) {
    const $mediumToDeActivate = $(Selectors.medium.replace('{i}', Settings.featureId));
    $mediumToDeActivate.animate({
        opacity: 0
    }, Settings.animationDuration, function () {
        $mediumToDeActivate.removeClass('active');
        $mediumToDeActivate.find(Selectors.video).each(function(_, stopVideo) {
            stopVideo.pause();
            stopVideo.classList.remove('active');
        });

        if (typeof callback === "function") {
            callback();
        }
    });
}

function activateFeatureMedium(feature) {
    const
        id = feature.dataset.id,
        $mediumToActivate = $(Selectors.medium.replace('{i}', id));

    $mediumToActivate.addClass('active');
    $mediumToActivate.find(Selectors.video).each(function(_, playVideo) {
        playVideo.currentTime = 0;
        playVideo.play();
        playVideo.classList.add('active');
    });

    $mediumToActivate.animate({
        opacity: 1
    }, Settings.animationDuration, function () {
        Settings.featureId = id;
    });
}

function clearAnimations(feature){
    $(Selectors.medium.replace('{i}', Settings.featureId)).stop(true, true);
    $(Selectors.medium.replace('{i}', feature.dataset.id)).stop(true, true);
}
