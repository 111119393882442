/* (modified) functions from the NG code base */

'use strict';

/* init has to take $scope as param and pass it on to onFind as first param
example:
export function initInScope($scope) {
    import {init} from "./scrolling-area";
    init($scope);
}
in scolling-area:
    function init($scope) {...
        onFind($scope, ......)
*/
export function onFind(scope, selector, callback) {
    $(scope).find(selector).each(function(idx, element) {
        callback(element);
    });
}
