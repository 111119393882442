import fetch from "../../libs/@elements/fetch";

export function initInScope($scope) {
    let bookNowButtons = $scope.find('[data-tracking-id="course-booking-link"]');
    let sourceUrl = window.location.href;

    bookNowButtons.each(function () {
        $(this).on('click', function () {
            let destinationUrl = $(this).attr('href');
            let endpoint = $(this).attr('action-url');

            let payload = {
                sourceUrl: sourceUrl,
                destinationUrl: destinationUrl
            };

            let pendingRequest = fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify(payload)
            });
            pendingRequest.then((res) => {
                return res.clone().json()
            })
                .then((res) => {
                    if (res.success && window.console) {
                        console.log('Console is available');
                    }
                })
        });
    });
}