'use strict';

import {BasicScenarioFinder} from "../../../shared/core/TransitionTree/BasicScenarioFinder";
import {StateWithPath} from "../../../shared/core/state/StateWithPath";
import {LevelMenuState} from "../state/LevelMenuState";
import {Tr_LevelContent} from "../TransitionElements/MenuLevel/Tr_LevelContent";
import {LevelMenu_Scenarios} from "./scenarios";

const MENU_TRIGGER_TYPES = BasicScenarioFinder.MENU_TRIGGER_TYPES
const KEYS = LevelMenuState.KEYS

const scenarioMapping = new Map([
    [MENU_TRIGGER_TYPES.OPEN, LevelMenu_Scenarios.sublevel_open],
    [MENU_TRIGGER_TYPES.CLOSE, LevelMenu_Scenarios.sublevel_close],
    [MENU_TRIGGER_TYPES.SWITCH, LevelMenu_Scenarios.sublevel_switch],
    [MENU_TRIGGER_TYPES.REOPEN, LevelMenu_Scenarios.sublevel_reopen],
])


export const LevelMenu_ScenarioMatching = Object.freeze({

    /**
     * @param {NavigationState} state
     * @param {LevelMenu_ScenarioData} scenarioData
     * @param {boolean} onlyActivatingTypes
     * @return {string}
     */
    findScenarioType: (state, scenarioData, onlyActivatingTypes = false) => {
        const lvl = scenarioData.level
        const basePath = [KEYS._PREFIX_LEVEL + lvl, KEYS.ACTIVE_CONTENT]
        const contentState = StateWithPath.getValue(state, [...basePath, KEYS._STATE_])
        const contentElement = StateWithPath.getValue(state, [...basePath, KEYS._ELEMENT_])

        if (!Tr_LevelContent.isAVisibleState(contentState)) {
            return MENU_TRIGGER_TYPES.OPEN
        }
        const isSameElement = contentElement === scenarioData.menuElement

        if (!isSameElement) {
            return MENU_TRIGGER_TYPES.SWITCH
        }

        if (!onlyActivatingTypes && (contentState === Tr_LevelContent.TRANS_STATES.ACTIVE || contentState === Tr_LevelContent.TRANS_STATES.ACTIVATING)) {
            return MENU_TRIGGER_TYPES.CLOSE
        } else {
            return MENU_TRIGGER_TYPES.REOPEN
        }
    },

    /**
     * @param {NavigationState} state
     * @param {LevelMenu_ScenarioData} scenarioData
     * @param {boolean} onlyActivatingTypes
     */
    findScenario: (state, scenarioData, onlyActivatingTypes = false) =>
        BasicScenarioFinder.retrieveScenario_fromMapping(
            scenarioMapping,
            LevelMenu_ScenarioMatching.findScenarioType(state, scenarioData, onlyActivatingTypes)
        )
})