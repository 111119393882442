"use strict";

import {translate} from "../../../shared/translations/translations";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-product-grid-slider');

    import('nouislider').then(function (noUiSlider) {
        $slider.each(function () {
            let $container = $(this);
            let slider = $container.find('.js-product-grid-slider__slider')[0];
            let $slider = $container.find('.js-product-grid-slider__slider');
            let $minInput = $container.find('.js-product-grid-slider__min');
            let $maxInput = $container.find('.js-product-grid-slider__max');
            let $minTextInput = $container.find('.js-product-grid-slider__min-text');
            let $maxTextInput = $container.find('.js-product-grid-slider__max-text');

            let min = $slider.data('min');
            let max = $slider.data('max');

            noUiSlider.create(slider, {
                start: [
                    $minInput.val() || min,
                    $maxInput.val() || max
                ],
                step: 1,
                connect: true,
                range: {
                    'min': min,
                    'max': max
                },
                handleAttributes: [
                    { 'aria-label': translate('aria.slider.lower-value')},
                    { 'aria-label': translate('aria.slider.upper-value')},
                ]
            });

            let $minText = $container.find('.noUi-handle-lower .noUi-touch-area');
            let $maxText = $container.find('.noUi-handle-upper .noUi-touch-area');


            /* product-grid.input-changed or changed to react to changes from the product grid */
            $minInput.on('product-grid.input-changed', function () {
                setMin(this.value !== '' ? this.value : min)
            });

            /* product-grid.input-changed or changed to react to changes from the product grid */
            $maxInput.on('product-grid.input-changed', function () {
                setMax(this.value !== '' ? this.value : max);
            });

            $minTextInput.on('change', function () {
                let val = +$(this).val();

                setMin(val);
            });
            $maxTextInput.on('change', function () {
                let val = +$(this).val();

                setMax(val);
            });

            slider.noUiSlider.on('update', function( values, handle ) {
                let value = +values[handle];
                if ( handle ) {
                    setMaxText(value);
                } else {
                    setMinText(value);
                }
            });

            slider.noUiSlider.on('change', function ( values, handle ) {
                /* trigger change to notify the product-grid*/
                if ( handle ) {
                    setMax(values[handle]);
                    $maxInput.trigger('change');
                } else {
                    setMin(values[handle]);
                    $minInput.trigger('change');
                }
            });

            function setMin(value) {
                value = isNaN(value) ? min : +value;

                $minInput.val(+value === min ? null: value);
                slider.noUiSlider.set([value, null]);
                setMinText(value);
            }

            function setMinText(value) {
                value = isNaN(value) ? min : +value;
                let text = value.toLocaleString(_config.local);

                $minText.html(text)
                $minTextInput.val(text);
                $minInput.data('text', text);
            }

            function setMax(value) {
                value = isNaN(value) ? max : +value;

                $maxInput.val(+value === max ? null: value);
                slider.noUiSlider.set([null, value]);
                setMaxText(value);
            }

            function setMaxText(value) {
                value = isNaN(value) ? max : +value;
                let text = value.toLocaleString(_config.local);

                $maxText.html(text)
                $maxTextInput.val(text);
                $maxInput.data('text', text);
            }
        })
    });
}