import {LevelMenuState} from "../../state/LevelMenuState";
import {TAction} from "../../../../shared/core/TransitionTree/TAction";
import {Tr_ImageTiles_Move} from "./Tr_ImageTiles_Move";
import * as R from "ramda";
import {LevelMenu_Shared_Scenarios} from "../../TransitionsConfig/scenarios.shared";

const KEYS = LevelMenuState.KEYS;
const MENU_WIDTH = 1370


export const ImageTiles_Move_Atomics = Object.freeze({
    /**
     * @param {LevelState[]} states
     * @param {boolean} useTransition
     * @return {TAction}
     */
    create_Action__moveImageTilesContainer: (useTransition, states) => {
        const state = Object.values(states)[0]
        const transInfo = Tr_ImageTiles_Move.moveContainer

        const highestActiveLevel = LevelMenu_Shared_Scenarios.getHighestActiveLevel(state)

        return new TAction(
            '(lvl ' + highestActiveLevel + ') ' +  transInfo.debugDescription,
            R.curry(Tr_ImageTiles_Move.moveContainer.func)(MENU_WIDTH, highestActiveLevel)(useTransition),
            [KEYS.IMAGETILES_POSITION],
            [
                [[KEYS._STATE_], transInfo.startState],
                [[KEYS._LEVEL_], highestActiveLevel],
            ],
            [
                [[KEYS._STATE_], transInfo.endState],
                [[KEYS._LEVEL_], highestActiveLevel],
            ]
        )
    },

    // TODO: Find better way to reuse create_Action__moveImageTilesContainer with new parameters "forceExecution" and "overwriteHightestActiveLevel"
    // Currently unable to because of R.curry() questionable syntax for adding parameters
    /**
     * @param {LevelState[]} states
     * @param {boolean} useTransition
     * @return {TAction}
     */
    create_Action__moveImageTilesContainerForceLvl1: (useTransition, states) => {
        const state = Object.values(states)[0]
        const transInfo = Tr_ImageTiles_Move.moveContainer


        const highestActiveLevel = 1

        return new TAction(
            '(lvl ' + highestActiveLevel + ') ' +  transInfo.debugDescription,
            R.curry(Tr_ImageTiles_Move.moveContainer.func)(MENU_WIDTH, highestActiveLevel)(useTransition),
            [KEYS.IMAGETILES_POSITION],
            [
                [[KEYS._STATE_], transInfo.startState],
                [[KEYS._LEVEL_], highestActiveLevel],
            ],
            [
                [[KEYS._STATE_], transInfo.endState],
                [[KEYS._LEVEL_], highestActiveLevel],
            ],
            true
        )
    },

    // TODO: Find better way to reuse create_Action__moveImageTilesContainer with new parameters "forceExecution" and "overwriteHightestActiveLevel"
    // Currently unable to because of R.curry() questionable syntax for adding parameters
    /**
     * @param {LevelState[]} states
     * @param {boolean} useTransition
     * @return {TAction}
     */
    create_Action__moveImageTilesContainerForceLvl2: (useTransition, states) => {
        const state = Object.values(states)[0]
        const transInfo = Tr_ImageTiles_Move.moveContainer


        const highestActiveLevel = 2

        return new TAction(
            '(lvl ' + highestActiveLevel + ') ' +  transInfo.debugDescription,
            R.curry(Tr_ImageTiles_Move.moveContainer.func)(MENU_WIDTH, highestActiveLevel)(useTransition),
            [KEYS.IMAGETILES_POSITION],
            [
                [[KEYS._STATE_], transInfo.startState],
                [[KEYS._LEVEL_], highestActiveLevel],
            ],
            [
                [[KEYS._STATE_], transInfo.endState],
                [[KEYS._LEVEL_], highestActiveLevel],
            ],
            true
        )
    },
})
