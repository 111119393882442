'use strict';

let autoplayVideoElems

const SELECTORS = {
    Container: '.js-autoplay-video-controls',
    ProgressBar: '.js-autoplay-video-controls__progress-bar',
    Video: '.js-autoplay-video-controls__video',
    PauseBtn: '.js-autoplay-video-controls__control--pause',
    PlayBtn: '.js-autoplay-video-controls__control--play'
}

const STATE_PAUSED = 'js-autoplay-video-controls--video-paused'
const STATE_PLAYING = 'js-autoplay-video-controls--video-playing'

export function initInScope() {
    autoplayVideoElems = Array.from(document.querySelectorAll(SELECTORS.Container))

    autoplayVideoElems.forEach(autoplayVideoElem => {
        const autoplayVideoElemVideo = autoplayVideoElem.querySelector(SELECTORS.Video),
              autoplayVideoElemProgressBar = autoplayVideoElem.querySelector(SELECTORS.ProgressBar),
              autoplayVideoElemPauseBtn = autoplayVideoElem.querySelector(SELECTORS.PauseBtn),
              autoplayVideoElemPlayBtn = autoplayVideoElem.querySelector(SELECTORS.PlayBtn)

        if (!autoplayVideoElemVideo || !autoplayVideoElemProgressBar) return
        updateAutoplayVideoProgressBar(autoplayVideoElemVideo, autoplayVideoElemProgressBar)

        if (!autoplayVideoElemPauseBtn || !autoplayVideoElemPlayBtn) return
        updateAutoplayVideoElemPausedState(autoplayVideoElem, autoplayVideoElemVideo)
        bindCustomVideoControlElems(autoplayVideoElem, autoplayVideoElemVideo, autoplayVideoElemPauseBtn, autoplayVideoElemPlayBtn)
    });
}

function updateAutoplayVideoProgressBar(autoplayVideoElemVideo, autoplayVideoElemProgressBar) {
    let videoProgressPercentage = 0
    const radius = autoplayVideoElemProgressBar.r.baseVal.value,
          circumference = radius * 2 * Math.PI

    autoplayVideoElemProgressBar.style.strokeDasharray = `${circumference} ${circumference}`
    autoplayVideoElemProgressBar.style.strokeDashoffset = circumference

    autoplayVideoElemVideo.ontimeupdate = () =>  {
        videoProgressPercentage = (autoplayVideoElemVideo.currentTime / (autoplayVideoElemVideo.duration % 60)) * 100
        autoplayVideoElemProgressBar.style.strokeDashoffset = circumference - videoProgressPercentage / 100 * circumference
    }
}

function updateAutoplayVideoElemPausedState(autoplayVideoElem, autoplayVideoElemVideo) {
    autoplayVideoElemVideo.addEventListener('pause', () => {
        autoplayVideoElem.classList.add(STATE_PAUSED)
        autoplayVideoElem.classList.remove(STATE_PLAYING)
    })

    autoplayVideoElemVideo.addEventListener('play', () => {
        autoplayVideoElem.classList.remove(STATE_PAUSED)
        autoplayVideoElem.classList.add(STATE_PLAYING)
    })
}

function bindCustomVideoControlElems(autoplayVideoElem, autoplayVideoElemVideo, autoplayVideoElemPauseBtn, autoplayVideoElemPlayBtn) {
    autoplayVideoElemPauseBtn.addEventListener('click', () => {
        autoplayVideoElemVideo.pause()
        autoplayVideoElem.classList.add(STATE_PAUSED)
    })

    autoplayVideoElemPlayBtn.addEventListener('click', () => {
        autoplayVideoElemVideo.play()
        autoplayVideoElem.classList.remove(STATE_PAUSED)
    })
}
