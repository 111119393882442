const _cache = {}

export const _initialize = (...mainMenus) => {
    mainMenus.forEach(menuName => _cache[menuName] = document.getElementById(menuName + '-content'));
}

export const DOM_MainMenuItemCache = Object.freeze({
    initialize: _initialize,
    getMenu: (menuName) => _cache[menuName],
})
