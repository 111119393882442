'use strict';
import throwError from "../../libs/@elements/throw-error"
import {onEnterViewPort} from "../../libs/@elements/viewport-utils";

const Selectors = {
    Container: '.ovx__diract-voice:not(.ovx__diract-voice--no-js)',
    Modals: '.ovx__diract-voice__modal',
    TrackWrappers: '.ovx__diract-voice__track-wrapper',
    TrackTogglePlay: '.ovx__diract-voice__track-toggle--play',
    TrackTogglePause: '.ovx__diract-voice__track-toggle--pause',
    Speechbubble: '.ovx__diract-voice__speechbubble-inner'
}

const Attributes = {
    TrackPath: 'data-track-path',
    TrackText: 'data-track-text'
}

const IN_VIEWPORT_STATE = 'ovx__diract-voice--in-viewport';
const IS_PLAYING_STATE = 'ovx__diract-voice__track-wrapper--is-playing';
const IS_SPEECH_BUBBLE_ACTIVE_STATE = 'ovx__diract-voice__speechbubble--active';

let $container;
let $modals;
let audioArr = [];
let isSpeechBubbleVisible = false;

export function init($scope = $('body')) {
    $container = $scope.find(Selectors.Container).length ? $scope.find(Selectors.Container) : throwError("Diract Voice JS – Container missing");
    $modals = $container.find(Selectors.Modals).length ? $container.find(Selectors.Modals) : throwError("Diract Voice JS – Modals missing");
}

export function initInScope($scope = $('body')) {
    onEnterViewPort($container, ($container) => {
        $container.addClass(IN_VIEWPORT_STATE)
    });

    if (window.diractVoiceAlreadyInitialized) {
        return;
    }

    $modals.each((index, modal) => {
        const $trackWrappers = $(modal).find(Selectors.TrackWrappers);
        const $speechbubble = $(modal).find(Selectors.Speechbubble);

        if (!$trackWrappers.length) {
            throwError("Diract Voice JS – TrackWrappers missing");
        }

        if (!$speechbubble.length) {
            throwError("Diract Voice JS – Speechbubble missing");
        }

        $trackWrappers.each((index2, trackWrapper) => {
            $(modal).one('show.bs.modal', () => {
                console.log('init track', modal);
                initTrack($(trackWrapper), $speechbubble);
            })
        })

        $(modal).on('hide.bs.modal', () => {
            pauseOtherAudioObjs(null);
        })
    })

    window.diractVoiceAlreadyInitialized = true;
}


function initTrack($trackWrapper, $speechbubble) {
    const audioTrack = $trackWrapper.attr(Attributes.TrackPath) || false;
    const audioTrackText = $trackWrapper.attr(Attributes.TrackText) || false;

    if (!audioTrack) {
        throwError("Diract Voice JS – No audio track found");
    }

    const audioObj = new Audio(audioTrack);
    audioObj.load(); // fix for ios. so the audio is always loaded

    if (!audioObj) {
        throwError("Diract Voice JS – No Audio object could be created");
    }

    if (!audioTrackText) {
        throwError("Diract Voice JS – No Audio speechbubble text could be found");
    }

    audioArr.push(audioObj)

    $(audioObj).off('loadeddata').on('loadeddata', () => {
        _onLoadeddata(audioObj, $trackWrapper);
    });

    $(audioObj).off('play').on('play', () => {
        _onPlay(audioObj, $trackWrapper, audioTrackText, $speechbubble)
    })

    $(audioObj).off('pause').on('pause', () => {
        _onPause(audioObj, $trackWrapper, $speechbubble, true)
    })

    $(audioObj).off('ended').on('ended', () => {
        _onPause(audioObj, $trackWrapper, $speechbubble)
    })
}

function _onLoadeddata(audioObj, $trackWrapper) {
    $trackWrapper.on('click', () => {
        if (audioObj.paused) {
            audioObj.play();
        } else {
            audioObj.pause();
        }
    })
}

function _onPlay(currentAudioObj, $trackWrapper, audioTrackText, $speechbubble) {
    $trackWrapper.addClass(IS_PLAYING_STATE);

    pauseOtherAudioObjs(currentAudioObj);

    if(!isSpeechBubbleVisible) {
        showSpeechBubble($speechbubble);
    }
    $speechbubble.text(audioTrackText)
}

function _onPause(audioObj, $trackWrapper, $speechbubble, resetTrack = false) {
    // Reset audio track on pause
    if (resetTrack) audioObj.currentTime = 0;

    $trackWrapper.removeClass(IS_PLAYING_STATE);

    // Check if audio is currenty playing
    // Remove speechbubble text if no audio is playing (e.g. audio finished)
    if (!(audioArr.filter(audioObj => !audioObj.paused).length)) {
        if(isSpeechBubbleVisible) {
            hideSpeechBubble($speechbubble);
        }
        $speechbubble.text('');
    }
}

function pauseOtherAudioObjs(currentAudioObj) {
    const audioObjsToPause = audioArr.filter(audioObj => audioObj !== currentAudioObj);

    if (!audioObjsToPause.length) {
        return;
    }

    audioObjsToPause.forEach(audioObj => audioObj.pause());
}

function showSpeechBubble($speechbubble) {
    $speechbubble.parent().addClass(IS_SPEECH_BUBBLE_ACTIVE_STATE);
    isSpeechBubbleVisible = true;
}

function hideSpeechBubble($speechbubble) {
    $speechbubble.parent().removeClass(IS_SPEECH_BUBBLE_ACTIVE_STATE);
    isSpeechBubbleVisible = false;
}
