import {StateWithPath} from "../../../../shared/core/state/StateWithPath";
import {LevelMenuState} from "../../state/LevelMenuState";
import {Tr_LevelContent} from "../MenuLevel/Tr_LevelContent";
import {TOPLEVEL} from "../../../MainMenu/Desktop_MainMenuTriggers";
import {TAction} from "../../../../shared/core/TransitionTree/TAction";
import {Tr_ImageTiles_ActiveItem} from "./Tr_ImageTiles_ActiveItem";
import * as R from "ramda";
import {TreeResolver} from "../../../../shared/core/TransitionTree/TreeResolver";
import {TUnresolvedItem} from "../../../../shared/core/TransitionTree/NodesClasses";

const KEYS = LevelMenuState.KEYS;

const _container = document.getElementById('desknav-imagetiles-container')
const STATE_AFTER_LAST_NODE = TreeResolver.STATE_AFTER_LAST_NODE
const INITIAL_STATE = TreeResolver.INITIAL_STATE

/**
 *
 * @param {LevelState} state
 * @return {HTMLElement[]}
 */
const getActiveLevelContents = (state) => {
    const nLevels = StateWithPath.getValue(state, [KEYS.MAX_LEVEL])

    let activeContents = []
    for (let lvl = nLevels - 1; lvl >= TOPLEVEL; lvl -= 1) {
        const basePath = [KEYS._PREFIX_LEVEL + lvl, KEYS.ACTIVE_CONTENT]
        const isActive = StateWithPath.getValue(state, [...basePath, KEYS._STATE_]) === Tr_LevelContent.TRANS_STATES.ACTIVE
        if (isActive) {
            activeContents.push(
                StateWithPath.getValue(state, [...basePath, KEYS._ELEMENT_])
            )
        }
    }

    return activeContents
}

const _retrieveTargetImagetiles = (state_after_last_node) => {
    const activeLevelContents = getActiveLevelContents(state_after_last_node);
    const imageTilesId = _determineImageTilesToActivate(activeLevelContents)
    return _findImageTileByTilesId(imageTilesId)
}


/**
 * @param {HTMLElement[]} activeContents
 * @return {?int}
 */
const _determineImageTilesToActivate = (activeContents) => {
    for (const content of activeContents) {
        const targetId = content.dataset['imageTilesTargetId']
        if (targetId) {
            return parseInt(targetId)
        }
    }
}

/**
 * @param imageTilesTargetId
 * @returns {Element|undefined}
 */
const _findImageTileByTilesId = (imageTilesTargetId) =>
    _container.querySelector(`[data-image-tiles-id="${imageTilesTargetId}"]`);

const _get_active_element = (state) => {
    return StateWithPath.getValue(state, [KEYS.ACTIVE_IMAGETILES_CONTENT, KEYS._ELEMENT_])
}

/**
 * @param {boolean} useTransition
 * @param {Element} activeElement
 * @return {?TAction}
 */
const _create_Action__deactivateImagetiles = (activeElement, useTransition) => {
    const transitionInfo = Tr_ImageTiles_ActiveItem.deactivate
    if (!activeElement) {
        return null
    }

    return new TAction(
        transitionInfo.debugDescription,
        R.curry(transitionInfo.func)(activeElement)(useTransition),
        [KEYS.ACTIVE_IMAGETILES_CONTENT],
        [
            [[KEYS._STATE_], transitionInfo.startState],
            [[KEYS._ELEMENT_], activeElement],
        ],
        [
            [[KEYS._STATE_], transitionInfo.endState],
            [[KEYS._ELEMENT_], undefined],
        ],
    )
}

/**
 * @param {boolean} useTransition
 * @param {HTMLElement} targetElement
 * @return {?TAction}
 */
const _create_Action__activateImagetiles = (targetElement, useTransition) => {
    const transitionInfo = Tr_ImageTiles_ActiveItem.activate

    if (!targetElement) {
        return null
    }

    return new TAction(
        transitionInfo.debugDescription,
        R.curry(transitionInfo.func)(targetElement)(useTransition),
        [KEYS.ACTIVE_IMAGETILES_CONTENT],
        [
            [[KEYS._STATE_], transitionInfo.startState],
            [[KEYS._ELEMENT_], targetElement],
        ],
        [
            [[KEYS._STATE_], transitionInfo.endState],
            [[KEYS._ELEMENT_], targetElement],
        ],
    )
}

/**
 * @param {boolean} useTransition
 * @return {TUnresolvedItem}
 */
const create_Action__deactivateImagetiles = (useTransition, forceExecution) =>
    new TUnresolvedItem([INITIAL_STATE, STATE_AFTER_LAST_NODE], (states) => {
        const targetElement = _retrieveTargetImagetiles(states[STATE_AFTER_LAST_NODE])
        const currentElement = _get_active_element(states[INITIAL_STATE])

        const shouldDeactivate = currentElement && targetElement !== currentElement || forceExecution
        if (!shouldDeactivate) {
            return null
        }
        return _create_Action__deactivateImagetiles(currentElement, useTransition)
    })


/**
 * @param {boolean} useTransition
 * @return {TUnresolvedItem}
 */
const create_Action__activateImagetiles = (useTransition, forceExecution) =>
    new TUnresolvedItem([INITIAL_STATE, STATE_AFTER_LAST_NODE], (states) => {
        const currentElement = _get_active_element(states[INITIAL_STATE])
        const targetElement = _retrieveTargetImagetiles(states[STATE_AFTER_LAST_NODE])
        const shouldActivate = targetElement && targetElement !== currentElement || forceExecution

        if (!shouldActivate) {
            return
        }

        return _create_Action__activateImagetiles(targetElement, useTransition)
    })

const create_Path__switchImagetiles = (createPath, useTransition, forceExecution) => {
    const deactivate = create_Action__deactivateImagetiles(useTransition, forceExecution)
    const activate = create_Action__activateImagetiles(useTransition, forceExecution)

    if (useTransition) {
        return createPath([
            deactivate
        ], [
            activate
        ])
    }

    return createPath([
        deactivate,
        activate
    ])
}


export const ImageTiles_Content_Atomics = Object.freeze({
    create_Action__activateImagetiles,
    create_Action__deactivateImagetiles,
    create_Path__switchImagetiles,
})