import {Overlay} from "../shared/MainMenu/Overlay/Overlay";

const _overlayElement = document.getElementById('mobile-overlay')

export const Tr_MobileOverlay = Object.freeze({
    /**
     * @param {boolean} useTransition
     * @param {TransitionCancellationFunc} isTransitionCanceled
     * @return {Promise}
     */
    show: (useTransition,  isTransitionCanceled) => Overlay.show.func(_overlayElement, useTransition, isTransitionCanceled),

    hide: (useTransition, isTransitionCanceled) => Overlay.hide.func(_overlayElement, useTransition, isTransitionCanceled),

    initCloseTrigger: (callback) => Overlay.initCloseTrigger(_overlayElement, callback),
})
