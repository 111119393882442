'use strict'
import { preloadNSlide } from '../../libs/@elements/slider';
import { onEnterViewPort } from "../../libs/@elements/viewport-utils";
import { throttle } from 'throttle-debounce';
import { initInScope as initAccordionCustom, updateCollapseStateOfRelatedTriggers } from './accordionToggle'
import {findIn} from "../../libs/@elements/dom-utils";

const
    mainSliderOptions = {
        slidesToShow: 1,
        adaptiveHeight: true,
        infinite: false,
        prevArrow: false,
        nextArrow: false,
        dots: true,
        initialSlide: 0
    },
    addOnSliderOptions = {
        slidesToShow: 4,
        adaptiveHeight: true,
        dots: false,
        infinite: false,
        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-ortovox_arrow-right icon-4x"></i></button>',
        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-ortovox_arrow-left icon-4x"></i></button>',
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    arrows: false,
                    // Redmine: 41807
                    // @see: https://github.com/kenwheeler/slick/pull/2635
                    outerEdgeLimit: true
                }
            }
        ]
    }

export function initInScope($scope) {
    const
        $productOverview            = $scope.find('.js-pds__product'),
        $application                = $scope.find('.js-pds__application'),
        $addOnSliders               = $scope.find('.js-pds__addon-slider'),
        $accordionCustomTriggers    = $scope.find('.js-accordion-custom-toggle'),
        $flyoutDocumentLink         = $scope.find('.js-pds-flyout__document');

    if($productOverview.length !== 0){
        initProductOverview($productOverview);
    }
    if($application.length !== 0){
        initApplicationCollapse($application)
    }
    if($addOnSliders.length !== 0){
        $addOnSliders.each((_, el) => {
            initAddonSlider($(el));
        })
    }
    if ($accordionCustomTriggers.length !== 0) {
        $accordionCustomTriggers.each(function(_, el) {
            initAccordionCustom($(el));
        })
    }
    if($flyoutDocumentLink.length !== 0){
        $flyoutDocumentLink.each((_, el) => {
            initFlyoutDocumentLink($(el));
        })
    }

    $(document).on('hide.bs.collapse', function (event) {
        _onCollapseToggle(event, false)
    })

    $(document).on('show.bs.collapse', function (event) {
        _onCollapseToggle(event, true)
    })
}

function _onCollapseToggle(event, collapseToggle) {
    updateCollapseStateOfRelatedTriggers(event, collapseToggle)
}


function initAddonSlider($addOnSliders) {
    if ($addOnSliders.is('.slick-initialized')) {
        // Error source here https://stackoverflow.com/questions/35884752/error-in-slick-js-uncaught-typeerror-cannot-read-property-add-of-null
        // Error source comes from re-initializing slick slider
        // Fix https://stackoverflow.com/questions/32371003/how-to-reload-slick-slider-carousel-jquery-plugin
        $addOnSliders.slick('refresh');
    } else {
        $addOnSliders.slick({
            ...addOnSliderOptions,
        }).on('afterChange', function (event, slick, currentSlide) {
            preloadNSlide(slick, currentSlide + 1, addOnSliderOptions.slidesToShow);
        });
    }

    onEnterViewPort($addOnSliders, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, addOnSliderOptions.slidesToShow);
    });
}

function initApplicationCollapse($application) {
    const
        mobileBreak     = window.matchMedia('(max-width: 767px)'),
        $collapses      = $application.find('.pds__application-collapse-mobile'),
        $collapseBtn    = $application.find('.js-pds__application-heading')

    $collapseBtn.on('click', (e) => {
        if(matchMedia('(min-width: 768px)').matches){
            e.stopPropagation();
        }
    })
    /**
     * manuel polyfill. addListener is deprecated but in ie and edge addEventListerner is not yet supported
     */
    if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent)  || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)){
        mobileBreak.addListener((e) => {
            _onMobileBreak(e, $collapses)
        })
    }else{
        mobileBreak.addEventListener('change', (e) => {
            _onMobileBreak(e, $collapses)
        })
    }
    _onMobileBreak(mobileBreak, $collapses)
}

function _onMobileBreak(mobileBreak, $collapses) {
    if(mobileBreak.matches){
        $collapses.each(function() {
            $(this).collapse('hide')
        })
    }
}

function setTextOffset($productOverview) {
    const
        $descr                  = $('.js-pds__descr'),
        $descrContainerDesktop  = $('.js-pds__descr-desktop'),
        $descrContainerMobile   = $('.js-pds__descr-mobile')

    if($(window).outerWidth() <= 767){
        $productOverview.find('.js-pds__product-slider').css({
            "transform": `translateY(-${0}px)`,
            "margin-bottom" : `-${0}px`
        })
        $descrContainerMobile.append($descr)
        $descrContainerDesktop.empty();
        return;
    }
    const
        textHeight  = $productOverview.find('.js-pds__product-name').height(),
        $slider     = $productOverview.find('.js-pds__product-slider')
    $slider.css({
        "transform" : `translateY(-${textHeight}px)`,
        "margin-bottom" : `-${textHeight}px`
    })
    $descrContainerDesktop.append($descr);
    $descrContainerMobile.empty()
}

function initProductOverview($productOverview) {
    setTextOffset($productOverview)
    /* setTimeout fixes initialisation bug in firefox due to css not loaded */
    setTimeout(() => {
        initProductSliders($productOverview)
    }, 1000)

    const textOffsetFnc = throttle(150, () => setTextOffset($productOverview))
    $(window).on('load resize orientationchange', textOffsetFnc);
}
function initProductSliders($productOverview) {
    const $mainSlider   = $productOverview.find('.js-pds__product-slider-main');
    const mediaQuery    = window.matchMedia('(max-width: 767px)');
    function handleMediaChange(e) {
        if (e.matches && $mainSlider.length) {
            const videoToSwitch = $mainSlider.find('[data-slick-index="3"]');

            if(videoToSwitch.length) {
                const slickIdsToSwitch = [1,2];
                let elementsToSwitch = [];

                // get the content from the second an third slide
                slickIdsToSwitch.forEach((value) => {
                    let element = $mainSlider.find('[data-slick-index="' + value + '"]');

                    if(element.length) {
                        elementsToSwitch[value] = element.html();
                    }
                });

                // remove the second and third slide
                elementsToSwitch.forEach(() => {
                    $mainSlider.slick('slickRemove', 1);
                });

                // add the second and third slide after the video
                elementsToSwitch.forEach((element, index) => {
                    $mainSlider.slick('slickAdd', element, index);
                });
            }
        }
    }

    let mainSliderNavOptions = {...mainSliderOptions};

    if ($mainSlider) {
        $mainSlider.slick(mainSliderNavOptions).on('afterChange', function (event, slick, currentSlide) {
            preloadNSlide(slick, currentSlide + 1, mainSliderOptions.slidesToShow);
        });

        onEnterViewPort($mainSlider, function ($slider) {
            preloadNSlide($slider.slick('getSlick'), 1, mainSliderOptions.slidesToShow);
        });

        mediaQuery.addListener(handleMediaChange)
        handleMediaChange(mediaQuery)
    }
}
function initFlyoutDocumentLink(flyoutDocumentLink) {
    const label = findIn('span', flyoutDocumentLink[0]).innerText
    const icon = findIn('.icon', flyoutDocumentLink[0])

    flyoutDocumentLink[0].innerHTML = label.replace(
        new RegExp(label.split(" ").pop(), 'gi'), '<span style="white-space: nowrap;">$&' + icon.outerHTML + '</span>'
    )
}
