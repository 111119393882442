export function initInScope($scope) {
    const $customFiles = $scope.find('.js-custom-file');

    $customFiles.each((_,el) => {
        const
            $customFile         = $(el),
            $input              = $customFile.find('input'),
            $filenameContainer  = $customFile.find('.js-custom-file__name');

        $input.on('change', (e) => {
            const filename = e.target.value.split('\\').pop()
            $filenameContainer.text(filename);
        })
    })

}