'use strict';

import {LevelMenu_Atomics as At} from "./atomics";
import {create_autoOpenLvl2_ScenarioData, findTriggeringElement} from "../DomFunctions";
import {TOPLEVEL} from "../../MainMenu/Desktop_MainMenuTriggers";
import {LevelMenu_Shared_Scenarios} from "./scenarios.shared";
import * as R from "ramda";
import {LevelMenu_Scenarios} from "./scenarios";
import {TNodeHelper} from "../../../shared/core/TransitionTree/TNodeHelper";
import {TreeResolver} from "../../../shared/core/TransitionTree/TreeResolver";
import {TUnresolvedItem} from "../../../shared/core/TransitionTree/NodesClasses";
import {ImageTiles_Move_Atomics as At_ImgMove} from "../TransitionElements/ImageTiles/atomics.move"
import {ImageTiles_Content_Atomics as At_ImgContent} from "../TransitionElements/ImageTiles/atomics.content"
import {TRANSLATE_OVERRIDES} from "./translate_overrides"

const STATE_AFTER_LAST_NODE = TreeResolver.STATE_AFTER_LAST_NODE

/**
 * @param {LevelState} state
 * @param {CreatePathFunc} createPath
 * @param {LevelMenu_ScenarioData} chainData
 * @return {?TAnyNode}
 */
const _auto_open_level2 = (createPath, state, chainData) => {
    const chainData_open_lvl2 = create_autoOpenLvl2_ScenarioData(chainData.menuElement)
    if (!chainData_open_lvl2) {
        return undefined
    }

    return LevelMenu_Scenarios.sublevel_autoopen(createPath, state, chainData_open_lvl2)
}

export const LevelMenu_Toplevel_Scenarios = Object.freeze({
    /**
     * @param {LevelState} state
     * @param {CreatePathFunc} createPath
     * @param {LevelMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    toplevel_open: (createPath, state, scenarioData) => {
        const sublevels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, TOPLEVEL)
        return TNodeHelper.joinPaths(
            createPath(
                [
                    LevelMenu_Shared_Scenarios.setTriggerHighlighting(state, findTriggeringElement(scenarioData.menuId), TOPLEVEL, true),

                    ...LevelMenu_Shared_Scenarios.fadeout_all_contents(state, sublevels, false),
                    At.create_Action__setActiveMenuLevel_moveMenus(TOPLEVEL, false, undefined, TRANSLATE_OVERRIDES),

                    new TUnresolvedItem([STATE_AFTER_LAST_NODE], R.curry(At_ImgMove.create_Action__moveImageTilesContainer)(false)),
                    At_ImgContent.create_Path__switchImagetiles(createPath, false),
                ], [
                    At.create_Action__fadeInContent(TOPLEVEL, scenarioData.menuElement, false),
                ], [
                    At.create_Action__show_LevelNavigation(true),
                ]),

            _auto_open_level2(createPath, state, scenarioData)
        )
    },


    /**
     * @param {LevelState} state
     * @param {CreatePathFunc} createPath
     * @param {LevelMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    toplevel_switch: (createPath, state, scenarioData) => {
        const levels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, TOPLEVEL)
        return TNodeHelper.joinPaths(
            createPath(
                [
                    ...LevelMenu_Shared_Scenarios.fadeout_all_contents(state, levels, true),

                    LevelMenu_Shared_Scenarios.setTriggerHighlighting(state, findTriggeringElement(scenarioData.menuId), levels[levels.length - 2], true, true),

                    At.create_Action__show_LevelNavigation(true),

                    At_ImgContent.create_Action__deactivateImagetiles(true),

                ], [
                    new TUnresolvedItem([STATE_AFTER_LAST_NODE], R.curry(At_ImgMove.create_Action__moveImageTilesContainer)(false)),

                    At.create_Action__setActiveMenuLevel_moveMenus(TOPLEVEL, false, undefined, TRANSLATE_OVERRIDES),
                ], [
                    At.create_Action__fadeInContent(scenarioData.level, scenarioData.menuElement, true),
                    At_ImgContent.create_Action__activateImagetiles(true),
                ]),

            _auto_open_level2(createPath, state, scenarioData)
        )
    },
    /**
     * @param {LevelState} state
     * @param {CreatePathFunc} createPath
     * @param {LevelMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    toplevel_reopen: (createPath, state, scenarioData) => {
        const sublevels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, TOPLEVEL + 1)
        return TNodeHelper.joinPaths(
            createPath(
                [

                    LevelMenu_Shared_Scenarios.setTriggerHighlighting(state, findTriggeringElement(scenarioData.menuId), TOPLEVEL, true),

                    At.create_Action__show_LevelNavigation(true),

                    At.create_Action__setActiveMenuLevel_moveMenus(TOPLEVEL, true),
                    ...LevelMenu_Shared_Scenarios.fadeout_all_contents(state, sublevels, true),
                    At.create_Action__fadeInContent(scenarioData.level, scenarioData.menuElement, true),

                    At_ImgContent.create_Action__activateImagetiles(true),

                    new TUnresolvedItem([STATE_AFTER_LAST_NODE], R.curry(At_ImgMove.create_Action__moveImageTilesContainer)(false)),
                ]),

            _auto_open_level2(createPath, state, scenarioData)
        )
    },

    /**
     * @param {LevelState} state
     * @param {CreatePathFunc} createPath
     * @param {LevelMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    toplevel_close: (createPath, state, scenarioData) => {
        const sublevels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, TOPLEVEL)
        return createPath(
            [
                At.create_Action__hide_LevelNavigaton(true),
                LevelMenu_Shared_Scenarios.setTriggerHighlighting(state, null, TOPLEVEL, true),
                At.create_Action__fadeOutContent(TOPLEVEL, scenarioData.menuElement, true),
                At_ImgContent.create_Action__deactivateImagetiles(true),
                ...LevelMenu_Shared_Scenarios.fadeout_all_contents(state, sublevels, true),
            ]
        )
    },

})
