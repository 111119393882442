"use strict";

export function initInScope ($scope) {
    $scope.find('.js-collapsing-checkbox__input').on('change',function (e) {

        if ($(this).prop('checked')) {
            $(this).closest('.js-collapsing-checkbox').find('.js-collapsing-checkbox__add:first').removeAttr('hidden');

            if(window.matchMedia('(min-width: 1200px)').matches) {
                if($(this).hasClass('js-collapsing-checkbox__input--scroll')) {
                    let $scrollElement = $(this).attr('data-scroll-to');
                    $('html, body').stop().animate({
                        scrollTop: $('#shipping-addresses-scroll').offset().top-170
                    }, '300');
                }
            }
        } else {
            $(this).closest('.js-collapsing-checkbox').find('.js-collapsing-checkbox__add:first').attr('hidden','hidden');

            /* if collapse detail added */
            $(this).closest('.js-collapsing-checkbox').find('.js-collapse-detail-container__add').attr('hidden','hidden');

        }
    });

    if ($('.js-collapsing-checkbox__input').prop('checked')) {
        $(this).closest('.js-collapsing-checkbox').find('.js-collapsing-checkbox__add:first').removeAttr('hidden');
    }



    /* open area on load if checked */
    $scope.find('.js-collapsing-checkbox__input').each(function(){
        let $this = $(this);
        if($this.prop('checked')) {
            $(this).closest('.js-collapsing-checkbox').find('.js-collapsing-checkbox__add').removeAttr('hidden');
        }
    });
}