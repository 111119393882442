import {cloneDeep} from "lodash";
import * as R from "ramda";

/**
 * execute fn but clone the first argument before and use that instead of the original argument
 * @param {Function} fn
 * @param {ChainDatum[]} obj - cloned
 * @returns {Function}
 */
export const doWithClone = R.curry((fn, obj) => R.curry(fn)(cloneDeep(obj)))

/**
 * if predicate (bool or function) is satisfied, clone the object and apply the applyFunc function
 * the predicate function gets not parameter passed
 * the applyFunc gets the object as first parameter passed, then the rest of the parameters given
 *
 * @param {*} obj
 * @param {boolean|function:boolean} predicateFuncOrValue
 * @param {function(*, ...*):*} applyFunc
 * @param {...*} rest
 */
export function applyToCloneIf(obj, predicateFuncOrValue, applyFunc, ...rest) {
    if (typeof predicateFuncOrValue !== 'function' && !predicateFuncOrValue) {
        return obj
    }

    if (typeof predicateFuncOrValue === 'function' && !predicateFuncOrValue()) {
        return obj
    }

    return applyFunc(cloneDeep(obj), ...rest)
}