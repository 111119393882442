'use strict';

export function init() {

    const xhr = new XMLHttpRequest();

    let rootUrl = [window.location.protocol, '//', window.location.host].join('');
    if (process.env.CACHE_BUSTER) {
        rootUrl = rootUrl + '/cache-buster-' + process.env.CACHE_BUSTER;
    }

    xhr.open('GET', rootUrl + '/static/img/flags/4x3/round/flag.svg', true);
    xhr.addEventListener('load', () => {
        const div = document.createElement('div');

        div.classList.add('as__icon__template', 'd-none');
        div.innerHTML = xhr.responseText;
        document.body.appendChild(div);
    });
    xhr.send();
}