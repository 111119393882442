'use strict'

import fetch from "../../libs/@elements/fetch";
import initModulesInScope from "../../libs/@elements/init-modules-in-scope";
import 'url-search-params-polyfill'; // Edge Polyfill
import 'url-polyfill';
import {removeLoadingContainer} from "../../scripts/loading";

let shubPopupUrl;
let lightboxtimer;
const showAfterSeconds = 5;
let sendShubPopupRequestUrl = '/rest/v1/customer/first-login';

export function init() {
    /**
     * the shubPopupUrl is a predefined property that can be set on every page.
     */
    shubPopupUrl = _config['shubPopupUrl']
    if(!shubPopupUrl){
        return;
    }

    initSHubPopup();
}

function initSHubPopup() {
    fetch(shubPopupUrl).then((res) => {
        return res.clone().json();
    }).then((res) => {
        if(res.success && res.html){
            const
                $html               = $(res.html), /* html is espected to be a bootstrap modal! */
                $closeBtn           = $html.find('.js-shub-popup__close'),
                $lightboxContainer  = $('body').find('.js-lightbox-container').length ? $('body').find('.js-lightbox-container') : $('body'),
                delayInSeconds = $html.data('displayDelay') ? $html.data('displayDelay') : showAfterSeconds;

            $lightboxContainer.append($html);
            initModulesInScope($html);
            initShubSubmit($html);

            lightboxtimer = setTimeout(
                () => {
                    $html.modal({keyboard: false});
                    $html.modal('show');
                },
                delayInSeconds * 1000
            );

            $html.on('hidden.bs.modal', () => {
                sendShubPopupRequest(sendShubPopupRequestUrl, $html);
            })

            $html.on('hide.bs.modal', () => {
                if(lightboxtimer){
                    clearTimeout(lightboxtimer);
                }
            });

            $closeBtn.on('click', () => {
                $html.modal('hide');
            })

        }
    })
}

export function sendShubPopupRequest(url, $scope) {
    let method = 'POST';
    fetch(url, {
        method : method
    }).then((res) => {
        return res.clone().json()
    }).then((res) => {
        if(res.success){
            // @TODO: Add required logic if needed after BE implementation.
        }
        removeLoadingContainer($scope);
    }).catch(() =>{
        removeLoadingContainer($scope)
    });
}

export function closeShubPopup($scope) {
    if($scope) {
        $scope.modal('hide');
    }
}

function initShubSubmit($scope) {
    const $submitButton = $scope.find('.js-shubPopup--submit'),
          url = sendShubPopupRequestUrl || '';

    $submitButton.on('click', (e) => {
        closeShubPopup($scope);
    });
}
