import {setupThrottledClickDoubleClick} from "../../../../../../shared/events/dbl_click/setupThrottledClickDoubleClick";

export const DropDownMenu = Object.freeze({

    initTriggers: (callback, menuNames) => {
        menuNames.forEach(menuName => {
            const content = document.getElementById(menuName + '-content')
            if (!content) {
                return
            }

            const closeButton = content.querySelector('[data-dropdown--close-button]')
            if (!closeButton) {
                return
            }

            setupThrottledClickDoubleClick(closeButton, () => callback())
        })
    },

})
