'use strict'

import {getPrefixedDataSet} from '../../libs/@elements/data-set-utils';
import PhotoSwipeLightbox from '../../libs/photoswipe/dist/photoswipe-lightbox.esm.min.js';

const loadingIconPath = window._config && window._config.loadingIconPath ? window._config.loadingIconPath : false

const Selectors = {
    base: '.js-pdsLightbox',
    item: '.js-pdsLightbox__item',
    productTitle: '.pds__title'
};

const pdsLightboxArrowString = `<svg width="6px" height="11px" viewBox="0 0 6 11" aria-hidden="true" class="pswp__icn">
    <g id="portal" transform="translate(-260.000000, -672.000000)">
        <g id=".stage" transform="translate(0.000000, 100.000000)">
            <g id=".pager" transform="translate(260.000000, 555.000000)">
                <path d="M5.80330086,22.8535534 L0.853553391,27.8033009 C0.658291245,27.998563 0.341708755,27.998563 0.146446609,27.8033009 C-0.0488155365,27.6080387 -0.0488155365,27.2914562 0.146446609,27.0961941 L4.768,22.475 L0.146446609,17.8535534 C-0.0488155365,17.6582912 -0.0488155365,17.3417088 0.146446609,17.1464466 C0.341708755,16.9511845 0.658291245,16.9511845 0.853553391,17.1464466 L5.80330086,22.0961941 C5.89008403,22.1829773 5.93829691,22.2937249 5.94793949,22.4071354 L5.94793949,22.5426121 C5.93829691,22.6560225 5.89008403,22.7667702 5.80330086,22.8535534 Z" id="arrow.left" transform="translate(2.974874, 22.474874) scale(-1, 1) translate(-2.974874, -22.474874) "></path>
            </g>
        </g>
    </g>
</svg>`

const mediaQuery = window.matchMedia('(max-width: 767px)');

export function initInScope($scope, options = {}) {
    const productTitle = document.querySelector(Selectors.productTitle);

    let lightbox = null;
    let lightboxOptions = {
        bgOpacity: 1,
        pswpModule: () => import ('../../libs/photoswipe/dist/photoswipe.esm.min.js'),
        counter: false,
        zoom: false,
        closeOnVerticalDrag: false,
        showHideAnimationType: 'none',
        arrowPrevSVG: pdsLightboxArrowString,
        arrowNextSVG: pdsLightboxArrowString,
        paddingFn: (viewportSize, itemData, index) => {
            return {
                // check based on viewport size
                top: viewportSize.x < 768 ? 65 : 75,
                bottom: viewportSize.x < 768 ? 15 : 25,
                right: 15,
                left: 15
            };
        }
    };

    function initLightbox(e) {
        if(lightbox) {
            lightbox.destroy();
        }

        lightboxOptions.gallery = Selectors.base;
        lightboxOptions.children = Selectors.item;
        lightboxOptions.allowPanToNext = mediaQuery.matches ? false : true;

        lightbox = new PhotoSwipeLightbox(lightboxOptions);

        lightbox.on('uiRegister', function() {
            lightbox.pswp.ui.registerElement({
                name: 'product-title',
                order: 6,
                tagName: 'h3',
                onInit: (el, pswp) => {
                    if (!productTitle) return false

                    el.innerText = productTitle.innerText;
                }
            });
        });
        lightbox.on('pointerMove', (lightboxEvent) => {
            const currZoomLevel = lightbox.pswp.currSlide.currZoomLevel;
            const initialZoomLevel = lightbox.pswp.currSlide.zoomLevels.initial;

            // If on mobile view and
            // if not zoomed in disable panning
            if (mediaQuery.matches && currZoomLevel == initialZoomLevel) {
                lightboxEvent.preventDefault && lightboxEvent.preventDefault();
                lightboxEvent.stopPropagation && lightboxEvent.stopPropagation();
                return false;
            }
        });

        if (loadingIconPath) {
            lightbox.on('uiRegister', function() {
                lightbox.pswp.ui.registerElement({
                    name: 'custom-loading-icon',
                    order: 10,
                    tagName: 'div',
                    className: 'pswp__loading-icon-wrapper',
                    onInit: (el, pswp) => {
                        if (!loadingIconPath) return false
                        
                        el.innerHTML = `<img src="${loadingIconPath}" class="pswp__loading-icon">`;
                    }
                });
            });
        }

        lightbox.init();
    }

    mediaQuery.addListener(initLightbox);
    initLightbox(mediaQuery);
}
