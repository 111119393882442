'use strict';
import * as stdSlider from '../../libs/@elements/slider';

export function initInScope($scope) {
    const $sliders = $scope.find('.js-productSlider');

    $sliders.each(function(index, slider) {
        $(window).on('resize', function() {
            if (!$(slider).hasClass('slick-initialized')) {
                return;
            }

            $(slider).slick('refresh'); //if refresh doesn't work correctly use slick('unslick') and initialize again
        });
        initSlider($scope, slider);
    });
}

function initSlider($scope, slider) {
    stdSlider.createInitInScope(slider, getOptions(slider))($scope);
}

function getOptions(slider) {
    return {
        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-Pfeil-nachoben rotate-right"></i></button>',
        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-Pfeil-nachoben rotate-left"></i></button>',
        dots: false,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    outerEdgeLimit: true,
                    variableWidth: true,
                },
            },
        ],
    };
}
