"use strict";

export function init() {
    document.querySelectorAll('.js__content').forEach(el => getContent(el))
}

function getContent(htmlElement) {
    const url = htmlElement.getAttribute('data-url')
    const defaultPrefix = htmlElement.getAttribute('data-default-prefix')
    if(!url || !defaultPrefix) {
        return
    }

    const bearerToken = htmlElement.getAttribute('data-token')
    const headers = {}
    if(bearerToken) {
        headers['Authorization'] = `Bearer ${bearerToken}`
    }

    fetch(`${url}?default-prefix=${defaultPrefix}`, {
        headers: headers
    }).then((res) => {
        return res.clone().json();
    }).then((res) => {
        if (res.success && res.html) {
            htmlElement.innerHTML = res.html
        }
    })
}