import {cloneDeepWith} from "lodash";
import {StateWithPath} from "./StateWithPath";

/**
 * keep copy function reference, elements etc in deepcloning
 */
const _customizedClone = (value) => {
    if (typeof value === 'function') {
        return value
    }
    if (value instanceof Element) {
        return value
    }
}

/**
 * @param {NavigationState} state
 * @returns {NavigationState}
 */
export const cloneState = (state) => {
    const cloned = cloneDeepWith(state, _customizedClone);
    StateWithPath.setValue(cloned, PATH_DEBUG_IS_CLONE, true)
    return cloned
}

/**
 * @param value
 */
export const cloneStateValue = (value) => {
    return cloneDeepWith(value, _customizedClone);
}

export const PATH_DEBUG_IS_CLONE = '__clone'