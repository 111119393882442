"use strict";

import $ from 'jquery'
import { getConfig } from '../config-utils'

export default function ($scope) {
    let _config = getConfig('_config');

    if(!$scope) {
        $scope = $('body');
    }

    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( _config['_preload'][_key] && app.modules[_key] && typeof window.app.modules[_key] === 'function' ){
                window.app.modules[_key]($scope);
            }
        });
    }

    //init all modules if the right _config key is set for them
    for (let module in _config) {
        if (_config[module] && app.modules[module] && typeof  app.modules[module] === 'function') {
            app.modules[module]($scope);
        }
    }

    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( _config['_reload'][_key] && app.modules[_key] && typeof window.app.modules[_key] === 'function' ){
                window.app.modules[_key]($scope);
            }
        });
    }
}
// Functions copied from maiersports_ng/static/js/libs/@elements/init-modules-in-scope/index.js
import {findAllIn, off, on, trigger} from '../dom-utils';

export function initInScope(scope = document.body) {
    trigger('initialize-in-scope', scope);
    trigger('initialized-in-scope', scope);
}

export function onInitInScope(callbackFnc, scope = document.body) {
    let boundCallbackFnc = (evt) => callbackFnc(evt.target);

    on('initialize-in-scope', boundCallbackFnc, scope);

    return () => off('initialize-in-scope', boundCallbackFnc, scope);
}

export function cleanUpInScope (scope = document.body) {
    trigger('clean-up-in-scope', scope);
    trigger('cleaned-up-in-scope', scope);
}

// todo does scope parameter make sense?
export function onCleanUpInScope(callbackFnc, scope = document.body) {
    let boundCallbackFnc = (evt) => callbackFnc(evt.target);

    on('clean-up-in-scope', boundCallbackFnc, scope);

    return () => off('clean-up-in-scope', boundCallbackFnc, scope);
}

export function onFind(selector, init, scope = document.body) {
    let cleanUpFunctions = [];
    // add callback
    onInitInScope(initInScope, scope);

    // call immediately
    initInScope(scope);


    onCleanUpInScope(function (scope) {
        cleanUpFunctions = cleanUpFunctions
            .filter(({element, cleanUpFunction}) => {
                if (scope.contains(element)) {
                    cleanUpFunction(element);
                    return false;
                }

                return true;
            });
    });

    function initInScope(scope) {
        findAllIn(selector, scope).map(element => {
            let result = init(element);

            if (!!result && typeof result === 'function') {
                cleanUpFunctions.push({
                    element,
                    cleanUpFunction: result
                });
            }

            if (!!result && result.cleanUp && typeof result.cleanUp === 'function') {
                cleanUpFunctions.push({
                    element,
                    cleanUpFunction: result.cleanUp
                });
            }
        });
    }
}
