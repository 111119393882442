import {Desktop_MainMenuTriggers} from "../desktop/MainMenu/Desktop_MainMenuTriggers";
import {Mobile_MainMenuTriggers} from "../mobile/MainMenu/Mobile_MainMenuTriggers";

const BREAK_POINT = window.matchMedia('(min-width: 992px)');

export function init() {
    if (!document.getElementById('mobile-nav')) {
        return
    }

    BREAK_POINT.addEventListener("change", (e) => {
        if (e.matches) {
            return Mobile_MainMenuTriggers.close()
        } else {
            return Desktop_MainMenuTriggers.close()
        }
    });

    initCloseOnModalOpenHook()
}

function initCloseOnModalOpenHook() {
    document.querySelectorAll(`[data-toggle="modal"]`).forEach(modalTrigger => {
        modalTrigger.addEventListener('click', () => {
            Desktop_MainMenuTriggers.close().then()
            Mobile_MainMenuTriggers.close().then()
        })
    })
}
