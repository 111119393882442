'use strict';

import {LevelMenu_Toplevel_Scenarios} from "./scenarios.toplevel";
import {BasicScenarioFinder} from "../../../shared/core/TransitionTree/BasicScenarioFinder";
import {LevelMenu_ScenarioMatching} from "./scenarioMatching";

const MENU_TRIGGER_TYPE = BasicScenarioFinder.MENU_TRIGGER_TYPES

const scenarioMapping = new Map([
    [MENU_TRIGGER_TYPE.OPEN, LevelMenu_Toplevel_Scenarios.toplevel_open],
    [MENU_TRIGGER_TYPE.CLOSE, LevelMenu_Toplevel_Scenarios.toplevel_close],
    [MENU_TRIGGER_TYPE.SWITCH, LevelMenu_Toplevel_Scenarios.toplevel_switch],
    [MENU_TRIGGER_TYPE.REOPEN, LevelMenu_Toplevel_Scenarios.toplevel_reopen],
])

export const LevelMenu_Toplevel_ScenarioMatching = Object.freeze({

    findScenario: (state, scenarioData, onlyActivatingTypes = false) => BasicScenarioFinder.retrieveScenario_fromMapping(
        scenarioMapping,
        LevelMenu_ScenarioMatching.findScenarioType(state, scenarioData, onlyActivatingTypes)
    ),

    getCloseScenario: () =>BasicScenarioFinder.retrieveScenario_fromMapping(
        scenarioMapping,
        MENU_TRIGGER_TYPE.CLOSE
    ),
})