'use strict';

/**
 * @abstract
 */
export class TAnyNode {
    constructor() {
        /** @type {undefined|TAnyNode} */
        this.nextNode = undefined
    }
}

export class TUnresolvedNode extends TAnyNode {
    /**
     * @param {string[]} dependencies - names of the other nodes which are necessary to resolve the node
     * @param {function(NavigationState):TAnyNode} resolveFunc
     */
    constructor(dependencies, resolveFunc) {
        super();
        this.resolveFunc = resolveFunc;

        /** @type {string[]} */
        this.dependencies = dependencies
    }
}

export class TResolvedNode extends TAnyNode {
    /** @param {TAction|TAnyNode[]} parallelExecutedNodesAndActions */
    constructor(parallelExecutedNodesAndActions = []) {
        super();

        /** @type {TAction|TAnyNode[]} */
        this.parallelExecutedNodesAndActions = parallelExecutedNodesAndActions
    }
}

export class TUnresolvedItem {
    /**
     * @param {function({string:NavigationState}):TAction|TAnyNode} resolveFunc
     * @param {string[]} dependencies - names of the nodes which are necessary to resolve the item
     *  */
    constructor(dependencies, resolveFunc) {
        this.dependencies = dependencies

        this.resolveFunc = resolveFunc;
    }
}