'use strict';

import {TreeExecutor} from "./TreeExecutor";
import {TreeResolver} from "./TreeResolver";
import {PathCreationLimited} from "./PathCreationLimited";
import {cloneState} from "../state/cloneState";
import {NavigationDebugInfo, navierr} from "../debug";
import {createExecuteActionFunc} from "./TActionExecutor";
import {TransitionCancellation} from "./TransitionCancellation";
import {cloneDeep} from "lodash";


/**
 * @param {function} executeFunc
 * @return {function(function)}
 */
const defaultExecuteFunc = (executeFunc) => executeFunc();

/**
 * @param {TUnresolvedItem} unresolvedItem
 * @param {NavigationState} tempEndstate
 * @return {ResolveFunction}
 */
const noResolveFunc = (unresolvedItem, tempEndstate) => {
    throw new Error('should never have been called')
}

/**
 * @param {TResolvedNode} resolvedRootNode
 * @param {NavigationState} realStateObj
 * @param {TransitionCancellationFunc} isTransitionCanceledFunc
 * @param {NavigationDebugInfo} debugInfo
 */
const execute = (realStateObj, resolvedRootNode, isTransitionCanceledFunc, debugInfo) =>
    TreeExecutor.executeWholeTreeWith(
        createExecuteActionFunc(realStateObj, isTransitionCanceledFunc, defaultExecuteFunc, debugInfo),
        noResolveFunc,
        resolvedRootNode,
        isTransitionCanceledFunc,
        debugInfo
    )

export const Executor = Object.freeze({
    /**
     * @param {FindScenarioFunc} findScenario
     * @param {NavigationState} realStateObj
     * @param {ScenarioData} scenarioData
     * @param {NavigationState[]} otherStates
     */
    handle: (findScenario, realStateObj, scenarioData, otherStates) => {
        const clonedStateObj = cloneState(realStateObj)

        const scenario = findScenario(clonedStateObj, scenarioData)
        return Executor.handleScenario(scenario, realStateObj, scenarioData, otherStates)
    },


    /**
     * @param {Scenario} scenario
     * @param {NavigationState} realStateObj
     * @param {ScenarioData} scenarioData
     * @param {NavigationState[]} otherStates
     */
    handleScenario: async (scenario, realStateObj, scenarioData, otherStates) => {
        const debugInfo_init = new NavigationDebugInfo(scenario.createScenario.name, scenarioData, realStateObj)

        const isTransitionCanceledFunc = TransitionCancellation.startPartialTransition_returnNewCancellation(realStateObj, otherStates, debugInfo_init)
        const debugInfo = debugInfo_init

        const pathCreationLimited = new PathCreationLimited()
        const headOfUnresolvedTree = scenario.createScenario((...x) => pathCreationLimited.createPath(...x), cloneState(realStateObj), scenarioData);

        const headOfResolvedTree = await TreeResolver.resolve(cloneState(realStateObj), headOfUnresolvedTree, isTransitionCanceledFunc)

        await execute(realStateObj, headOfResolvedTree, isTransitionCanceledFunc, debugInfo)

        navierr(debugInfo, 'END\t', cloneDeep(realStateObj))
    },


})