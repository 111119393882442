'use strict';

const MENU_TRIGGER_TYPES = {
    OPEN: 'open',
    CLOSE: 'close',
    SWITCH: 'switch',
    REOPEN: 're-open'
}

export class Scenario {
    /**
     * @param {string} scenarioType
     * @param {CreateScenarioFunc} createScenario
     */
    constructor(scenarioType, createScenario) {
        this.scenarioType = scenarioType;
        this.createScenario = createScenario;
    }
}

/**
 * @param {string} scenarioType
 * @param {Map[string:function]} mapping
 * @return {Scenario}
 */
const _findScenarioInMapping = (mapping, scenarioType) => {
    return new Scenario(scenarioType, mapping.get(scenarioType))
};


/**
 * @param {Map[string:function]} scenarioMapping
 * @param {string} scenarioType
 * @return {Scenario}
 */
const retrieveScenario_fromMapping = (scenarioMapping, scenarioType) =>
    _findScenarioInMapping(
        scenarioMapping,
        scenarioType
    )

export const BasicScenarioFinder = Object.freeze({

    MENU_TRIGGER_TYPES,
    retrieveScenario_fromMapping

})
