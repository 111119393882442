'use strict'

export function initInScope ($scope) {
    const
        $videoJsVideos      = $scope.find('.js-video_js'),//only asset videos
        $contentVideos      = $scope.find('.js-video_js--content');

    if($videoJsVideos || $contentVideos){
        import('video.js').then(function (videojs){
            if($videoJsVideos){
                initStandardVideoJs($videoJsVideos, videojs);
            }
            if($contentVideos){
                initContentVideosJs($contentVideos, videojs);
            }
        });
    }
}

function initStandardVideoJs($videos, videojs) {
    $videos.each(function () {
        init(this, videojs);
    });
}

function initContentVideosJs($videos, videojs) {
    $videos.each(function (_, el) {
        const
            $container = $(el),
            player = init($container.find('.js-video_js--content__video').get(0), videojs),
            $playButton = $container.find('.js-video__playbtn'),
            $pauseButton = $container.find('.js-video__pausebtn');

        if ($playButton.length) {
            $playButton.on('click', () => {
                player.play();
                $(this).closest('.js-video_js--content').find('.pimcore_editable_video').addClass('is-playing');
            })
        }

        if ($pauseButton.length) {
            $pauseButton.on('click', () => {
                player.pause();
                $(this).closest('.js-video_js--content').find('.pimcore_editable_video').removeClass('is-playing');
            })
        }

        let $containerModal = $videos.closest('.js-video-modal');

        if($containerModal.length > 0){
            $containerModal.one('hidden.bs.modal', function (e) {
                player.dispose()
            })
        }
    });
}

//init for all video Elements
export function init(video, videojs){
    let $video = $(video);
    let id = $video.attr('id');
    let player = videojs.default(id);
    let $playerPlayBtn = $(player.el_).closest('.js-video_js--content').find('.js-video__playbtn');
    let $playerPauseBtn = $(player.el_).closest('.js-video_js--content').find('.js-video__pausebtn');
    let btn = $video.parent().find(".vjs-big-play-button");
    btn.empty();
    btn.addClass('icon icon-play-button');

    player.ready(() => {
        window.requestAnimationFrame(() => {
            if($video.data('autoplay') || $video.attr('autoplay')){
                player.play();
                $(player.el_).closest('.js-video_js--content').find('.pimcore_editable_video').addClass('is-playing');
                $playerPlayBtn.hide();
            }
        })
    })

    player.on('play', () => {
        if (!$('#textContainer-'+player.id()+'').hasClass('video-js__textcontainer--toggle-play-pause-btns')) {
            $('#textContainer-'+player.id()+'').css('opacity', 0);
        }
        if($playerPlayBtn && $playerPauseBtn) {
            $playerPlayBtn.hide();
            $playerPauseBtn.show();
        }
    });

    player.on('pause', () => {
        if($playerPlayBtn && $playerPauseBtn) {
            $playerPlayBtn.show();
            $playerPauseBtn.hide();
        }
    });
    return player;
}
