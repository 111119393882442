'use strict'

import {copyLangModalToMenucard} from './footerHandler-langselect';

let menucardContainer = null

let SELECTOR = {
    menucards: null,
    menucardFooterTargets: '[data-js-mobile-nav="footer-template"]',
}

export const initFooterHandler = (_menucardContainer, menucards_selector) => {
    menucardContainer = _menucardContainer
    SELECTOR.menucards = menucards_selector
};


export function copyFooterTo(menuCardTarget) {
    const findMenucardLinkInContainer = (container, menuCard) => container.querySelector(':scope [data-mobile-nav__menucard-target="' + menuCard.id + '"]');

    function findAllMenuCardAncestors(menuCard, foundCards = []) {
        const foundLinkToMenucard = findMenucardLinkInContainer(menucardContainer, menuCard)
        if (!foundLinkToMenucard) {
            return []
        }

        const nextMenucard = foundLinkToMenucard.closest(SELECTOR.menucards)

        if (foundCards.indexOf(nextMenucard) === -1) {
            foundCards.push(nextMenucard)
            foundCards.push(...findAllMenuCardAncestors(nextMenucard, foundCards))
        }

        return foundCards
    }

    if (!'footerHtml' in menucardContainer.dataset) {
        return
    }

    copyLangModalToMenucard(menuCardTarget)

    const footerTarget = menuCardTarget.querySelector(':scope ' + SELECTOR.menucardFooterTargets)

    if (!footerTarget) {
        return //login or country-lang menucard
    }

    const footerTemplate = menucardContainer.dataset.footerHtml

    footerTarget.insertAdjacentHTML("beforebegin", footerTemplate)
    footerTarget.remove();

    //don't render clickable 'account' in menucard 'account'
    //don't render clickable 'account' or 'orders' in menucard 'orders'
    //etc.
    ([menuCardTarget, ...findAllMenuCardAncestors(menuCardTarget)]).forEach(menucardsToDeleteInFooterLinks => {
        const toDelete = findMenucardLinkInContainer(menuCardTarget, menucardsToDeleteInFooterLinks)
        if (toDelete) {
            toDelete.remove()
        }
    })
}