'use strict'

import { detect } from 'detect-browser'

export function init() {
    const browser = detect();

    if (browser) {
        if(browser.os.toLowerCase().includes('mac')){
            $('body').addClass('os-mac')
        }
        if(browser.os.toLowerCase().includes('ios')){
            $('body').addClass('os-ios')
        }
        if(browser.version.toLowerCase().includes('ios')){
            $('body').addClass('os-ios')
        }

    }


}