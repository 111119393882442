'use strict'

import throwError from '../../libs/@elements/throw-error'
import fetch from "../../libs/@elements/fetch";
import initModulesInScope from "../../libs/@elements/init-modules-in-scope";
import { isParsleyForm, isValid } from "../../libs/@elements/parsley-bootstrap-validation"
import asyncAppend from '../../libs/@elements/async-append';
import formDataEntries from 'form-data-entries';
import 'url-search-params-polyfill'; // Edge Polyfill
import 'url-polyfill';
import {getLoadingContainer, removeLoadingContainer} from "../../scripts/loading";


/**
 This module opens a Lightbox for newsletter automatically when mouse is outside the document (yes.. there are better solutions and apis, but this is an explicit customer wish...).
 For mobile it displays the Newsletter lightbox after 30s.

 When closed a parameter in session storage is written, so it wont open again.
 */
let newsletterPopupUrl;
let lightboxtimer;
const showAfterSeconds = 40;

export function init() {
    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    /**
     * #49704: Disable on mobile views
     */
    if(isMobile()){
        return;
    }

    if(typeof Storage === "undefined"){
        throwError('cannot access localStorage - sheep stories not loaded');
        return
    }
    /**
     * check if already dissmissed
     */
    if(window.localStorage.getItem('newsletterPopupDisabled') !== null){
        return;
    }

    /**
     * the newsletterPopupUrl is a predefined property that can be set on every page.
     */
    newsletterPopupUrl = _config['newsletterPopupUrl']
    if(!newsletterPopupUrl){
        return;
    }

    initNewsletterPopup();
}

function initNewsletterPopup() {
    fetch(newsletterPopupUrl).then((res) => {
        return res.clone().json();
    }).then((res) => {
        if(res.success && res.html){
            const
                $html               = $(res.html), /* html is espected to be a bootstrap modal! */
                $closeBtn           = $html.find('.js-newsletterPopup__close'),
                $lightboxContainer  = $('body').find('.js-lightbox-container').length ? $('body').find('.js-lightbox-container') : $('body');

            $lightboxContainer.append($html);
            initModulesInScope($html);
            initNewsletterSubmit($html);

            if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){
                lightboxtimer = setTimeout(
                    () => {
                        $html.modal({backdrop: 'static', keyboard: false});
                        $html.modal('show');

                    },
                    showAfterSeconds * 1000
                );
            }else{
                $(document).on('mouseleave.newsletterLightbox',(e) => {
                    //if e.relatedTarget === null there is no next element for mouse -> outside the window
                    //otherwise mouseleave could also be a bubbled event from bottom tree
                    if(e.relatedTarget === null){
                        $html.modal({backdrop: 'static', keyboard: false});
                        $html.modal('show');
                    }
                })
            }

            $html.on('hidden.bs.modal', () => {
                removeNewsletterPopup($html);
            })
            $html.on('hide.bs.modal', () => {
                disableNewsletterPopup();
            });

            $closeBtn.on('click', () => {
                $html.modal('hide');
            })

        }
    })
}

export function disableNewsletterPopup() {
    if(lightboxtimer){
        clearTimeout(lightboxtimer);
    }
    $(document).off('mouseleave.newsletterLightbox');
    window.localStorage.setItem('newsletterPopupDisabled', true);
}

export function removeNewsletterPopup($scope = $('body')) {
    const $modal = $scope.hasClass('.modal--newsletter-popup') ? $scope : $scope.find('.modal--newsletter-popup');
    if($modal.hasClass('show')){
        $modal.modal('hide');
    }
    $modal.remove();
}


function initNewsletterSubmit($scope) {
    const
        $formContainer  = $scope.find('.js-newsletterPopup__form-container'),
        $form           = $scope.find('.js-newsletterPopup__form');

    let gender;
    $form.find('button[type=submit]').on('click', function (evt) {
        gender = $(this).attr('value');
    });

    $form.on('submit', (e) => {
        e.preventDefault();

        if (isParsleyForm($form) && !isValid($form)) {
            return;
        }

        let action = $form.data('action') || $form.attr('action');
        let method = 'POST';

        let formData = new URLSearchParams(formDataEntries($form[0]));
        formData.append('gender', gender);

        asyncAppend({
            $target: $form,
            $loading: $(getLoadingContainer(true, false)).appendTo($scope),
            $notification: $scope.find('.js-newsletterPopup__notifications')
        }, fetch(action, {
            method : method,
            body: formData
        }).then((res) => {
            return res.clone().json()
        })).then((res) => {
            if(res.success){
                if(res.html){
                    $formContainer.html($(res.html));
                    initModulesInScope($formContainer);
                    initNewsletterSubmit($scope);
                }else if(res.url){
                    window.localStorage.setItem('newsletterPopupDisabled', true);
                    window.location.href = res.url;
                }else{
                    throwError('something went wrong!')
                }
            }
            removeLoadingContainer($scope);
        }).catch(() =>{
            removeLoadingContainer($scope)
        })
    })
}
