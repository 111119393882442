'use strict';

import {StateWithPath} from "../state/StateWithPath";
import {TreeExecutor} from "./TreeExecutor";
import {navierr} from "../debug";
import {cloneState} from "../state/cloneState";

export class CancellationObj {
    /**
     * @param {int} timestamp
     * @param {NavigationState[]} states
     */
    constructor(timestamp, states) {
        this.timestamp = timestamp
        this.states = states
    }
}

export const TransitionCancellation = Object.freeze({
    /**
     * @unpure
     *
     * @param {NavigationState} state
     * @param {NavigationState[]} otherStatesForCancellationCheck
     * @param {NevigationDebugInfo} debugInfo_init - missing field timestamp is initialized here
     * @return {TransitionCancellationFunc}
     */
    startPartialTransition_returnNewCancellation: (state, otherStatesForCancellationCheck, debugInfo_init) => {
        const timestamp = otherStatesForCancellationCheck.length > 0 ? _getCurrentTimestampFromState(otherStatesForCancellationCheck[0]) : Date.now()

        const newCancellation = new CancellationObj(timestamp, [...otherStatesForCancellationCheck, state])
        _setCurrentTransitionTimestamp(state, newCancellation.timestamp, debugInfo_init)
        // noinspection UnnecessaryLocalVariableJS
        const debugInfo = debugInfo_init

        return _create_isOverallTransitionCanceledFunc(newCancellation, debugInfo)
    },

})

/**
 * @param {NavigationState} state
 * @return {int}
 */
const _getCurrentTimestampFromState = state => StateWithPath.getValue(state, TreeExecutor.PATH_CURRENT_TRANSITION_TIMESTAMP);

/**
 * @param {NavigationState} state
 * @param {int} timestamp
 * @param {NevigationDebugInfo} debugInfo_init
 */
const _setCurrentTransitionTimestamp = (state, timestamp, debugInfo_init) => {
    StateWithPath.setValue(state, TreeExecutor.PATH_CURRENT_TRANSITION_TIMESTAMP, timestamp);
    debugInfo_init.timestamp = timestamp
}

/**
 * @param {CancellationObj} cancellationObj
 * @param {NevigationDebugInfo} debugInfo
 * @return {TransitionCancellationFunc}
 */
const _create_isOverallTransitionCanceledFunc = (cancellationObj, debugInfo) => {
    navierr(debugInfo, 'START', '\t', 'Cancellation:', _getCurrentTimestampFromState(cancellationObj.states[0]))
    return (debugName ) => {
        const different = cancellationObj.states.filter(
            state => {
                if (debugName) {
                    navierr(debugInfo, 'cancellation', debugName, cancellationObj.timestamp, '<->', _getCurrentTimestampFromState(state))
                }
                return cancellationObj.timestamp !== _getCurrentTimestampFromState(state);
            }
        )

        if (!different.length) {
            return false
        }
        return different.map(state => cloneState(state))
    };
}

