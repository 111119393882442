import {CSSTransitionsHelper} from "../../../../../../../../../shared/events/transitions/CSSTransitionsHelper";
import {MainMenuState} from "../../../../shared/MainMenu/MainMenuState";

const TRANS_STATES = MainMenuState.TRANS_STATES

const _getBaseConfig = (menuContainer, useTransition, isTransitionCanceled) => ({
    useTransition,
    isCanceledFunc: isTransitionCanceled,
    transitioningElement: menuContainer,
    stateElement: menuContainer,
    clearCssClasses: Object.values(TRANS_STATES),
})

const _buildOpenConfig = (menuContainer, useTransition, isTransitionCanceled) => ({
    ..._getBaseConfig(menuContainer, useTransition, isTransitionCanceled),
    beginTransCssClass: TRANS_STATES.OPENING,
    endTransCssClass: TRANS_STATES.OPEN,
})

const _buildCloseConfig = (menuContainer, useTransition, isTransitionCanceled) => ({
    ..._getBaseConfig(menuContainer, useTransition, isTransitionCanceled),
    beginTransCssClass: TRANS_STATES.CLOSING,
    endTransCssClass: TRANS_STATES.CLOSED,
})

export const Tr_TopLevel = Object.freeze({

    INITIAL_STATE: TRANS_STATES.CLOSED,

    isVisible: state => [TRANS_STATES.CLOSED].indexOf(state) === -1,

    /** @type {TransitionInfo} */
    open: ({
        /**
         * @param {HTMLElement} menuContainer
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (menuContainer, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_buildOpenConfig(menuContainer, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.OPENING,
        endState: TRANS_STATES.OPEN,
        debugDescription: '(top) LevelMenu\tOpen',
    }),

    /** @type {TransitionInfo} */
    close: ({
        /**
         * @param {HTMLElement} menuContainer
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (menuContainer, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_buildCloseConfig(menuContainer, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.CLOSING,
        endState: TRANS_STATES.CLOSED,
        debugDescription: '(top) LevelMenu\tClose',
    }),

})


