'use strict'

export function initInScope($scope) {
    const
        $emailInput   = $scope.find('.js-newsletterBanner__email');

    if($emailInput.length){
        $emailInput.on('keypress', (e) => {
            if(e.key === 'Enter'){
                e.preventDefault();
                return false;
            }
        })
    }
}