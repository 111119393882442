'use strict'

import {initSliderModules} from "./content-slider";
import {initFooterHandler} from "./footerHandler";
import {init as initNavSlider, resetNavSlider} from "./nav-slider"
import {initMenuCard, menucards_CLASS} from "./menu-cards";
import {Tr_MobileOverlay} from "./Overlay";
import {MainMenuState} from "../shared/MainMenu/MainMenuState";
import {Mobile_MainMenuStateObject} from "./MainMenu/Mobile_MainMenuStateObject";
import {MOBILE_MAIN_MENUS, MOBILE_MAIN_MENUS__DROPDOWNS, Mobile_MainMenuTriggers} from "./MainMenu/Mobile_MainMenuTriggers";
import {DOM_MainMenuItemCache} from "../shared/MainMenu/DOM_MainMenuItemCache";
import {DropDownMenu} from "../shared/DropdownMenu";
import {Tr_TopLevel} from "../desktop/LevelMenu/TransitionElements/MenuLevel/Tr_TopLevel";
import * as R from "ramda";

const CLASS = {
    show: 'show',
}

class Main {
    constructor() {
        this._selector_menucards = '.mobile-nav__menucard'

        this._navRoot = document.getElementById('mobile-nav')
        if (!this._navRoot) {
            return;
        }

        this._navCanvas = document.getElementById('mobile-nav__canvas');
        this._navBtn = document.getElementById('mobile-nav__hamburgerbutton');

        this._menucardContainer = document.getElementById('mobile-nav__menucard-container')
        this._rootMenuitemContainer = document.getElementById('mobile-nav__root-menuitem-container')
    }

    init() {
        if (!this._navRoot) {
            return
        }

        DOM_MainMenuItemCache.initialize(...Object.values(MOBILE_MAIN_MENUS))

        Mobile_MainMenuStateObject.set(MainMenuState.createInitialState())

        initFooterHandler(this._menucardContainer, this._selector_menucards)

        this._navRoot.style.display = 'block'//prevent navigation flickering

        this._rootMenuitemContainer.querySelectorAll(':scope ' + this._selector_menucards).forEach(
            el => {
                el.classList.remove('d-none')
                initMenuCard(el, () => Mobile_MainMenuTriggers.close());
            }
        )

        Tr_MobileOverlay.initCloseTrigger(() => Mobile_MainMenuTriggers.close())

        Mobile_MainMenuTriggers.initDropdownMenus((menuName) => Mobile_MainMenuTriggers.triggerDropdown(menuName))

        DropDownMenu.initTriggers(Mobile_MainMenuTriggers.close, Object.values(MOBILE_MAIN_MENUS__DROPDOWNS))

        this._navBtn.addEventListener('click', () =>
            Mobile_MainMenuTriggers.trigger(
                MOBILE_MAIN_MENUS.MOBILE_HAMBURGERMENU,
                (isTransitionCanceledFunc)=>this.open(isTransitionCanceledFunc),
                (isTransitionCanceledFunc)=>this.close(isTransitionCanceledFunc),
            ));
    };


    async open(isTransitionCanceledFunc) {
        initNavSlider()
        initSliderModules();

        this._navBtn.classList.add(CLASS.show);

        await Tr_TopLevel.open.func(this._navCanvas, true, isTransitionCanceledFunc)
    }

    async close(isTransitionCanceledFunc) {
        this._navBtn.classList.remove(CLASS.show);

        this._menucardContainer.querySelectorAll(':scope ' + this._selector_menucards).forEach(el => {
            el.classList.remove(CLASS.show);
            el.classList.add(menucards_CLASS.displayOff)
        })
        await Tr_TopLevel.close.func(this._navCanvas, true, isTransitionCanceledFunc)

        resetNavSlider()
    }
}

let instance

export function init() {
    instance = new Main()
    instance.init()
}
