import {throttle} from "throttle-debounce";

export function initInScope($scope) {
    $scope.find('.js-scrollable-tabs').each(function () {
        let nav = this;
        let $nav = $(this);

        let $activeTab = $nav.find('.active');
        let $navTab = $nav.find('li');

        let reset;
        let tabClickHandler = function () {
            center($navTab, $(this))
        };

        if (matchMedia('(max-width: 767px)').matches && isScrollable(nav)) {
            center($navTab, $activeTab);

            $navTab.on('click', tabClickHandler);

            let timeout = setTimeout(function () { // wait for css rendering
                reset = makeScrollable({nav});

                clearTimeout(timeout);
            }, 1000);
        }

        $(window).on('resize', () => {
            if (matchMedia('(max-width: 767px)').matches && isScrollable(nav)) {
                reset = makeScrollable({nav});
                $navTab.on('click', tabClickHandler);
            } else {
                if (reset && typeof reset === "function") {
                    reset();
                    $navTab.off('click', tabClickHandler);
                    reset = null;
                }
            }
        });

    });
}

function makeScrollable({nav}) {
    $(nav).addClass('is-scrollable');

    const handler = throttle(100, () => {
        if (nav.offsetWidth + nav.scrollLeft >= nav.scrollWidth) {
            $(nav).addClass('is-at-scroll-end');
        } else {
            $(nav).removeClass('is-at-scroll-end');
        }
    });

    $(nav).on('scroll', handler);

    return () => {
        $(nav)
            .removeClass('is-scrollable')
            .removeClass('is-at-scroll-end')
            .off('scroll', handler);
    }
}

function isScrollable(element) {
    return element && element.scrollWidth > element.offsetWidth;
}

function center(navTab, tab) {
    if (tab && tab.length) {
        let childPos = tab.offset().left,
            parentScroll = tab.parent().scrollLeft(),
            offset = childPos + parentScroll - window.outerWidth / 2 + tab.width() / 2;

        navTab.closest('ul').animate({scrollLeft: offset}, 250);
    }
}