import $ from 'jquery';
import initModulesInScope from "../init-modules-in-scope";
import {getPrefixedDataSet} from '../data-set-utils';

const defaultSelectors = {
    base: '.js-product-grid',
    content: '.js-product-grid__content',
    container: '.js-product-grid__container',
};

export function createProductGrid(options = {}, selectors = defaultSelectors) {
    options = {
        ...defaultSelectors,
        ...options
    };

    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    let $productGrid = $();

    return {
        init: function () {
            $productGrid = $(selectors.base);

            let newState = $.extend({}, history.state, {
                productGridAjaxUrl: $productGrid.data('product-grid-ajax-url'),
                productGridInputState: getParams($productGrid),
                productGridAdditionalInputState: getAdditionalInputState($productGrid)
            });

            history.replaceState(newState, document.title);

            $(window).on('popstate', function (evt) {
                if (evt.originalEvent.state.productGridAjaxUrl) {
                    setInputState($productGrid, evt.originalEvent.state.productGridInputState.filter(function (currentInput) {
                        return currentInput.value !== '$$EMPTY$$'
                    }));

                    $productGrid.data('additional-input-state', evt.originalEvent.state.productGridAdditionalInputState);

                    loadUrl(
                        $productGrid,
                        evt.originalEvent.state.productGridAjaxUrl,
                        evt.originalEvent.state.productGridInputState
                    ).fail(function () {
                        /*reset the input state; otherwise the url params and the form inputs would not be the same*/
                        setInputState($productGrid, evt.originalEvent.state.productGridInputState);
                    });
                }
            });

            $('body').on('click', '.js-product-grid__link', function (evt) {
                let $productGrid = $(this).closest('.js-product-grid');
                let url = $(this).attr('href');
                let ajaxUrl = $(this).data('product-grid-ajax-url') || url;
                evt.preventDefault();

                $productGrid.data('additional-input-state', []);

                loadUrl($productGrid, ajaxUrl).done(function () {
                    $productGrid.triggerHandler('product-grid.changed', [getInputState($productGrid)]);
                    history.replaceState({
                        'productGridAjaxUrl': ajaxUrl,
                        'productGridInputState': getParams($productGrid),
                        'productGridAdditionalInputState': []
                    }, document.title);
                });

                /* the state is replace with the real form state after the grid is done loading the content.
                * This is needed because we can't know the input state before the form is loaded*/
                history.pushState({
                        'productGridAjaxUrl': ajaxUrl,
                        'productGridInputState': [],
                        'productGridAdditionalInputState': []
                    },
                    document.title,
                    url
                );
            });
        },
        initInScope: function initInScope($scope) {
            $productGrid = $productGrid.add($scope.find('.js-product-grid'));

            getChangeInputElements($scope).on('change', function () {
                reloadGrid($(this).closest('.js-product-grid'));
                // change not submitted sections immediately
                setInputState($productGrid, getInputState($productGrid));
            });

            $scope.find('.js-product-grid__submit-section-btn').on('click', function (evt) {
                evt.preventDefault();
                let $productGrid = $(this).closest('.js-product-grid');
                updateSubmitSectionDataValues($(this).closest('.js-product-grid__submit-section'));
                reloadGrid($productGrid);
            });

            $scope.find('.js-product-grid__submit-section').each(function () {
                updateSubmitSectionDataValues($(this));
            }).on('keydown', function (evt) {
                if (evt.keyCode === 13) {
                    let $productGrid = $(this).closest('.js-product-grid');
                    evt.preventDefault();
                    updateSubmitSectionDataValues($(this));

                    reloadGrid($productGrid);
                }
            });

            $scope.find('.js-product-grid__content-link').on('click', function (evt) {
                let $productGrid = $(this).closest('.js-product-grid');
                let url = $(this).attr('href');
                let ajaxUrl = $(this).data('product-grid-ajax-url') || url;
                evt.preventDefault();

                loadUrl($productGrid, ajaxUrl, [], true);
                history.pushState({
                        'productGridAjaxUrl': ajaxUrl,
                        'productGridInputState': getParams($productGrid),
                        'productGridAdditionalInputState': getAdditionalInputState($productGrid)
                    },
                    document.title,
                    url
                );
            });

            $scope.find('.js-product-grid__load-more-link').on('click', function (evt) {
                let $productGrid = $(this).closest('.js-product-grid');

                let url = $(this).attr('href');
                let ajaxUrl = $(this).data('product-grid-ajax-url') || url;
                evt.preventDefault();

                loadUrl($productGrid, ajaxUrl, [], true, $(this).data('load-more-type'));
                history.replaceState({
                        'productGridAjaxUrl': ajaxUrl,
                        'productGridInputState': getParams($productGrid),
                        'productGridAdditionalInputState': getAdditionalInputState($productGrid)
                    },
                    document.title,
                    url
                );
            });

            $scope.find('.js-product-grid__remove-input').on('click', function (evt) {
                let $productGrid = $(this).closest('.js-product-grid');
                let nameString = $(this).attr('data-name');

                if (!nameString) {
                    console.error('Can\'t remove product grid inputs. data-name is not set on', this);
                    return;
                }

                evt.preventDefault();

                if (nameString === '*') {
                    removeInputs($productGrid, getInputState($productGrid).filter(function (currentInput) {
                        return currentInput.hidden !== true;
                    }));
                } else {
                    removeInputs($productGrid, nameString.replace(/\s/g, '').split(',').map(function (name) {
                        return {
                            'name': name
                        };
                    }));
                }
            });
        }
    }
}

let defaultProductGrid = createProductGrid();
export const init = defaultProductGrid.init;
export const initInScope = defaultProductGrid.initInScope;

export function reloadGrid($productGrid) {
    let url = getBaseUrl($productGrid);
    let ajaxUrl = $productGrid.data('product-grid-ajax-url') || url;

    let params = getParams($productGrid);

    loadUrl($productGrid, ajaxUrl, params);

    history.pushState({
            'productGridAjaxUrl': ajaxUrl,
            'productGridInputState': params,
            'productGridAdditionalInputState': getAdditionalInputState($productGrid)
        },
        document.title,
        addParamsToUrl(url, params)
    );

    $productGrid.triggerHandler('product-grid.changed', [getInputState($productGrid)]);
}

let pendingRequest,
    cache = [];

function loadUrl($productGrid, ajaxUrl, params = [], loadContentOnly = false, insertMode = '') {
    params = [].concat(params);
    if (pendingRequest) {
        pendingRequest.abort();
        pendingRequest = null;
    }

    if (insertMode != 'append') {
        if ($(window).scrollTop() > $productGrid.offset().top) {
            $('html, body').animate({
                scrollTop: $productGrid.offset().top - 80
            }, 500);
        }
    }

    if (loadContentOnly) {
        params.push({
            name: 'contentOnly',
            value: 1
        });
    }

    params.push({
        name: '_original_href',
        value: getBasePath($productGrid)
    });

    let cacheResult = $.grep(cache, function (cacheEntry) {
        return cacheEntry.url === addParamsToUrl(ajaxUrl, params)
    });
    if (cacheResult.length) {
        setResults(cacheResult[0].content, $productGrid, loadContentOnly);


        return $.Deferred().resolveWith(null, [cacheResult[0].content]);
    } else {
        $productGrid.addClass('is-loading');
        let $loading = $productGrid.find('.js-product-grid__loading');
        $loading.attr('hidden', false);

        pendingRequest = $.ajax(ajaxUrl, {
            cache: false,
            dataType: 'html',
            data: params
        }).done(function (responseContent) {
            setResults(responseContent, $productGrid, loadContentOnly, insertMode);

            cache.push({
                url: addParamsToUrl(ajaxUrl, params),
                content: responseContent
            });

            if (cache.length > 40) {
                cache.shift();
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {
            if (textStatus === 'abort') {
                return;
            }

            console.error(textStatus, errorThrown, jqXHR);

            $productGrid.find('.js-product-grid__error').attr('hidden', null);
        }).always(function () {
            pendingRequest = null;
            $productGrid.removeClass('is-loading');
            $loading.attr('hidden', 'hidden');
        });

        return pendingRequest;
    }
}

function setResults(data, $productGrid, loadContentOnly, insertMode = '') {

    if (insertMode) {
        let $contentContainer = $productGrid.find('.js-product-grid__content');
        let $responseContent = $(data);
        let $elsToHide;

        if (insertMode == 'prepend') {
            // hide the load-previous-button and sorting-form
            $elsToHide = $productGrid.find('.js-product-grid__hide-when-load-previous');
            $elsToHide.attr('hidden', true);

            $contentContainer.prepend($responseContent);
        } else if (insertMode == 'append') {
            // hide the load-next-button
            $elsToHide = $productGrid.find('.js-product-grid__hide-when-load-next');
            $elsToHide.attr('hidden', true);

            $contentContainer.append($responseContent);
        }


        initModulesInScope($responseContent);
    } else {
        let $contentContainer = loadContentOnly
            ? $productGrid.find('.js-product-grid__content')
            : $productGrid.find('.js-product-grid__container');

        // todo focus after reload
        // let $focusedElement = $contentContainer.find(':focus');

        $contentContainer.html(data);
        initModulesInScope($contentContainer);
    }
}

// todo add support to add functionality to this function
function serializeInputElements($inputs) {
    let inputState = [];

    $inputs.each(function () {
        let $currentInput = $(this);
        let currentInputState = $currentInput.serializeArray()[0];

        if (currentInputState && currentInputState.value !== '') {
            // todo rename
            if ($currentInput.is('.js-product-grid__input--hidden')) {
                currentInputState.hidden = true;
            }

            currentInputState.text = getText($currentInput);

            currentInputState = {
                ...getPrefixedDataSet('inputoptions', $currentInput),
                ...currentInputState
            };

            inputState.push(currentInputState);
        }

    });

    return inputState
}

export function getInputState($productGrid) {
    let inputState = serializeInputElements(getChangeInputElements($productGrid));

    $productGrid.find('.js-product-grid__submit-section').each(function () {
        if ($(this).data('product-grid-input-state')) {
            inputState = inputState.concat($(this).data('product-grid-input-state'));
        }
    });

    inputState = inputState.concat(getAdditionalInputState($productGrid));

    return inputState;
}

function getEmptyParams($productGrid) {
    return getEmptyAbleInputNames($productGrid).filter(function (name) {
        return !(getInputState($productGrid).filter(function (currentInput) {
            return currentInput.name === name;
        })[0]);
    }).map(function (name) {
        return {
            'name': name,
            'value': '$$EMPTY$$',
            'hidden': true
        }
    });
}

function getParams($productGrid) {
    return getInputState($productGrid).concat(getEmptyParams($productGrid));
}

function setInputState($productGrid, inputState) {
    let $allInputs = getInputElements($productGrid);

    $allInputs.not('[type=radio], [type=checkbox]')
        .each(function () {
            let $currentInput = $(this);
            let result = findInInputState(inputState, $currentInput.attr('name'));
            let prevValue = $currentInput.val();

            if (result && result.value !== '$$EMPTY$$') {
                $currentInput.val(result.value);
            } else {
                $currentInput.val('');
            }

            if (prevValue !== $currentInput.val()) {
                $currentInput.trigger('changed').trigger('product-grid.input-changed');
            }
        });

    $allInputs.filter('[type=radio], [type=checkbox]')
        .each(function () {
            let $currentInput = $(this);
            let result = findInInputState(inputState, $currentInput.attr('name'), $currentInput.attr('value'));
            let shouldBeChecked = !!result && result.value !== '$$EMPTY$$';
            let wasChecked = $currentInput.is(':checked');

            if (shouldBeChecked) {
                $currentInput.prop('checked', 'checked');
            } else {
                $currentInput.prop('checked', null);
            }

            if (shouldBeChecked !== wasChecked) {
                $currentInput.trigger('changed');
            }
        });

    $productGrid.find('.js-product-grid__submit-section').each(function () {
        updateSubmitSectionDataValues($(this));
    });

    $productGrid.triggerHandler('product-grid.changed', [getInputState($productGrid)]);
}

function findInInputState(inputState, name, value) {
    return inputState.filter(function (currentInputState) {
        if (typeof value !== 'undefined') {
            return currentInputState.name === name && currentInputState.value === value;
        } else {
            return currentInputState.name === name;
        }
    })[0];
}

function addParamsToUrl(url, params) {
    let urlWithParams = url;
    if (params && params.length) {
        urlWithParams += urlWithParams.indexOf('?') < 0 ? '?' : '&';
        urlWithParams += $.param(params)
    }
    return urlWithParams;
}

function getText($input) {
    let text = $input.data('text') || $input.val();

    if ($input.is('select')) {
        let $selectedOption = $input.find(':selected');
        if ($selectedOption && $selectedOption.length) {
            text = $selectedOption.data('text') || $selectedOption.text().trim();
        }
    }

    if ($input.data('text-prefix')) {
        text = $input.data('text-prefix') + text;
    }

    if ($input.data('text-suffix')) {
        text += $input.data('text-suffix');
    }

    return text;
}

function getInputElements($scope) {
    return $scope.find(
        '.js-product-grid__input, ' +
        '.js-product-grid__form input, ' +
        '.js-product-grid__form select, ' +
        '.js-product-grid__submit-section input, ' +
        '.js-product-grid__submit-section select'
    );
}

/* get all inputs that trigger a reaload on change*/
function getChangeInputElements($scope) {
    return $scope.find(
        '.js-product-grid__input, ' +
        '.js-product-grid__form input, ' +
        '.js-product-grid__form select'
    ).not(
        '.js-product-grid__submit-section .js-product-grid__input, ' +
        '.js-product-grid__submit-section input, ' +
        '.js-product-grid__submit-section select'
    );
}

/* EmptyAble = elements that use the $$empty$$ param*/
function getEmptyAbleInputElements($scope) {
    return getInputElements($scope)
        .filter('[data-product-grid-use-empty-param], [data-product-grid-use-empty-param] *')
}

export function removeInput($productGrid, name, value) {
    let newState = getInputState($productGrid).filter(function (currentInputState) {
        if (typeof value !== 'undefined') {
            return currentInputState.name !== name || currentInputState.value !== value;
        } else {
            return currentInputState.name !== name;
        }
    });

    let additionalInputState = getAdditionalInputState($productGrid);
    additionalInputState = additionalInputState.filter(function (currentInputState) {
        if (typeof value !== 'undefined') {
            return currentInputState.name !== name || currentInputState.value !== value;
        } else {
            return currentInputState.name !== name;
        }
    });

    $productGrid.data('additional-input-state', additionalInputState);

    setInputState($productGrid, newState);
    reloadGrid($productGrid);
}

export function removeInputs($productGrid, inputsToRemove) {
    let newState = getInputState($productGrid).filter(function (currentInputState) {
        let shouldBeRemoved = !!(inputsToRemove.filter(function (inputToRemove) {
            if (typeof inputToRemove.value !== 'undefined') {
                return currentInputState.name === inputToRemove.name && currentInputState.value === inputToRemove.value;
            } else {
                return currentInputState.name === inputToRemove.name;
            }
        })[0]);

        return !shouldBeRemoved;
    });

    setInputState($productGrid, newState);
    reloadGrid($productGrid);
}

export function setInput($productGrid, name, value) {
    let newState = getInputState($productGrid).map(function (currentInputState) {
        if (currentInputState.name === name) {
            return $.extend({}, currentInputState, {
                value: value
            });
        }

        return currentInputState;
    });

    setInputState($productGrid, newState);
    reloadGrid($productGrid);
}

function getBaseUrl($productGrid) {
    return location.origin + getBasePath($productGrid);
}

export function getBasePath($productGrid) {
    if (location.search) {
        let inputNames = getAllInputNames($productGrid);
        let additionalInputNames = getAdditionalInputState($productGrid).map(x => x.name);
        let parmsArray = getUrlParams().filter(function (param) {
            return (
                inputNames.indexOf(param.name) < 0
                && additionalInputNames.indexOf(param.name) < 0
            ) && param.name !== 'page';
        });

        return addParamsToUrl(location.pathname, parmsArray);
    } else {
        return location.pathname;
    }
}

function getUrlParams() {
    return location.search
        ? decodeURIComponent(location.search)
            .substr(1)
            .split("&")
            .map(function (paramString) {
                let paramArr = paramString.split('=');
                return {
                    name: paramArr[0],
                    value: paramArr[1]
                }
            })
        : [];
}

function getAllInputNames($productGrid) {
    return getNamesOfInputs(getInputElements($productGrid));
}

/* EmptyAble = elements that use the $$empty$$ param*/
function getEmptyAbleInputNames($productGrid) {
    return getNamesOfInputs(getEmptyAbleInputElements($productGrid));
}

function getNamesOfInputs($inputs) {
    return $inputs.toArray().reduce(function (names, input) {
        let currentName = $(input).attr('name');
        if (currentName && names.indexOf(currentName) < 0) {
            names.push(currentName);
        }
        return names;
    }, []);
}

function updateSubmitSectionDataValues($productGridSection) {
    $productGridSection.data('product-grid-input-state', serializeInputElements($productGridSection.find('input, select')));
}

function getAdditionalInputState($productGrid) {
    return $productGrid.data('additional-input-state') || [];
}

export function setValue($productGrid, name, value, text) {
    setValues($productGrid, [{
        name: name,
        value: value,
        text: text
    }]);
}

export function setValues($productGrid, values) {
    let inputState = getAdditionalInputState($productGrid);

    values.forEach(function (currentValue) {
        let existingValue = (inputState.filter(function (x) {
            return currentValue.name === x.name;
        })[0]);

        if (existingValue) {
            existingValue.value = currentValue.value;
        } else {
            inputState.push(currentValue);
        }
    });

    $productGrid.data('additional-input-state', inputState);
    reloadGrid($productGrid);
}
