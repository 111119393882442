'use strict'

import fetch from '../../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill
import formDataEntries from 'form-data-entries';
import {isParsleyForm, isValid} from "../../libs/@elements/parsley-bootstrap-validation";
import {submitAjaxForm} from '../../scripts/friendlyCaptchaV1';
import throwError from "../../libs/@elements/throw-error"

const Selectors = {
     AjaxPriceRequestFormModal: '#priceRequestModal',
     AjaxPriceRequestFormModalContentWrapper: '.ajax-price-request-form-modal-content-wrapper'
}

const Attributes = {
    AjaxPriceRequestFormModalAction: 'data-ajax-price-request-form-modal-action'
}

const States = {
    IsLoading: 'is-loading'
}

const AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_RENDERED_CONTENT_EVENT = 'ajaxPriceRequestFormModalRequest:renderedContent';
const AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_FINISHED_EVENT = 'ajaxPriceRequestFormModalRequest:finished';
const AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_ERRORED_EVENT = 'ajaxPriceRequestFormModalRequest:errored';

let $ajaxPriceRequestFormModal;
let $ajaxPriceRequestFormModalContentWrapper;
let ajaxPriceRequestFormModalContentWrapperHTML = null;
let ajaxPriceRequestFormModalAction = false;
let ajaxPriceRequestFormModalRequest = false;

export function initInScope() {
    const $scope = $(document);
    $ajaxPriceRequestFormModal = $scope.find(Selectors.AjaxPriceRequestFormModal).length ? $scope.find(Selectors.AjaxPriceRequestFormModal) : throwError("1657629848 Product price request JS – Modal missing");
    $ajaxPriceRequestFormModalContentWrapper = $ajaxPriceRequestFormModal.find(Selectors.AjaxPriceRequestFormModalContentWrapper).length ? $ajaxPriceRequestFormModal.find(Selectors.AjaxPriceRequestFormModalContentWrapper) : throwError("1657629888 Product price request JS – Modal content wrapper missing");
    ajaxPriceRequestFormModalAction = $ajaxPriceRequestFormModal.attr(Attributes.AjaxPriceRequestFormModalAction) ? $ajaxPriceRequestFormModal.attr(Attributes.AjaxPriceRequestFormModalAction) : throwError("1657629892 Product price request JS – Modal ajax form action missing");

    $ajaxPriceRequestFormModal.on('show.bs.modal', onModalShow);
    $ajaxPriceRequestFormModal.on(AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_RENDERED_CONTENT_EVENT, onAjaxPriceRequestFormModalRequestContentRendered);
    $ajaxPriceRequestFormModal.on(AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_FINISHED_EVENT, onAjaxPriceRequestFormModalRequestFinished);
    $ajaxPriceRequestFormModal.on(AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_ERRORED_EVENT, (event, eventData) => onAjaxPriceRequestFormModalRequestErrored(event, eventData));
}

function onModalShow() {
    setAjaxPriceRequestFormModalContent();
}

function setAjaxPriceRequestFormModalContent() {
    // If request is already pending or content is already loaded, do nothing
    if (ajaxPriceRequestFormModalRequest || ajaxPriceRequestFormModalContentWrapperHTML ) return;

    ajaxPriceRequestFormModalRequest = false;
    $ajaxPriceRequestFormModalContentWrapper.html('')

    $ajaxPriceRequestFormModal.addClass(States.IsLoading);

    ajaxPriceRequestFormModalRequest = fetch(ajaxPriceRequestFormModalAction, { method: 'GET' });

    ajaxPriceRequestFormModalRequest.then(response => {
        ajaxPriceRequestFormModalContentWrapperHTML = response.clone().json();
        return ajaxPriceRequestFormModalContentWrapperHTML
    }).then(response => {
        if (!response || !response.success) throw new Error('1657629908 Product price request JS - Invalid ajax review form response')
        if (!response.html) throw new Error('1657629941 Product price request JS - Empty ajax review form response')

        $ajaxPriceRequestFormModalContentWrapper.html(response.html);
        $ajaxPriceRequestFormModal.trigger(AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_RENDERED_CONTENT_EVENT)
    }).finally(() => {
        $ajaxPriceRequestFormModal.trigger(AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_FINISHED_EVENT)
    }).catch(error => {
        $ajaxPriceRequestFormModal.trigger(AJAX_PRICE_REQUEST_FORM_MODAL_REQUEST_ERRORED_EVENT, [error])
    })
}

function onAjaxPriceRequestFormModalRequestContentRendered() {
    initProductPriceRequest();
}

function onAjaxPriceRequestFormModalRequestFinished() {
    $ajaxPriceRequestFormModal.removeClass(States.IsLoading);
    ajaxPriceRequestFormModalRequest = false
}

function onAjaxPriceRequestFormModalRequestErrored(event, eventData) {
    console && console.error && console.error(eventData);
}

export function initProductPriceRequest() {
    const $scope = $ajaxPriceRequestFormModal;

    // Re-initialize floating labels
    window.app && window.app.modules && window.app.modules.floatingLabel && window.app.modules.floatingLabel($scope);

    let $priceRequest = $scope.find('.js-add-product-price-request');
    let $priceRequestForm = $scope.find('.js-add-price-request__form');
    let pendingRequest;
    let $content = $scope.find('.js-content');

    const $loading = $('.js-modal__loading');

    document.getElementById('price_request_form_country').value =
        location.pathname.split('/')[1].split('-')[0].toUpperCase();

    $priceRequest.on('click', (e) => {

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        e.preventDefault();

        if (isParsleyForm($priceRequestForm) && !isValid($priceRequestForm)) {
            return;
        }

        showLoading();

        let url = $(e.currentTarget).data('url');
        let formData = new URLSearchParams(formDataEntries($priceRequestForm[0]));

        submitAjaxForm(url, formData, sendRequest);
    });

    function sendRequest(url, formData) {

        pendingRequest = fetch(url, {
            method: 'POST',
            body: formData
        });

        pendingRequest.then((res) => {
            return res.clone().json()
        }).then((res) => {
            if (res.success) {
                if (res.html) {
                    const $response = res.html;
                    $content.html($response);
                    $priceRequest.attr('disabled', 'disabled');
                    $priceRequest.addClass('d-none');
                    $scope.find('.js-cancel-product-price-request').addClass('d-none');
                    $scope.find('.js-close-product-price-request').removeClass('d-none');
                }
            }
        }).finally(() => {
            hideLoading();
        })
    }

    function showLoading() {
        $loading.removeAttr('hidden');
    }

    function hideLoading() {
        $loading.attr('hidden', true);
    }

}
