'use strict'

export function initInScope($scope) {
    const
        $spinners = $scope.find('.js-number-spinner');

    $spinners.each((_,el) => initSpinner($(el)) )
}

function initSpinner($scope) {
    const
        $input  = $scope.find('.js-number-spinner__input'),
        $plus   = $scope.find('.js-number-spinner__plus'),
        $minus  = $scope.find('.js-number-spinner__minus'),
        min     = $input.attr('min') || 1,
        max     = $input.attr('max') || Number.MAX_SAFE_INTEGER;

    if(!$input.val()){
        $input.val(1);
    }

    $plus.on('click', () => {
        const currentVal = +$input.val();
        if(currentVal < max){
            $input.val(currentVal + 1);
            $scope.trigger('change');
        }
    })

    $minus.on('click', () => {
        const currentVal = +$input.val();
        if(currentVal > min){
            $input.val(currentVal - 1);
            $scope.trigger('change');
        }
    })
}