'use strict'
import '../../libs/@elements/menu-aim';

let _isMobileNav, _backdropActive, _navOpen, _searchOpen, _cartOpen, _accountNavOpen;

let $mainNav, $accountNav, $navCanvas;
let $searchBtn, $cartBtn, $navItems, $mobileNavBtn, $mobileBackBtn, $mobileNavRoot, $accountNavBtn;
let $searchDropdown, $cartDropdown, $navDropdowns, $navItems_sub, $subMenus;
let $navBar;
let $closeBtn;
export const breakPoint = window.matchMedia('(max-width: 991px)');
export const MOBILE_NAV_ROOT_SELECTOR = '#mobile-nav';
export const DESKTOPNAV_CART_BTN_SELECTOR = '#desktop-nav__cart';

export const init = () => {
    const $scope = $('body');

    $mainNav        = $scope.find('#main-nav');
    $accountNav     = $scope.find('.ovx__account-nav__nav-list');
    $navCanvas      = $scope.find('#mobile-nav__canvas')

    $searchBtn      = $scope.find('.js-header__searchBtn'),
    $cartBtn        = $scope.find('.js-header__cartBtn'),
    $navItems       = $scope.find('.js-header__navItem'),
    $navItems_sub   = $scope.find('.js-header__has-submenu'),
    $mobileNavBtn   = $scope.find('.js-header__opennav'),
    $mobileBackBtn  = $scope.find('.js-header__submenu-heading-back'),
    $mobileNavRoot  = $scope.find(MOBILE_NAV_ROOT_SELECTOR),
    $accountNavBtn  = $scope.find('.js-header__toggle-account-nav'),

    $searchDropdown = $scope.find('.js-header__searchDropdown'),
    $cartDropdown   = $scope.find('.js-header__cartDropdown'),
    $navDropdowns   = $scope.find('.js-header__navDropdown'),
    $navBar         = $scope.find('.js-header__navbar'),
    $closeBtn       = $scope.find('.js-header__dropdown-close'),
    $subMenus       = $scope.find('.js-header__submenu');

    /** temporary old mobile menu **/
    if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)) {
        breakPoint.addListener((e) => resizeHandler(e)) //ie fallback
    }else{
        breakPoint.addEventListener("change", resizeHandler)
    }
    /** init Navigation **/
    if(!$mobileNavRoot.length && breakPoint.matches){
        initMobileNav();
    }else{
        initDesktopNav();
    }


    /** Bind NavButton Actions**/
    bindNavButtons();
}

/** temporary old mobile menu **/

function resizeHandler(e) {
    if(e.matches){
        initMobileNav();
    }else{
        initDesktopNav();
    }
}

function initMobileNav() {
    closeAll();
    $mainNav.menuAim('destroy');
    $navItems.unbind('touchend.desktop');
    $navItems_sub.on('click.mobile', (e) => {
        const $target = $(e.currentTarget);
        e.preventDefault();

        $target.siblings('.js-header__submenu').addClass('show');

    })

    $closeBtn.on('click', (e) => {
        closeAll(e)
    })

    $mobileBackBtn.on('click', (e) => {
        const $target = $(e.currentTarget);

        $target.closest('.js-header__submenu').removeClass('show');
    })

    _isMobileNav = true;
}



function initDesktopNav() {
    closeAll();
    $mainNav = $('#main-nav');
    $navItems_sub.unbind('click.mobile')
    $mainNav.menuAim({
        activate: (li) => {
            if(matchMedia('(max-width: 1024px)').matches){
                return
            }
            openDropdown($(li).find('> a'))
        },
        deactivate: (li) => {
            if(matchMedia('(max-width: 1024px)').matches){
                return
            }
            closeDropdown($(li).find('> a'));
        },
        exitMenu: () => {
            if(matchMedia('(max-width: 1024px)').matches){
                return
            }
            closeNavigation();
        },
        submenuDirection: "below",
        tolerance: 1
    })

    $closeBtn.on('click', (e) => {
        closeAll(e)
    })

    function closeDropdown($target) {
        if($target.hasClass('show')) {
            $target.removeClass('show');
            $target.siblings('.js-header__navDropdown').removeClass('show');
            _navOpen = false;
        }
    }

    function openDropdown($target){
        if(_searchOpen || _cartOpen){
            return;
        }

        if (_accountNavOpen) {
            closeAccountNav();
        }

        if($target.hasClass('show')){
            closeAll();
            return
        }

        if($target.siblings('.js-header__navDropdown').length) {
            showBackdrop();
            $target
                .addClass('show')
                .siblings('.js-header__navDropdown')
                .addClass('show')
                .focus();

            _navOpen = true;
        }else{
            removeBackdrop()
        }

    }

    $navItems.on('touchend.desktop', (e) => {
        const $target = $(e.currentTarget);
        if($target.siblings('.js-header__navDropdown').length){
            openDropdown($target);
            e.preventDefault();
        }else{
            closeAll();
        }
    })

    _isMobileNav = false;
}

function bindNavButtons() {

    $searchBtn.on('click', () => {
        if(_searchOpen){
            closeAll();
        }else{
            openSearch();
        }
    });

    $cartBtn.on('click', () => {
        if(_cartOpen){
            closeAll();
        }else{
            openCart();
        }
    });

    if(!$mobileNavRoot.length) {
        $mobileNavBtn.on('click', () => {
            if (_navOpen) {
                closeAll()
            } else {
                openNavigation()
                $mobileNavBtn.addClass('show')
            }
        })
    }

    $accountNavBtn.on('click',(e)  => {
        console.log('$accountNavBtnClick:breakpoint.matches', breakPoint.matches);
        if (breakPoint.matches) return;

        e.preventDefault();

        if(_accountNavOpen){
            closeAll()
        }else{
            openAccountNav()
        }
    })
}

export function openNavigation() {
    if(_navOpen){
        return
    }
    closeAll();
    showBackdrop();
    $navBar.addClass('show');

    _navOpen = true;
}

export function openAccountNav() {
    console.log('openAccountNav:_accountNavOpen', _accountNavOpen);
    if(_accountNavOpen){
        return
    }
    closeAll();
    showBackdrop();
    $accountNav.addClass('show');

    _accountNavOpen = true;
}

export function closeNavigation() {
    /** desktop classes **/
    $navItems.removeClass('show');
    $navDropdowns.removeClass('show');

    /** mobile classes **/
    $navBar.removeClass('show')
    $subMenus.removeClass('show');

    if(!_searchOpen && !_cartOpen && !_accountNavOpen){
        removeBackdrop();
    }
    _navOpen = false;
}
export function closeAccountNav() {
    /** general classes **/
    $accountNav.removeClass('show');

    if(!_searchOpen && !_cartOpen){
        removeBackdrop();
    }
    _accountNavOpen = false;
}
export function closeAll() {
    /** general classes **/
    $cartDropdown.removeClass('show');
    $searchDropdown.removeClass('show');
    $cartBtn.removeClass('show');
    $searchBtn.removeClass('show');
    $accountNav.removeClass('show');
    $navCanvas.removeClass('show');

    /** desktop classes **/
    $navItems.removeClass('show');
    $navDropdowns.removeClass('show');

    /** mobile classes **/
    $navBar.removeClass('show');
    $subMenus.removeClass('show');
    $mobileNavBtn.removeClass('show');

    removeBackdrop();

    _navOpen = false;
    _accountNavOpen = false;
    _searchOpen = false;
    _cartOpen = false;
}

export function showBackdrop() {
    if(_backdropActive){
        return;
    }
    $('body').append('<div class="header__backdrop fade"></div>')
    setTimeout(() => {
        $('.header__backdrop').addClass('show').on('click', () => {
            closeAll();
        })
    }, 0);
    _backdropActive = true;
}

export function removeBackdrop() {
    if(!_backdropActive){
        return
    }
    $('.header__backdrop').remove();
    _backdropActive = false;
}



export function openCart() {
    if(_cartOpen){
       return;
    }
    closeAll();

    $cartBtn.addClass('show');
    $cartDropdown.addClass('show').trigger('cart:shown')
    const cartBtn = document.querySelector(DESKTOPNAV_CART_BTN_SELECTOR);

    //todo 55059 remove after old mobile navi is obsolete
    if(breakPoint.matches && !$mobileNavRoot.length) {
        showBackdrop();
    }
    if(!breakPoint.matches && !cartBtn) {
        showBackdrop();
    }
    _cartOpen = true;
}

export function openSearch() {
    if(_searchOpen){
        return;
    }
    closeAll();

    $searchBtn.addClass('show');
    $searchDropdown.addClass('show').trigger('search:shown')
    showBackdrop();

    //focus in search
    $('.js-typeahead__input.tt-input').focus();

    _searchOpen = true;
}
