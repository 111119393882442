'use strict'

import throwError from '../../libs/@elements/throw-error'
import fetch from "../../libs/@elements/fetch";

let getSheepUrl;


export function init() {

    if(typeof Storage === "undefined"){
        throwError('cannot access localStorage - sheep stories not loaded');
        return
    }

    /**
     * every page can set their own sheep url so that it's sheep gets renderd.
     */
    getSheepUrl = _config['sheepStoriesUrl']
    if(!getSheepUrl){
        return
    }

    /**
     * check if i dont want no more sheeeeps!
     */
    if(window.localStorage.getItem('sheepStoriesDisabled') === null){
        initSheepStories()
    }

    if(window.localStorage.getItem('expirationDate')){
        var values = JSON.parse(localStorage.getItem('expirationDate'))
        if(Date.parse(values) < Date.parse(new Date())){
            localStorage.removeItem('sheepStoriesDisabled');
            localStorage.removeItem('expirationDate');
        }
    }
}

function initSheepStories() {
    fetch(getSheepUrl).then((res) =>{
        return res.clone().json();
    }).then((res) => {
        if(res.success && res.html){
            const $html = $(res.html);

            $html.find('.js-sheep-stories__close').on('click', () => {
                $html.remove();
                window.localStorage.setItem('sheepStoriesDisabled', true);

                var actualTime = new Date();
                var time = new Date();

                time.setDate(actualTime.getDate() +30);
                window.localStorage.setItem('expirationDate',JSON.stringify(time));
            })

            $('body').append($html)
        }
    })
}