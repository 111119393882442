import {LevelMenu_ScenarioData} from "./TransitionsConfig/ScenarioData";

/**
 * @param {int} menuId
 * @return HTMLElement */
export const findTriggeringElement = menuId =>
    document.getElementById('desktop-nav').querySelector(`[data-menu-target-id="${menuId}"]`);

/**
 * @param {int} menuId
 * @param {HTMLElement} container
 * @return {HTMLElement}
 */
export const findMenuElement = (menuId, container = document.body) =>
    container.querySelector(`:scope [data-menu-id="${menuId}"]`);

/**
 * @param {int} level
 * @return {HTMLElement}
 */
export const findMenuContainer = (level) => document.querySelector(`[data-menu-container-level="${level}"]`)

/**
 * @param {HTMLElement} container
 * @return {NodeListOf<HTMLElement>}
 */
export const findTriggeringElements = container => container.querySelectorAll(':scope [data-menu-target-id]');

/**
 * @param {HTMLElement} level1Content
 * @return {?LevelMenu_ScenarioData}
 */
export const create_autoOpenLvl2_ScenarioData = (level1Content)=> {
    const targetElements = findTriggeringElements(level1Content)
    if (targetElements.length !== 1) {
        return null
    }

    const openMenuId = parseInt(targetElements[0].dataset['menuTargetId'])

    return new LevelMenu_ScenarioData(openMenuId, findMenuElement(openMenuId), 2)
}