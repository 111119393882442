'use strict';

export function initInScope($scope) {
    window.setTimeout(function () {
        if (( 'ontouchstart' in window || navigator.msMaxTouchPoints ) == undefined) {
            $('.slick-initialized').slick("slickSetOption", "draggable", false);
            $('.slick-initialized').slick("slickSetOption", "accessibility", false);
            $('.slick-initialized').slick("slickSetOption", "touchMove", false);
            $('.slick-slider, .btn').css("-webkit-user-select", "text");
            $('.slick-slider, .btn').css("-moz-user-select", "text");
            $('.slick-slider, .btn').css("-ms-user-select", "text");
            $('.slick-slider, .btn').css("user-select", "text");
        }
    }, 1000);

    const shippingMethodClass = $scope.find('.shipping-methods .row .col-md-4:nth-child(2)')
    if(shippingMethodClass.length) {
        shippingMethodClass.attr('id', 'ovxCustom__return_info');
        $('#ovxCustom__return_info > div').append('<span style="position:absolute;background:#111;padding: 2px 10px 0px;right:0;top:3px;color: #fff;font-size:14px;">INFO</span>');
    }
}