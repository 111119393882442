'use strict'

import {override, getLoadingContainerSmall} from '../../scripts/loading'

override({
    addLoadingContainer,
    getLoadingContainer,
})

const loadingIconPath = window._config && window._config.loadingIconPath ? window._config.loadingIconPath : false;

function addLoadingContainer($scope, config = [], big = true) {
    if(big){
        $scope.prepend(getLoadingContainer(...config));
    }else{
        if($scope.css('position') !== 'absolute' || 'relative'){
            $scope.css({
                'position' : 'relative'
            });
        }
        $scope.prepend(getLoadingContainerSmall(...config));
    }
}

function getLoadingContainer(overlay = true, sticky = true, newLoadingIcon = false){
    if (newLoadingIcon && loadingIconPath) {
        return `<div class="load-container load-container--new-loading-icon${overlay ? ' load-container--bg' : ''}${sticky ? ' load-container--sticky' : ''}">
                    <img src="${loadingIconPath}" class="load-container__icon">
                </div>` +
                (overlay ? '<div class="load-container-bg"></div>' : '');
    }

    return(
        '<div class="load-container ' + (overlay ? 'load-container--bg ' : '') + (sticky ? 'load-container--sticky ' : '') + '">' +
            '<div class="loader-ripple">' +
                '<div></div>' +
                '<div></div>' +
            '</div>' +
        '</div>' +
        (overlay ? '<div class="load-container-bg"></div>' : '')
    )
}
