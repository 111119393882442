'use strict'
import { addLoadingContainer, removeLoadingContainer } from "./loading";
import { isParsleyForm, isValid } from "../libs/@elements/parsley-bootstrap-validation";
import initModulesInScope from "../libs/@elements/init-modules-in-scope";
import { showNotification } from "../libs/@elements/alert-notification";
import throwError from "../libs/@elements/throw-error"
import { translate } from "../../../shared/translations/translations"
import fetch from "../libs/@elements/fetch"
import 'url-search-params-polyfill'; // Edge Polyfill
import formDataEntries from 'form-data-entries';

let pendingRequest;

export function initInScope($scope) {
    const
        $container = $scope.find('.js-ajax-login');

    $container.each((_,el) => initLogin($(el)));
}



function initLogin($container) {
    const $form = $container.find('form');
    $form.on('submit', (e) => requestLogin(e))

    function requestLogin(e) {
        let $form = $(e.target);
        
        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        e.preventDefault();

        if (isParsleyForm($form) && !isValid($form)) {
            return;
        }

        addLoadingContainer($container, [true, false])

        let formData = new URLSearchParams(formDataEntries($form[0]));
        let url = $form.data('action') || throwError(translate('cart.no.action.url'))

        pendingRequest = fetch(url, {
            method: 'POST',
            body: formData
        });
        pendingRequest
            .then((res) => {
                return res.clone().json()
            }).then((res) => {
            if (res.success) { 
                const data = res.data
                if (data.loginstate) {
                    window.location.href = data.url || throwError(translate('login.no.url.given'));
                } else {
                    let $html = $(data.html) || throwError(translate('login.no.html.given'))
                    $form.replaceWith($html)
                    initModulesInScope($container.parent());
                    removeLoadingContainer($container)
                }
            }
        }).catch((error) => {
            if (error.name !== 'AbortError') {
                /*Do error stuff*/
                console.error(error);
            }
            removeLoadingContainer($container)
        });
        showNotification(pendingRequest);

    }
}