'use strict'

import fetch from '../../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill
import initModulesInScope from "../../libs/@elements/init-modules-in-scope";
import throwError from "../../libs/@elements/throw-error"
import { addLoadingContainer, removeLoadingContainer } from "../../scripts/loading";
import {showNotification} from "../../libs/@elements/alert-notification";

let $flyout;



export function init($scope = $('body')) {
    $flyout = $scope.find('.js-ajax-flyout').length ? $scope.find('.js-ajax-flyout') : throwError("Ajax Flyout Container Missing - But no module was found");
}

export function initInScope($scope) {
    const
        $flyoutBtn =     $scope.find('.js-ajax-flyout__btn'),
        $flyoutContainer = $flyout.find('.js-ajax-flyout__content'),
        $flyoutLoadingContainer = $flyout.find('.js-ajax-flyout__loading-container');

    let pendingRequestResult = null,
        pendingRequestComplete = [],
        urlsArr = [],
        flyoutHTMLArr = [],
        flyoutHeadline = '',
        flyoutPretext = '',
        $flyoutHeadline = null,
        $flyoutPretext = null;

    if (!$flyoutBtn.length) {
        return;
    }

    $flyoutBtn.on('click', (e) => {
        const urlsAttr = $(e.currentTarget).data('urls');

        if(!urlsAttr) {
            return;
        }

        e.preventDefault();

        // Reset flyout HTML and data
        pendingRequestComplete = [];
        urlsArr = [];
        flyoutHTMLArr = [];
        flyoutHeadline = '';
        flyoutPretext = '';
        $flyoutHeadline = null;
        $flyoutPretext = null;
        resetFlyout();

        addLoadingContainer($flyoutLoadingContainer, [false, false]);
        $flyout.modal('show');

        flyoutHeadline = $(e.currentTarget).data('headline') || '';
        flyoutPretext = $(e.currentTarget).data('pretext') ? $(e.currentTarget).data('pretext') : '';
        $flyoutHeadline = $flyout.find('.js-ajax-flyout__headline'),
        $flyoutPretext = $flyout.find('.js-ajax-flyout__pretext');

        urlsArr = urlsAttr.split(',');

        urlsArr.forEach((url, i) => {
            let pendingRequest = null;

            if(pendingRequest) {
                pendingRequest.abort();
                pendingRequest = null;
            }

            pendingRequest = fetch(url, {
                method: 'GET'
            });

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                if(!res.success || !res.html) {
                    pendingRequestComplete.splice(i, 0, {url: url, success: false});
                }

                pendingRequestComplete.splice(i, 0, {url: url, success: true});
                $flyout.trigger('pendingRequestComplete', [{url: url, index: i, response: res.html}]);
            }).catch(() => {
                pendingRequestComplete.splice(i, 0, {url: url, success: false});
            })
            showNotification(pendingRequest);
        });
    });

    $flyout.on('pendingRequestComplete', (e, customData) => {
        if (customData && customData.index >= 0 && customData.response) {
            flyoutHTMLArr.splice(customData.index, 0, customData.response);
        }

        if (!urlsArr.length ||
            !pendingRequestComplete.length ||
            urlsArr.length !== pendingRequestComplete.length)
        {
            return;
        }

        // All requests completed
        const pendingRequestResult = Boolean(pendingRequestComplete.filter(request => request.success === true).length);

        if (!pendingRequestResult) {
            $flyout.one('shown.bs.modal', () => {
                $flyout.modal('hide');
            })
            return;
        }

        // At least one successful request
        $flyout.addClass('js-ajax-flyout--loaded');

        let flyoutContainerHTML = flyoutHTMLArr.join('');

        const flyoutContentHeadlinePattern = /<!-- js-ajax-flyout__headline start -->?\s+(.*)\s+<!-- js-ajax-flyout__headline end -->/,
              flyoutContentHeadlineMatches = flyoutContainerHTML.match(flyoutContentHeadlinePattern),
              flyoutContentPretextPattern = /<!-- js-ajax-flyout__pretext start -->?\s+([a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]*)\s+<!-- js-ajax-flyout__pretext end -->/,
              flyoutContentPretextMatches = flyoutContainerHTML.match(flyoutContentPretextPattern);

        if (flyoutContentHeadlineMatches) {
            flyoutHeadline = flyoutContentHeadlineMatches[flyoutContentHeadlineMatches.length - 1] || flyoutHeadline
            flyoutContainerHTML = flyoutContainerHTML.replace(flyoutContentHeadlineMatches[0], '')
            $flyoutHeadline.replaceWith(flyoutHeadline)
        } else {
            $flyoutHeadline.html(flyoutHeadline);
        }

        if (flyoutContentPretextMatches) {
            flyoutPretext = flyoutContentPretextMatches[flyoutContentPretextMatches.length - 1] || flyoutPretext
            flyoutContainerHTML = flyoutContainerHTML.replace(flyoutContentPretextMatches[0], '')
            $flyoutPretext.replaceWith(flyoutPretext);
        } else {
            $flyoutPretext.html(flyoutPretext);
        }

        if (flyoutHeadline) {
            $flyoutHeadline.show();
        } else {
            $flyoutHeadline.hide();
        }

        if (flyoutPretext) {
            $flyoutPretext.show();
        } else {
            $flyoutPretext.hide();
        }

        $flyoutContainer.html(flyoutContainerHTML);
        initModulesInScope($flyoutContainer);
        removeLoadingContainer($flyoutLoadingContainer);
    })
}

function resetFlyout() {
    if($flyout.length){
        $flyout.removeClass('js-ajax-flyout--loaded');
        $flyout.find('.js-ajax-flyout__content').empty();
        $flyout.find('.js-ajax-flyout__headline').empty();
        $flyout.find('.js-ajax-flyout__pretext').empty();
    }else{
        throwError("Ajax Flyout Container Missing - But no module was found")
    }
}
