import {_readOutElementsWidths, _sum_menu_widths, calcTranslate} from "./MenuPositioner.func";
import {Tr_LevelMover} from "./Tr_LevelMover";

/**
 * @typedef MenuWidthElement
 * @property {int} level
 * @property {HTMLElement} element
 * @property {width} width
 */

/**
 * @type {MenuWidthElement[]}
 */
const _menuWidths = []

const _initMenuWidths = (maxMenuLevel) => {
    _menuWidths.push(..._readOutElementsWidths([1, maxMenuLevel]))
    Object.freeze(_menuWidths)
}

/**
 *
 * @param {int} maxMenuLevel
 * @param {int} startZIndex
 */
const _initPositions_and_zIndex = (maxMenuLevel, startZIndex) => {
    let sum = 0

    _menuWidths.forEach(menuWidthElement => {
        menuWidthElement.element.style.left = sum + 'px'
        menuWidthElement.element.style.zIndex = '' + startZIndex--
        sum += menuWidthElement.width
    })
}

const _calculateTranslations = (newActiveLevel, translateOverrides, useCurrentLevel) => {
    if (newActiveLevel > _menuWidths.length || newActiveLevel < 1) {
        return
    }

    return _menuWidths.slice(1).map(menuWidthElement => {
        const translateValue = calcTranslate(newActiveLevel, menuWidthElement, translateOverrides, useCurrentLevel)

        return {element: menuWidthElement.element, translateValue}
    })
}

/**
 * @param {int} newActiveLevel
 * @param {boolean} useTransition
 * @param {TransitionCancellationFunc} isTransitionCanceled
 * @return {Promise[]}
 */
const _moveAllMenus = (newActiveLevel, useTransition, isTransitionCanceled, translateOverrides, useCurrentLevel = false, delay = 0) => {
    const translateValues = _calculateTranslations(newActiveLevel, translateOverrides, useCurrentLevel)

    if (!translateValues) {
        return []
    }
    return translateValues.map(dat =>
        Tr_LevelMover.moveFunc(dat.element, dat.translateValue, useTransition, isTransitionCanceled, delay)
    )
}

export const MenuPositioner = Object.freeze({
    init(translateOverrides) {
        const maxMenuLevel = 3
        _initMenuWidths(maxMenuLevel)
        _initPositions_and_zIndex(maxMenuLevel, 2000)
        _moveAllMenus(1, false, () => false, translateOverrides)
    },

    sum_menu_widths(fromLevel, toLevel) {
        return _sum_menu_widths(_menuWidths, fromLevel, toLevel)
    },

    /** @type {TransitionInfo} */
    setActiveMenuLevel_moveMenus: ({
        /**
         * @param {int} newActiveLevel
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (newActiveLevel, useTransition, isTransitionCanceled, translateOverrides, useCurrentLevel, delay) =>
            Promise.allSettled(_moveAllMenus(newActiveLevel, useTransition, isTransitionCanceled, translateOverrides, useCurrentLevel, delay)),
        startState: Tr_LevelMover.TRANS_STATES.MOVING,
        endState: Tr_LevelMover.TRANS_STATES.STILL,
        debugDescription: 'LevelMenu\tMove container',
    }),

    INITIAL_STATE: Tr_LevelMover.INITIAL_STATE,


})
