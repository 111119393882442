import {cloneStateValue} from "./cloneState";

/**
 * @param {object} state
 * @param {string[]} path - any number of strings, strings containing . are split
 * @return {*}
 */
const _getCopyOfValue = (state, path) => cloneStateValue(__getValueByPath(state, path))

const _pathsToPathList = (strings) => strings.flatMap(str => str.split('.').filter(part => part !== ''));

/**
 * @param {object} state
 * @param {string[]} path
 * @return {*}
 */
const __getValueByPath = (state, path) =>
    _pathsToPathList(path).reduce(
        (current, el) => current[el], state
    );

/**
 * @param {object} state
 * @param {string[]} path
 * @param {*} value
 * @return {*}
 */
const _setValueByPath = (state, path, value) => {
    const list = _pathsToPathList(path)
    const listUntilParent = list.slice(0, -1)
    const key = list.slice(-1)

    const parent = listUntilParent.reduce(
        (current, el) => current[el], state
    );

    parent[key] = value
}

export const StateWithPath = Object.freeze({
    /**
     * @param {Object} state
     * @param {string|string[]} path - returns the value at the given path; separator is . (e.g. lvl5.activeItem)
     * @return {*}
     */
    getValue(state, path) {
        return _getCopyOfValue(state, (Array.isArray(path) ? path : [path]))
    },

    /**
     * @param {object} state
     * @param value
     * @param {string|string[]} path
     */
    setValue(state, path, value) {
        _setValueByPath(state, (Array.isArray(path) ? path : [path]), value)
    }
})