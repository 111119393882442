'use strict';

import * as R from "ramda";
import {MainMenu_ScenarioData} from "./ScenarioData";
import {StateWithPath} from "../core/state/StateWithPath";
import {MainMenuState} from "./MainMenuState";
import {TAction} from "../core/TransitionTree/TAction";
import {Overlay} from "./Overlay/Overlay";

const KEYS = MainMenuState.KEYS
const TRANS_STATES = MainMenuState.TRANS_STATES

/**
 * @param {MainState} state
 * @param {MainMenu_ScenarioData} scenarioData
 * @return {TAction}
 */
const _create_openAction = (state, scenarioData) => {
    const closeFunc = scenarioData.closeFunc ? scenarioData.closeFunc : () => {
    }

    return new TAction(
        'MainMenu: call open-func',
        scenarioData.openFunc,
        [KEYS.ACTIVE_MENU],
        [
            [[KEYS._CLOSE_FUNCTION_], closeFunc],
            [[KEYS._MENUNAME_], scenarioData.menuName],
            [[KEYS._MENU_SUB_ID_], scenarioData.menuSubId],
            [[KEYS._STATE_], TRANS_STATES.OPENING],
        ],
        [
            [[KEYS._CLOSE_FUNCTION_], closeFunc],
            [[KEYS._MENUNAME_], scenarioData.menuName],
            [[KEYS._MENU_SUB_ID_], scenarioData.menuSubId],
            [[KEYS._STATE_], TRANS_STATES.OPEN],
        ],
    )
}
/**
 * @param {MainState} state
 * @return {?TAction}
 */
const _create_closeAction = (state) => {
    const basePath = [KEYS.ACTIVE_MENU]

    const closeFunc = StateWithPath.getValue(state, [...basePath, KEYS._CLOSE_FUNCTION_])

    return new TAction(
        'MainMenu: call close-func',
        closeFunc,
        basePath,
        [
            [[KEYS._STATE_], TRANS_STATES.CLOSING],
        ], [
            [[KEYS._CLOSE_FUNCTION_], undefined],
            [[KEYS._MENUNAME_], undefined],
            [[KEYS._MENU_SUB_ID_], undefined],
            [[KEYS._STATE_], TRANS_STATES.CLOSED],
        ]
    )
}


/**
 * @param {MainState} state
 * @param {MainMenu_ScenarioData} scenarioData
 * @return {TAction}
 */
const _create_openOverlay = (state, scenarioData) => {
    const transInfo = Overlay.show
    return new TAction(
        transInfo.debugDescription,
        R.curry(scenarioData.overlay.show)(true),
        [KEYS.OVERLAY],
        [[[KEYS._STATE_], transInfo.startState]],
        [[[KEYS._STATE_], transInfo.endState]],
    )
}


/**
 * @param {MainState} state
 * @param {MainMenu_ScenarioData} scenarioData
 * @return {TAction}
 */
const _create_closeOverlay = (state, scenarioData) => {
    const transInfo = Overlay.hide
    return new TAction(
        transInfo.debugDescription,
        R.curry(scenarioData.overlay.hide)(true),
        [KEYS.OVERLAY],
        [[[KEYS._STATE_], transInfo.startState]],
        [[[KEYS._STATE_], transInfo.endState]],
    )
}


export const MainMenu_Scenarios = Object.freeze({
    /**
     * @param {MainState} state
     * @param {CreatePathFunc} createPath
     * @param {MainMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    mainMenu_open: (createPath, state, scenarioData) =>
        createPath([
                _create_openOverlay(state, scenarioData),
                _create_openAction(state, scenarioData)
            ]
        ),

    /**
     * @param {MainState} state
     * @param {CreatePathFunc} createPath
     * @param {MainMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    mainMenu_switch: (createPath, state, scenarioData) =>
        createPath([
                _create_closeAction(state)
            ], [
                _create_openOverlay(state, scenarioData),
                _create_openAction(state, scenarioData)
            ]
        ),

    /**
     * @param {MainState} state
     * @param {CreatePathFunc} createPath
     * @param {MainMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    mainMenu_reopen: (createPath, state, scenarioData) =>
        MainMenu_Scenarios.mainMenu_open(createPath, state, scenarioData),


    /**
     * @param {MainState} state
     * @param {CreatePathFunc} createPath
     * @param {MainMenu_ScenarioData} scenarioData
     * @return {TAnyNode}
     */
    mainMenu_close: (createPath, state, scenarioData) =>
        createPath([
            _create_closeAction(state),
            _create_closeOverlay(state, scenarioData),
        ])

})
