'use strict'

import * as compareProduct from './../../scripts/compareProduct';
import { translate } from "../../../../shared/translations/translations"

export function initInScope($scope) {
    const $items = $scope.find('.js-productGridItem');

    $items.each((_ ,el) => {
        initproductGridItem($(el));
    })

    function initproductGridItem($item) {
        const
            $sizesContainer         = $item.find('.js-productGridItem__sizes'),
            $labelContainer         = $item.find('.js-productGridItem__label'),
            $priceContainer         = $item.find('.js-productGridItem__price'),
            $compareContainer       = $item.find('.js-productGridItem__compare'),
            $imageContainer         = $item.find('.js-productGridItem__image'),
            $availabilityContainer  = $item.find('.js-productGridItem__availability'),
            $disabledSizes          = $item.find('.js-productGridItem__size.disabled'),
            $link                   = $item.find('.js-go-to-link__link'),
            $variants               = $item.find('.js-productGridItem__variant');

        // Find availability status for initial variant
        setAvailabilityState($sizesContainer, $availabilityContainer);

        $disabledSizes.on('click', (e) => {
           e.preventDefault();
        });

        $variants.on('click', (e) => {
            const
                $button             = $(e.currentTarget),
                $template           = $button.find('.js-productGridItem__template'),
                template            = $($button.find('.js-productGridItem__template').html()),
                url                 = $template.data('url'),
                price               = $template.data('price'),
                compare             = $template.data('compare'),
                $sizesHtml          = $(template[0]);

            var $image = null;

            $sizesContainer.html($sizesHtml.contents());
            $labelContainer.html('');

            template.each((index,element)=>{
                if($(element).hasClass('js-template-label')) {
                    $labelContainer.append($(element).contents());
                }
                if($(element).hasClass('js-template-image')) {
                    $image = $(element).contents();
                }
            });

            // Find availability status on variant change
            setAvailabilityState($sizesContainer, $availabilityContainer);

            $image?.appendTo($imageContainer).css({
                visibility: 'hidden',
                display: 'none'
            });

            onImgLoad($image, () => {
                /* 2 nested animation frames because image gets drawn on 2nd after load */
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        $image.css({
                            visibility: 'visible',
                            display: 'block'
                        })
                        $image.prev().remove()
                    })
                })
            })

            $priceContainer.html(price);
            $compareContainer.html(compare);
            compareProduct.initInScope($compareContainer);
            $imageContainer.attr('href', url);
            $link.attr('href', url);
        })
    }

}

/**
 * Trigger a callback when the selected images are loaded:
 * @param {String} selector
 * @param {Function} callback
 */
var onImgLoad = function(selector, callback){
    $(selector).each(function(){
        if (this.complete || /*for IE 10-*/ $(this).height() > 0) {
            callback.apply(this);
        }
        else {
            $(this).on('load', function(){
                callback.apply(this);
            });
        }
    });
};

const setAvailabilityState = function ($sizesContainer, $availabilityContainer) {
    let $hasAvailability = $sizesContainer.find('.product-grid-item__size').not('.disabled').length > 0;
    if ($hasAvailability) {
        $availabilityContainer.parent().addClass('invisible');
    } else {
        $availabilityContainer.parent().removeClass('invisible');
    }
};
