'use strict';

import Bloodhound from 'corejs-typeahead';
import {translate} from '../../../../../shared/translations/translations';
import 'whatwg-fetch'; // IE10 Polyfill

const defaultValues = {
    hint: true,
    highlight: false,
    minLength: 3,
    autocompleteText: 'title'
};

const defaultLayout = {
    headerLayout : (data, category) => {
        return(`<div class="typeahead-heading d-flex justify-content-between">
            ${!!data.overviewUrl ? (
                `<a href="${data.overviewUrl}" 
                    class="typeahead-heading__text mb0">`
            ) : ''}
            ${category.title ? (
                `<span class="typeahead-heading__icon text-primary ' + category.icon + '"> ${category.title}</span>`
            ) : ''}
            ${data.overviewUrl ? (
                `</a>`
            ) : ''}
           ${data.overviewUrl ? (
                `<a href="${data.overviewUrl}"
                    class="typeahead-heading__link mb0"><span class=""> ${category.overviewText || translate('typeahead.showmore')} </span></a>`
            ) : ''}
            </div>`)
                    
    },
    suggestionLayout: (data) => {
        // remove spaces and put optional spaces between all characters
        // so the query M10x60 will also find "M10 x 60"

        let regexStr = escapeRegExp(data._query);
        // remove all spaces
        // split with regex, so escaped chars don't get separated
        // -> get every non-whitespace character that has 0 or 1 \ before
        // -> then join them and put a optional white space between the characters
        // -> replace "*" php-wildcard (escaped \*) with regex-wildcard .*

        regexStr = regexStr.match(/(\\?\S)/g).join('\\s?').replace(/\\\*/g, '\.\*');
        let regexp = new RegExp(regexStr, 'gi');

        if (data.title) {
            return (
                `<div class="typeahead-suggestion">
                    ${data.title.replace(regexp, '<mark class="tt-highlight">$&</mark>')}
                </div>`
            )
        }

        return `<div class="typeahead-suggestion">${ data.title }</div>`;

    },
    errorLayout: () => {
        return false;
    }
};

export function createInitInScope(defaultOptions = defaultValues, overrideLayout = {}) {
    return function ($scope) {
        const $input = $scope.find('.js-typeahead__input');
        const categories = $input.data('categories');

        const layout = {
            ...defaultLayout,
            ...overrideLayout
        };

        if (!categories || !Array.isArray(categories)) {
            return;
        }

        let options = {
            ...defaultValues,
            ...defaultOptions
        };
        let dataSets = [];

        categories.map( (category) => {
            let bloodhound = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('text'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: $input.data('queryurl'),
                    wildcard: '%QUERY',
                    transport: fetchTransport,
                    filter: function (response) {
                        if (response && response[category.name] && response[category.name]) {
                            return response[category.name];
                        }
                        return [];
                    }
                }
            });

            dataSets.push({
                name: category.name,
                limit: Number.MAX_VALUE, /*workaround for https://github.com/twitter/typeahead.js/issues/1232*/
                display: defaultOptions.autocompleteText,
                source: bloodhound,
                templates: {
                    empty: (data) => layout.errorLayout(data),
                    header: (data) => {
                        if(category.overviewUrl){
                            data.overviewUrl = category.overviewUrl
                                + (category.overviewUrl.indexOf('?') >= 0 ? '&' : '?' )
                                + 'q=' + data.query;
                        }else{
                            data.overviewUrl = '';
                        }
                        if(!category.title){
                            category.title = '';
                        }

                        return layout.headerLayout(data, category);
                    },
                    suggestion: (data) => layout.suggestionLayout(data, category)

                }
            })
        });

        $input.typeahead(options, dataSets)
            .on('typeahead:asyncrequest', () => {
                //TODO LOADING
            })
            .on('typeahead:asynccancel typeahead:asyncreceive', () => {
                //TODO LOADING hide
            })
            .on('typeahead:select typeahead:autocompleted', function (typeaheadObj, data) {
                if (data['url']) {
                    location.href = data.url;
                }
            });
    }
}

export const initInScope = createInitInScope();

const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;

function escapeRegExp(string) {
    if (typeof string !== 'string') {
        throw new TypeError('Expected a string');
    }

    return string.replace(matchOperatorsRegex, '\\$&');
}



function fetchTransport(opts, onSuccess, onError) {
    fetch(opts.url, {
        type: "GET",
    })
        .then(respones => respones.json())
        .then(onSuccess)
        .catch(onError)
}
