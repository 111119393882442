"use strict";
import { addLoadingContainer, removeLoadingContainer } from "./loading";
import {isParsleyForm, isValid} from "../libs/@elements/parsley-bootstrap-validation";
export function initInScope ($scope) {
    const submitBtn = $scope.find('button[type="submit"]');
    window.addEventListener('pagehide', (event) => {
        removeLoadingIndicator($scope, submitBtn)
    });

    submitBtn.on('click',function (e) {
        e.preventDefault();
        const $form = $(this).closest('form');
        if($form.length) {
            if (isParsleyForm($form) && isValid($form)) {
                this.disabled = true
                addLoadingContainer($scope.find(this), [], false);
            }
        }
        $form.submit();
    });
}
function removeLoadingIndicator($scope, el) {
    el.disabled = false
    removeLoadingContainer($scope.find(el));
}
