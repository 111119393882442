'use strict'
import { preloadNSlide } from '../../libs/@elements/slider';
import { onEnterViewPort } from "../../libs/@elements/viewport-utils";
import { throttle } from 'throttle-debounce';

const
    teaserCardsSliderOptions = {
        slidesToShow: 4,
        adaptiveHeight: true,
        infinite: false,
        dots: true,
        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next text-primary" aria-label="Next"><i class="icon icon-ortovox_arrow-right"></i></button>',
        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev text-primary" aria-label="Previous"><i class="icon icon-ortovox_arrow-left"></i></button>',
        responsive: [
            {
                breakpoint: 1451,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    arrows: false,
                    dots: false,
                    // Redmine: 41807
                    // @see: https://github.com/kenwheeler/slick/pull/2635
                    outerEdgeLimit: true
                }
            }
        ]
    }

export function initInScope($scope) {
    const $teaserCardsSliders = $scope.find('.js-teaser-card-slider');

    if($teaserCardsSliders.length !== 0){
        $teaserCardsSliders.each((_, el) => {
            initTeaserCardsSlider($(el));
        })
    }
}


function initTeaserCardsSlider($teaserCardsSliders) {
    if ($teaserCardsSliders.is('.slick-initialized')) {
        // Error source here https://stackoverflow.com/questions/35884752/error-in-slick-js-uncaught-typeerror-cannot-read-property-add-of-null
        // Error source comes from re-initializing slick slider
        // Fix https://stackoverflow.com/questions/32371003/how-to-reload-slick-slider-carousel-jquery-plugin
        $teaserCardsSliders.slick('refresh');
    } else {
        $teaserCardsSliders.slick({
            ...teaserCardsSliderOptions,
        }).on('afterChange', function (event, slick, currentSlide) {
            preloadNSlide(slick, currentSlide + 1, teaserCardsSliderOptions.slidesToShow);
        });
    }

    onEnterViewPort($teaserCardsSliders, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, teaserCardsSliderOptions.slidesToShow);
    });
}
