'use strict'

import $ from 'jquery'

let defaultOptions = {
    offset: 0
};
let defaultSelectors = {
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    defaultOptions = options;
    defaultSelectors = selectors;

    setTimeout(function() {
    window.scrollTo(0, 0);
    setInitialPosition($('body'), options, selectors);
    }, 1);
}

export function initInScope($scope = $('body')) {
    const $anchor = $scope.find(defaultSelectors.link);
    $anchor.on('click', function() {
        window.scrollTo(0, 0);
        smoothscroll(this, defaultOptions.offset, defaultSelectors, $(this).offset().top)
    });

}

export function setOffset(offset) {
    defaultOptions.offset = offset;
}

export function smoothscroll(el, offset = defaultOptions.offset, selectors, anchorOffset) {
    const
        $anchor = $(el),
        $target = $(el.hash);

    offset = $anchor.data(selectors.dataOffset) ? $anchor.data(selectors.dataOffset) : offset;

    let time = $target.offset().top - anchorOffset > 0 ? ($target.offset().top - anchorOffset)/3 : (($target.offset().top - anchorOffset)*-1)/3;
    console.log(time);

    if ($target.length) {
        event.preventDefault();
        $('html, body').stop().animate({
            scrollTop: $target.offset().top - offset
        }, time, 'swing', function() {

            $target.focus();
            history.replaceState({}, '', el.hash);
            if ($target.is(":focus")) {
                return false;
            } else {
                $target.attr('tabindex','-1');
                $target.focus();
            };
        });
    }
}

function setInitialPosition($scope, options, selectors) {
    if(window.location.hash) {
        const
            pattern = new RegExp(window.location.hash),
            $anchors = $scope.find(selectors.link),
            $target = $(window.location.hash);

        let offset = options.offset;

        $anchors.each((i,_el) => {
            offset = pattern.test($(_el).attr('href')) && $(_el).data(selectors.dataOffset) ? $(_el).data(selectors.dataOffset) : offset;
        });
        $('html, body').stop().animate({
            scrollTop: $target.offset().top - offset
        }, 0);
    }
}