"use strict";

import $ from 'jquery';
import {onEnterViewPort} from "../viewport-utils";
import {getPrefixedDataSet} from "../data-set-utils";


const defaultSelectors = {
    base: '.js-lazy-iframe'
};

const defaultOptions = {
    offset: window.innerHeight / 2
};

export const initInScope = createInitInScope();

export function createInitInScope(selectors = defaultSelectors, options) {
    return function ($scope) {
        let $iframes = $scope.find(selectors.base);

        if ('loading' in HTMLImageElement.prototype) {
            $iframes.each((_, el) => {
                const $iframe = $(el);

                switchIframe($iframe, true)
            })
        }else{
            $iframes.each((_, el) => {
                const $iframe = $(el);

                let elementOptions = {
                    ...defaultOptions,
                    ...options,
                    ...getPrefixedDataSet('lazy-iframe', $iframe)
                };

                onEnterViewPort($iframe, function (iframe) {
                    switchIframe($iframe);
                }, elementOptions);
            });
        }

        return $iframes;
    };
}

export function loadIframe($iframe) {
    switchIframe($iframe);
}

function switchIframe($iframe, addLazyLoading = false) {
    if(addLazyLoading){
        $iframe.attr('loading', 'lazy');
    }

    if ($iframe.data('src')) {
        $iframe.attr('src', $iframe.data('src'));
    }
}
