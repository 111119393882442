'use strict'

export function initInScope($scope) {

    const $popovers = $scope.find('[data-toggle="popover"]')

    if($popovers.length){
        $popovers.each((_, popover) => {
            const $popover = $(popover);

            if($popover.data('template')){
                $popover.popover({
                    template: $popover.data('template')
                });
            }else{
                $popover.popover();
            }
        })

        $popovers.on('shown.bs.popover', function () {
            const
                $popover = $(this),
                $target = $('#' + $popover.attr('aria-describedby')),
                $button = $target.find('.js-popover--close');

            $popover.addClass('js-popover--show')

            if($button.length){
                $button.one('click', () => {
                    $popover.popover('hide');
                })
            }
        })

        $popovers.on('hide.bs.popover', function (e) {
            $(this).removeClass('js-popover--show')
        })
    }


}