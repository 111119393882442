'use strict'

const
    sliderOptions = {
        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next text-white mr-1" aria-label="Next"><i class="icon icon-arrow-right icon-2x"></i></button>',
        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev text-white ml-1" aria-label="Previous"><i class="icon icon-arrow-left icon-2x"></i></button>',

    }

export function initInScope($scope) {

    const
        $contentTeams = $scope.find('.js-content-team')

    $contentTeams.each((_, el) => {
        initContentTeam($(el))
    })
}

function initContentTeam($scope) {
    const
        $teamCard = $scope.find('.js-content-team__card'),
        $teamContainer = $scope.find('.js-content-team__container');

    setActiveContainers($scope, $teamCard, $teamContainer);
    bindBreakpoints($scope, $teamCard, $teamContainer);

    $teamCard.css('cursor', 'pointer')
    $teamCard.on('click', function () {
        const
            $card = $(this),
            $html = $($card.find('template').html()),
            count = $card.data('count'),
            $targetContainer = $card.nextAll('.js-content-team__container.is-active').eq(0),
            $slider = $html.find('.js-content-team__slider');

        if($targetContainer.data('active') === count){
            $targetContainer.data('active', '')
            $targetContainer.stop().slideUp();
            $targetContainer.removeClass('is-open');
            $card.removeClass('is-active');
        }else{
            const $openContainer = $teamContainer.filter('.is-open');
            $openContainer.data('active', '');
            $openContainer.removeClass('is-open');
            $teamCard.removeClass('is-active');
            if($openContainer.length){
                $openContainer.stop().slideUp(250, function () {
                    $targetContainer.data('active', count)
                    $targetContainer.empty().html($html);
                    $targetContainer.slideDown(250, function () {
                        initSlider($slider);
                        $targetContainer.css('height', '')
                    });
                    $targetContainer.addClass('is-open');
                    $card.addClass('is-active');
                });
            }else{
                $targetContainer.data('active', count)
                $targetContainer.empty().html($html);
                $targetContainer.stop().slideDown(250, function () {
                    initSlider($slider);
                    $targetContainer.css('height', '')
                });
                $targetContainer.addClass('is-open');
                $card.addClass('is-active');
            }
        }
    })
}

function initSlider($scope) {
    if(!$scope.length || $scope.hasClass('slick-inizialized')){
        return
    }
    $scope.slick(sliderOptions);
}

function bindBreakpoints($scope, $teamCard, $teamContainer) {
    const mobileBreak = window.matchMedia('(max-width: 767px)');
    const tabletBreak = window.matchMedia('(min-width: 768px) and (max-width: 1199px)');
    const desktopBreak = window.matchMedia('(min-width: 1200px)');

    /**
     * manuel polyfill. addListener is deprecated but in ie and edge addEventListerner is not yet supported
     */
    if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent)  || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)){
        mobileBreak.addListener((e) => {
            if(e.matches){
                setActiveContainers($scope, $teamCard, $teamContainer)
            }
        })
        tabletBreak.addListener((e) => {
            if(e.matches) {
                setActiveContainers($scope, $teamCard, $teamContainer)
            }
        })
        desktopBreak.addListener((e) => {
            if(e.matches){
                setActiveContainers($scope, $teamCard, $teamContainer)
            }
        })
    }else{
        mobileBreak.addEventListener('change', (e) => {
            if(e.matches){
                setActiveContainers($scope, $teamCard, $teamContainer)
            }
        })
        tabletBreak.addEventListener('change', (e) => {
            if(e.matches) {
                setActiveContainers($scope, $teamCard, $teamContainer)
            }
        })
        desktopBreak.addEventListener('change', (e) => {
            if(e.matches){
                setActiveContainers($scope, $teamCard, $teamContainer)
            }
        })
    }
}

function setActiveContainers($scope, $teamCard, $teamContainer) {
    let modulo = null;
    if(window.innerWidth < 768) {
        modulo = 1;
    }else if(window.innerWidth < 1200){
        modulo = 3
    } else {
        modulo = 4
    }
    $teamCard.removeClass('is-active');
    $teamContainer.stop().removeClass('is-active').hide().data('active', '');
    $scope.find('.js-content-team__container:nth-child(' + modulo * 2 + 'n)').addClass('is-active')

    if($teamContainer.length % modulo){
        $teamContainer.last().addClass('is-active')
    }
}