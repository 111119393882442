'use strict';

export function initInScope($scope) {
    let $modal = $scope.find('.js-swiss-modal');

    if (!sessionStorage.getItem('swissModal')) {
        setTimeout(function(){
            $modal.modal('show')
        }, 250);
    }

    $('body').on('click', '.js-swiss-modal__dismiss', function(e) {
        sessionStorage.setItem('swissModal', 'accepted');
    });
}