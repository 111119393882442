import * as R from "ramda";
import {MenuPositioner} from "./MenuPositioner";

/**
 * @typedef {[int,int]} InclusiveRange
 */

/**
 * @param {int} value
 * @param {InclusiveRange}[] min,max
 * @return {boolean}
 */
export const _inRange = (value, [min, max]) => value >= min && value <= max

/**
 * @param {InclusiveRange} inclusiveRange
 */
export const _readOutElementsWidths = (inclusiveRange) => {
    const elementsWidths = []
    document.querySelectorAll('[data-menu-container-level]').forEach(element => {
        const level = parseInt(element.dataset['menuContainerLevel'])
        if (!_inRange(level, inclusiveRange)) {
            return
        }
        elementsWidths.push({level, width: parseInt(getComputedStyle(element).width.replace('px', '')), element})
    })
    return elementsWidths
}

/**
 * @param {int}newActiveLevel
 * @param {MenuWidthElement} current
 * @return {number}
 */
export const calcTranslate = (newActiveLevel, current, translateOverrides, useCurrentLevel = false) => {
    if (current.level <= newActiveLevel && !useCurrentLevel) {
        return 0
    }

    const lvl = useCurrentLevel ? newActiveLevel : newActiveLevel + 1

    if (translateOverrides) return -1 * translateOverrides[lvl]

    return -1 * MenuPositioner.sum_menu_widths(useCurrentLevel ? newActiveLevel : newActiveLevel + 1, current.level)
};

/**
 * @param {MenuWidthElement[]} menuWidths
 * @param {int} fromLevel
 * @param {int} toLevel
 * @return {int}
 */
export const _sum_menu_widths = R.curry((menuWidths, fromLevel, toLevel = menuWidths.length) => {
    return menuWidths.reduce((prev, curr) => {
        const newVar = prev + ((curr.level >= fromLevel && curr.level <= toLevel) ? curr.width : 0);
        return newVar;
    }, 0)
})
