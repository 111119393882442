'use strict';

import {onEnterViewPort} from "../../libs/@elements/viewport-utils";

export function initInScope($scope) {
    const $videos = $scope.find('.js-usp-video');

    //show last frame after video has ended
    $videos.each(function(idx, video){
        video.addEventListener('ended', function() {
            this.pause();
            this.currentTime = this.duration;
        }, false);
    });

    //(re)start video when in viewport
    onEnterViewPort($videos, function($video) {
        const video = $video[0];
        if (!video.classList.contains('active')) {
            return;
        }
        video.currentTime = 0;
        video.play();
    }, {
        triggerOnlyOnce: false,
    });
}
