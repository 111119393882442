import {initSliders} from './sliderInit';

const
    initiativesSliderOptions = {
        slidesToShow: 6,
        adaptiveHeight: true,
        dots: true,
        infinite: false,
        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next text-primary" aria-label="Next"><i class="icon icon-ortovox_arrow-right"></i></button>',
        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev text-primary" aria-label="Previous"><i class="icon icon-ortovox_arrow-left"></i></button>',
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    arrows: false,
                    dots: false,
                    // Redmine: 41807
                    // @see: https://github.com/kenwheeler/slick/pull/2635
                    outerEdgeLimit: true,
                },
            },
        ],
    };

export function initInScope($scope) {

    const $initiativesSliders = $scope.find('.js-m5__initiatives-slider');
    initSliders($initiativesSliders, initiativesSliderOptions);
}
