"use strict";

export function initInScope ($scope) {
    $scope.find('.js-ajax-address__link').on('click',function (e) {
        $('.js-ajax-address').removeClass('js-ajax-address--active');
        $(this).closest('.js-ajax-address').addClass('js-ajax-address--active');

        let url = $(this).attr('data-href');
        $.ajax({
            type: "POST",
            url: url,
            dataType: 'JSON'
        }).done(function(response) {
            const $response = $(response. html)
            $('.js-ajax-address__body').html($response);
            app.initModules($response);
            app.modules.floatingLabel($scope);
            app.modules.parsley($scope);
            addressForm($scope);
        });
    });
    function addressForm($scope) {
        $scope.find('.js-address-form').on('submit',function (e) {

            e.preventDefault();
            let action = $(this).attr('data-action');
            let form = $('#modal-form');
            if($(this).parsley().isValid()){
                $.ajax({
                    method: 'POST',
                    url: action,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                    },
                    data: form.serialize(),
                    dataType: 'JSON'
                }).done(function(response) {
                    if(!response.error){
                        $('#editAdress').modal('hide');
                        if(response.delete){
                            $('.js-ajax-address--active').remove();
                        }else{
                            $('.js-ajax-address--active').find('.js-ajax-address__text').html(response.html);
                            let href = $('.js-ajax-address--active').find('.js-ajax-address__link').attr('data-href');
                            $('.js-ajax-address--active').find('.js-ajax-address__link').attr('data-href', href.replace(response.oldId, response.id));
                            $('.js-ajax-address--active').find('input').prop('checked',true);
                        }
                    }
                });
            }
        });
    }

}