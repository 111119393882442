'use strict';
import * as stdSlider from '../../libs/@elements/slider';

export function initInScope($scope) {
    function getOptions() {
        return {
            slidesToScroll: 1,
            slidesToShow: 1,
            nextArrow: false,
            prevArrow: false,
            adaptiveHeight: true,
            dots: true,
            focusOnSelect: true,
        };
    }

    const $navSlider = $scope.find('.js-usp-slider');
    $navSlider.each(function(index, slider) {
        stdSlider.createInitInScope(slider, getOptions())($scope);

        $(slider).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            if (currentSlide === nextSlide) {
                return;
            }
            const $stopVideo = $(slick.$slides[currentSlide]).find('.js-usp-video');
            const $playVideo = $(slick.$slides[nextSlide]).find('.js-usp-video');

            if ($stopVideo.length) {
                $stopVideo[0].pause();
                $stopVideo.removeClass('active')
            }
            if ($playVideo.length) {
                $playVideo[0].currentTime = 0;
                $playVideo[0].play();
                $playVideo.addClass('active')
            }
        });
    });
}

