'use strict';

import {LevelMenu_Atomics as At} from "./atomics";
import {StateWithPath} from "../../../shared/core/state/StateWithPath";
import {LevelMenuState} from "../state/LevelMenuState";
import {TOPLEVEL} from "../../MainMenu/Desktop_MainMenuTriggers";
import {Tr_LevelContent} from "../TransitionElements/MenuLevel/Tr_LevelContent";
import {TAction} from "../../../shared/core/TransitionTree/TAction";
import * as R from "ramda";
import {Tr_LevelTrigger} from "../TransitionElements/MenuLevel/Tr_LevelTrigger";
import {findMenuContainer, findTriggeringElements} from "../DomFunctions";

const KEYS = LevelMenuState.KEYS

export const LevelMenu_Shared_Scenarios = Object.freeze({

    /**
     * @param {LevelState} state
     * @param {int[]} levels
     * @param {boolean} useTransition
     * @return {TAction[]}
     */
    fadeout_all_contents: (state, levels, useTransition) => {
        const maxLevel = StateWithPath.getValue(state, KEYS.MAX_LEVEL)

        const actions = []

        levels.forEach(lvl => {
            let contentElement, triggerElement

            //fade out the content element
            contentElement = StateWithPath.getValue(state, [KEYS._PREFIX_LEVEL + lvl, KEYS.ACTIVE_CONTENT, KEYS._ELEMENT_])
            if (contentElement) {
                actions.push(At.create_Action__fadeOutContent(lvl, contentElement, useTransition))
            }

            //fade out the triggering element to the NEXT level
            const nextLvl = lvl + 1
            if (nextLvl < maxLevel) {
                triggerElement = StateWithPath.getValue(state, [KEYS._PREFIX_LEVEL + nextLvl, KEYS.ACTIVE_TRIGGER, KEYS._ELEMENT_])
                if (triggerElement) {
                    actions.push(At.create_Action__deHighlight_Trigger(lvl, triggerElement, useTransition))
                }
            }
        })

        return actions
    },


    /**
     * @param {LevelState} state
     * @param {?HTMLElement} activeElement
     * @param {int} level
     * @param {boolean} useTransition
     * @return {TAction}
     */
    setTriggerHighlighting: (state, activeElement, level, useTransition, findAllTriggers) => {
        const basePath = [KEYS._PREFIX_LEVEL + level, KEYS.ACTIVE_TRIGGER]
        return new TAction(
            '(lvl ' + level + ') ' + 'Mult. TriggerElements\tSet correct states',
            R.curry((isTransitionCanceled) => {
                if (!findAllTriggers) {
                    findTriggeringElements(findMenuContainer(level - 1)).forEach(el => {

                        if (el === activeElement) {
                            Tr_LevelTrigger.activate.func(el, useTransition, () => false).then()
                        } else {
                            Tr_LevelTrigger.deactivate.func(el, useTransition, () => false).then()
                        }
                    })
                } else {
                    const levels = LevelMenu_Shared_Scenarios.getSublevelsStartingFrom(state, TOPLEVEL)
                    
                    levels.forEach(level => {
                        findTriggeringElements(findMenuContainer(level - 1)).forEach(el => {

                            if (el === activeElement) {
                                Tr_LevelTrigger.activate.func(el, useTransition, () => false).then()
                            } else {
                                Tr_LevelTrigger.deactivate.func(el, useTransition, () => false).then()
                            }
                        })
                    })
                }
            }),
            basePath,
            [
                [[KEYS._STATE_], Tr_LevelTrigger.activate.endState],
                [[KEYS._ELEMENT_], activeElement],
            ], [
                [[KEYS._STATE_], Tr_LevelTrigger.activate.endState],
                [[KEYS._ELEMENT_], activeElement],
            ]
        )
    },


    /**
     * @param {LevelState} state
     * @param {int} fromLvl
     * @return {int[]}
     */
    getSublevelsStartingFrom: (state, fromLvl) => {
        const maxLevels = StateWithPath.getValue(state, KEYS.MAX_LEVEL)
        const lvls = []
        for (let lvl = fromLvl; lvl < maxLevels; lvl++) {
            lvls.push(lvl)
        }
        return lvls
    },

    /**
     *
     * @param {LevelState} state
     * @return {?int}
     */
    getHighestActiveLevel: (state) => {
        const nLevels = StateWithPath.getValue(state, [KEYS.MAX_LEVEL])

        for (let lvl = nLevels - 1; lvl >= TOPLEVEL; lvl -= 1) {
            const statePath = [KEYS._PREFIX_LEVEL + lvl, KEYS.ACTIVE_CONTENT, KEYS._STATE_]
            const isActive = StateWithPath.getValue(state, statePath) === Tr_LevelContent.TRANS_STATES.ACTIVE
            if (isActive) {
                return lvl
            }
        }
    }

})
