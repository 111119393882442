'use strict'

import Splide from '@splidejs/splide'
import { throttle} from "throttle-debounce";

const SELECTORS = {
    MobileCategoriesContainer: '.js-product-grid-mobile-categories-container',
    MobileCategories: '.js-product-grid-mobile-categories',
    MobileCategoriesSlider: '.js-product-grid-mobile-categories__slider',
    MobileFilterToggleContainer: '.js-product-grid-mobile-filter-toggle-sticky-container',
    MobileFilterToggle: '.js-product-grid-mobile-filter-toggle-sticky',
}

const STATES = {
    Sticky: 'is-sticky',
    Visible: 'is-visible',
    HeaderIsHidden: 'header-is-hidden'
}

const SLIDER_CONFIG = {
    autoWidth: true,
    nav: false,
    arrows: false,
    pagination: false,
    padding: 15,
    focus: 'center'
}

const isMobile = window.matchMedia('(max-width: 767px)');

let mobileCategoriesContainer
let mobileCategories
let mobileFilterToggleContainer
let mobileFilterToggle
let lastScrollTop

export function initInScope($scope) {
    initSlider()

    mobileCategoriesContainer = document.querySelector(SELECTORS.MobileCategoriesContainer)
    mobileCategories = document.querySelector(SELECTORS.MobileCategories)
    mobileFilterToggleContainer = document.querySelector(SELECTORS.MobileFilterToggleContainer)
    mobileFilterToggle = document.querySelector(SELECTORS.MobileFilterToggle)

    window.addEventListener('scroll', throttle(10, onScroll))
    window.addEventListener('resize', () => onResize())
}

function initSlider() {
    Array.from(document.querySelectorAll(SELECTORS.MobileCategoriesSlider)).forEach(sliderElement => {
        const slider = new Splide(sliderElement, SLIDER_CONFIG)

        slider.on('overflow', isOverflow => {
            slider.options = {
                drag: isOverflow,
            }
        })

        slider.mount()
    })
}

function onScroll() {
    if (!isMobile.matches) return

    stickyMobileCategories();
    showHideStickyMobileFilterToggle();
    onScrollDirection(onDownScroll, onUpScroll)
}

function onDownScroll() {
    toggleHeaderIsHiddenState(true)
}

function onUpScroll() {
    toggleHeaderIsHiddenState(false)
}

function onResize() {
    toggleHeaderIsHiddenState(false)
    mobileCategories.classList.remove(STATES.Sticky)
    mobileFilterToggle.classList.remove(STATES.Visible)
}

function toggleHeaderIsHiddenState(trueFalse) {
    if (trueFalse) {
        document.body.classList.add(STATES.HeaderIsHidden)
    } else {
        document.body.classList.remove(STATES.HeaderIsHidden)
    }
}

function stickyMobileCategories() {
    if (!mobileCategoriesContainer || !mobileCategories) return

    if (mobileCategoriesContainer.getBoundingClientRect().top <= 0) {
        mobileCategories.classList.add(STATES.Sticky)
    } else {
        mobileCategories.classList.remove(STATES.Sticky)
    }
}

function showHideStickyMobileFilterToggle() {
    if (!mobileFilterToggleContainer || !mobileFilterToggle) return

    if (mobileFilterToggleContainer.getBoundingClientRect().bottom <= 0) {
        mobileFilterToggle.classList.add(STATES.Visible)
    } else {
        mobileFilterToggle.classList.remove(STATES.Visible)
    }
}

function onScrollDirection(downScrollFunc, upscrollFunc) {
    const scrollTop = window.pageYOffset

    if (scrollTop > lastScrollTop) {
        // downscroll code
        downScrollFunc()
    } else if (scrollTop < lastScrollTop) {
        // upscroll code
        upscrollFunc()
    } // else was horizontal scroll

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
}
