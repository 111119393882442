import {findMenuContainer} from "../../DomFunctions";
import {Tr_LevelContent} from "./Tr_LevelContent";

/**
 * @param {int} level
 */
const _setInitialVisibilities_for_menuLevelContainers = (level) => {
    const menuContainer = findMenuContainer(level)
    const menuTransitionContainer = menuContainer.dataset.menuTransitionContainer ? document.getElementById(menuContainer.dataset.menuTransitionContainer) : menuContainer

    menuTransitionContainer.style.display = 'block'
}

/**
 * @param {int} level
 */
const _setInitialVisibilities_for_menuItems = (level) => {
    const menuContainer = findMenuContainer(level)
    menuContainer.querySelectorAll('[data-menu-id]').forEach(el => el.classList.add(Tr_LevelContent.INITIAL_STATE))
}

export const MenuLevelContainerLayer = Object.freeze({
    /**
     * @param {...int} levels
     */
    init_MenuContainers_and_MenuItems(...levels) {
        levels.forEach(lv => {
            _setInitialVisibilities_for_menuLevelContainers(lv)
            _setInitialVisibilities_for_menuItems(lv)
        })
    }
})