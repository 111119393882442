'use strict'
import { onEnterViewPort } from "../libs/@elements/viewport-utils";

export function initInScope($scope) {
    const $squarelovin = $scope.find('.js-squarelovin');

    if($squarelovin){
        const id = $squarelovin.data('squarelovin-id')
        const type = $squarelovin.data('squarelovin-type') || 'teaser';
        const pid = $squarelovin.data('squarelovin-pid');
        const dataStream = $squarelovin.data('squarelovin-stream') || '5909ae123ee0733012a39f13';

        let pidTemplate = '';
        if(pid) {
            pidTemplate = '&pid=' + pid;
        }

        onEnterViewPort($squarelovin, () => {
            $squarelovin.css('display', 'none'); // hide placeholder; if there is squarelovin content it is set to block again by squarelovin
            $squarelovin.append(
                '<script id="' + id + '"' +
                'type="text/javascript"' +
                'datastream="' + dataStream + '">' +
                '(function() {' +
                    'var lang = "de";' +
                    'var s = document.createElement(\'script\');' +
                    's.type = "text/javascript";' +
                    's.async = true;' +
                    's.src = "https://squarelovin.com/squarelovin.js?stream=' + dataStream + '&t=' + type + pidTemplate + '&l=' + _config.lang + '&u=' + location.href + '";' +
                    'var sc = document.getElementsByTagName("script")[0];' +
                    'sc.parentNode.insertBefore(s, sc);' +
                '})();' +
                '</script>')
        }, {
            offset: window.innerHeight / 2
        })
    }
}