import {preloadNSlide} from '../../libs/@elements/slider';
import {onEnterViewPort} from '../../libs/@elements/viewport-utils';

export function initSliders($sliders, $options) {
    if ($sliders.length === 0) {
        return;
    }

    $sliders.each((_, el) => {
        initSlider($(el), $options);
    });
}

function initSlider($slider, $options) {
    if ($slider.is('.slick-initialized')) {
        // Error source here https://stackoverflow.com/questions/35884752/error-in-slick-js-uncaught-typeerror-cannot-read-property-add-of-null
        // Error source comes from re-initializing slick slider
        // Fix https://stackoverflow.com/questions/32371003/how-to-reload-slick-slider-carousel-jquery-plugin
        $slider.slick('refresh');
    } else {
        $slider.slick({
            ...$options,
        }).on('afterChange', function(event, slick, currentSlide) {
            preloadNSlide(slick, currentSlide + 1,
                $options.slidesToShow);
        });
    }

    onEnterViewPort($slider, function($slider) {
        preloadNSlide($slider.slick('getSlick'), 1,
            $options.slidesToShow);
    });
}
