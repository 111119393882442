'use strict'

import Splide from '@splidejs/splide';

const SELECTOR = {
    sliderModule: '.js-mobile-nav__slider',
};

export function initSliderModules() {
    document.querySelectorAll(SELECTOR.sliderModule).forEach(sliderElement => {
        const splide = new Splide(
            sliderElement, {
                fixedWidth: '125px',
                pagination: false,
                arrows: false,
                gap: 15,
                padding: 30
            })

        splide.mount()
    })
}