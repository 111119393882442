"use strict";

/**
 * returns the size of a container of collapsables
 * sizes:
 *      default:
 *          width
 *          height
 *      uncollapsed:
 *          width
 *          height
 */
export function getCollapsableSize(container) {
    const sizes = {};
    sizes['default'] = {width: container.scrollWidth, height: container.scrollHeight};

    const $container = $(container);

    const containerPositionBefore = container.style.position;
    container.style.position = 'absolute';

    const $collapsedBefore = []
    $container.find('.collapse:not(.show').each(function(idx, el){
        const $el=$(el);
        $collapsedBefore.push($el);
        $el.addClass('show');
    });

    sizes['uncollapsed'] = {width: container.scrollWidth, height: container.scrollHeight};

    for (const el of $collapsedBefore) {
        $(el).removeClass('show');
    }

    container.style.position = containerPositionBefore;

    return sizes;
}
