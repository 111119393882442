'use strict';

import {setupThrottledClickDoubleClick} from "../../../../../../../shared/events/dbl_click/setupThrottledClickDoubleClick";
import {Executor} from "../core/TransitionTree/Executor";
import {MainMenu_ScenarioData} from "./ScenarioData";
import {MainMenu_ScenarioMatching} from "./scenarioMatching";
import {BasicScenarioFinder} from "../core/TransitionTree/BasicScenarioFinder";

const _initDropdown = (callback, menuName) => {
    const menuElement = document.getElementById(menuName)
    if (!menuElement) {
        return
    }
    const button = menuElement.querySelector(`[data-dropdown--open-button]`);
    setupThrottledClickDoubleClick(button, () => {
        callback(menuName)
    })
}

export const MainMenuTriggers = Object.freeze({

    initDropdownMenus: (dropdowns, callback) => {
        dropdowns.forEach(menuName => {
            _initDropdown(callback, menuName)
        })
    },

    /**
     * @param {MainState} state
     * @param {MainMenu_ScenarioData} scenarioData
     * @return {Promise<void>}
     */
    trigger(state, scenarioData) {
        return Executor.handle(
            MainMenu_ScenarioMatching.findScenario,
            state,
            scenarioData,
            []
        )
    },

    open: (state, scenarioData) => {
        return Executor.handleScenario(
            MainMenu_ScenarioMatching.findScenario(state, scenarioData, true),
            state,
            scenarioData,
            []
        );
    },

    close: (overlay, state) => Executor.handleScenario(
        MainMenu_ScenarioMatching.getScenario(BasicScenarioFinder.MENU_TRIGGER_TYPES.CLOSE),
        state,
        new MainMenu_ScenarioData(overlay, '', null, null),
        []
    )
})
