import {CSSTransitionsHelper} from "../../../../../../../../../shared/events/transitions/CSSTransitionsHelper";

const DISPLAY_CLASS = 'display-on'

const TRANS_STATES = {
    INACTIVE: 'inactive',
    ACTIVATING: 'activating',
    ACTIVE: 'active',
    DEACTIVATING: 'deactivating',
}

const _getBaseConfig = (element, useTransition, isTransitionCanceled) => ({
    useTransition,
    isCanceledFunc: isTransitionCanceled,
    transitioningElement: element,
    stateElement: element,
    clearCssClasses: Object.values(TRANS_STATES),
})

const _buildActivatingConfig = (element, useTransition, isTransitionCanceled) => ({
    ..._getBaseConfig(element, useTransition, isTransitionCanceled), ...{
        beginTransCssClass: TRANS_STATES.ACTIVATING,
        endTransCssClass: TRANS_STATES.ACTIVE,
        setDisplayCssClass: DISPLAY_CLASS,
    }
})

const _buildDeactivatingConfig = (element, useTransition, isTransitionCanceled) => ({
    ..._getBaseConfig(element, useTransition, isTransitionCanceled), ...{
        beginTransCssClass: TRANS_STATES.DEACTIVATING,
        endTransCssClass: TRANS_STATES.INACTIVE,
        unsetDisplayCssClass: DISPLAY_CLASS,
    }
})

export const Tr_ImageTiles_ActiveItem = Object.freeze({

    INITIAL_STATE: TRANS_STATES.INACTIVE,

    /** @type {TransitionInfo} */
    activate: {
        /**
         * @param {HTMLElement} menuElement
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (menuElement, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_buildActivatingConfig(menuElement, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.ACTIVATING,
        endState: TRANS_STATES.ACTIVE,
        debugDescription: 'ImageTiles\tActivate',
    },

    /** @type {TransitionInfo} */
    deactivate: {
        /**
         * @param {HTMLElement} menuElement
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (menuElement, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_buildDeactivatingConfig(menuElement, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.DEACTIVATING,
        endState: TRANS_STATES.INACTIVE,
        debugDescription: 'ImageTiles\tDeactivate',
    },

})

