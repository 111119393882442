'use strict'
import { addLoadingContainer, removeLoadingContainer } from "./loading";
import * as wishlistbundle from '../libs/@elements/wishlist';
import { translate } from "../../../shared/translations/translations"
let compareList = null;
let compareListIsInitialized = false;

if (window['_compareList']) {
    if(window['_compareList'].compareList) {
        compareList = wishlistbundle.createWishlist(
            {
                ...window['_compareList'].compareList,
                notificationOptions: {
                    $container: $('.js-compare-list__notification')
                },
                translations: {
                    add: translate('compareList.add'),
                    remove: translate('compareList.remove')
                }
            },{
                item: '.js-compare-list__btn',
                itemId: 'compare-list-id',
                wishlistId: 'compare-list-list',
                itemActiveClass: 'is-active'
            }
        );
    }
}

export function init() {
    let activeIds = null;
    $(document).on('renderd.wishlist', (e ,params) => {
        if(params.list === "compare-list-list"){
            if(params.activeIds && activeIds !== params.activeIds.length){
                activeIds = params.activeIds.length;
                let $badge =  $('.js-compare-product__count');
                if(activeIds > 0){
                    $('.js-compare__anchor').show();
                    $badge.removeClass('is-active');
                    $badge.html(activeIds)
                    $badge.addClass('is-active');
                } else{
                    $('.js-compare__anchor').hide()
                    $badge.html("")
                }
            }
        }
    })

    if (compareList) {
        compareList.init();
    }

}

export function initInScope($scope) {
    if(compareList){
        compareList.initInScope($scope);
        if(!compareListIsInitialized){
            compareListIsInitialized = true;

            const $compareContainer = $scope.find('.js-compare-product__container');

            $compareContainer.each((_,el) => {
                initCompareContainer($(el));
            })

            const $modal = $('.modal.modal--compare')
            if($modal.length){
                $modal.on('show.bs.modal', () => {
                    $modal.find('.js-ajax-form__form').trigger('submit');
                })
            }
        }

        const $compareRemoveBtn = $('.js-compare-list__remove');
        $compareRemoveBtn.each((_,el) => {
            const
                $btn = $(el),
                id = $btn.data('compare-list-id'),
                $container = $btn.closest('.js-ajax-form')

            $btn.on('click', (e) => {
                e.preventDefault();

                addLoadingContainer($container, [true, false])

                let _request = compareList.remove(id, {showUndo: true});
                _request.then((res) => {
                  return res.clone().json();
                }).then(function (res) {
                    if(res.success){
                        $container.find('.js-ajax-form__form').trigger('submit');
                    }else{
                        removeLoadingContainer($container)
                    }
                }).catch(()=> {
                    removeLoadingContainer($container)
                })
            })
        })
    }
}

function initCompareContainer($container) {
    const
        $form = $container.find('.js-ajax-form');

    $form.on('success.ajax-form', () => {
        removeLoadingContainer($form)
    })

}