'use strict';
import * as stdSlider from '../../libs/@elements/slider';


export function initInScope($scope){

    let options;
    let $sliders = $scope.find('.js-stdSlider');

    $sliders.each(function (index, slider) {
        $(window).on('resize', function() {
            $(slider).hasClass('slick-initialized')
            if( $(window).width() > 767 &&  !$(slider).hasClass('slick-initialized')) {
                options = getOptions(slider);
                stdSlider.createInitInScope(slider, options )($scope);
            }
        });

        options = getOptions(slider);
        stdSlider.createInitInScope(slider, options )($scope);
    });

    let $repaintElements = $('.js-stdSlider ').find('.js-repaint');
    for(let i = 0; i < $repaintElements.length; i++){
        $repaintElements[i].style.display = 'none';
        $repaintElements[i].offsetHeight;
        $repaintElements[i].style.display = '';

        setTimeout(function () {
            $repaintElements[i].style.display = 'none';
            $repaintElements[i].offsetHeight;
            $repaintElements[i].style.display = '';
        }, 5000)
    }
}

function getOptions(slider) {
    return {
        nextArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-next" aria-label="Next"><i class="icon icon-Pfeil-nachoben rotate-right"></i></button>',
        prevArrow: '<button type="button" class="btn btn-icon btn-unstyled slick-arrow slick-prev" aria-label="Previous"><i class="icon icon-Pfeil-nachoben rotate-left"></i></button>',
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        adaptiveHeight: matchMedia('(max-width: 767px)').matches
    }
}
