'use strict'
import { getInputState, reloadGrid, getBasePath } from '../../libs/@elements/product-grid'
import throwError from '../../libs/@elements/throw-error'
import { translate } from '../../../../shared/translations/translations'
import {getLoadingContainerSmall} from "../../scripts/loading";
import fetch from "../../libs/@elements/fetch";
import asyncAppend from "../../libs/@elements/async-append";
import { closeOverlay } from "../../scripts/overlay"

let $productGrid;
let filterURL;

export function init() {
    $productGrid = $('body').find('.js-product-grid').length === 1 ? $('body').find('.js-product-grid') : throwError(translate('product grid init error: no product grid forund!'))
    filterURL = _config.productGridFilterURL ? _config.productGridFilterURL :  throwError(translate('_config.productGridFilterURL missing!'))

    setInitialAdditionalState()
}

function setInitialAdditionalState($scope = $('body')) {
    let values = [];
    const $filterContainer      = $scope.find('.js-product-grid-filter-mobile__form-container');

    if(!$filterContainer.length){
        return;
    }

    const $additionalInputs     = getChangeInputElements($filterContainer);
    $additionalInputs.each((_, el) => {
        const $input = $(el);
        if($input.is(':checkbox, :radio')){
            if($input.is(':checked')){
                values.push({name: $input.attr('name'), value: $input.val()})
            }
        }else{
            if($input.val()){
                values.push({name: $input.attr('name'),value: $input.val()})
            }
        }
    })

    const $submitFilterInputs   = getSubmitFilterInputs($filterContainer)
    $submitFilterInputs.each((_, el) => {
        const $input = $(el);
        if($input.is(':checkbox, :radio')){
            if($input.is(':checked')){
                values.push({name: $input.attr('name'), value: $input.val()})
            }
        }else{
            if($input.val()){
                values.push({name: $input.attr('name'),value: $input.val()})
            }
        }
    })

    setValues(values, true);
}

export function initInScope($scope) {
    setInitialAdditionalState($scope)

    const
        $filter                 = getChangeInputElements($scope),
        $submitFilterBtn        = getSubmitFilterBtn($scope),
        $submitFilterInputs     = getSubmitFilterInputs($scope),
        $submitBtn              = $scope.find('.js-product-grid-filter-mobile__submit'),
        $mobileNotifications    = $scope.find('.js-product-grid-filter-mobile__notifications'),
        $removeFilterBtn        = $scope.find('.js-product-grid__remove-input');

    let filterd = false;
    let productGridState = [];

    if($removeFilterBtn.length){
        $removeFilterBtn[0].addEventListener('click', () => {
            removeAllInputs();
        }, true)
    }

    $filter.on('change', function(e) {
        const $input = $(this);

        if($input.is(':checkbox, :radio')){
            if($input.is(':checked')){
                setValue($input.prop('name'), $input.val())
            }else{
                removeValue($input.prop('name'), $input.val())
            }

        }else{
            setValue($input.prop('name'), $input.val());
        }

        if(window.matchMedia('(max-width: 767px)').matches){
            filterd = true;
            getFilterResults();
            $submitBtn.toggleClass('d-none', !productGridHasActiveFilterValue())
        }else{
            reloadGrid($productGrid);
        }
    });

    $submitFilterInputs.on('change', function (e) {
        const $input = $(this);


        if($input.is(':checkbox, :radio')){
            if($input.is(':checked')){
                setValue($input.prop('name'), $input.val())
            }else{
                removeValue($input.prop('name'), $input.val())
            }

        }else{
            setValue($input.prop('name'), $input.val());
        }

        if(window.matchMedia('(max-width: 767px)').matches){
            filterd = true;
            getFilterResults();
            $submitBtn.toggleClass('d-none', !productGridHasActiveFilterValue())
        }else{
            //if this line is added, it would work without the submit in desktop resolutions
            // reloadGrid($productGrid);
        }
    })

    $submitFilterBtn.on('click', () => {
        reloadGrid($productGrid);
    })

    $submitBtn.on('click', () => {
        closeOverlay('product-grid-filter-sidebar');
        if(filterd){
            reloadGrid($productGrid);
        }
    })

    let pendingRequest = null;

    function getFilterResults() {
        if(pendingRequest){
            pendingRequest.abort();
            pendingRequest = null;
        }

        productGridState = [].concat(getInputState($productGrid)).concat({name: 'mobileResultsCount', value: true});

        productGridState.push({
            name: '_original_href',
            value: getBasePath($productGrid)
        });

        const payload = productGridState.reduce((result, item) => {
            if(item && item.value){
                result.push([item.name , item.value]);
            }
            return result;
        }, [])

        const requestBody = new URLSearchParams(payload);

        let ajaxUrl = $productGrid.data('product-grid-ajax-url') || filterURL;
        ajaxUrl += '&' + requestBody.toString();

        asyncAppend({
            $target: $submitBtn,
            $loading:  $(getLoadingContainerSmall()).appendTo($submitBtn) ,
            $notifications: $mobileNotifications
        }, pendingRequest = fetch(ajaxUrl, {
            method: 'POST',
            body: requestBody
        }))


    }

    function productGridHasActiveFilterValue() {
        let activeFilterNames = [];

        productGridState.forEach(productGridStateFilter => {
            if (productGridStateFilter.name.indexOf('pgf-') !== 0 || productGridStateFilter.value !== 'true') return;
            activeFilterNames.push(productGridStateFilter.name.replace('pgf-', ''));
        });

        if (!activeFilterNames.length) return false

        return productGridState.some(productGridStateFilter => {
            if (!activeFilterNames.includes(productGridStateFilter.name.replace('[]', ''))) return false;
            return (productGridStateFilter.value);
        });
    }
}



function removeAllInputs() {
    let inputState = $productGrid.data('additional-input-state');
    if(!inputState || inputState === []){
        return
    }

    for(let i = 0; i < inputState.length; i++){
        if(inputState[i].value !== null){
            removeValue(inputState[i].name, inputState[i].value)
        }
    }
}

/**
 * overrides setValues function from ProductGridBundle because at the time of writing
 * this code it was buggy and I was told to leave it that way.
 */
export function setValues(values, clear = false) {
    let inputState = [];
    if(!clear){
        inputState = $productGrid.data('additional-input-state') || [];
    }

    values.forEach(function (currentValue) {
        let existingValue = (inputState.filter(function (x) {
            if(currentValue.name === x.name){
                if(currentValue.name.toString().slice(-2) !== '[]'){
                    return true;
                }
            }
        })[0]);

        if (existingValue) {
            existingValue.value = currentValue.value;
        } else {
            inputState.push(currentValue);
        }
    });

    $productGrid.data('additional-input-state', inputState);
}

/**
 * same for reason to make this code as setValues
 */
export function setValue (name, value, text) {
    setValues( [{
        name: name,
        value: value,
        text: text
    }]);
}

/**
 * additional function to remove the checkbox value to fix the issue.
 */
export function removeValue(name, value) {
    let inputState = $productGrid.data('additional-input-state') || [];

    if(name.startsWith("pgf")){
        return;
    }

    inputState = inputState.reduce(function (array, x) {
        if (x.name.slice(-2) === '[]') {
            if ((x.name === name && x.value === value)) {
                if (!inputState.find(x => x.name === name && x.value === null)) {
                    array.push({
                        name: x.name,
                        value: null
                    });
                }
            } else {
                array.push(x);
            }
        } else {
            array.push(x.name === name ? {
                name: x.name,
                value: null
            } : x);
        }

        return array;
    }, []);

    $productGrid.data('additional-input-state', inputState);
}

/* get all inputs that trigger a reaload on change*/
function getChangeInputElements($scope) {
    return $scope.find(
        '.js-product-grid-filter-mobile__form input, ' +
        '.js-product-grid-filter-mobile__form select'
    ).not(
        '.js-product-grid__submit-section .js-product-grid__input, ' +
        '.js-product-grid__submit-section input, ' +
        '.js-product-grid__submit-section select' +
        '.js-product-grid__mobile-submit-section .js-product-grid__input, ' +
        '.js-product-grid__mobile-submit-section input, ' +
        '.js-product-grid__mobile-submit-section select, ' +
        '.js-product-grid__mobile-filter-ignore'
    );
}

function getSubmitFilterInputs($scope) {
    return $scope.find(
        '.js-product-grid__mobile-submit-section input, ' +
        '.js-product-grid__mobile-submit-section select'
    )
}

function getSubmitFilterBtn($scope) {
    return $scope.find(
        '.js-product-grid__mobile-submit-section .product-grid__submit-section-btn'
    )
}
