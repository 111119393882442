'use strict';

import * as importedLottie from 'lottie-web';
import {onEnterViewPort} from '../libs/@elements/viewport-utils';

class Lottie {
    constructor(_lottiePlayer) {
        this.player = _lottiePlayer;
    }

    loadLottie(el) {
        if (!('lottiePath' in el.dataset)) {
            return null;
        }

        const config = {
            container: el,
            renderer: 'svg',
        };

        config.loop = 'lottieLoop' in el.dataset ?
            el.dataset.lottieLoop === 'true' :
            true;
        config.autoplay = 'lottieAutoplay' in el.dataset ?
            el.dataset.lottieAutoplay === 'true' :
            true;
        config.path = el.dataset.lottiePath;

        return this.player.loadAnimation(config);
    }

}

const player = new Lottie(importedLottie);

export function initInScope($scope) {
    $scope = jQuery($scope).find('.lottie').addBack('.lottie');

    const allAnimations = [];

    $scope.each(function(idx, el) {
        const isGroupMember = 'lottieGroup' in el.dataset;
        const isGroupLeader = 'lottieGroupLeader' in el.dataset
            && el.dataset.lottieGroupLeader === 'true';

        if (isGroupMember && !isGroupLeader) {
            return;
        }

        const groupElements = isGroupLeader ?
            findGroupMembers($scope, el.dataset.lottieGroup)
            : [el];

        const animationsData = [];
        for (let groupElement of groupElements) {
            const animationData = addAnimation(groupElement);
            animationsData.push(animationData);
            allAnimations.push(animationData.animation);
        }

        setupDelayedGroup(el, animationsData);
    });

    return allAnimations;
}

function addAnimation(el) {
    const animation = player.loadLottie(el);
    return {
        el: el,
        animation: animation,
        delay: $(el).data('lottie-delayed-start'),
    };
}

let cTimerIdx = 0;
function getNewTimerIdx() {
    cTimerIdx += 1;
    return cTimerIdx;
}

function stopAll(animationsData) {
    for (const animationData of animationsData) {
        if (animationData.animation) {
            animationData.animation.goToAndStop(0);
        } else {
            animationData.el.classList.remove('show')
        }
    }
}

function setupDelayedGroup(groupLeader, animationsData) {
    onEnterViewPort($(groupLeader), () => {
        const newTimerIdx = getNewTimerIdx()
        groupLeader.dataset.currentTimerIdx = newTimerIdx;

        for (const animationData of animationsData) {
            setupStartOfElement(
                animationData.el,
                groupLeader,
                newTimerIdx,
                animationData.animation,
                animationData.delay);
        }
    },{
        triggerOnlyOnce: false,
    }, () => {
        groupLeader.dataset.currentTimerIdx = getNewTimerIdx();

        stopAll(animationsData);
    });
}

function setupStartOfElement(el, groupLeader, timerIdx, animation, delay) {
    if (delay <= 0) {
       startItem(el, animation)
       return;
    }

    window.setTimeout((el, animation, groupLeader, timerIdx) => {
        if (timerIdx !== parseInt(groupLeader.dataset.currentTimerIdx)) {
            return;
        }
        startItem(el, animation)
    }, delay * 1000, el, animation, groupLeader, timerIdx);
}

function findGroupMembers($scope, group) {
    const elements = [];
    $scope.filter('[data-lottie-group=' + group + ']').each(function (idx, el){
        elements.push(el);
    })
    return elements;
}


function startItem(el, animation) {
    try {
        if (animation) {
            animation.play()
        } else {
            el.classList.add('show')
        }
    } catch (_) {}
}