'use strict'
import { default as throwError } from "../../../libs/@elements/throw-error";

let $scriptTag = [];
let $sizeInput;
let sizeEAN = undefined;

const OUTTRA_TAG_GTIN_ATTR = 'data-gtins'

export function init($changeSize) {
    if (!$changeSize || !$changeSize.length) return

    $changeSize.each(function() {
        $(this).change(function() {
            if (!$scriptTag.length) {
                $scriptTag = $('#outtra-script-tag')
                if (!$scriptTag.length) return
            }

            $sizeInput = $(this).find('.custom-radio__input')

            if (!$sizeInput.length) return

            sizeEAN = $sizeInput.data('size-ean')

            if (!sizeEAN) return

            $scriptTag.attr(OUTTRA_TAG_GTIN_ATTR, sizeEAN)
        })
    })
}
