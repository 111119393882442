'use strict'

//pause videos when lightbox [=youtube video] is opened, and resume when it is closed
export function initInScope($scope) {
    $scope.find('.js-hero-slider__slide > .hero-slider__lightbox').each(function (idx, lightbox) {
        const $lightbox = $(lightbox);

        $lightbox.on('onBeforeOpen.lg', function () {
            $("video").each(function (idx, video) {
                if (!video.paused) {
                    video.dataset.heroSliderContinueVideo = true;
                    video.pause();
                }
            });
        });

        $lightbox.on('onBeforeClose.lg', function () {
            $("video").each(function (idx, video) {
                if (video.dataset.heroSliderContinueVideo) {
                    delete video.dataset.heroSliderContinueVideo;
                    video.play();
                }
            });
        });
    })
}
