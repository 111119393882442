'use strict';

/** @type {NavigationState} */
let _obj

export const LevelMenuStateObject = Object.freeze({
    get() {
        return _obj
    },
    set(val) {
        _obj = val
    },
})