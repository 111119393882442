import {cachedFetches} from "../cached-fetches/cached-fetches";

const maxTries = 20;

export function hideByLoginState(container = document.body) {
    cachedFetches.get('is_user_logged_in').then((isLoggedIn)=> __hideByLoginState(isLoggedIn, container, 0)).catch(()=>{})
}

/**
 * sometimes the JS loads before the DOM is loaded; IDKW but waiting for window.load doesn't work either
 */
function __hideByLoginState(isLoggedIn, container = document.body, tryCount) {
    const elements = container.querySelectorAll(':scope [data-hide-by-login-state]')
    if (!elements.length && tryCount < maxTries) {
        window.setTimeout(()=> __hideByLoginState(isLoggedIn, container, tryCount + 1), 100);
    }
    elements.forEach((element) => {
        try {
            let hide = false;
            let states = element.dataset.hideByLoginState.toLowerCase().split(',');
            hide |= states.includes('logged-in');
            hide |= states.includes('not-logged-in');
            if (hide) {
                element.setAttribute('hidden', '');
            }
        }
        catch(err) {
            console.log(err);
        }
    })
}
