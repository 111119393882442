'use strict';

/**
    Set scroll target (sorted by precedence):
    a) by href attribute if the element is a link
         <a href="#target" data-js-scrollto>

    b) by manually setting a scrolltarget
        <div data-js-scrollto-target="contentTarget">

    c) by making the next pimcore content the target
        <a data-js-scrollto-next-content>

    If the linking element is an anchor the href attribute will be set automatically.
 **/

const menubarOffset = window.matchMedia('(max-width: 991px)').matches ? 75 : 100;

const getOffsetTop = (element) => {
    return !element.offsetParent ? 0 : getOffsetTop(element.offsetParent) + element.offsetTop;
}

export function initInScope($scope){
    const scope = $scope[0]
    const links = scope.querySelectorAll(':scope [data-js-scrollto], :scope [data-js-scrollto-target], :scope [data-js-scrollto-next-content]');
    links.forEach(link=> {
        let _target
        try {
            _target = findTarget(link);
        } catch (e) {
            console.warn(e, link)
            return;
        }

        const fromAnchorToAnchor = _target instanceof HTMLAnchorElement && _target.id && link instanceof HTMLAnchorElement
        if (fromAnchorToAnchor) {
            link.setAttribute('href', '#' + _target.id)
        }

        link.addEventListener('click', e=>{
            const targetOffset = getOffsetTop(_target) - menubarOffset

            e.preventDefault()
            e.stopImmediatePropagation()

            if (fromAnchorToAnchor) {
                window.history.pushState({}, "", '#' + _target.id)
            }

            window.scroll({
                behavior: 'smooth',
                top: targetOffset,
                left:0,
            })
        })
    })
}

function findTarget(link) {
    const _href = link.getAttribute('href')
    let _target;
    if (_href) {
        _target = document.getElementById(_href.replace(/^#/i, ''))
    }

    else if ('jsScrolltoTarget' in link.dataset) {
        _target = document.getElementById(link.dataset.jsScrolltoTarget)
    }

    else if ('jsScrolltoNextContent' in link.dataset) {
        _target = link.closest('.pimcore_area_content').nextElementSibling
    }

    if (_target) {
        return _target
    }

    throw new Error('no target element found')
}