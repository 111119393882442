import {StateWithPath} from "./state/StateWithPath";
import {PATH_DEBUG_IS_CLONE} from "./state/cloneState";

const ENABLED = false

/**
 * @param {string} funcName
 * @param {NavigationDebugInfo} debugInfo
 * @param rest
 * @param {...*} rest
 */
const log = (funcName, debugInfo, ...rest) => {
    if (!debugInfo || !ENABLED) {
        return
    }

    if (StateWithPath.getValue(debugInfo.realState, PATH_DEBUG_IS_CLONE)) {
        return
    }

    console[funcName](
        debugInfo.timestamp,
        '\t',
        debugInfo.scenario.padEnd(16, ' '),
        '\t',
        ...rest
    )
}

export const navilog = (debuginfo, ...rest) => {
    log('log', debuginfo, ...rest)
}
export const navierr = (debuginfo, ...rest) => {
    log('error', debuginfo, ...rest)
}

export class NavigationDebugInfo {
    constructor(scenario, scenarioData, realState) {
        this.timestamp = undefined
        this.scenario = scenario.replace(/toplevel/, '  ⌞ toplevel');
        this.scenarioData = scenarioData;
        this.realState = realState
    }
}

export const ifDebugNavigaton = func => {
    if (ENABLED) {
        func()
    }
}
