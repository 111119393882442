'use strict';


import {MainMenuTriggers} from "../../shared/MainMenu/MainMenuTriggers";
import {Mobile_MainMenuStateObject} from "./Mobile_MainMenuStateObject";
import {MainMenu_ScenarioData} from "../../shared/MainMenu/ScenarioData";
import {Tr_MobileOverlay} from "../Overlay";
import * as R from "ramda";
import {Tr_DropdownMenu} from "../../shared/Tr_DropdownMenu";
import {DOM_MainMenuItemCache} from "../../shared/MainMenu/DOM_MainMenuItemCache";

export const MOBILE_MAIN_MENUS__DROPDOWNS = {
    MOBILE_DROPDOWN_NAME__SEARCH: 'mobinav__search',
    MOBILE_DROPDOWN_NAME__CART: 'mobinav__cart',
}

export const MOBILE_MAIN_MENUS = {
    ...MOBILE_MAIN_MENUS__DROPDOWNS,
    MOBILE_HAMBURGERMENU: 'mobile-nav__hamburger-menu',
}


const _createDropdownScenarioData = (menuName) => {
    const menuContainer = DOM_MainMenuItemCache.getMenu(menuName)
    return new MainMenu_ScenarioData(
        Tr_MobileOverlay,
        menuName,
        R.curry(Tr_DropdownMenu.open)(menuContainer)(true),
        R.curry(Tr_DropdownMenu.close)(menuContainer)(true)
    )
}

/**
 * Adapter for Mobile & MainMenuTriggers
 */
export const Mobile_MainMenuTriggers = Object.freeze({

    initDropdownMenus: (callback) => MainMenuTriggers.initDropdownMenus(
        Object.values(MOBILE_MAIN_MENUS__DROPDOWNS),
        callback
    ),

    trigger: (menuName, openFunc, closeFunc) => MainMenuTriggers.trigger(
        Mobile_MainMenuStateObject.get(),
        new MainMenu_ScenarioData(Tr_MobileOverlay, menuName, openFunc, closeFunc)
    ),

    open: (menuName, openFunc, closeFunc) => MainMenuTriggers.open(
        Mobile_MainMenuStateObject.get(),
        new MainMenu_ScenarioData(Tr_MobileOverlay, menuName, openFunc, closeFunc)
    ),

    close: () => MainMenuTriggers.close(
        Tr_MobileOverlay,
        Mobile_MainMenuStateObject.get(),
    ),

    triggerDropdown: (menuName) => MainMenuTriggers.trigger(
        Mobile_MainMenuStateObject.get(),
        _createDropdownScenarioData(menuName)
    ),

    openDropdown: (menuName) => MainMenuTriggers.open(
        Mobile_MainMenuStateObject.get(),
        _createDropdownScenarioData(menuName)
    ),

})
