'use strict'

import fetch from '../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill
import initModulesInScope from "../libs/@elements/init-modules-in-scope";
import throwError from "../libs/@elements/throw-error"
import { addLoadingContainer, removeLoadingContainer } from "./loading";
import {showNotification} from "../libs/@elements/alert-notification";

let $modal;



export function init($scope = $('body')) {
    if (!$scope.find('.js-ajax-modal').length) {
        if (console && console.warn) console.warn("Ajax Modal Container Missing - But no module was found");
        return;
    }

    $modal = $scope.find('.js-ajax-modal')
}

export function initInScope($scope) {
    const
        $modalBtn =     $scope.find('.js-ajax-modal__btn,[data-lightbox-modal]'),
        $modalContainer = $scope.find('.js-ajax-modal__content');


    let pendingRequest;

    $modalBtn.each((id, el)=> {
        el.addEventListener('click', (e) => {
            let url = $(e.currentTarget).data('url') ? $(e.currentTarget).data('url') : $(e.currentTarget).attr('href');
            const useNewLoadingIcon = $(e.currentTarget).data('new-loading-icon') ? $(e.currentTarget).data('new-loading-icon') : false;

            if (url) {
                e.preventDefault()
            } else {
                return;
            }

            emptyModal();
            addLoadingContainer($modalContainer, [false, false, useNewLoadingIcon]);

            $modal.modal('show');

            if (pendingRequest) {
                pendingRequest.abort();
                pendingRequest = null;
            }

            pendingRequest = fetch(url, {
                method: 'GET'
            });

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                if (res.success) {
                    if (res.html) {
                        const $response = $(res.html);
                        $modalContainer.html($response);
                        initModulesInScope($modalContainer);
                    }
                } else {
                    $modal.one('shown.bs.modal', () => {
                        $modal.modal('hide');
                    })
                }
            }).finally(() => {
                removeLoadingContainer($modalContainer);
            }).catch(() => {
                $modal.one('shown.bs.modal', () => {
                    $modal.modal('hide');
                })
            })

            showNotification(pendingRequest);
        });
    })
}

function emptyModal() {
    if($modal.length){
        $modal.find('.js-ajax-modal__content').empty().css({border: 'none'});
    }else{
        throwError("Ajax Modal Container Missing - But no module was found")
    }
}