'use strict'

import $ from 'jquery';
import fetch from '../../../libs/@elements/fetch'; // IE10 Polyfill
import 'url-search-params-polyfill'; // Edge Polyfill
import initModulesInScope from "../../../libs/@elements/init-modules-in-scope";
import throwError from "../../../libs/@elements/throw-error"
import {showNotification} from "../../../libs/@elements/alert-notification";
import { addLoadingContainer, removeLoadingContainer } from "../../../scripts/loading";
import formDataEntries from 'form-data-entries';

const selectors = {
    aliasModal:       '.js-ajax-edit-product-alias__modal',
    aliasForm:        '.js-ajax-edit-product-alias__form',
    aliasText:        '.js-ajax-edit-product-alias__text',
    deviceId:         (deviceId) => `[data-device-id="${deviceId}"]`,
    loadingContainer: '.modal-content'
}

const attributes = {
    id:         'id',
    action:     'action',
    method:     'method'
}

const ALIAS_MODAL_DEVICE_ID_PREFIX = 'modal-edit-product-alias-';

export function initInScope() {
    const aliasModalElems = document.querySelectorAll(selectors.aliasModal) ?? [];

    if (!aliasModalElems.length) return;

    Array.prototype.slice.call(aliasModalElems).forEach(aliasModalElem => {
        initAliasModal(aliasModalElem);
    })
}

function initAliasModal(aliasModalElem) {
    const deviceId = getDeviceId(aliasModalElem);

    if (!deviceId) throw new Error('1669805191 – AJAX edit product alias: Could not determ device id');

    const
        aliasTextElems   = getAliasTextElems(deviceId),
        aliasForm        = aliasModalElem.querySelector(selectors.aliasForm),
        loadingContainer = aliasModalElem.querySelector(selectors.loadingContainer);

    if (!aliasForm) throw new Error('1669805110 – AJAX edit product alias: No form element found');

    let
        pendingRequest,
        formData,
        action = aliasForm.getAttribute(attributes.action),
        method = aliasForm.getAttribute(attributes.method);

    aliasForm.addEventListener('submit', (e) => {
        if (action) {
            e.preventDefault();
        } else {
            return;
        }

        formData = new URLSearchParams(formDataEntries(aliasForm))

        if(pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }

        addLoadingContainer($(loadingContainer), [true, false]);

        pendingRequest = fetch(action, {
            method: method,
            body: formData
        });

        pendingRequest.then((res) => {
            return res.clone().json();
        }).then((res) => {
            if(res.success){
                if (res.data && res.data.alias) {
                    updateAliasTextElemsText(aliasTextElems, res.data.alias)
                }
            } else {
                throw new Error(`1669804880 – AJAX edit product alias: ${res.msg}`);
            }
        }).finally(() => {
            removeLoadingContainer($(loadingContainer));
            $(aliasModalElem).modal('hide');
        }).catch(err => {
            console && console.error && console.error(err)
            $(aliasModalElem).modal('hide');
        })

        showNotification(pendingRequest);
    });
}

function getDeviceId(aliasModalElem) {
    const elemId = aliasModalElem.getAttribute(attributes.id);
    return elemId.replace(ALIAS_MODAL_DEVICE_ID_PREFIX, '');
}

function getAliasTextElems(deviceId) {
    return Array.prototype.slice.call(document.querySelectorAll(`${selectors.deviceId(deviceId)}${selectors.aliasText}`));
}

function updateAliasTextElemsText(aliasTextElems, newText) {
    aliasTextElems.forEach(aliasTextElems => aliasTextElems.innerText = newText);
}
