"use strict";

export function initInScope ($scope) {
    $scope.find('.js-link-with-input__link').on('click',function (e) {
        e.preventDefault();

        if ($(this).attr('data-bs-toggle') == 'tab') {
            $(this).tab('show')
        }

        let inputValue = $(this).attr('data-value');
        $(this).closest('.js-link-with-input').find('.js-link-with-input__input').val(inputValue);
        $(this).closest('.js-link-with-input').find('.js-link-with-input__link').removeClass('active');
        $(this).addClass('active');
    });
}