'use strict';

import {TAnyNode, TResolvedNode, TUnresolvedItem, TUnresolvedNode} from "./NodesClasses";
import {NumberOfExecutionsExceeded} from "./ExecutionLimiter";
import {TNodeHelper} from "./TNodeHelper";
import {TAction} from "./TAction";

const MSG_ERRORS_OCCURRED = 'navigation: while executing in parallel, error(s) occurred:';

/**
 * normal errors will be printed to console
 * NumberOfExecutionsExceeded will throw and break the loop
 * @param {PromiseSettledResult[]} promises
 */
const _handlePromiseResults = promises => {
    const rejected = promises.filter(p => p.status !== 'fulfilled')
    const firstExecMax_Error = promises.find(p => p.reason instanceof NumberOfExecutionsExceeded)
    const other_reasons = rejected.filter(p => !(p.reason instanceof NumberOfExecutionsExceeded) && !(p.reason instanceof TransitionCanceledError))

    other_reasons.forEach(result => console.error(MSG_ERRORS_OCCURRED, result.reason))

    if (firstExecMax_Error) {
        throw firstExecMax_Error
    }
};

/**
 * @param {Executor} executor
 * @param {TAnyNode} rootNode
 * @return {Promise<void>}
 */
const _executeTAnyNode = async (executor, rootNode) => {
    /** @type {undefined|TAnyNode} */
    let currentNode = rootNode

    /** @type {undefined|TAnyNode} */
    let previousNode = undefined

    do {
        if (executor.isTransitionCanceled()) {
            return
        }

        if (currentNode instanceof TUnresolvedNode) {
            // replaces the current node with the resolved chain; the local currentNode gets updated so the loop is able to directly continue
            currentNode = TNodeHelper.replaceWithChain(previousNode, currentNode, executor.resolve(currentNode))
        }

        if (currentNode instanceof TResolvedNode) {
            const promises = await Promise.allSettled(currentNode.parallelExecutedNodesAndActions.map(el => _executeAny(executor, el, currentNode)))

            _handlePromiseResults(promises)
        }

        previousNode = currentNode
        currentNode = currentNode.nextNode
    } while (currentNode)
}

/**
 * @param {Executor} executor
 * @param {TUnresolvedItem} item
 * @param {TResolvedNode} parentNode
 */
const _executeUnresolvedItem = (executor, item, parentNode) => {
    const maybeUnresolved = executor.resolve(item)
    if (maybeUnresolved instanceof TUnresolvedItem) {
        return
    }
    const resolved = maybeUnresolved

    parentNode.parallelExecutedNodesAndActions = parentNode.parallelExecutedNodesAndActions.map(
        el => (el !== item) ? el : resolved
    ).filter(x => !!x)

    if (!resolved) { //no action to be done
        return
    }

    return _executeAny(executor, resolved)
};

/**
 * @param {Executor} executor
 * @param {TAction} tAction
 */
const _executeTAction = async (executor, tAction) => executor.executeAction(tAction)

/**
 * order matters!
 */
const _execMapping = new Map([
    [TUnresolvedNode, _executeTAnyNode],
    [TResolvedNode, _executeTAnyNode],
    [TAction, _executeTAction],
    [TUnresolvedItem, _executeUnresolvedItem]
])

/**
 * @param {Executor} executor
 * @param {TAction|TAnyNode} anyParallelItem
 * @param {?TResolvedNode} parentNode
 * @return {Promise<void>|void}
 */
const _executeAny = (executor, anyParallelItem, parentNode = undefined) =>
    _execMapping.get(anyParallelItem.constructor)(executor, anyParallelItem, parentNode)

/**
 * @param {ExecuteActionFunc} executeActionFunc
 * @param {ResolveFunction} resolveFunc
 * @param {TransitionCancellationFunc} isTransitionCanceledFunc
 * @param {?NevigationDebugInfo} debugInfo
 * @return {Executor}
 */
const _createExecutor = (executeActionFunc, resolveFunc, isTransitionCanceledFunc, debugInfo) => {
    return ({
        executeAction: executeActionFunc,
        isTransitionCanceled: isTransitionCanceledFunc,
        resolve: resolveFunc,
        debugInfo: debugInfo,
    });
}

export const TreeExecutor = Object.freeze({
    /**
     * @param {ExecuteActionFunc} executeFunc
     * @param {ResolveFunction} resolveFunc
     * @param {TAnyNode} rootNode
     * @param {TransitionCancellationFunc} isTransitionCanceledFunc
     * @param {?NevigationDebugInfo} debugInfo
     */
    executeWholeTreeWith(executeFunc, resolveFunc, rootNode, isTransitionCanceledFunc, debugInfo) {
        return _executeAny(
            _createExecutor(executeFunc, resolveFunc, isTransitionCanceledFunc, debugInfo),
            rootNode
        )
    },

    PATH_CURRENT_TRANSITION_TIMESTAMP: ['__timestamp_start'],
})

export class TransitionCanceledError extends Error {
    constructor() {
        super();
        this.message = 'Transition was canceled'
    }
}