'use strict';

import {ExecutionLimiter} from "./ExecutionLimiter";
import {cloneState} from "../state/cloneState";

export class TItemResolverLimited {
    constructor() {
        this._tryResolveLimiter = new ExecutionLimiter(20)
        this._cycleLimiter = new ExecutionLimiter(5)
        /** @type {?NavigationState} */
        this._cycle = -1
        this.resolved = 0
        this.scheduled = 0
    }

    startCycle() {
        this._cycleLimiter.inc()
        this._cycle += 1
        this.scheduled = 0
    }

    /**
     * @param  {{string:NavigationState}} resolvedStatesStore
     * @param {TUnresolvedItem|TUnresolvedNode} item
     * @return {TUnresolvedItem|TAnyNode|TAction}
     */
    tryResolve(resolvedStatesStore, item) {
        this._tryResolveLimiter.inc()

        if (!_areAllKeysPresent(item.dependencies, resolvedStatesStore)) {
            this.scheduled += 1
            return item
        }

        this.resolved += 1

        return item.resolveFunc(_filterObjectByKeys(resolvedStatesStore, item.dependencies))
    }
}


const _areAllKeysPresent = (keysArray, object) => {
    const objectKeys = Object.keys(object);
    return keysArray.every(key => objectKeys.includes(key));
};

const _filterObjectByKeys = (object, keysArray) => Object.fromEntries(
    Object.entries(object).filter(([key, value]) => keysArray.includes(key))
);