'use strict'

import {initInScope as initButtons} from "../../../../scripts/langModal";

const SELECTORS = {
    modal : '.modal--lang .langlist',
    submenu : '.mobile-nav__footer-languages-list',
}

export function copyLangModalToMenucard(targetContainer) {
    const modal = document.querySelector(SELECTORS.modal)
    const menucard = targetContainer.querySelector(':scope ' + SELECTORS.submenu)

    if (!menucard || menucard.hasChildNodes()) {
        return;
    }

    menucard.appendChild(modal.cloneNode(true))
    initButtons(menucard.parentElement)
}