import {CSSTransitionsHelper} from "../../../../../../../../shared/events/transitions/CSSTransitionsHelper";

const TRANS_STATES = {
    INACTIVE: 'inactive',
    ACTIVATING: 'activating',
    ACTIVE: 'active',
    DEACTIVATING: 'deactivating',
}

const DISPLAY_CLASS = 'display-on'

const _getBaseConfig = (overlayElement, useTransition, isTransitionCanceled) => ({
    useTransition,
    isCanceledFunc: isTransitionCanceled,
    clearCssClasses: Object.values(TRANS_STATES),
    transitioningElement: overlayElement,
    stateElement: overlayElement,
})


const _buildActivateConfig = (overlayElement, useTransition, isTransitionCanceled) => ({
    ..._getBaseConfig(overlayElement, useTransition, isTransitionCanceled), ...{
        setDisplayCssClass: DISPLAY_CLASS,
        beginTransCssClass: TRANS_STATES.ACTIVATING,
        endTransCssClass: TRANS_STATES.ACTIVE,
    }
})

const _buildDeactivateConfig = (overlayElement, useTransition, isTransitionCanceled) => ({
    ..._getBaseConfig(overlayElement, useTransition, isTransitionCanceled), ...{
        unsetDisplayCssClass: DISPLAY_CLASS,
        beginTransCssClass: TRANS_STATES.DEACTIVATING,
        endTransCssClass: TRANS_STATES.INACTIVE,
    }
})


export const Tr_Overlay = Object.freeze({

    INITIAL_STATE: TRANS_STATES.INACTIVE,

    /** @type {TransitionInfo} */
    show: ({
        /**
         * @param {HTMLElement} overlayElement
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (overlayElement, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_buildActivateConfig(overlayElement, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.ACTIVATING,
        endState: TRANS_STATES.ACTIVE,
    }),


    /** @type {TransitionInfo} */
    hide: ({
        /**
         * @param {HTMLElement} overlayElement
         * @param {boolean} useTransition
         * @param {TransitionCancellationFunc} isTransitionCanceled
         * @return {Promise}
         */
        func: (overlayElement, useTransition, isTransitionCanceled) =>
            CSSTransitionsHelper.execute(_buildDeactivateConfig(overlayElement, useTransition, isTransitionCanceled)),
        startState: TRANS_STATES.DEACTIVATING,
        endState: TRANS_STATES.INACTIVE,
    }),

})