'use strict'
import { throttle } from 'throttle-debounce';

const sliderOptions = {
    draggable: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
}

export function initInScope($scope) {
    const
        $teaserGrids = $scope.find('.js-teaser-grid');

    $teaserGrids.each((_,el) => {
        const $container = $(el),
            $slider = $container.find('.js-teaser-grid__slider')

        if($slider.length){
            initGridSlider($container, $slider);
        }
    })
}

function initGridSlider($container, $slider) {
    $(window).on('load resize orientationchange', debouncedResizeHander($container, $slider));

    if(window.innerWidth > 767){
        return;
    }
    $slider.slick({
        ...sliderOptions,
        nextArrow: $container.find('.js-teaser-grid__slider-next'),
        prevArrow: $container.find('.js-teaser-grid__slider-prev'),
        adaptiveHeight: true
    });
}

function debouncedResizeHander($container, $slider) {
    return throttle(300, () => resizeHandler($container, $slider))
}

function resizeHandler($container, $slider){
    if(window.innerWidth > 767) {
        if ($slider.hasClass('slick-initialized')) {
            $slider.slick('unslick');
        }
        return
    }

    if(!!$container.data('mobile-slider')){
        if (!$slider.hasClass('slick-initialized')) {
            return $slider.slick({
                ...sliderOptions,
                nextArrow: $container.find('.js-teaser-grid__slider-next'),
                prevArrow: $container.find('.js-teaser-grid__slider-prev'),
            });
        }
    }else{
        if ($slider.hasClass('slick-initialized')) {
            $slider.slick('unslick');
        }
    }
}
