'use strict'

import Splide from '@splidejs/splide';

const SELECTOR = {
    compareTable: '.js-content_compare__table',
    autoHeightElements: ['perfectFor', 'topFeatures', 'allDetails', 'ventilationOptions']
};

export function init() {

    document.querySelectorAll(SELECTOR.compareTable).forEach(sliderElement => {
        const splide = new Splide(sliderElement, {
            fixedWidth: '260px',
            perMove: 1,
            omitEnd: true,
            pagination: false,
            arrows: true,
            arrowPath: 'm29.646 19.646-19 19M30.354 19.646l-19-19',
            breakpoints: {
                1024: {
                    fixedWidth: '150px',
                    arrows: false,
                },
            }
        })

        splide.on('mounted resized', function () {
            setAutoHeight()
        });


        splide.mount()
    })
}

function setAutoHeight() {

    SELECTOR.autoHeightElements.forEach(e => {
        let autoHeights = []
        const rowEl = document.querySelector(`#${e}Row`)
        if (rowEl) {
            const autoHeightCell = document.querySelectorAll(`.${e}Cell`);
            autoHeightCell.forEach(el => {
                el.style.minHeight = 'auto'
                const elMinHeight = parseInt(el.offsetHeight);
                autoHeights.push(elMinHeight < 75 ? 75 : elMinHeight )
            })

            let minHeight = Math.max(...autoHeights)
            if (matchMedia('(max-width: 767px)').matches) {
                minHeight >= 75 && e !== 'allDetails' ? minHeight += 22 : minHeight
            }

            autoHeightCell.forEach(el => {
                el.style.minHeight = minHeight + 'px'
            })
            rowEl.style.minHeight = minHeight + 'px'
        }
    })
}

