/**
 * @typedef {Object} TransitionConfig

 * @property {string} beginTransCssClass - css class which is set when the transition starts
 * @property {string} endTransCssClass - css class which is set when transition has ended
 * @property {string[]} clearCssClasses - css classes to be removed when the transition starts (classes from beginTransCssClass are ignored),
 *
 * @property {undefined|function} beginTransFunction - an optional function which is called to start the transition, e.g. if the transition is started directly on the element instead of classes
 *
 * @property {undefined|string} setDisplayCssClass - css class which is set before the transition starts, to set up the display-property
 * @property {undefined|string} unsetDisplayCssClass - css class which is unset after the transition
 *
 * @property {boolean} [useTransition=true] - use css transition, otherwise go straight to the targetstate
 *
 * @property {HTMLElement} transitioningElement - element with the css transition (and transition eventlistener)
 * @property {HTMLElement} stateElement - the element holding the state (beginning, open, done etc.) of the transition
 *
 * @property {function:boolean} [isCanceledFunc=function():false]
 */


const DEBUG_LIST = [
    // el => el === document.getElementById('desknav-overlay') ? 'overlay' : undefined,
    // el => el.dataset.imageTilesId ? 'imagetile' : undefined,
]

/**
 * @param {TransitionConfig} config
 * @return {TransitionConfig}
 */
export const _completeConfig = (config) => {
    const clearCssClasses = config.clearCssClasses.filter(
        cssClass => ![config.beginTransCssClass, config.endTransCssClass].includes(cssClass)
    )

    /** @type TransitionConfig */
    return {
        ...config,
        clearCssClasses: clearCssClasses,
        isCanceledFunc: typeof config.isCanceledFunc === 'function' ? config.isCanceledFunc : () => false,
        useTransition: typeof config.useTransition !== 'undefined' ? config.useTransition : true,
        beginTransFunction: config.beginTransFunction ? config.beginTransFunction : () => {
        }
    }
}

export const _setCssBeginClasses = (conf) => {
    conf.stateElement.classList.remove(...conf.clearCssClasses, conf.endTransCssClass)
    conf.stateElement.classList.add(conf.beginTransCssClass)
}

/**
 * @param {TransitionConfig} conf
 */
export const _setCssEndClasses = (conf) => {
    const stateElement = conf.stateElement
    stateElement.classList.remove(...conf.clearCssClasses, conf.beginTransCssClass)
    stateElement.classList.add(conf.endTransCssClass)
}

export const hasElementAppliedTransitions = element => {
    return getComputedStyle(element).transition !== 'all 0s ease 0s';
};

export const getElementsStyleTransitionProperty = element => element.style.getPropertyValue('transition');
export const getElementsStyleTransitionPriority = element => element.style.getPropertyPriority('transition');
export const setElementsStyleTransitionProperty = (element, value, priority) => element.style.setProperty('transition', value, priority);

export const maybeSetCssDisplayClass = async (conf) => {
    if (!conf.setDisplayCssClass) {
        return
    }

    conf.transitioningElement.classList.add(conf.setDisplayCssClass)
    await wait(10)
}
/**
 * @param {TransitionConfig} conf
 */
export const maybeUnsetCssDisplayClass = (conf) => {
    if (!conf.unsetDisplayCssClass) {
        return
    }
    conf.stateElement.classList.remove(conf.unsetDisplayCssClass)
}

export const wait = (duration) => new Promise((resolve) => setTimeout(() => resolve(), duration))

/**
 * @param {HTMLElement} element
 * @return {int} in ms
 */
export const findMaxTransitionDurationInElement = (element) => {
    /* transitionDuration is always in seconds */
    /** @type {int[]} */
    const transitionDurations = getComputedStyle(element).transitionDuration.split(',').map(
        duration => parseFloat(duration) * 1000 || 0
    );

    return Math.max(...transitionDurations);
}

/**
 * @param {TransitionConfig} conf
 * @param rest
 */
export const debugCssElementFunc = (conf, ...rest) => {
    const name = debugFindElementName(conf)
    if (!name) {
        return
    }
    console.log(name + ':\t', conf.beginTransCssClass, '->', conf.endTransCssClass, conf.transitioningElement, ...rest)
}

/**
 * @param {TransitionConfig} conf
 * @return {?string}
 */
export const debugFindElementName = (conf) => {
    try {
        const found = DEBUG_LIST.find(fn => fn(conf.transitioningElement));
        if (found) {
            return found(conf.transitioningElement)
        }
    } catch (e) {
        return undefined
    }
}