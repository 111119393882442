import { debounce } from 'throttle-debounce';

export function initInScope($scope) {
    $scope.find('.js-expandable').each(function () {
        let $expandable = $(this);
        let $collapse = $expandable.find('.js-expandable__collapse');
        let $toggle = $expandable.find('.js-expandable__toggle');
        let interactionOccurred = false;

        if (!$collapse.length || !$toggle.length) {
            disableExpandable({$collapse, $toggle});
            return;
        }

        $collapse.collapse({
            toggle: false
        });

        setExpandabelState({$collapse, $toggle, $expandable});

        // make sure the size is calculated correctly after the css is applied.
        // There is currently no callback that is triggered when the css is applied.
        let timeout = setTimeout(function () {
            if (!interactionOccurred) {
                setExpandabelState({$collapse, $toggle, $expandable});
            }

            clearTimeout(timeout);
        }, 2000);

        $toggle.on('click', function () {
            interactionOccurred = true;
            if ($collapse.hasClass('show')) {
                $toggle.addClass('collapsed')
                    .attr('aria-expanded', "false");

                $collapse.collapse('hide');
            } else {
                $toggle.removeClass('collapsed')
                    .attr('aria-expanded', "true");

                $collapse.collapse('show');
            }
        });

        // prevent any double click shenanigans
        $collapse.on('show.bs.collapse hide.bs.collapse', () => {
            $toggle.attr('disabled', 'disabled');
        });

        $collapse.on('shown.bs.collapse hidden.bs.collapse', () => {
            $toggle.attr('disabled', null);

            if ($(document.body).hasClass('os-mac')) {
                /* force re-layout due to strange Safari rendering bug that causes problems with "Umblaut" after the expandable area*/
                $collapse.css('display', 'none').offset();
                $collapse.css('display', 'block');
            }
        });

        // recalculate on window resize
        let previousWidth = window.innerWidth;
        $(window).on('resize', debounce(500,function () {
            if (window.innerWidth !== previousWidth) {
                /* because a height resize is triggered on mobile devices after when the browser bar appears / disappears*/
                setExpandabelState({$collapse, $toggle, $expandable});
                previousWidth = window.innerWidth;
            }
        }, ))
    })
}

function setExpandabelState({$collapse, $expandable, $toggle}) {
    $collapse.addClass('show');
    let baseHeight = $expandable.height();
    let collapseContentHeight = $collapse.height();
    let toggleHeight = $toggle.outerHeight(true);
    $collapse.removeClass('show');
    $collapse[0].style.cssText = '';
    let collapsedHeight = $expandable.height();

    if (matchMedia('(max-width: 767px)').matches) {
        // mobile
        let height = +$collapse.data('expandable-height-mobile');
        if (height && collapseContentHeight > height) {
            enableExpandable({$collapse, $toggle, height: height});
        } else {
            disableExpandable({$collapse, $toggle});
        }
    } else {
        // desktop
        let fixedCollapseHeight = +$collapse.data('expandable-height');

        if (fixedCollapseHeight) {
            if ((fixedCollapseHeight + toggleHeight) < baseHeight) {
                enableExpandable({$collapse, $toggle, height: fixedCollapseHeight});
            } else {
                disableExpandable({$collapse, $toggle});
            }
        }
        else {
            if (collapsedHeight < baseHeight) {
                enableExpandable({$collapse, $toggle, height: collapsedHeight - toggleHeight});
            } else {
                disableExpandable({$collapse, $toggle});
            }

        }
    }
}

function disableExpandable({$collapse, $toggle}) {
    $collapse.addClass('show');
    $toggle.attr('hidden', 'hidden');
}

function enableExpandable({$collapse, $toggle, height}) {
    $collapse[0].style.cssText = `
        display: block !important;
        min-height: ${height}px !important;
    `;
    $toggle.attr('hidden', null)
        .addClass('collapsed')
        .attr('aria-expanded', "false");
}