export function initInScope($scope){
    $scope.find('.js-deselectable-radio').on('click', function (evt) {
        let $radio = $(this).find(':input');

        if ($radio.is(':checked')) {
            $radio.prop('checked', false);
        } else {
            $radio.prop('checked', true);
        }

        evt.stopPropagation();
        evt.preventDefault();
    });
}