import {ExecutionLimiter} from "./ExecutionLimiter";

/**
 * only allow x actions to prevent infinite loops (should only happen while setting up the configuration)
 */
export class TreeActionExecutionLimited {
    constructor() {
        this._limiter = new ExecutionLimiter(80)
    }

    executeAction(func) {
        this._limiter.inc()
        return func()
    }
}