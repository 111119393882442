"use strict";

import fetch from "../libs/@elements/fetch"
import { addLoadingContainer } from "./loading";

export function initInScope ($scope) {
    let $paymentPending = $scope.find('.js-payment-pending');

    addLoadingContainer($scope.find('.js-payment-pending__loading-container'), [], false);

    let failedRequests = 0;
    let requestCount = 0;

    let url = $paymentPending.data('url');

    let checkPending = function (timeout) {
        if(requestCount > 20) {
            return
        }

        setTimeout(
            function () {
                requestCount++;

                let pendingRequest = fetch(url);
                pendingRequest
                    .then((res) => {
                        return res.clone().json()
                    }).then((res) => {

                    if (res.url) {
                        location.href = res.url;
                    } else {
                        checkPending((requestCount + 1) * 1000);
                    }
                }).catch((error) => {
                    failedRequests++;
                    if(failedRequests < 3) {
                        checkPending((requestCount + 1) * 1000);
                    }
                });
            }, timeout);

    };


    checkPending(2000);
}